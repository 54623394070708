import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { UserService } from '../user.service';
import { StatusService } from './status.service';
import { Router } from '@angular/router';
import { AlertService } from '../alert.service';
import { ServicesService } from './services.service';
import { SocketService } from '../socket.service';
import { UiService } from '../ui.service';
import { MapService } from '../map.service';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class EstimateService {
	public estimate:any = {};
	public estimate_editing:any = {};
	initialize(estimate){
		estimate.estimate_number = Number(estimate.estimate_number);
		estimate.markup_rate = Number(estimate.markup_rate);
		estimate.markup_amount = Number(estimate.markup_amount);
		estimate.total = Number(estimate.total);
		estimate.total_due = Number(estimate.total_due);
		estimate.date = new Date(estimate.date);
		estimate.expire_date = new Date(estimate.expire_date);
		if(!Array.isArray(estimate.services)||!estimate.services){
			estimate.services = [];
		}
		if(estimate.discount && typeof estimate.discount == 'object'){
			if(estimate.discount.percent){
				estimate.discount = estimate.discount.percent;
				estimate.discount_type = '%';
			}else{
				estimate.discount = estimate.discount.amount;
			}
		}
		for(let i = 0; i < estimate.services.length; i++){
			if(estimate.services[i].fields){
				for(let j = 0; j < estimate.services[i].fields.length; j++){
					estimate.services[i].fields[j].field_id = estimate.services[i].fields[j].pivot.field_id;
					estimate.services[i].fields[j].value = estimate.services[i].fields[j].pivot.value;
				}
			}
			if(estimate.services[i].tax){
				estimate.services[i].tax.rate = Number(estimate.services[i].tax.value);
			}
			if(estimate.services[i].discount && typeof estimate.services[i].discount == 'object'){
				if(estimate.services[i].discount.percent){
					estimate.services[i].discount = estimate.services[i].discount.percent;
					estimate.services[i].discount_type = '%';
				}else{
					estimate.services[i].discount = estimate.services[i].discount.amount;
				}
			}
			this.ss.initialize(estimate.services[i]);
		}
		this.calculate(estimate);
		this.update_estimate(estimate);

	}
	fetch_by_project(projectId, cb=estimate=>{}, cb_error=error=>{}){
		this.http.c_fetch('estimates', {
			project_id: projectId
		}, estimate=>{
			this.initialize(estimate);
			this.estimate = estimate;
			cb(estimate);
		}, cb_error);
	}
	fix_service_id(services){
		for(let i = 0; i < services.length; i++){
			services[i].offers_service_id = services[i].id;
			delete services[i].id;
		}
		return services;
	}
	create(cb = resp=>{}, estimate={}){
		this.http.c_create('estimates', estimate, created => {
			created.user_id = this.us.me.id;
			created.date = new Date();
			created.expire_date = new Date(created.date.getTime()+2592000000);
			created.estimate_number_prefix = 'EST-';
			created.estimate_number = 0;
			this.update(created);
			cb(created);
		});
	}
	clone(redirect=false, estimate = this.estimate){
		let clone = Object.assign({}, estimate);
		delete clone.id;
		clone.status_id = 1;
		this.http.c_create('estimates', this.to(clone), created => {
			this.socket.emit('estimate_refresh', created);
			if(redirect){
				this.router.navigate(['/new/quote']);
			}
		});
	}
	/*
	*	General Use
	*/
		public now = new Date().getTime();
		refresh(){
			this.now = new Date().getTime();
		}
		calculate(estimate=this.estimate){
			this.http.afterWhile(this, ()=>{
				estimate.cost = 0;
				estimate.hours = 0;
				let work_hours = 0;
				for(let i = 0; i < estimate.services.length; i++){
					if(estimate.services[i].status==0) continue;
					if(!isNaN(estimate.services[i].hours)){
						estimate.hours += Number( estimate.services[i].hours );
						work_hours += Number( estimate.services[i].work_hours );
					}
					estimate.cost += Number( estimate.services[i].price || 0 );
				}
				if( estimate.travel_time_status && estimate.travel_time_status != (estimate.address+work_hours) ){
					estimate.travel_time_status = estimate.address+work_hours;
					return this.map.calculate(estimate.lat, estimate.lng, (time:any)=>{
						let full_days = Math.floor(work_hours / 10);
						let remainder_day = work_hours % 10;
						if(full_days){
							time += time * full_days;
						}
						if(remainder_day > 5 && remainder_day < 10) {
							time += time;
						}
						if(remainder_day <= 5 && remainder_day >= 3) {
							time += time / 1.5;
						}
						if(remainder_day < 3 && remainder_day >= 2) {
							time += time / 2;
						}
						if(remainder_day < 2 && remainder_day > 0) {
							time += time / 2.5;
						}
						if(!full_days && remainder_day < 2 && remainder_day >= 0) {
							time += time / 2.5;
						}
						time = Math.floor(time*100)/100;
						for(let i = 0; i < estimate.services.length; i++){
							estimate.services[i].extra_hours = time;
							this.ss.calculate(estimate.services[i]);
						}
						this.calculate(estimate);
					});
				}
				if(!estimate.travel_time_status) estimate.travel_time_status = estimate.address+work_hours;
				if(!estimate.products) estimate.products=[];
				for(let i = 0; i < estimate.products.length; i++){
					if(!estimate.products[i].quantity){
						estimate.products[i].quantity = 0;
					}
					if(estimate.products[i].price && estimate.products[i].quantity){
						estimate.cost += Number(estimate.products[i].price) * Number(estimate.products[i].quantity);
					}
				}
				if(estimate.products){
					for(let i = 0; i < estimate.products.length; i++){
						if(estimate.products[i].price) estimate.cost += Number(estimate.products[i].price);
					}
				}
				/*estimate.price = */estimate.cost = Math.round(estimate.cost*100)/100;
				/*
				estimate.price = estimate.cost = Math.round(estimate.cost*100)/100;
				if(estimate.discount && (!estimate.discount_type || estimate.discount_type == '$')){
					estimate.price -= estimate.discount;
				}else if(estimate.discount){
					if(estimate.discount>=100)  estimate.price=0;
					else estimate.price *= (100 - estimate.discount)/100;
				}
				if(estimate.tax && typeof estimate.tax == 'object'){
					let rate = Number(estimate.tax.value||estimate.tax.rate) || 0;
					estimate.tax_amount = (estimate.price * (rate / 100)).toFixed(2);
					estimate.price *= (rate / 100) + 1;
				}
				estimate.price = Math.round(estimate.price * 100)/100;
				*/
				if(!estimate.id){
					this.http.apipost('/estimates/math', estimate, resp=>{
						estimate.math = resp.math;
					});
				}
			}, 250);
		}
		private addDiscount(service, obj){
			let discount:any = {};
			if(service.discount_type=='%'){
				discount.percent = service.discount;
				discount.amount = Number(service.tprice || service.price) * service.discount / 100;
			}else{
				discount.amount = service.discount || 0;
				if(discount.amount && typeof discount.amount == 'object'){
					discount.amount = discount.amount.amount || 0;
				}
			}
			if(discount.amount){
				obj.discount = discount;
			}else{
				obj.discount = {
					amount: 0
				}
			}
			return obj;
		}
		private addTax(service, obj){
			if(!obj.id) delete obj.id;
			if(service.tax){
				if(service.tax.value) service.tax.rate = service.tax.value;
				obj.tax_id = service.tax.id || null;
				obj.tax_rate = service.tax.rate || null;
				obj.tax_amount = Number(service.tprice || service.price) * service.tax.rate / 100;
				if(!obj.tax_amount) obj.tax_amount = 0;
			}else{
				obj.tax_id = service.tax_id || null;
				obj.tax_rate = service.tax_rate || null;
				obj.tax_amount = service.tax_amount || 0;
			}
			return obj;
		}
		to_services(services){
			return this.http.to_id(services, service=>{
				return this.addDiscount(service, /*this.addTax(service, */{
					id: service.id,
					status: service.status,
					offers_service_id: service.offers_service_id || 0,
					description: service.description || '',
					work_hours: service.work_hours || 0,
					extra_hours: service.extra_hours || 0,
					price: service.price || service.cprice || 0,
					markup_rate: service.markup_rate || 0,
					markup_amount: service.markup_amount || 0,
					fields: this.http.to_id(service.fields, field=>{
						return {
							field_id: field.id || field.field_id,
							value: field.value 
						};
					}),
					specialists: this.http.to_id(service.specialists, specialist=>{
						return {
							offers_specialist_id: specialist.id,
							price: specialist.price
						};
					}),
					setups: this.http.to_id(service.setups, setup=>{
						if(!setup) setup={equipment:{}};
						return {
							equipment: {
								offers_equipment_id: setup.equipment.id,
								price_type: setup.price_type || 'hourly',
								price: setup.equipment.price,
							},
							attachments: this.http.to_id(setup.attachments, attachment=>{
								return {
									offers_attachment_id: attachment.id,
									price_type: attachment.pivot.price_type || 'hourly',
									price: attachment.price,
								}
							})
						};
					})
				}/*)*/);
			})
		}
		custom(estimate, obj){
			if(estimate.estimate_number)
				obj.estimate_number = estimate.estimate_number;
			return obj;
		}
		to(estimate){
			return this.addTax(estimate, this.addDiscount(estimate, this.custom(estimate, {
				id: estimate.id,
				estimate_number_prefix: estimate.estimate_number_prefix || '',
				markup_rate: estimate.markup_rate || 0,
				markup_amount: estimate.markup_amount || 0,
				total: estimate.total || 0,

				price: estimate.price || 0,

				total_due: estimate.total_due || 0,
				status_id: estimate.status_id,
				tags: this.http.to_id(estimate.tags),
				// Details
				admin_note: estimate.admin_note || '',
				user_id: estimate.user_id || null,
				expire_date: estimate.expire_date || '',
				date: estimate.date || '',
				// Client
				client_note: estimate.client_note || '',
				client_id: estimate.client_id || null,
				address: estimate.address || '',
				city: estimate.city || '',
				zip: estimate.zip || '',
				state: estimate.state || '',
				country: estimate.country || '',
				lat: estimate.lat || '',
				lng: estimate.lng || '',
				contact_email_id: estimate.contact_email_id || '',
				contact_phone_id: estimate.contact_phone_id || '',
				// Services
				services: this.to_services(estimate.services)
			}) ) );
		}
	/*
	*	General Pull
	*/
	private used = false;
	private data:any = {};
	offers(kind, cb){
		if(this.data[kind]) return cb(this.data[kind]);
		if(this.used){
			return setTimeout(()=>{
				this.offers(kind, cb);
			}, 300);
		}
		this.used = true;
		this.http.c_getAll('estimates/offers', resp => {
			this.data = resp;
			cb(this.data[kind]);
		});
	}
	/*
	*	Show Estimates
	*/
	public estimates:any = [];
	public refill: any;
	public search = '';
	public page = 1;
	public total: any;

	public sortBy = localStorage.getItem('estimateSortBy') || 'estimate_number';
	public sort = localStorage.getItem('estimateSort') || 'asc';
	setSortBy(sortBy){
		this.sortBy = sortBy.active;
		this.sort = sortBy.direction;
		localStorage.setItem('estimateSortBy', this.sortBy);
		localStorage.setItem('estimateSort', this.sort);
		this.load();
	}
	paginate(event){
		this.page = event.pageIndex+1;
		this.ui.setPerPage(event.pageSize);
		this.load();
	}

	public make_filters = false;
	public filters: any = {};
	public filtersApplied: any = {};
	public types = [];
	public tags = [];
	load(statuses?){
		this.http.afterWhile(this, ()=>{
			for(let key in this.filters){
				if(!this.filters[key]) delete this.filters[key];
			}
			this.filtersApplied = Object.assign({}, this.filters);
			let opts:any = {
				perPage: this.ui.perPage,
				page: this.page,
				sortBy: this.sortBy,
				sort: this.sort
			}
			if(this.search) opts.search = this.search;
			for(let each in this.filters){
				if(each=='date_from'||each=='date_to'||each=='expire_date_from'||each=='expire_date_to'){
					opts['filters['+each+']']=moment(this.filters[each]).format('YYYY-MM-DD');
				}else opts['filters['+each+']']=this.filters[each];
			}
			if(statuses&&statuses.length){
				for(let i = 0; i < statuses.length; i++){
					opts['filters[status_id]['+i+']'] = statuses[i].id;
				}
			}else if(this.sts.quotes_statuses.length){
				for(let i = 0; i < this.sts.quotes_statuses.length; i++){
					opts['filters[status_id]['+i+']'] = this.sts.quotes_statuses[i].id;
				}
			}else return;
			if(this.types.length && this.types.length!=3){
				for(let i = 0; i < this.types.length; i++){
					opts['filters[type]['+i+']'] = this.types[i].title;
				}
			}
			if(this.tags.length && this.tags.length!=3){
				for(let i = 0; i < this.tags.length; i++){
					opts['filters_ids[tags]['+i+']'] = this.tags[i].id;
				}
			}
			this.http.c_get('estimates', resp => {
				this.estimates = resp.data;
				for(let i = 0; i < this.estimates.length; i++){
					this.estimates[i]._status_id = this.estimates[i].status_id;
				}
				this.total = resp.total;
				if(typeof this.refill == 'function') this.refill();
			}, opts);
		}, 200);
	}

	fieldsData: Array<any> = [];
	chosenFields: Array<any> = [];
	filters_ids: any = {};
	address: any = '';
	loadFilters(test){}

	loadAfterWhile(){
		this.http.afterWhile(this, this.load.bind(this), 2000);
	}
	/*
	*	CRUD
	*/
	update_estimate(estimate){
		this.estimate.math = estimate.math;
		// if(estimate.math && this.estimate.id == estimate.id){
		// 	this.estimate.cost = estimate.math.total_for_estimate;
		// 	this.estimate.price = estimate.math.total;
		// 	this.estimate.totaldue = estimate.math.total_due;
		// }
	}
	update(estimate=this.estimate, cb = (resp:any)=>{
		for(let i = 0; i < resp.services.length; i++){
			this.estimate.services[i].id = resp.services[i].id;
		}
	}){
		this.http.afterWhile(estimate, ()=>{
			this.http.c_update('estimates', this.to(estimate), resp=>{
				this.update_estimate(resp);
				this.socket.emit('estimate_refresh', resp);
				cb(resp);
			});
		});
	}

	update_services(estimate=this.estimate, cb = (resp:any)=>{
		for(let i = 0; i < resp.services.length; i++){
			estimate.services[i].id = resp.services[i].id;
		}
	}){
		this.http.afterWhile(estimate, ()=>{
			this.http.c_update('estimates', {
				services: this.to_services(estimate.services),
				id: estimate.id
			}, resp=>{
				this.update_estimate(resp);
				this.socket.emit('estimate_refresh', resp);
				cb(resp);
			});
		});
	};

	to_products(estimate){
		return this.http.to_id(estimate.products, (product)=>{
			let id = product.id;
			product = {
				offers_product_id: product.offers_product_id,
				description: product.description,
				unit_price: product.unit_price,
				amount: product.amount,
				price: product.price
			};
			if(id) product.id = id;
			return product;
		});
	}
	update_products(estimate=this.estimate, cb = (resp:any)=>{
		for(let i = 0; i < estimate.products.length; i++){
			estimate.products[i].id = resp.products[i].id;
		}
	}){
		this.http.afterWhile(estimate, ()=>{
			this.http.c_update('estimates', {
				products: this.to_products(estimate),
				id: estimate.id
			}, resp=>{
				this.socket.emit('estimate_refresh', resp);
				this.update_estimate(resp);
				cb(resp);
			});
		});
	};

	private timeout = {};
	save(estimate, cb=()=>{}, ignore={}){
		if(!estimate) return;
		let timeout = '';
		for(let each in estimate){
			timeout += each;
		}
		if(!estimate.id) estimate.id = this.estimate.id;
		clearTimeout(this.timeout[timeout]);
		this.timeout[timeout] = setTimeout(()=>{
			this.save_direct(estimate, cb, ignore);
		}, 2000);
	}
	save_direct(estimate, cb=()=>{}, ignore={}){
		this.http.c_update('estimates', estimate, resp=>{
			this.socket.emit('estimate_refresh', resp);
			this.update_estimate(resp);
			cb();
		}, ignore);
	}



	update_status(estimate=this.estimate, cb?:any){
		this.http.c_update('estimates', {
			status_id: estimate.status_id,
			reason_id: estimate.reason_id,
			id: estimate.id
		}, (resp)=>{
			this.socket.emit('estimate_refresh', resp);
			if(cb) cb();
		});
	}
	fetch(id, cb=resp=>{}){
		this.http.c_fetch('estimates', {
			id: id
		}, cb);
	}
	delete(id = this.estimate.id, cb=()=>{}){
		this.alert.yes('Are you sure that you want to delete this quote? This action will remove workorder and invoice if such exists.', () => {
			this.http.c_delete('estimates', {
				id: id
			}, this.load.bind(this));
			this.socket.emit('estimate_refresh', 'delete');
			cb();	
		});	
	}
	clean_files(fileId){
		if(!this.estimate.files) return;
		for(let i = this.estimate.files.length-1; i >= 0; i--){
			for(let j = this.estimate.services.length-1; j >= 0; j--){
				for(let k = this.estimate.services[j].files.length-1; k >= 0; k--){
					if(this.estimate.services[j].files[k].id == fileId){
						this.estimate.services[j].files.splice(i, 1);
						break;
					}
				}
			}
			if(this.estimate.files[i].id == fileId){
				this.estimate.files.splice(i, 1);
				break;
			}
		}
	}
	edit(_id, cb=resp=>{}){
		this.fetch(_id, doc=>{
			this.initialize(doc);
			this.estimate = doc;
			cb(this.estimate);
		});
	}
	/*
	*	Start
	*/
	public scrollable:any;
	constructor(private http: HttpService,
		private socket: SocketService,
		private sts: StatusService,
		private map: MapService,
		private ui: UiService,
		private ss: ServicesService,
		private router: Router,
		private alert: AlertService,
		private us: UserService) {
		this.offers('services', services=>{
			for(let i = 0; i < services.length; i++){
				if(!services[i].order) services[i].order = i;
				this.ss.calculate(services[i]);
			}
			this.ss.services = services;
			this.http.set('services_loaded');
		});
	}
}
