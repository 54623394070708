import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostponeComponent } from './Equipment/Postpone/postpone/postpone.component';
import { CompletionComponent } from './Equipment/Completion/completion/completion.component';
import { EditRepairComponent } from './Equipment/Repair/editrepair/editrepair.component';
import { EditServiceComponent } from './Equipment/Service/editservice/editservice.component';
import { CustomMaterialModule } from '@root/modules/template';
import { ContactComponent } from './Client/contact/contact.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicMasksModule, DatepickerModule } from '@common';
import { NgxMaskModule } from 'ngx-mask';
import { SharedPipes } from '@pipes';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddComponent } from './Equipment/Status/add/add.component';
import { DeleteComponent } from './Equipment/Status/delete/delete.component';
import { SettingsComponent } from './Equipment/Service/settings/settings.component';
import { EditReminderComponent } from './Equipment/Service/editreminder/editreminder.component';
@NgModule({
	declarations: [PostponeComponent, CompletionComponent, ContactComponent, EditRepairComponent, EditServiceComponent, AddComponent, DeleteComponent, SettingsComponent, EditReminderComponent],
	entryComponents: [PostponeComponent, CompletionComponent, ContactComponent, EditRepairComponent, EditServiceComponent, AddComponent, DeleteComponent, SettingsComponent, EditReminderComponent],
	exports: [PostponeComponent, CompletionComponent, ContactComponent, EditRepairComponent, EditServiceComponent, AddComponent, DeleteComponent, SettingsComponent, EditReminderComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		FormsModule,
		ReactiveFormsModule,
		DynamicMasksModule,
		NgxMaskModule,
		DatepickerModule,
		SharedPipes,
		NgMultiSelectDropDownModule,
		CustomMaterialModule
	]
})
export class ModalsModule { }
