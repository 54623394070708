import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '@services';

@Component({
	selector: 'actClientCreate',
	inputs: ['activity'],
	templateUrl: './client-create.component.html',
	styleUrls: ['./client-create.component.scss']
})
export class ClientCreateComponent implements OnInit {
	@Input ('activity') activity;
	constructor(public us: UserService) {}
	ngOnInit() {}
}
