import { Component, ViewChild, ViewChildren, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ClientService,
	CustomFieldService,
	HttpService,
	UiService,
	TasksService,
	MatcherMail,
	Matcher,
	AlertService } from '@services';
@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss']
})
export class ContactComponent{
	public hide_lead = false;
	public services:any = [];
	public _services = {};
	public matcher:any;
	public matcherMail:any;
	public form:any={};
	constructor(private dialogRef: MatDialogRef<ContactComponent>,
		public http: HttpService,
		public cfs: CustomFieldService,
		public alert: AlertService,
		public ui: UiService,
		public ts: TasksService,
		@Inject(MAT_DIALOG_DATA) data,
		public cs: ClientService){
		this.matcher = new Matcher(this.form);
		this.matcherMail = new MatcherMail(this.form);
		if(data && data.hide_lead) this.hide_lead = true;
		this.ts.getServices().subscribe(services => {
			this.services = services;
		});
	}
	public formNextStep = false;
	public show_errors = false;
	public tags:any[] = [];
	public types: Array<any> = [
		{ name: 'Lead', value: 'lead' },
		{ name: 'Client', value: 'client' },
		{ name: 'Partner', value: 'partner' },
		{ name: 'Subcontractor', value: 'subcontractor' },
		{ name: 'Supplier', value: 'supplier' },
		{ name: 'Vendor', value: 'vendor' },
		{ name: 'Other', value: 'other' }
	];;
	public sources: Array<any> = [
		{ name: 'Website', value: 'website' },
		{ name: 'Google', value: 'google' },
		{ name: 'Facebook', value: 'facebook' },
		{ name: 'Truck Signage', value: 'truck signage' },
		{ name: 'Friend', value: 'friend' },
		{ name: 'Employee', value: 'employee' }
	];
	public clientClasses: Array<any> = [
		{ name: 'Main', value: 'Main' },
		{ name: 'Work', value: 'Work' },
		{ name: 'Mobile', value: 'Mobile' },
		{ name: 'Home', value: 'Home' },
		{ name: 'Other', value: 'Other' }
	];
	public client:any = {
		source: 'phone',
		type: 'personal',
		category: 'lead',
		tags: [],
		contact_information: [{
			name: '',
			emails: [{
				class: 'Main',
				value: '',
				primary: 1
			}],
			numbers: [{
				class: 'Main',
				value: '1',
				primary: 1
			}],
		}],
		lead: {}
	};
	ngOnInit() {
		this.dialogRef.disableClose = true;
		this.dialogRef.backdropClick().subscribe(_ => {
			this.alert.yes("Do you want to discard changes?", ()=>{
				this.dialogRef.close();
			})
		});
		this.ui.customFieldsPage='newContact'
	}
	public leadAddress = false;
	add_member(){
		this.client.contact_information.push({
			name: '',
			emails: [{
				class: 'Main'
			}],
			numbers: [{
				class: 'Main',
				value: '1'
			}],
		});
	}
	clear(which='emails'){
		for(let i = 0; i < this.client.contact_information.length; i++){
			for(let j = 0; j < this.client.contact_information[i][which].length; j++){
				this.client.contact_information[i][which][j].primary = 0;
			}
		}
	}
	ensure(){
		let number_exists = false, email_exists = false;
		for(let i = 0; i < this.client.contact_information.length; i++){
			for(let j = 0; j < this.client.contact_information[i].emails.length; j++){
				if(email_exists){
					break;
				}
				if(this.client.contact_information[i].emails[j].primary){
					email_exists = true;
				}
			}
			for(let j = 0; j < this.client.contact_information[i].numbers.length; j++){
				if(number_exists){
					break;
				}
				if(this.client.contact_information[i].numbers[j].primary){
					number_exists = true;
				}
			}
		}
		if(!email_exists){
			for(let i = 0; i < this.client.contact_information.length; i++){
				if(this.client.contact_information[i].emails.length){
					this.client.contact_information[i].emails[0].primary = 1;
					break;
				}
			}
		}
		if(!number_exists){
			for(let i = 0; i < this.client.contact_information.length; i++){
				if(this.client.contact_information[i].numbers.length){
					this.client.contact_information[i].numbers[0].primary = 1;
					break;
				}
			}
		}
		for(let i = 0; i < this.client.contact_information.length; i++){
			for(let j = 0; j < this.client.contact_information[i].emails.length; j++){
				if(!this.client.contact_information[i].emails[j].primary){
					this.client.contact_information[i].emails[j].primary = 0;
				}
			}
			for(let j = 0; j < this.client.contact_information[i].numbers.length; j++){
				if(!this.client.contact_information[i].numbers[j].primary){
					this.client.contact_information[i].numbers[j].primary = 0;
				}
			}
		}
		this.exists();
	}
	updateAddress(loc){
		this.http.to_address(this.client, loc);
	}
	updateLeadAddress(loc){
		this.http.to_address(this.client.lead, loc);
	}
	next(){
		this.show_errors = true;
		this.form.submited = true;
		for(let i = 0; i < this.phones.length; i++){
			if(this.phones._results[i].nativeElement.classList.value.indexOf('ng-invalid')>-1) return;
		}
		for(let i = 0; i < this.client.contact_information.length; i++){
			if(!this.client.contact_information[i].name) return;
			for(let j = 0; j < this.client.contact_information[i].emails.length; j++){
				if(!this.ui.isEmail(this.client.contact_information[i].emails[j].value)) return;
			}
			for(let j = 0; j < this.client.contact_information[i].numbers.length; j++){
				if(!this.ui.isPhone(this.client.contact_information[i].numbers[j].value)) return;
			}
		}
		if(!this.client.name) this.client.name = this.client.contact_information[0].name;
		this.show_errors = false;
		this.formNextStep = true;
		this.form.submited = false;
	}
	public fields = [];
	back(){
		this.formNextStep = false
		//this.customFields.saveValues();
		//this.fields = this.customFields.values;
	}
	create(){
		if(!this.leadAddress){
			this.updateLeadAddress(this.client);
		}
		let client = JSON.parse(JSON.stringify(this.client));
		this.show_errors = true;
		if(!client.name) return;
		//if(!this.customFields.valuesValidationCheck()) return;
		//this.customFields.saveValues();
		//client.fields = this.customFields.values;
		client.fields = [];
		if(!this.hide_lead && (client.category == 'lead' || client.category == 'client')){
			if(!this.client.lead.address) this.client.lead.address = this.client.lead.full_address;	
			client.lead.services_ids = [];
			for(let each in this._services){
				if(this._services[each]){
					client.lead.services_ids.push(each);
				}
			}
		}
		if(client.category != 'lead' && client.category != 'client'){
			client.source = 'Not needed';
		}
		client.tags = this.http.to_id(client.tags);
		client.lead.services_ids = [];
		for(let each in this._services){
			if(this._services[each]) client.lead.services_ids.push(each);
		}
		if(!this.client.address) this.client.address = this.client.full_address;	
		this.http.apipost('/clients/create', client, newClient => {
			this.dialogRef.close(newClient);
		}, resp=>{
			this.alert.fail(resp.statusText);
		});
	}
	close() {
		this.alert.yes("Do you want to discard changes?", ()=>{
			this.dialogRef.close();
		})
	}
	public exists_clients:any = [];
	public _exists_clients:any = {};
	private unique;
	exists(){
		this.http.afterWhile(this, ()=>{
			let phones, emails;
			for(let i = 0; i < this.client.contact_information.length; i++){
				for(let j = 0; j < this.client.contact_information[i].emails.length; j++){
					if(this.ui.isEmail(this.client.contact_information[i].emails[j].value)){
						if(!emails) emails=[];
						let email = this.client.contact_information[i].emails[j].value;
						emails.push(email);
					}
				}
				for(let j = 0; j < this.client.contact_information[i].numbers.length; j++){
					if(this.client.contact_information[i].numbers[j].value=='+1') continue;
					if(this.ui.isPhone(this.client.contact_information[i].numbers[j].value)){
						if(!phones) phones=[];
						let phone = this.client.contact_information[i].numbers[j].value;
						phones.push(phone);
					}
				}
			}
			if(!phones && !emails) return;
			let json:any = {}, unique = '';
			if(phones){
				json.phones = phones;
				for(let i = 0; i < phones.length; i++){
					unique += phones[i];
				}
			}
			if(emails){
				json.emails = emails;
				for(let i = 0; i < emails.length; i++){
					unique += emails[i];
				}
			}
			if(this.unique == unique) return;
			this.exists_clients = [];
			this.unique = unique;
			this._exists_clients = {};
			if(phones){
				for(let i = 0; i < phones.length; i++){
					this._exists_clients[phones[i]]=true;
				}
			}
			if(emails){
				for(let i = 0; i < emails.length; i++){
					this._exists_clients[emails[i]]=true;
				}
			}
			this.http.apipost('/clients/contact/exists', json, resp=>{
				this.exists_clients = resp;
			});
		}, 2000);
	}
	select(contact){
		this.dialogRef.close(contact);
	}
	@ViewChild('autosize', { static: false }) autosize: CdkTextareaAutosize;
	@ViewChild('customFields', { static: false }) customFields: any;
	@ViewChildren('phones') private phones:any;
}