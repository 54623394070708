import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { UserService } from '../user.service';
import { SocketService } from '../socket.service';
import { ConstructorService } from '../constructor.service';

@Injectable({
	providedIn: 'root'
})
export class StatusService {
	public statuses:any = {
		invoices: [],
		leads: [],
		quotes: [],
		workorders: []
	};
	public _statuses:any = {
		leads: {},
		quotes: {},
		workorders: {},
		invoices: {}
	};
	public _reasons:any = {};
	public kinds = [
		{
			value: 'leads',
			url: 'tasks/leads',
			name: 'Leads Statuses'
		}, {
			value: 'quotes',
			url: 'estimates',
			name: 'Quotes Statuses'
		}, {
			value: 'workorders',
			url: 'workorders',
			name: 'Workorders Statuses'
		}, {
			value: 'invoices',
			url: 'invoices',
			name: 'Invoices Statuses'
		}
	]
	public _kinds:any = {}
	public kind = this.kinds[0].value;
	public which = ['confirmed', 'declined', 'sent', 'default'];
	// for estimate list
	public refresh:any;
	public invoices_statuses:any = [];
	public _invoices_statuses:any = [];
	public leads_statuses:any = [];
	public _leads_statuses:any = [];
	public quotes_statuses:any = [];
	public _quotes_statuses:any = [];
	public workorders_statuses:any = [];
	public _workorders_statuses:any = [];
	public status:any = {
		invoices: {},
		leads: {},
		quotes: {},
		workorders: {}
	};
	public lead_quoted;

		public confirmedId;
	public load:boolean = false;
	constructor(private http: HttpService,
		private cs: ConstructorService,
		private socket: SocketService,
		private us: UserService) {
		this.quotes_statuses = this.selected_statuses.quotes_statuses||[];
		this.leads_statuses = this.selected_statuses.leads_statuses||[];
		this.invoices_statuses = this.selected_statuses.invoices_statuses||[];
		this.workorders_statuses = this.selected_statuses.workorders_statuses||[];
		this.us.base('statuses', resp=>{
			this.statuses = resp;
			for(let i = 0; i < this.statuses.leads.length; i++){
				if(this.statuses.leads[i].quoted){
					this.lead_quoted = this.statuses.leads[i];
					break;
				}
			}
			for(let i = 0; i < this.kinds.length; i++){
				this._kinds[this.kinds[i].value] = this.kinds[i];
				if(!this._statuses[this.kinds[i].value]){
					this._statuses[this.kinds[i].value] = {};
				}
				if(!this.statuses[this.kinds[i].value].length) continue;
				for(let j = 0; j < this.statuses[this.kinds[i].value].length; j++){
					this._statuses[this.kinds[i].value][this.statuses[this.kinds[i].value][j].id] = this.statuses[this.kinds[i].value][j];
					if(this.kinds[i].value=='quotes'&&this.statuses[this.kinds[i].value][j].declined){
						this.status[this.kinds[i].value] = this.statuses[this.kinds[i].value][j];
						for(let j = 0; j < this.status[this.kinds[i].value].reasons.length; j++){
							this._reasons[this.status[this.kinds[i].value].reasons[j].id] = this.status[this.kinds[i].value].reasons[j];
						}
					}
						if(this.kinds[i].value=='quotes'&&this.statuses[this.kinds[i].value][j].confirmed){
							this.confirmedId = this.statuses[this.kinds[i].value][j].id;
						}
				}
			}
			this.quotes_statuses = this.selected_statuses.quotes_statuses||[this._statuses.quotes[1], this._statuses.quotes[2]];
			this.leads_statuses = this.selected_statuses.leads_statuses||[this._statuses.leads[1], this._statuses.leads[2], this._statuses.leads[3], this._statuses.leads[4]];
			this.invoices_statuses = this.selected_statuses.invoices_statuses||[this._statuses.invoices[1], this._statuses.invoices[2]];
			this.workorders_statuses = this.selected_statuses.workorders_statuses||[this._statuses.workorders[1], this._statuses.workorders[2]];
			this.http.set('statuses');
			this.load = true;
			if(this.refresh) this.refresh();
		});
		this.http.on('constructors', ()=>{
			this.selected_statuses = localStorage.getItem('selected_statuses') && JSON.parse(localStorage.getItem('selected_statuses')) || {};
		});
		this.socket.on("statuses_refresh", obj=>{
			this.http.afterWhile(this, ()=>{
				if(obj.action=='create'){
					this.statuses[obj.kind].push(obj.status);
					if(this.refresh) this.refresh();
				}
				if(obj.action=='update'){
					for(let i = 0; i < this.statuses[obj.kind].length; i++){
						if(this.statuses[obj.kind][i].id == obj.status.id){
					 		this.statuses[obj.kind][i]=obj.status;
					 		if(this.refresh) this.refresh();
					 		break;
						}
					}
				}
				if(obj.action=='delete'){
					for(let i = 0; i < this.statuses[obj.kind].length; i++){
						if(this.statuses[obj.kind][i].id == obj.status.id){
							if(this.statuses[obj.kind][i].declined){
								this.status[obj.kind][i] = {id: null, reasons: []};//id: null by RG
							}
					 		this.statuses[obj.kind].splice(i, 1);
					 		if(this.refresh) this.refresh();
					 		break;
						}
					}
				}
				
			},2000);
		});
	}
	public selected_statuses = localStorage.getItem('selected_statuses') && JSON.parse(localStorage.getItem('selected_statuses')) || {};
	private label;
	update_selected_statuses(){
		let label = 'lead';
		this.selected_statuses.leads_statuses = this.leads_statuses;
		this.selected_statuses.quotes_statuses = this.quotes_statuses;
		this.selected_statuses.workorders_statuses = this.workorders_statuses;
		this.selected_statuses.invoices_statuses = this.invoices_statuses;
		localStorage.setItem('selected_statuses', JSON.stringify(this.selected_statuses));
		this.cs.save({
			slug: 'selected_statuses',
			module: 'common',
			page: 'common',
			user_id: true,
			schema: this.selected_statuses
		});
	}
	create(status){
		this.http.c_create(this._kinds[this.kind].url+'/statuses', status, created => {
			this.statuses[this.kind].push(created);
			if(this.refresh) this.refresh();
			this.socket.emit("statuses_refresh", {
				action: 'create',
				status: created,
				kind: this.kind
			});
		});
	}
	create_reasson(reason, status){
		this.http.c_create(this._kinds[this.kind].url+'/statuses/reasons', reason, created => {
			status.reasons.push(created);
		});
	}
	fetch(statusId){}
	update(status, cb=(resp)=>{}){
		this.http.afterWhile(status, ()=>{
			this.http.c_update(this._kinds[this.kind].url+'/statuses', this.to(status), resp=>{
				this.socket.emit("statuses_refresh", {
					action: 'update',
					status: resp,
					kind: this.kind
				});
				if(cb) cb(resp);
			});
		});
	}
	update_reason(reason, cb=()=>{}){
		this.http.afterWhile(reason, ()=>{
			this.http.c_update(this._kinds[this.kind].url+'/statuses/reasons', this.to_reason(reason), cb);
		});
	}
	delete(statusId){
		for(let i = 0; i < this.statuses[this.kind].length; i++){
			if(this.statuses[this.kind][i].id == statusId){
				if(this.statuses[this.kind][i].declined){
					this.status[this.kind] = {id: null, reasons: []};//id: null by RG
				}
		 		this.statuses[this.kind].splice(i, 1);
		 		break;
			}
		}
		if(this.refresh) this.refresh();
		this.http.c_delete(this._kinds[this.kind].url+'/statuses', {
			id: statusId
		}, resp=>{
			this.socket.emit("statuses_refresh", {
				action: 'delete',
				status: resp,
				kind: this.kind
			});
		});
	}
	delete_reason(reassonId){
		this.http.c_delete(this._kinds[this.kind].url+'/statuses/reasons', {
			id: reassonId
		});
	}
	to_reason(reason){
		return {
			status_id: reason.status_id,
			title: reason.title,
			id: reason.id
		};
	}
	to(status){
		let newStatus = {
			id: status.id,
			title: status.title
		}
		for(let j = 0; j < this.which.length; j++){
			if(status[this.which[j]]){
				newStatus[this.which[j]] = 1;
			}else{
				newStatus[this.which[j]] = 0;
			}
		}
		return newStatus;
	}
}
