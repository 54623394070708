import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(arr: any, groupName: any, refresh?): any {
  	if(!Array.isArray(arr)) return [];
  	arr = arr.slice();
  		if(groupName == 'Created') {
  			for(let i = arr.length-1; i >= 0; i--) {
  				if(arr[i].title == groupName) {
  					arr.splice(i, 1);
  				}
  			}
  		} else if (groupName == 'Opened' || groupName == 'In progress') {
  			for(let i = arr.length-1; i >= 0; i--) {
  				if(arr[i].title == 'Created') {
  					arr.splice(i, 1);
  				}
  			}
  		} else if (groupName == 'Closed') {
  			for(let i = arr.length-1; i >= 0; i--) {
  				if(arr[i].title == 'Created' || arr[i].title == 'In progress') {
  					arr.splice(i, 1);
  				}
  			}
  		}
    return arr;
  }

}
