import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'preview',
	inputs: ['src'],
	templateUrl: './preview.component.html',
	styleUrls: ['./preview.component.scss']
})
export class PreviewComponent {
	@Output('delete') delete = new EventEmitter();
	constructor(
		private dialog: MatDialog
	) {}
	public src;
	open() {
		this.dialog.open(DialogComponent, {
			data: {
				delete: this.delete,
				src: this.src
			},
			autoFocus: false
		});
	}
  }
