import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CategoriesService, HttpService, TaskService, EqItemService } from '@services';

@Component({
	selector: 'categories-picker',
	inputs: ['which', 'categories', 'placeholder'],
	templateUrl: './categories.component.html',
	styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
	public categoriesCtrl = new FormControl();
	@Output() update = new EventEmitter<any>();
	@Output() delete = new EventEmitter<any>();
	@Input ('profile') profile:any;
  	@ViewChild('categoriesInput', { static: true }) categoriesInput: ElementRef<HTMLInputElement>;
	public placeholder:any;
	public which:any;
	public categories:any;
	constructor(public ct: CategoriesService,
		public http: HttpService,
		public ts: TaskService,
		public eqi: EqItemService){
		if(!this.which) this.which='tasks';
	}
	ngOnInit() {}
	deleteTag(data) {
		if(this.which == 'tasks') {
			this.ts.save({id: data.id, tags: this.http.to_id(data.tags)});
		} else if (this.which == 'equipments') {
			this.eqi.save({id: data.id, tags: this.http.to_id(data.tags)});
		}
		
	}
}
