import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService, EqServicesService, BaseService } from '@services';
import * as moment from 'moment';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
	public service:any = {};
	public update_service:any = {};
	public current;
	public dropdownSettings = {
		singleSelection: false,
		idField: 'id',
		enableCheckAll: false,
		textField: 'title',
		maxHeight: 500,
		itemsShowLimit: 1,
		allowSearchFilter: false
	};
	public equipment_service_ids:any = [];
	public update_equipment_service_ids:any = [];
	constructor(private dialogRef: MatDialogRef<SettingsComponent>,
		public eqs: EqServicesService,
		public us: UserService,
		public bs: BaseService,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		if (this.data.reminder.service) {
			this.service = this.data.reminder.service;
			this.equipment_service_ids = [this.service.equipment_service];
		}
		this.service.equipment_ids = [this.data.reminder.equipment.id];
		this.current = new Date(moment().format('YYYY-MM-DD HH:mm:ss'));
		this.service.period_start = new Date(moment().format('YYYY-MM-DD HH:mm:ss'));
	}
	ngOnInit() {
	}
	close() {
		this.dialogRef.close();
	}
	save() {
		if(!this.service.id) {
			this.eqs.createReminder(this.service, ()=> {
				this.dialogRef.close(true);
			});
		} else {
			this.eqs.saveReminder(this.update_service, ()=> {
				this.dialogRef.close(true);
			});
		}
	}
}
