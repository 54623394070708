import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-crop',
  templateUrl: './crop.component.html',
  styleUrls: ['./crop.component.scss']
})
export class CropComponent implements OnInit, AfterViewInit {

  public aspectRatio = 1;
    public resizeToWidth = 1000;
    public showCropper = false;
	public imageBase64: any = '';

	constructor(
		private dialogRef: MatDialogRef<CropComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
		public http: HttpClient
	) {
    if (this.data.aspectRatio) {
      this.aspectRatio = this.data.aspectRatio;
    }
    if (this.data.resizeToWidth) {
      this.resizeToWidth = this.data.resizeToWidth;
    }
    if (this.data.fileEvent) {
      this.imageChangedEvent = this.data.fileEvent;
    }
  }
  ngOnInit() {}
  ngAfterViewInit() {
	  if (this.data.edit) {

		  const myCanvas = <HTMLCanvasElement> document.getElementById('my_canvas_id');
		  const ctx = myCanvas.getContext('2d');
		  const img = new Image;
		  img.crossOrigin = 'Anonymous';
		  img.onload = (ev: any) => {
			  myCanvas.height = ev.path[0].height;
			  myCanvas.width = ev.path[0].width;
			  ctx.drawImage(img, 0, 0, ev.path[0].width, ev.path[0].height);
			  this.imageBase64 = myCanvas.toDataURL('image/png');
		  };
		  img.src = this.data.edit;
	  }
  }
  imageChangedEvent: any = '';
  croppedImage: any = '';

  public loadImgForCrop(ev): void {
  	console.log(ev);
  }

  fileChangeEvent(event: any): void {
      this.showCropper = false;
      this.imageChangedEvent = event;
  }
  imageCropped(event: any) {
      this.croppedImage = event.base64;
  }
  imageLoaded() {
      this.showCropper = true;
      // show cropper
  }
  loadImageFailed() {
      // show message
  }
  upload() {
    this.dialogRef.close(this.croppedImage);
  }
  close() {
    this.dialogRef.close();
  }
}
