import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';

@Injectable({
    providedIn: 'root'
})
export class LogService {
    uri = environment.api;

    constructor(private http: HttpClient) { }

    getLogs() {
        return this.http.get(`${this.uri}/logs/get`);
    }

    getLogsWithParams(page = 0, limit = 25, search = '') {
        const reqBody = {
            search: search,
            page: page,
            limit: limit
        };
        return this.http.post(`${this.uri}/logs/search`, reqBody);
    }
}
