import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeComponent } from './time.component';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomMaterialModule } from '@root/modules/template';

@NgModule({
	exports: [TimeComponent],
	declarations: [TimeComponent],
	imports: [
		CommonModule,
		FormsModule,
		FlexLayoutModule,
		CustomMaterialModule

	]
})
export class TimeModule {}