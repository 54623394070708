import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'splice'
})
export class SplicePipe implements PipeTransform {
	transform(arr: any, spliceArr: any, field='id', refresh?, to_field=''): any {
		if(!Array.isArray(arr)) return [];
		if(!Array.isArray(spliceArr)) return arr;
		if(!field) field = 'id';
		arr = arr.slice();
		for(let i = arr.length-1; i >= 0; i--){
			for(let j = spliceArr.length-1; j >= 0; j--){
				if(arr[i][field] == spliceArr[j][field]){
					arr.splice(i, 1);
					break;
				}
				if(to_field && arr[i][to_field] == spliceArr[j][field]){
					arr.splice(i, 1);
					break;
				}
				if(to_field && arr[i][field] == spliceArr[j][to_field]){
					arr.splice(i, 1);
					break;
				}
			}
		}
		return arr;
	}
}