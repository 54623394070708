import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusComponent } from './status.component';
import { FormsModule } from '@angular/forms';
import { SharedPipes } from '@pipes';
import { CustomMaterialModule } from '@root/modules/template';

@NgModule({
	exports: [StatusComponent],
	declarations: [StatusComponent],
	imports: [
		CommonModule,
		SharedPipes,
		FormsModule,
		CustomMaterialModule
	]
})
export class StatusModule { }
