import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { HttpService } from './http.service';
import { AlertService } from './alert.service';
import { ServicesService } from './estimate/services.service';

@Injectable({
	providedIn: 'root'
})
export class FieldsService {
	public cfields:any = [];
	public _cfields:any = {};
	public fields:any = [];
	public _fields:any = {};
	public load = false;
	constructor(private us: UserService,
		private ss: ServicesService,
		private http: HttpService,
		private alert: AlertService) {
		this.fetch();
	}
	public now = new Date().getTime();
	refresh(){
		this.now = new Date().getTime();
	}
	fetch(){
		let counter = 2;
		this.http.apiget('/clients/fields/get', cfields=>{
			this.cfields = cfields;
			for(let i = 0; i < cfields.length; i++){
				this._cfields[cfields[i].id] = cfields[i];
			}
			if(--counter===0) this.load=true;
		});
		this.http.apiget('/estimates/offers/services/fields/get', fields=>{
			this.fields = fields;
			for(let i = 0; i < fields.length; i++){
				if(typeof fields[i].is_print != 'boolean'){
					fields[i].is_print = false;
				}
				this._fields[fields[i].id] = fields[i];
				
			}
			if(--counter===0) this.load=true;
		});
	}
	/*
	*	Clients Management
	*/
		cset(fields){
			if(!this.load){
				return setTimeout(()=>{
					this.cset(fields);
				}, 100);
			}
			for(let each in this._cfields){
				this._cfields[each].value = '';
			}
			for(let i = 0; i < fields.length; i++){
				if(!this._cfields[fields[i].field_id]) continue;
				this._cfields[fields[i].field_id].value = fields[i].value;
			}
		}
		ccreate(field, cb=created=>{}){
			for(let i = 0; i < this.fields.length; i++){
				if(this.fields[i].name.toLowerCase() == field.name.toLowerCase()){
					return this.alert.fail('Field '+field.name+' already exists');
				}
			}
			if(!field.icon) field.icon = 'test';
			if(!field.type) field.type = 'radio';
			if(!field.required) field.required = false;
			if(typeof field.data == 'string'){
				field.data = field.data.split(',');
			}
			field.values = field.data;
			this.http.apipost('/clients/fields/create', field, created=>{
				this.fields.unshift(created);
				this.refresh();
				cb(created);
			});
		}
		cupdate(field, cb=updated=>{}) {
			if(typeof field.data == 'string'){
				field.data = field.data.split(',');
			}
			field.values = field.data;
			this.http.apipost('/clients/fields/update', field, updated=>{
				const updatedField = this.fields.find(fld=>fld.id===field.id);
				this.fields[this.fields.indexOf(updatedField)] = field;
				for (let i=this.ss.services.length-1; i>=0; i--) {
				    for (let j=this.ss.services[i].fields.length-1; j>=0; j--) {
				        if(this.ss.services[i].fields[j].id===field.id){
				        	this.ss.services[i].fields[j]=JSON.parse(JSON.stringify(field));
				        }
				    }
				}
				this.refresh();
				cb(updated);
			});
		}
		cdelete(id: number, cb=()=>{}) {
			this.http.apipost('/clients/fields/delete', {id: id}, deleted=>{
				for (let i=this.fields.length-1; i>=0; i--) {
				    if(this.fields[i].id==id){
				    	this.fields.splice(i,1);
				    }
				}
				for (let i=this.ss.services.length-1; i>=0; i--) {
				    for (let j=this.ss.services[i].fields.length-1; j>=0; j--) {
				        if(this.ss.services[i].fields[j].id===id){
				        	this.ss.services[i].fields.splice(j,1);
				        }
				    }
				}
				this.refresh();
				cb();
			});
		}
	/*
	*	Quotes Management
	*/
		set(fields){
				if(!fields) return;
			if(!this.load){
				return setTimeout(()=>{
					this.set(fields);
				}, 100);
			}
			for(let i = fields.length-1; i >= 0; i--){
				if(!this._fields[fields[i].id]){
					fields.splice(i, 1);
				}else{
					fields[i].name = this._fields[fields[i].id].name;
					fields[i].type = this._fields[fields[i].id].type;
					fields[i].data = this._fields[fields[i].id].data;
					fields[i].required = this._fields[fields[i].id].required;
				}
			}
		}
		create(field, cb=created=>{}){
			for(let i = 0; i < this.fields.length; i++){
				if(this.fields[i].name.toLowerCase() == field.name.toLowerCase()){
					return this.alert.fail('Field '+field.name+' already exists');
				}
			}
			if(!field.icon) field.icon = 'test';
			if(!field.type) field.type = 'radio';
			if(!field.required) field.required = false;
			if(typeof field.data == 'string'){
				field.data = field.data.split(',');
			}
			field.values = field.data;
			this.http.apipost('/estimates/offers/services/fields/create', field, created=>{
				this.fields.unshift(created);
				this.refresh();
				cb(created);
			});
		}
		update(field, cb=updated=>{}) {
			if(typeof field.data == 'string'){
				field.data = field.data.split(',');
			}
			if(!field.is_print){
				field.is_print = false;
			}
			field.values = field.data;
			this.http.apipost('/estimates/offers/services/fields/update', field, updated=>{
				const updatedField = this.fields.find(fld=>fld.id===field.id);
				this.fields[this.fields.indexOf(updatedField)] = field;
				for (let i=this.ss.services.length-1; i>=0; i--) {
				    for (let j=this.ss.services[i].fields.length-1; j>=0; j--) {
				        if(this.ss.services[i].fields[j].id===field.id){
				        	this.ss.services[i].fields[j]=JSON.parse(JSON.stringify(field));
				        }
				    }
				}
				this.refresh();
				cb(updated);
			});
		}
		delete(id: number, cb=()=>{}) {
			this.http.apipost('/estimates/offers/services/fields/delete', {id: id}, deleted=>{
				for (let i=this.fields.length-1; i>=0; i--) {
				    if(this.fields[i].id==id){
				    	this.fields.splice(i,1);
				    }
				}
				for (let i=this.ss.services.length-1; i>=0; i--) {
				    for (let j=this.ss.services[i].fields.length-1; j>=0; j--) {
				        if(this.ss.services[i].fields[j].id===id){
				        	this.ss.services[i].fields.splice(j,1);
				        }
				    }
				}
				this.refresh();
				cb();
			});
		}
	/*
	*	End of Management
	*/
}


/*

GET api/estimates/offers/services/fields/get

api/estimates/offers/services/fields/create
api/estimates/offers/services/fields/update
	id:string
	name:string
	icon:string
	required:1 or 0
	type:(text,number,date,checkbox,radio,dropdown)
	если type radio или dropdown, передаем возможные значения:
	values[0]:string
	values[1]:string

api/estimates/offers/services/fields/delete
	id:int

api/estimates/offers/services/fields/fetch
	id:int
*/