import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})

export class TaskCategoryService {
    uri = environment.api;

    constructor(private http: HttpClient, private us: UserService) {

    }

    getTasksCategories(){
        return this.http.get(`${this.uri}/tasks/category/get`);
    }

    addTaskCategory(data: any){
        return this.http.post(`${this.uri}/tasks/category/create`, data);
    }

    deleteTaskCategory(id: number){
        return this.http.post(`${this.uri}/tasks/category/delete`, {id: id});
    }

    updateTaskCategory(data:any){
        console.log(data);
        return this.http.post(`${this.uri}/tasks/category/update`, data);
    }
}