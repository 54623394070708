export default [
    {
      'id': 0,
      'isActive': true,
      'system': 'mac',
      'location': '312 Durland Place, Vernon, Wisconsin, 552',
      'last_login': '2016-02-04T05:42:13 -02:00'
    },
    {
      'id': 1,
      'isActive': false,
      'system': 'android',
      'location': '792 Lexington Avenue, Hilltop, Vermont, 1591',
      'last_login': '2018-03-31T09:48:22 -03:00'
    },
    {
      'id': 2,
      'isActive': false,
      'system': 'windows',
      'location': '405 Conselyea Street, Canby, District Of Columbia, 1841',
      'last_login': '2015-10-26T01:52:17 -03:00'
    },
    {
      'id': 3,
      'isActive': true,
      'system': 'android',
      'location': '377 Verona Place, Oneida, Kentucky, 2720',
      'last_login': '2015-07-03T11:44:11 -03:00'
    },
    {
      'id': 4,
      'isActive': false,
      'system': 'mac',
      'location': '975 Furman Avenue, Wyano, South Carolina, 6422',
      'last_login': '2017-10-11T01:54:15 -03:00'
    }
  ];
