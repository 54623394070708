import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addcontact'
})
export class AddcontactPipe implements PipeTransform {

  transform(arr: any, contact: any): any {
  	var add = false;
  	arr = arr.slice(0, 25);
  	if(contact.id) {
  		for (var i = 0; i < arr.length; i++){
	  	    if(arr[i].id == contact.id) add = true;
	  	}
	  	if(!add) arr.unshift(contact);
  	}
  	
    return arr;
  }

}
