import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddPaymentComponent } from './add-payment.component';

import { CustomMaterialModule } from '@root/modules/template';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { AttachmentModule } from '../Attachment/attachment/attachment.module';

@NgModule({
	entryComponents: [AddPaymentComponent],
	declarations: [AddPaymentComponent],
	exports: [AddPaymentComponent],
	imports: [
		CustomMaterialModule,
		FormsModule,
		CommonModule,
		FlexLayoutModule,
		AttachmentModule
	]
})
export class AddPaymentModule { }
