import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'time',
	inputs: ['date', 'hide_clean'],
	templateUrl: './time.component.html',
	styleUrls: ['./time.component.scss']
})
export class TimeComponent implements OnInit {
	@Output('update') update = new EventEmitter();
	public hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
	public minutes = Array(60);
	public types = ['AM', 'PM'];
	public type = 'AM';
	public selected_hour;
	public selected_minute;

	public date:any;
	public hide_clean:any;
	
	set(){
		if(!this.date||typeof this.date =='string') return;
		if(this.type == 'AM'||this.type == 'am'){
			this.date.setHours(this.selected_hour);
		}else{
			this.date.setHours(this.selected_hour+12);
		}
		this.date.setMinutes(this.selected_minute);
	}
	set_hour(){
		if(!this.selected_minute) this.selected_minute=0;
		this.save();
	}
	set_minute(){
		if(!this.selected_hour) this.selected_hour=12;
		this.save();
	}
	set_type(){
		if(!this.selected_hour) this.selected_hour=12;
		if(!this.selected_minute) this.selected_minute=0;
		this.save();
	}
	public fill_fields(date=this.date){
		if(date&&date!='Invalid date'){
			let times = date.split(':');
			this.selected_hour = Number(times[0]);
			this.selected_minute = Number(times[1].split(' ')[0]||0);
			this.type = times[1].split(' ')[1];
		}else{
			this.selected_hour = '';
			this.selected_minute = '';
		}
		this.set();
	}
	constructor() {
		this.set();
	}
	ngOnInit() {
		this.fill_fields(this.date);
	}
	save(){
		this.set();
		if(this.type == 'AM'){
			this.update.emit({
				full: (this.selected_hour&&(this.selected_minute||this.selected_minute===0))&&(this.selected_hour+':'+this.selected_minute+' '+this.type)||"",
				time: this.selected_hour + ':' + this.selected_minute,
				minute: this.selected_minute,
				hour: this.selected_hour,
				date: this.date
			});
		}else{
			this.update.emit({
				full: (this.selected_hour&&(this.selected_minute||this.selected_minute===0))&&(this.selected_hour+':'+this.selected_minute+' '+this.type)||"",
				time: (this.selected_hour+12) + ':' + this.selected_minute,
				minute: this.selected_minute,
				hour: (this.selected_hour+12),
				date: this.date
			});
		}
	}
}