import { Component, EventEmitter, Output, OnInit, ViewChild } from '@angular/core';
import { HttpService, StatusService, MapService, GroupsService } from '@services';
import * as html2canvas from 'html2canvas';

@Component({
	selector: 'clean_map',
	inputs: ['filter', 'header', 'status', 'statuses', 'users', 'taskstatuses', 'snapshot', 'zoomControl', 'fullscreenControl', 'mapTypeControl', 'zoom', 'location', 'mapTypeId', 'marker'],
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
	public taking_snapshot = false;
	take_snapshot(){
		if(this.taking_snapshot) return;
		this.taking_snapshot = true;
		let element = this.container.nativeElement.children[0].children[0].children[0];
		if(element.children.length==2){
			// console.log(element.children[1].children[0].children[0].children[9].children[0].children);
			element = element.children[1].children[0].children[0].children[9].children[0].children[0];
			// console.log(element);
			// console.log({
			// 	dataUrl: )
			// });
			this.take.emit({
				wdith: this.container.nativeElement.clientHeight,
				height: this.container.nativeElement.clientWidth,
				dataUrl: element.toDataURL("image/png")
			});
			this.taking_snapshot = false;
		}else{
			element = element.children[0].children[0];
			(html2canvas as any)(element, {
				backgroundColor: null,
				imageTimeout: 0,
				useCORS: true
			}).then(canvas => {
				this.taking_snapshot = false;
				this.take.emit({
					wdith: this.container.nativeElement.clientHeight,
					height: this.container.nativeElement.clientWidth,
					dataUrl: canvas.toDataURL()
				});
			});
		}
	}

	@ViewChild('map', { static: true }) map: any;
	@ViewChild('container', { static: true }) container: any;
	public header;
	public status;
	public filter;

	public zoom=8;
	public zoomControl=false;
	public fullscreenControl=false;
	public mapTypeControl=false;
	public mapTypeId='roadmap';
	public marker:any=false;

	public showPageTips;
	public dropdownSettings = {
		singleSelection: false,
		idField: 'id',
		enableCheckAll: false,
		textField: 'name',
		maxHeight: 500,
		itemsShowLimit: 1,
		allowSearchFilter: false
	};
	public dropdownSettingsStatus = {
		singleSelection: false,
		idField: 'id',
		enableCheckAll: false,
		textField: 'title',
		maxHeight: 500,
		itemsShowLimit: 1,
		allowSearchFilter: false
	};
	public location = {
		lat: 43.653908,
		lng: -79.384293
	};

	public statuses = false;
	public users = false;
	public taskstatuses = false;
	public snapshot = false;

	@Output('load') load = new EventEmitter();
	@Output('take') take = new EventEmitter();
	@Output('fload') fload = new EventEmitter();
	@Output('open') open = new EventEmitter();
	public show = false;
	constructor(private http: HttpService,
		public gs: GroupsService,
		public ms: MapService,
		private sts: StatusService) {}
	ngOnInit(){
		if(this.statuses){
			this.http.on('statuses', ()=> this.show = true );
		}
		if(this.taskstatuses){
			this.http.on('taskstatuses', ()=> this.show = true );
		}
	}
	getIconSVG(item){
		let color = '1E88E5';
		if (item.user.color && item.user.color !='') color = item.user.color.replace('#', '');
		let number = item.number || 0;
		if(number<0) number = 0;
		if(item.priority=='priority'){
			return "data:image/svg+xml,%3Csvg id='map-marker' xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cstyle%3E .vi-primary %7B fill: %23"+color+"; fill-rule: evenodd; %7D .vi-primary, .vi-accent %7B stroke: %23"+(item.has_brother && '000' || color)+"; stroke-linecap: round; stroke-width: 2; %7D .vi-accent %7B fill: %23070c2b; %7D %3C/style%3E%3C/defs%3E%3Cpath class='vi-primary' d='M24,11c7.7,0,14,5.6,14,12.6C38,35,24,47,24,47S10,35.1,10,23.6C10,16.6,16.3,11,24,11z'/%3E%3Ctext transform='matrix(1 0 0 1 23.4385 32)' fill='%23000' style='font-family: Arial, sans-serif;font-weight:bold;text-align:center;' font-size='20' text-anchor='middle'%3E"+(number||0)+"%3C/text%3E%3Cpath class='st3'  fill='%23F39C12' stroke='%237F8C8D' stroke-width='1' d='M45.9,5.8c0,0.1-0.1,0.3-0.2,0.4l-2.7,2.7l0.6,3.8c0,0.1,0,0.1,0,0.2c0,0.2-0.1,0.4-0.3,0.4c-0.1,0-0.2,0-0.3-0.1l-3.4-1.8 l-3.4,1.8c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.2-0.3-0.4c0-0.1,0-0.1,0-0.2l0.6-3.8l-2.7-2.7c-0.1-0.1-0.2-0.2-0.2-0.4 c0-0.2,0.2-0.3,0.4-0.3l3.8-0.6l1.7-3.4c0.1-0.1,0.2-0.3,0.4-0.3c0.2,0,0.3,0.2,0.4,0.3l1.7,3.4l3.8,0.6C45.6,5.5,45.9,5.6,45.9,5.8 L45.9,5.8z'/%3E%3C/svg%3E%0A";
		}else if(item.priority=='emergency'){
			return "data:image/svg+xml,%3Csvg id='map-marker' xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cstyle%3E .vi-primary %7B fill: %23"+color+"; fill-rule: evenodd; %7D .vi-primary, .vi-accent %7B stroke: %23"+(item.has_brother && '000' || color)+"; stroke-linecap: round; stroke-width: 2; %7D .vi-accent %7B fill: %23070c2b; %7D %3C/style%3E%3C/defs%3E%3Cpath class='vi-primary' d='M24,11c7.7,0,14,5.6,14,12.6C38,35,24,47,24,47S10,35.1,10,23.6C10,16.6,16.3,11,24,11z'/%3E%3Ctext transform='matrix(1 0 0 1 23.4385 32)' fill='%23000' style='font-family: Arial, sans-serif;font-weight:bold;text-align:center;' font-size='20' text-anchor='middle'%3E"+(number||0)+"%3C/text%3E%3Cpath class='st3' fill='%23D35400' d='M33.5,3C33.1,3.3,36,2.6,35.7,4c-0.3,1.4-3.1-0.3-6.2-0.2c-1.4,0.1-2.6,0.5-3.4,1.4c0.8-0.9,2.5-0.7,2-0.1 c-1,1.5-2.9,0.4-4.2,3.4c0.2-0.3,0.6-1,2.4-1.3c1.4-0.2,3.6,0,4.3,1.1c0.8,1.2-3.2,1.1-1,1.3c3.1,0.3,5.4,2.8,8.4,2.8 c3.9,0,6.3-2,6.4-5.2C44.5,1.4,36.7,0.2,33.5,3L33.5,3z M39.3,9.4c-1,0-2.1-0.4-2.9-0.8c-2-1-2.9-1.3-2.9-1.4c0-0.1,1.2,0.4,3.3-0.7 C38,5.9,38,5.4,39.3,5.3c1.5-0.1,2.5,1,2.5,2.1C41.8,8.5,40.8,9.5,39.3,9.4L39.3,9.4z'/%3E%3C/svg%3E%0A";
		}else{
			return "data:image/svg+xml,%3Csvg id='map-marker' xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cstyle%3E .vi-primary %7B fill: %23"+color+"; fill-rule: evenodd; %7D .vi-primary, .vi-accent %7B stroke: %23"+(item.has_brother && '000' || color)+"; stroke-linecap: round; stroke-width: 2; %7D .vi-accent %7B fill: %23070c2b; %7D %3C/style%3E%3C/defs%3E%3Cpath class='vi-primary' d='M24,11c7.7,0,14,5.6,14,12.6C38,35,24,47,24,47S10,35.1,10,23.6C10,16.6,16.3,11,24,11z'/%3E%3Ctext transform='matrix(1 0 0 1 23.4385 32)' fill='%23000' style='font-family: Arial, sans-serif;font-weight:bold;text-align:center;' font-size='20' text-anchor='middle'%3E"+(number||0)+"%3C/text%3E%3C/svg%3E";
		}
	}
}
