import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jobs'
})
export class JobsPipe implements PipeTransform {

	transform(arr: any, statuses:any, refresh?): any {
		if(!Array.isArray(arr)) return [];
		arr = arr.slice();
		var result = [];
		for (var i = arr.length - 1; i >= 0; i--) {
			for (var j = statuses.length - 1; j >= 0; j--) {
				if(arr[i].status.title == statuses[j]) {
					result.push(arr[i]);
				}
			}
			
		}
    	return result;
	}

}
