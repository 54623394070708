import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { SocketService } from '../socket.service';
import { debounceTime } from 'rxjs/operators';
import { ClientService } from './client.service';

@Injectable({
	providedIn: 'root'
})
export class NotesService {
	private url = environment.api;
	constructor(private http:HttpClient,
		private cs: ClientService,
		private socket: SocketService) {
	}	
	create(note, cb=created=>{}){
		let formData: FormData = new FormData();
		for(let i=0; i<note.files.length; i++){
			formData.append("files["+i+"]", note.files[i]);
		}
		formData.append("note", note.note);
		if(note.estimate_id) formData.append("estimate_id", note.estimate_id);
		if(note.lead_id) formData.append("lead_id", note.lead_id);
		if(note.client_id) formData.append("client_id", note.client_id);
		if(note.workorder_id) formData.append("workorder_id", note.workorder_id);
		if(note.invoice_id) formData.append("invoice_id", note.invoice_id);
		if(note.task_id) formData.append("task_id", note.task_id);
		this.http.post(this.url+'/clients/notes/create', formData).subscribe((res)=>{
			this.socket.emit('notes_refresh', res);
			cb(res);
		});
	}
	update(note, cb=created=>{}){
		let formData: FormData = new FormData();
		let counter = 0;
		if(note.files){
			for(let i=0; i<note.files.length; i++){
				if(note.files[i].new){
					delete note.files[i].new;
					formData.append("files["+(counter++)+"]", note.files[i]);
				}
			}
		}
		if(note.files_delete&&note.files_delete.length){
			for(let i=0; i<note.files_delete.length; i++){
				formData.append("files_delete["+i+"]", note.files_delete[i]);
			}
		}
		note.files_delete = [];
		formData.append("id", note.id);
		formData.append("note", note.note);
		formData.append("top", note.top);
		if(note.estimate_id) formData.append("estimate_id", note.estimate_id);
		if(note.lead_id) formData.append("lead_id", note.lead_id);
		if(note.client_id) formData.append("client_id", note.client_id);
		if(note.workorder_id) formData.append("workorder_id", note.workorder_id);
		if(note.invoice_id) formData.append("invoice_id", note.invoice_id);
		if(note.task_id) formData.append("task_id", note.task_id);
		this.http.post(this.url+'/clients/notes/update', formData).subscribe((res)=>{
			this.socket.emit('notes_refresh', res);
			cb(res);
		});
	}
	delete(note_id){
		this.http.post(`${this.url}/clients/notes/delete`, {id: note_id}).subscribe(()=>{
			if(this.fetchedFor)
				this.checkForNotesUpdate(this.fetchedFor);
			this.socket.emit('notes_refresh', {lead_id: 'delete'});
		});
	}






















	fetchedFor:number;
	public notes = [];
	public note:any={};
	fetch(note){
		for (let i=this.notes.length-1; i>=0; i--) {
			if(this.notes[i].id==note.id){
				this.notes[i]=note;
			}
		}
	}
	getNotes(client_id){
		if(this.fetchedFor !== client_id){
			//this.fetchedFor = client_id;
			this.http.get(`${this.url}/clients/notes/get?client_id=${client_id}`)
			.pipe(
				debounceTime(1000)
				)
			.subscribe((resp:any)=>{
				this.notes=resp;
				this.sort();
			});
		}
	}
	checkForNotesUpdate(client_id){
		this.http.get(`${this.url}/clients/notes/get?client_id=${client_id}`).subscribe((resp:any)=>{
			this.notes=resp;
			this.cs.notes=resp;
			this.sort();
			this.fetchedFor = client_id;
		});
	}
	sort(){
		this.notes.sort((a,b)=>{
			if(b.top-a.top!=0) return b.top-a.top;
			if(a.created_at < b.created_at) return 1;
			if(a.created_at > b.created_at) return -1;
			return 0;
		});
	}


}
