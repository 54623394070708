import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicMasksModule } from '../phone-mask/phone.mask.module';
import { AddressModule } from '../address/address.module';
import { SharedModule } from '@services';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ClientInfoCommonComponent } from './client-info.component';
import { CustomMaterialModule } from '@root/modules/template';
import { SharedPipes } from '@pipes';
import { NgxMaskModule } from 'ngx-mask';

import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [ClientInfoCommonComponent],
    imports:[
        CustomMaterialModule,
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        DynamicMasksModule,
        SharedModule,
        NgMultiSelectDropDownModule,
        RouterModule,
        AddressModule,
        SharedPipes,
        NgxMaskModule
    ],
    exports: [ClientInfoCommonComponent]
})
export class ClientInfoCommonModule {}