import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { EstimateService } from './estimate.service';
import { AlertService } from '../alert.service';

@Injectable({
	providedIn: 'root'
})
export class AttachmentsService {
	public refresh:any;
	public attachments:any = [];
	constructor(private http: HttpService, private alert: AlertService, private es: EstimateService) {
		this.es.offers('attachments', attachments=>{
			this.attachments = attachments;
			if(this.refresh) this.refresh();
		});
	}
	create(doc, equipment){
		this.http.c_create('estimates/offers/equipments/attachments', doc, resp => {
			this.attachments.push(resp);
			equipment.attachments.push(resp);
			if(this.refresh) this.refresh();
		});
	}
	update(doc){
		this.http.c_update('estimates/offers/equipments/attachments', doc, resp => {
			if(this.refresh) this.refresh();
		});
		localStorage.setItem('attachments', JSON.stringify(this.attachments));
	}
	delete(doc){
		this.alert.yes('Are you sure that you want to delete attachment?', () => {
			for(let i = 0; i < this.attachments.length; i++){
				if(this.attachments[i].id == doc.id){
					this.attachments.splice(i, 1);
				}
			}
			this.http.c_delete('estimates/offers/equipments/attachments', doc, resp => {
				if(this.refresh) this.refresh();
			});
		});
	}
}
