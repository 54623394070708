import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { AlertService } from '../alert.service';
import { SocketService } from '../socket.service';
import { UiService } from '../ui.service';
import { EqGroupsService } from '../equipment/eq.groups.service';
import * as moment from 'moment';
@Injectable({
	providedIn: 'root'
})
export class ListService {
	public kind;
	public items:any = JSON.parse(localStorage.getItem('templatesItems'))||{};
	constructor(private http: HttpService,
		private socket: SocketService,
		private router: Router,
		private alert: AlertService,
		private us: UserService,
		private ui: UiService,
		private eqp: EqGroupsService) {}
	create(item){
		if(!this.items[this.kind]) this.items[this.kind]=[]
		item.id=new Date().getTime();
		this.items[this.kind].push(item);
		localStorage.setItem('templatesItems', JSON.stringify(this.items));
		this.load();
	}
	delete(id){
		this.alert.yes('Are you sure that you want to delete it?', ()=>{
			for (let i=0; i < this.items[this.kind].length; i++){
				if(id==this.items[this.kind][i].id){
				    this.items[this.kind].splice(i, 1);
				    localStorage.setItem('templatesItems', JSON.stringify(this.items));
					return this.load();
				}
			}
		});
	}
	update(item){
		localStorage.setItem('templatesItems', JSON.stringify(this.items));
	}
	public now = new Date().getTime();
	refresh(){
		this.now = new Date().getTime();
	}

	public data:any=[];
	public refill: any;
	public search = '';
	public page = 1;
	public total: any;

	public sortBy = localStorage.getItem(this.kind+'SortBy') || this.kind+'_number';
	public sort = localStorage.getItem(this.kind+'Sort') || 'asc';
	setSortBy(sortBy){
		this.sortBy = sortBy.active;
		this.sort = sortBy.direction;
		localStorage.setItem(this.kind+'SortBy', this.sortBy);
		localStorage.setItem(this.kind+'Sort', this.sort);
		this.load();
	}
	paginate(event){
		this.page = event.pageIndex+1;
		this.ui.setPerPage(event.pageSize);
		this.load();
	}

	public make_filters = false;
	public filters: any = {};
	public filtersApplied: any = {};
	public types = [];
	load(){
		this.http.afterWhile(this, ()=>{
		this.filtersApplied = Object.assign({}, this.filters);
		let opts:any = {
			perPage: this.ui.perPage,
			page: this.page,
			sortBy: this.sortBy,
			sort: this.sort
		}

		if(this.search) opts.search = this.search;
		for(let each in this.filters){
			if(each=='date_from'||each=='date_to'||each=='expire_date_from'||each=='expire_date_to'){
				opts['filters['+each+']']=moment(this.filters[each]).format('YYYY-MM-DD');
			}else opts['filters['+each+']']=this.filters[each];
		}
		/*if(this.eqp && this.eqp.statuses_selected) {
			for(let i = 0; i < this.eqp.statuses_selected.length; i++) {
				opts['filters[status_id]['+i+']'] = this.eqp.statuses_selected[i].id;
			}
		}
		if(this.eqp && this.eqp.groups_selected) {
			for(let i = 0; i < this.eqp.groups_selected.length; i++) {
				opts['filters[group_id]['+i+']'] = this.eqp.groups_selected[i].id;
			}
		}*/
		if(this.types.length && this.types.length!=3){
			for(let i = 0; i < this.types.length; i++){
				opts['filters[type]['+i+']'] = this.types[i].title;
			}
		}
		this.data=this.items[this.kind]&&this.items[this.kind].slice()||[];
		if(opts.search){
			this.data=[];
			for (let i=0; i < this.items[this.kind].length; i++){
			    for(let key in this.items[this.kind][i]){
			    	if(typeof this.items[this.kind][i][key].toLowerCase != 'function'){
			    		if(this.items[this.kind][i][key] == opts.search){
				    		this.data.push(this.items[this.kind][i]);
				    		break;
			    		}
			    	}else if(this.items[this.kind][i][key].toLowerCase().indexOf(opts.search.toLowerCase())>-1){
			    		this.data.push(this.items[this.kind][i]);
			    		break;
			    	}
			    }
			}
		}
		for(let key in this.filters){
			for (let i=this.data.length-1; i>=0; i--) {
				if(this.data[i][key].toLowerCase().indexOf(this.filters[key].toLowerCase())==-1){
					this.data.splice(i, 1);
				}
			}
		}
		this.refill();
	}, 200);}

	loadAfterWhile(){
		this.http.afterWhile(this, this.load.bind(this), 2000);
	}

	fieldsData: Array<any> = [];
	chosenFields: Array<any> = [];
	filters_ids: any = {};
	address: any = '';
}
