import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-activities',
	inputs: ['activities'],
	templateUrl: './activities.component.html',
	styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {
	dropdownList = [];
	selectedItems = [];
	dropdownSettings = {};
	closeDropdownSelection=false;
	constructor() {}
	ngOnInit() {
		this.dropdownList = [
		{ item_id: 1, item_text: 'Contact created' },
		{ item_id: 2, item_text: 'Contact updated' }
		];
		this.selectedItems = [
		{ item_id: 1, item_text: 'Contact created' },
		{ item_id: 2, item_text: 'Contact updated' }
		];
		
		this.dropdownSettings = {
			singleSelection: false,
			idField: 'item_id',
			textField: 'item_text',
			selectAllText: 'Select All',
			unSelectAllText: 'Unselect All',
			itemsShowLimit: 1,
			allowSearchFilter: true
		};
	}
	onItemSelect(item: any) {
		console.log(item);
	}
	onSelectAll(items: any) {
		console.log(items);
	}
}
