import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class BuilderService {
	public template:any = {};
	public templates = localStorage.getItem('templates') && JSON.parse(localStorage.getItem('templates')) || [];
	constructor(){}
	create(template){
		template.id = Math.floor(Math.random() * Math.floor(10000));
		this.templates.push(template);
		localStorage.setItem('templates', JSON.stringify(this.templates));
	}
	delete(template){
		localStorage.setItem('templates', JSON.stringify(this.templates));
	}
	add(template){
		if(!template.objects) template.objects=[];
		template.objects.push({});
		localStorage.setItem('templates', JSON.stringify(this.templates));
	}
}
