import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpService } from '@services';
@Component({
	inputs: ['field'],
	selector: 'field-direct',
	templateUrl: './direct.component.html',
	styleUrls: ['./direct.component.scss']
})
export class DirectComponent implements OnInit {
	@Output('update') update = new EventEmitter();
	public field;
	constructor(private http: HttpService) {}
	public _values = {};
	set(){
		this.field.value = '';
		for(let each in this._values){
			if(!this._values[each]) continue;
			if(this.field.value){
				this.field.value+=', '+each;
			}else{
				this.field.value=each;
			}
		}
		this.update.emit();
	}
	ngOnInit(){
		if(typeof this.field.data == 'string'){
			this.field.data = JSON.parse(this.field.data);
		}
		if(this.field.type=='checkboxes' && this.field.value){
			let values = this.field.value.split(', ');
			for(let i = 0; i < values.length; i++){
				this._values[values[i]]=true;
			}
		}
	}
	afterWhile(){
		this.http.afterWhile(this, ()=>{
			this.update.emit();
		});
	}
}
