import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { UserService } from './user.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})

export class GroupsService {
	public uri = environment.api;
	public groups;
	public statuses;
  public statuses_selected;
  public _statuses_selected;
  constructor(private http: HttpClient,
		private https: HttpService,
		private us: UserService) {
    this.getStatuses();
    this.getGroups();
  }
  	getStatuses() {
  		this.https.apiget('/tasks/statuses/get', (resp:any)=> {
  			this.statuses = resp;
        this.statuses_selected = resp.slice();
        this.https.set('taskstatuses');
  		});
  	}
  	getGroups() {
  		this.http.get(`${this.uri}/tasks/statuses/groups`).subscribe((resp:any)=> {
  			this.groups = resp;
  		});
  	}
  	createStatus(title, group, color) {
      this.http.post(`${this.uri}/tasks/statuses/create`, { 
        color: color,
        group: group,
        title: title
      }).subscribe((resp:any)=> {
         this.getStatuses();
      });
    }
    deleteStatus(id, move_to) {
       this.http.post(`${this.uri}/tasks/statuses/delete`, {
        id: id,
        move_to: move_to
       }).subscribe((resp:any)=> {
         this.getStatuses();
      });;
    }
    updateStatus(status) {
      this.http.post(`${this.uri}/tasks/statuses/update`, {
        id: status.id,
        title: status.title,
        color: status.color
      }).subscribe((resp:any)=> {
         this.fetchStatus(status);
      });
    }
    fetchStatus(status) {
      this.http.post(`${this.uri}/tasks/statuses/update`,  {id: status.id});
    }
}

