import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { GroupsService } from './groups.service';
import { UserService } from './user.service';
import { SocketService } from './socket.service';
import * as moment from 'moment';
@Injectable({
	providedIn: 'root'
})
export class TaskService {
	constructor(private http: HttpService, private gr: GroupsService, public socket: SocketService, public us: UserService) {
	}
	/*
	*	Pagination
	*/
	public now = new Date().getTime();
	refresh(){
	    this.now = new Date().getTime();
	}
	public page: number = 1;
	public perPages = [10, 20, 50, 100];
	public perPage:any = 50;
	private perPageLast = this.perPage;
	paginate(event){
		this.page = event.pageIndex+1;
		this.perPage = event.pageSize;
		if(this.perPageLast!=this.perPage){
			this.perPageLast=this.perPage;
			this.page = 1;
		}
		localStorage.setItem('rfqPerPage', this.perPage);
		this.load({
			page: this.page,
			perPage: this.perPage,
			sort: this.sort,
			sortby: this.sortby
		});
	}
	public mine_tasks = false;
	public participants = [];
	public observers = [];
	public tasks:any = [];
	public mytasks: any = [];
	public tags = [];
	public files: any = [];
	public notes:any = [];
	public total: number = 0;
	public refill:any;
	public search = '';
	public sortby = localStorage.getItem('tasksortby') || 'id';
	public sort = localStorage.getItem('taskSort') || 'desc';
	public filters: any = {};
	public filtersApplied: any = {};
	public filtersIdsArray;
	setsortby(sortby){
		this.sortby = sortby.active;
		this.sort = sortby.direction;
		localStorage.setItem('tasksortby', this.sortby);
		localStorage.setItem('taskSort', this.sort);
		this.load({
			page: this.page,
			perPage: this.perPage,
			sort: this.sort,
			sortby: this.sortby
		});
	}
	load(opts:any={}, cb=(resp:any)=>{}){
		this.filtersIdsArray = [];
		for(let key in this.filters){
			if(!this.filters[key]) delete this.filters[key];
		}
		delete this.filters.assigned_type;
		this.filtersApplied = Object.assign({}, this.filters);
		for(let i = 0; i < this.participants.length; i++) {
			this.filtersIdsArray.push(this.participants[i]);
		}
		for(let i = 0; i < this.observers.length; i++) {
			this.filtersIdsArray.push(this.observers[i]);
		}
		for(let i = 0; i < this.tags.length; i++) {
			this.filtersIdsArray.push(this.tags[i]);
		}
		let params:any = {};
		if(opts.page) params.page = opts.page;
		if(opts.perPage) params.per_page = opts.perPage;
		if(opts.sort) params.sort = opts.sort;
		if(opts.sortby) {
			params.sortby = opts.sortby;
		} else {
			params.sortby = 'id';
		}
		if(this.search) params.search = this.search;
		if(this.filters.assigned_id) {
			this.filters.assigned_type = 'client';
		}
		if(this.filters) {
			for(const key in this.filters) {
				if(!this.filters[key]) continue;
				if(key=='date_from'||key=='date_to'||key=='deadline_from'||key=='deadline_to') {
					params['filters['+key+']'] = moment(this.filters[key]).format('YYYY-MM-DD');
				} else {
					params['filters['+key+']'] = this.filters[key];
				}
			}
		}
		if(this.gr && this.gr.statuses_selected) {
			for(let i = 0; i < this.gr.statuses_selected.length; i++) {
				params['filters[status_id]['+i+']'] = this.gr.statuses_selected[i].id;
			}
		}
		if(this.participants) {
			for(let i = 0; i < this.participants.length; i++) {
				params['filters[participant_id]['+i+']'] = this.participants[i].id;
			}
			
		}
		if(this.observers) {
			for(let i = 0; i < this.observers.length; i++) {
				params['filters[observer_id]['+i+']'] = this.observers[i].id;
			}
			
		}
		if(this.tags) {
			for(let i = 0; i < this.tags.length; i++) {
				params['filters[tag_id]['+i+']'] = this.tags[i].id;
			}
		}
		this.http.c_get('tasks', (resp:any) => {
			for(let i = 0; i < resp.data.length; i++){
				if(opts.replace){
					for(let each in opts.replace){
						if(typeof opts.replace[each] == 'function'){
							resp.data[i][each] = opts.replace[each](resp.data[i]);
						}
					}
				}
			}
			this.tasks = resp.data;
			this.total = resp.total;
			if(this.refill) this.refill();
			cb(this.tasks);
		}, params);
	}
	loadAfterWhile(){
		this.http.afterWhile(this, this.load.bind(this), 2000);
	}

	getTasks(cb=(resp:any)=>{}) {
		if(this.us.me.id) {
			this.http.apiget('/tasks/get?page=1'+'&per_page=9999'+'&sortby=id'+'&sort=desc'+'&filters[participant_id][0]=' + this.us.me.id , (resp:any) => {
				this.mytasks = resp.data;
				cb(this.mytasks);
			});
		}
		
	}
	public fetched = {};
	fetch(id: number, cb=resp=>{}) {
		if (!id) { return; }
		if(!this.fetched[id]){
			this.fetched[id] = {};
			this.http.apipost('/tasks/fetch', {
				id: id
			}, resp=>{
				for(let each in resp){
					this.fetched[id][each] = resp[each];
				}
				cb(this.fetched[id]);
			});
		}else if(!this.fetched[id].id){
			setTimeout(()=>{
				this.fetch(id, cb);
			}, 100);
		}else{
			cb(this.fetched[id]);
		}
		return this.fetched[id];
	}
	save(task, cb=resp=>{}, ignore={}){
		if(!task) return;
		this.http.c_update('tasks', task, resp=>{
			this.socket.emit('task_refresh', resp);
			cb(resp);
		}, ignore);
	}
	delete(task_id, cb=resp=>{}) {
		this.http.apipost('/tasks/delete', {id: task_id}, resp=>{
			this.socket.emit('task_refresh', resp);
			cb(resp);
		});
	}
	create(task, cb=resp=>{}) {
		this.http.apipost('/tasks/create', task, resp=>{
			this.socket.emit('task_refresh', resp);
			cb(resp);
		});
	}
	updateChecklists(data, cb=resp=>{}) {
		this.http.apipost('/tasks/checklists/update', data, resp=>{
			this.socket.emit('task_refresh', resp);
			cb(resp);
		});
	}
	updateStatus(task, cb=resp=>{}) {
		this.http.apipost('/tasks/status', task, resp=>{
			this.socket.emit('task_refresh', resp);
			cb(resp);
		});
	}
	createNote(note, cb=resp=>{}) {
		this.http.apipost('/tasks/notes/create', note, cb);
	}
	updateNote(note, cb=resp=>{}) {
		this.http.apipost('/tasks/notes/update', note, cb);
	}
	deleteNote(note_id, cb=resp=>{}) {
		this.http.apipost('/tasks/notes/delete', {id: note_id}, cb);
	}
	getNotes(task_id, cb=resp=>{}) {
		this.http.apiget('/tasks/notes/get?task_id='+task_id, (resp:any) => {
			this.notes = resp;
		});
	}
	getAttachments(task_id) {
		this.http.apiget('/tasks/attachments/get?task_id='+task_id, (resp:any) => {
			this.files = resp;
		});
	}
	updateAttachment(data, cb=resp=>{}){
		this.http.apipost('/tasks/attachments/update', data, cb);
	}
	deleteAttachment(attachment_id, cb=resp=>{}) {
		this.http.apipost('/tasks/attachments/delete', {id: attachment_id}, cb);
	}
	deleteNoteAttachment(data, cb=resp=>{}) {
		this.http.apipost('/notes/attachments/delete', data, cb);
	}
	
}
