import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'activity',
	inputs: ['activity'],
	templateUrl: './activity.component.html',
	styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {
	public fake = {};

	@Input ('activity') activity;
	constructor() {}
	
	ngOnInit() {}
	
}
