import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ServicesComponent } from './services.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedPipes } from '@pipes';
import { FieldsModule } from '../../Fields/fields/fields.module';
import { AttachmentModule } from '../../Attachment/attachment/attachment.module';
import { CustomMaterialModule } from '@root/modules/template';

import { AttachmentsComponent } from './attachments/attachments.component';
import { AttachmentsComponent as Attachments } from './attachments_direct/attachments.component';
import { ProductsComponent } from './products/products.component';
import { ProductsComponent as Products } from './products_direct/products.component';

import { WizardComponent } from './wizard/wizard.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
	declarations: [
		AttachmentsComponent,
		Attachments,
		ProductsComponent,
		Products,
		ServicesComponent,
		WizardComponent
	],
	exports: [ServicesComponent],
	imports: [
		NgScrollbarModule,
		FieldsModule,
		TextFieldModule,
		CommonModule,
		SharedPipes,
		FormsModule,
		FlexLayoutModule,
		AttachmentModule,
		CustomMaterialModule
	]
})
export class ServicesModule {}