import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from '@env';

@Injectable({
	providedIn: 'root'
})
export class CustomFieldService {
	uri = environment.api;
	public fields = [];
	public now = new Date().getTime();
	refresh(){
		this.now = new Date().getTime();
	}
	constructor(private http: HttpService) {
		this.http.apiget('/clients/fields/get', fields=>{
			this.fields = fields;
			this.http.set('field_load');
		});
	}
	create(field, cb=created=>{}){
		this.http.apipost('/clients/fields/create', field, created=>{
			this.fields.push(created);
			this.refresh();
			cb(created);
		});
	}
	update(field, cb=updated=>{}) {
		this.http.apipost('/clients/fields/update', field, updated=>{
			const updatedField = this.fields.find(fld=>fld.id===field.id);
			this.fields[this.fields.indexOf(updatedField)] = field;
			this.refresh();
			cb(updated);
		});
	}
	delete(id: number, cb=()=>{}) {
		this.http.apipost('/clients/fields/delete', {id: id}, deleted=>{
			const deletedField = this.fields.find(fld=>fld.id===id);
			this.fields.splice(this.fields.indexOf(deletedField),1);
			this.refresh();
			cb();
		});
	}
}
