import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@services';

import { AppBlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';

const routes: Routes = [{
	path: '',
	component: AppBlankComponent,
	children: [{
		path: '',
		loadChildren: () => import('./modules/Users/Login/login/login.module').then(m => m.LoginModule)
	}, {
		path: 'login',
		loadChildren: () => import('./modules/Users/Login/login/login.module').then(m => m.LoginModule)
	}, {
		path: 'quote/v/:_id',
		loadChildren: () => import('./modules/Estimate/View/view/view.module').then(m => m.ViewModule)
	}, {
		path: 'invoice/v/:_id',
		loadChildren: () => import('./modules/Invoice/View/view/view.module').then(m => m.ViewModule)
	}, {
		path: '',
		loadChildren: () => import('./modules/Users/Reset/reset/reset.module').then(m => m.ResetModule)
	}]
}, {
	path: '',
	component: FullComponent,
	canActivate: [AuthGuard],
	children: [{
		path: 'leads/map',
		loadChildren: () => import('./modules/Leads/Map/map/map.module').then(m => m.MapModule)
	}, {
		path: 'leads/calendar',
		loadChildren: () => import('./modules/Tasks/Calendar/leads-calendar/leads-calendar.module').then(m => m.LeadsCalendarModule)
	},  {
		path: 'workorders/map',
		loadChildren: () => import('./modules/Workorder/Map/map/map.module').then(m => m.MapModule)
	}, {
		path: 'tags',
		loadChildren: () => import('./modules/System/Tags/tags/tags.module').then(m => m.TagsModule)
	}, {
		path: 'time',
		loadChildren: () => import('./modules/Time/time/time.module').then(m => m.TimeModule)
	}, {
		path: 'tasks/list',
		loadChildren: () => import('./modules/Tasks/List/list/list.module').then(m => m.TasksListModule)
	}, {
		path: 'leads/list',
		loadChildren: () => import('./modules/Leads/List/list/lead-list.module').then(m => m.LeadListModule)
	}, {
		path: 'new/lead',
		loadChildren: () => import('./modules/Leads/Create/create/add-lead.module').then(m => m.AddLeadModule)
	},  {
		path:  'task/:taskid',
	 	loadChildren: () => import('./modules/Tasks/Profile/profile/edit.module').then(m => m.TaskEditModule)
	}, {
	// 	path: 'task/:id',
	// 	loadChildren: './modules/Tasks/List/edit/edit-router.module#TaskEditRouteModule'
	// }, {
		path: 'tasks/map',
		loadChildren: () => import('./modules/Tasks/Map/map/map.module').then(m => m.MapModule)
	}, {
		path: 'contacts/templates',
		loadChildren: () => import('./modules/Clients/Templates/templates/templates.module').then(m => m.TemplatesModule)
	}, {
		path: 'contacts/list',
		loadChildren: () => import('./modules/Clients/List/list/list.module').then(m => m.ListModule)
	}, {
		path: 'new/contact',
		loadChildren: () => import('./modules/Clients/List/list/list.module').then(m => m.ListModule)
	}, {
		path: 'roles/settings',
		loadChildren: () => import('./modules/System/Roles/List/list/list.module').then(m => m.ListModule)
	}, {
		path: 'users',
		loadChildren: () => import('./modules/Users/List/list/list.module').then(m => m.ListModule)
	}, {
		path: 'mysettings',
		loadChildren: () => import('./modules/System/Settings/settings/settings.module').then(m => m.SettingsModule)
	}, {
		path: 'dashboard',
		loadChildren: () => import('./modules/Dashboard/dashboard/dashboard.module').then(m => m.DashboardModule)
	}, {
		path: 'language/settings',
		loadChildren: () => import('./modules/System/Translate/List/list/list.module').then(m => m.ListModule)
	}, {
		path: 'quotes/list',
		loadChildren: () => import('./modules/Estimate/List/list/list.module').then(m => m.ListModule)
	}, {
		path: 'schema',
		loadChildren: () => import('./modules/Estimate/MapScheme/scheme/scheme.module').then(m => m.SchemeModule)
	}, {
		path: 'calendar',
		loadChildren: () => import('./modules/Calendar/calendar/calendar.module').then(m => m.CalendarModule)
	}, {
		path: 'services/list',
		loadChildren: () => import('./modules/Estimate/Services/services/services.module').then(m => m.ServicesModule)
	}, {
		path: 'quotes/offers/specialists',
		loadChildren: () => import('./modules/Estimate/Offers/Specialists/specialists/specialists.module').then(m => m.SpecialistsModule)
	}, {
		path: 'workorders/list',
		loadChildren: () => import('./modules/Workorder/List/list/list.module').then(m => m.ListModule)
	}, {
		path: 'quotes/offers/equipment',
		loadChildren: () => import('./modules/Estimate/Offers/Equipment/equipment/equipment.module').then(m => m.EquipmentModule)
	}, {
		path: 'quotes/offers/products',
		loadChildren: () => import('./modules/Estimate/Offers/Products/products/products.module').then(m => m.ProductsModule)
	}, {
		path: 'new/quote',
		loadChildren: () => import('./modules/Estimate/Create/create/create.module').then(m => m.CreateModule)
	}, {
		path: 'status/management',
		loadChildren: () => import('./modules/Estimate/Statuses/statuses/statuses.module').then(m => m.StatusesModule)
	}, {
		path: 'system/settings',
		loadChildren: () => import('./modules/System/System/system/system.module').then(m => m.SystemModule)
	}, {
		path: 'system/modules',
		loadChildren: () => import('./modules/System/Modules/modules.module').then(m => m.ModulesModule)
	}, {
		path: 'system/modules/tasks',
		loadChildren: () => import('./modules/System/Modules/tasks/tasks.module').then(m => m.TasksModule)
	}, {
		path: 'system/modules/tasks/statuses',
		loadChildren: () => import('./modules/System/Modules/tasks/statuses/statuses.module').then(m => m.StatusesModule)
	}, {
		path: 'system/modules/tasks/tags',
		loadChildren: () => import('./modules/System/Modules/tasks/tags/tags.module').then(m => m.TagsModule)
	}, {
		path: 'system/modules/clients',
		loadChildren: () => import('./modules/System/Modules/clients/clients.module').then(m => m.ClientsModule)
	}, {
		path: 'system/modules/clients/requirements',
		loadChildren: () => import('./modules/System/Modules/clients/requirements/requirements.module').then(m => m.RequirementsModule)
	}, {
		path: 'system/modules/quotes',
		loadChildren: () => import('./modules/System/Modules/quotes/quotes.module').then(m => m.QuotesModule)
	}, {
		path: 'system/modules/quotes/requirements',
		loadChildren: () => import('./modules/System/Modules/quotes/requirements/requirements.module').then(m => m.RequirementsModule)
	}, {
		path: 'system/modules/quotes/pdf',
		loadChildren: () => import('./modules/System/Pdf/pdf/pdf.module').then(m => m.PdfModule)
	}, {
		path: 'system/modules/equipments',
		loadChildren: () => import('./modules/System/Modules/equipments/equipments.module').then(m => m.EquipmentsModule)
	}, {
		path: 'system/modules/equipments/groups',
		loadChildren: () => import('./modules/System/Modules/equipments/groups/groups.module').then(m => m.GroupsModule)
	}, {
		path: 'system/modules/equipments/makes',
		loadChildren: () => import('./modules/System/Modules/equipments/makes/makes.module').then(m => m.MakesModule)
	}, {
		path: 'system/modules/equipments/services',
		loadChildren: () => import('./modules/System/Modules/equipments/services/services.module').then(m => m.ServicesModule)
	}, {
		path: 'system/modules/equipments/statuses',
		loadChildren: () => import('./modules/System/Modules/equipments/statuses/statuses.module').then(m => m.StatusesModule)
	}, {
		path: 'system/modules/equipments/repairs',
		loadChildren: () => import('./modules/System/Modules/equipments/repairs/repairs.module').then(m => m.RepairsModule)
	}, {
		path: 'system/modules/equipments/licenses',
		loadChildren: () => import('./modules/System/Modules/equipments/licenses/licenses.module').then(m => m.LicensesModule)
	}, {
		path: 'system/modules/equipments/reminders',
		loadChildren: () => import('./modules/System/Modules/equipments/reminders/reminders.module').then(m => m.RemindersModule)
	}, {
		path: 'order/settings',
		loadChildren: () => import('./modules/System/Order/order/order.module').then(m => m.OrderModule)
	}, {
		path: 'profile',
		loadChildren: () => import('./modules/Users/Profile/profile/profile.module').then(m => m.ProfileModule)
	}, {
		path: 'invoices/build',
		loadChildren: () => import('./modules/Invoice/Build/build/build.module').then(m => m.BuildModule)
	}, {
		path: 'invoices/list',
		loadChildren: () => import('./modules/Invoice/List/list/list.module').then(m => m.ListModule)
	}, {
		path: 'schedule',
		loadChildren: () => import('./modules/Schedule/schedule.module').then(m => m.CrewSCheduleModule)
	},{
		path: 'google-redirect',
		loadChildren: () => import('./modules/Mail/GoogleRedirect/google-redirect/google-redirect.module').then(m => m.GoogleRedirectModule)
	}, {
		path: 'mail',
		loadChildren: () => import('./modules/Mail/Mailbox/mailbox/mailbox.module').then(m => m.MailboxModule)
	},{
		path: 'templates/list',
		loadChildren: () => {
			return import('./templates/list/list.module').then(m => m.ListModule);
		}
	},

	/*EQUIPMENTS*/
	{
		path: 'equipments/list',
		loadChildren: () => {
			return import('./modules/Equipment/Equipments/equipments/equipments.module').then(m => m.EquipmentsModule);
		}
	},{
		path: 'equipment/reminders',
		loadChildren: () => {
			return import('./modules/Equipment/Reminders/reminders/reminders.module').then(m => m.RemindersModule);
		}
	},{
		path: 'equipment/reports',
		loadChildren: () => {
			return import('./modules/Equipment/Reports/reports/reports.module').then(m => m.ReportsModule);
		}
	},{
		path: 'equipment/requests',
		loadChildren: () => {
			return import('./modules/Equipment/Requests/requests/requests.module').then(m => m.RequestsModule);
		}
	},{
		path: 'equipment/:equipment_id',
		loadChildren: () => {
			return import('./modules/Equipment/Profile/profile/profile.module').then(m => m.ProfileModule);
		}
	},

	/*END OF EQUIPMENTS*/
	 {
		path: '',
		loadChildren: () => {
			return import('./modules/Clients/View/view/view.module').then(m => m.ViewModule);
		}
	}]
}, {path: "**", redirectTo: "/login"}];

/*
const routes: Routes = [{
	path: 'home',
	loadChildren: './home/home.module#HomeModule',
	canActivate: [AuthGuard]
}, {
	path: 'login',
	loadChildren: './auth/auth.module#AuthModule'
}, {
	path: 'login',
	loadChildren: './auth1/auth.module#AuthModule'
}, {
	path: 'login',
	loadChildren: './auth2/auth.module#AuthModule'
}, {
	path: 'List',
	loadChildren: './Clients/List/list/list.module#ListModule'
}, {path: "**", redirectTo: "/login"}];
*/

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
