import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class HttpService {
	public url = environment.api;
	constructor(private http: HttpClient) {}
	/*
	*	Token Handle
	*/
	apipost(url, data = {}, cb: any= resp => {}, errcb: any= resp => {}) {
		if (typeof data == 'function') {
			cb = data;
			data = {};
		}
		this.http.post < any > (this.url+url, data).subscribe(cb, errcb);
	}
	apiget(url, cb: any= resp => {}, errcb: any= resp => {}) {
		this.http.get < any > (this.url+url).subscribe(cb, e => {console.log(e); });
	}
	post(url, data = {}, cb: any= resp => {}, errcb: any= resp => {}) {
		if (typeof data == 'function') {
			cb = data;
			data = {};
		}
		this.http.post < any > (url, data).subscribe(cb, errcb);
	}
	get(url, cb: any= resp => {}, errcb: any= resp => {}) {
		this.http.get < any > (url).subscribe(cb, e => {console.log(e); });
	}
	upload(url, fields:any = {}, cb: any= resp => {}, errcb: any= resp => {}){
		this.http.post<any>(url, fields).subscribe(resp=>{
			cb(resp)
		});
	}
	/*
	*	CRUD Handle
	*/
	c_get(module, cb:any=()=>{}, opts={}) {
		let args = '';
		for(let each in opts){
			if(args){
				args += '&'+each+'='+opts[each];
			}else{
				args += '?'+each+'='+opts[each];
			}
		}
		const url = environment.api + '/' + module + '/get' + args;
		this.get(url, cb);
	}
		c_get_all(module, cb: any= () => {}) {
			const url = environment.api + '/' + module + '/getall';
			this.get(url, cb);
		}
		c_getAll(module, cb: any= () => {}) {
			const url = environment.api + '/' + module + '/get';
			this.get(url, cb);
		}
	c_create(module, doc, cb: any= () => {}) {
		const url = environment.api + '/' + module + '/create';
		this.post(url, doc, cb);
	}
	c_fetch(module, doc, cb: any= () => {}, error: any= () => {}) {
		const url = environment.api + '/' + module + '/fetch';
		this.post(url, doc.project_id && {
			project_id: doc.project_id
		} || {
			id: doc.id
		}, resp => {
			for (const each in resp) {
				doc[each] = resp[each];
			}
			cb(resp);
		}, error);
	}
	c_update(module, doc, cb = resp => {}, ignore:any={}) {
		doc = Object.assign({}, doc);
		delete doc._timeout;
		delete doc.updated_at;
		delete doc.deleted_at;
		if(!ignore.created_at){
			delete doc.created_at;
		}
		delete doc.email_verified_at;
		const url = environment.api + '/' + module + '/update';
		this.post(url, doc, cb);
	}
	c_delete(module, doc, cb: any= () => {}) {
		const url = environment.api + '/' + module + '/delete';
		this.post(url, {
			id: doc.id
		}, cb);
	}
	/*
	*	Supportive Scripts
	*/
	afterWhile(doc, cb: any= () => {}, timeout=1000) {
		clearTimeout(doc._timeout);
		doc._timeout = setTimeout(cb, timeout);
	}
	private once:any={};
	onceInTime(key, cb: any= () => {}, timeout=1000){
		if(this.once[key]) return;
		this.once[key] = true;
		cb();
		setTimeout(()=>{
			this.once[key] = false;
		}, timeout);
	}
	clean_address(which, cb: any= () => {}){
		delete which.address;
		delete which.full_address;
		delete which.flat_number;
		delete which.area;
		delete which.country;
		delete which.city;
		delete which.lat;
		delete which.lng;
		delete which.state;
		delete which.zip;
		delete which.billing_address;
		delete which.billing_flat_number;
		delete which.billing_area;
		delete which.billing_country;
		delete which.billing_city;
		delete which.billing_lat;
		delete which.billing_lng;
		delete which.billing_state;
		delete which.billing_zip;
		cb(which);
	}
	clean_billing_address(which){
		delete which.billing_address;
		delete which.billing_flat_number;
		delete which.billing_country;
		delete which.billing_city;
		delete which.billing_lat;
		delete which.billing_lng;
		delete which.billing_state;
		delete which.billing_zip;
	}
	clean_standart_address(which) {
		delete which.address;
		delete which.flat_number;
		delete which.country;
		delete which.city;
		delete which.lat;
		delete which.lng;
		delete which.state;
		delete which.zip;
	}
	to_address(where, from){
		if(from.address) where.address = from.address;
		if(from.flat_number) where.flat_number = from.flat_number;
		if(from.area) where.area = from.area;
		if(from.country) where.country = from.country;
		if(from.city) where.city = from.city;
		if(from.lat) where.lat = from.lat;
		if(from.lng) where.lng = from.lng;
		if(from.state) where.state = from.state;
		if(from.zip) where.zip = from.zip;
		if(from.billing_address) where.billing_address = from.billing_address;
		if(from.billing_flat_number) where.billing_flat_number = from.billing_flat_number;
		if(from.billing_area) where.billing_area = from.billing_area;
		if(from.billing_country) where.billing_country = from.billing_country;
		if(from.billing_city) where.billing_city = from.billing_city;
		if(from.billing_lat) where.billing_lat = from.billing_lat;
		if(from.billing_lng) where.billing_lng = from.billing_lng;
		if(from.billing_state) where.billing_state = from.billing_state;
		if(from.billing_zip) where.billing_zip = from.billing_zip;
	}
	address_to_billing(where) {
		where.billing_address = where.address;
		where.billing_flat_number = where.flat_number;
		where.billing_area = where.area;
		where.billing_country = where.country;
		where.billing_city = where.city;
		where.billing_lat = where.lat;
		where.billing_lng = where.lng;
		where.billing_state = where.state;
		where.billing_zip = where.zip;
	}
	billing_to_address(where, from) {
		if(from.billing_address) where.billing_address = from.billing_address;
		if(from.billing_flat_number) where.billing_flat_number = from.billing_flat_number;
		if(from.billing_area) where.billing_area = from.billing_area;
		if(from.billing_country) where.billing_country = from.billing_country;
		if(from.billing_city) where.billing_city = from.billing_city;
		if(from.billing_lat) where.billing_lat = from.billing_lat;
		if(from.billing_lng) where.billing_lng = from.billing_lng;
		if(from.billing_state) where.billing_state = from.billing_state;
		if(from.billing_zip) where.billing_zip = from.billing_zip;
	}
	standart_address_to_address (where, from) {
		if(from.address) where.address = from.address;
		if(from.flat_number) where.flat_number = from.flat_number;
		if(from.area) where.area = from.area;
		if(from.country) where.country = from.country;
		if(from.city) where.city = from.city;
		if(from.lat) where.lat = from.lat;
		if(from.lng) where.lng = from.lng;
		if(from.state) where.state = from.state;
		if(from.zip) where.zip = from.zip;
	}
	setFullAddress(data) {
		let full_address = '';
		if(data.address) {
			full_address += (full_address&&' ') + data.address + ',';
		}
		if(data.flat_number) {
			full_address += (full_address&&' ') + data.flat_number + ',';
		}
		if(data.area) {
			full_address += (full_address&&' ') + data.area + ',';
		}
		if(data.zip) {
			full_address += (full_address&&' ') + data.zip + ',';
		}
		if(data.city) {
			full_address += (full_address&&' ') + data.city + ',';
		}
		if(data.state) {
			full_address += (full_address&&' ') + data.state + ',';
		}
		if(data.country) {
			full_address += (full_address&&' ') + data.country + ',';
		}
		full_address = full_address.replace(/^\s+/g, '').slice(0, -1);
		return full_address;
	}
	private load = {};
	on( something, cb=()=>{} ) {
		if (typeof something == 'string') {
			something = something.split(' ');
		}
		if (!Array.isArray(something)) { return false; }
		for (let i = 0; i < something.length; i++) {
			if (!this.load[something[i]]) {
				return setTimeout(() => {
					this.on(something, cb);
				}, 100);
			}
		}
		cb();
	}
	set(something) {
		this.load[something] = true;
	}
	to_id(arr, cb:any=false, one = false){
		if(typeof arr != 'object' || arr == null) return one&&{}||[];
		if(typeof arr == 'object' && !Array.isArray(arr) || one){
			if(cb){
				return cb(arr);
			}else{
				return arr.id || arr;
			}
		}
		arr = arr.slice();
		for(let i = 0; i < arr.length; i++){
			if(cb){
				arr[i] = cb(arr[i]);
			}else{
				arr[i] = arr[i].id || arr[i];
			}
		}
		return arr;
	}
	server_date_handle(arr, field){
		if(Array.isArray(field)){
			for(let i = 0; i < field.length; i++){
				this.server_date_handle(arr, field[i]);
			}
			return;
		}
		if(Array.isArray(arr)){
			for(let i = 0; i < arr.length; i++){
				arr[i][field] = arr[i][field];
			}
		}else{
			arr[field] = arr[field];
		}
	}
	to_server_date(jsDate){
		if(!jsDate) return;
		if(typeof jsDate == 'string'){
			return jsDate;
		} else {
			return jsDate.getFullYear()+'-'+(jsDate.getMonth()+1)+'-'+jsDate.getDate();
		}
	}
	to_server_time(jsDate){
		if(typeof jsDate == 'string'){
			return jsDate;
		} else {
			return jsDate.getHours()+':'+(jsDate.getMinutes()+1)+':'+jsDate.getSeconds();
		}
	}
	/*
	*	End of HTTP service
	*/
}
