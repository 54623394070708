import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { UserService } from './user.service';
import { Observable, of, Subject } from 'rxjs';
import { StatusService } from './estimate/status.service';

@Injectable({
	providedIn: 'root'
})

export class TasksService {
	uri = environment.api;
	public newTypeTitle: Subject<any> = new Subject();
	public newTypeTitle$: Observable<any> = this.newTypeTitle.asObservable();
	constructor(private http: HttpClient, private us: UserService, private ss: StatusService) {}
	getTasks(page: number = 0, per_page: number = 50, sortby = 'id', sort = 'desc', filters: any = undefined, search: string='') {
		sortby = sortby.substr(0, sortby.lastIndexOf('_sort'));
		let filterStr = `?page=${page + 1}&per_page=${per_page}&sortby=${sortby}&sort=${sort}`;
		if (filters) {
			for (const key in filters) {
				filterStr += `&filters[${key}]=${filters[key]}`;
			}
		}
		if(search) filterStr += '&search='+search;
		return this.http.get(`${this.uri}/tasks/get${filterStr}`);
	}
	addTask(data: any) {
		if (!this.us.perm.createtasks) {
			return;
		}
		return this.http.post(`${this.uri}/tasks/create`, data);
	}
	deleteTask(task_id: number) {
		if (!this.us.perm.deletetasks) {
			return;
		}
		return this.http.post(`${this.uri}/tasks/delete`, {id: [task_id]});
	}
	deleteTasks(task_ids: Array<number>) {
		if (!this.us.perm.deletetasks) {
			return;
		}
		return this.http.post(`${this.uri}/tasks/delete`, {id: task_ids});
	}
	updateTask(task: any) {
		if (!this.us.perm.edittasks) {
			return;
		}
		return this.http.post(`${this.uri}/tasks/update`, task);
	}
	fetchTask(task_id: number) {
		if (!this.us.perm.viewtasks) {
			return;
		}
		return this.http.post(`${this.uri}/tasks/fetch`, {id: task_id});
	}
	taskStatusAssign(task_ids: Array<number>, status_id: string) {
		if (!this.us.perm.edittasks) {
			return;
		}
		return this.http.post(`${this.uri}/tasks/leads/status`, {id: [...task_ids], status_id});
	}
	taskUsersAssign(task_ids: Array<number>, user_id: number) {
		if (!this.us.perm.edittasks) {
			return;
		}
		return this.http.post(`${this.uri}/tasks/leads/assign`, {id: [...task_ids], user_id});
	}
	public getTaskTypes(): Observable<object> {
		return this.http.get(`${this.uri}/tasks/types/get`);
	}
	public updateTaskTypeTitle(id: number, title: string): Observable<object> {
		return this.http.post(`${this.uri}/tasks/types/update`, {id, title});
	}
	public getServices(): Observable<object> {
		return this.http.get(`${this.uri}/tasks/leads/services/get`);
	}
	public fetchTaskType(id: number): Observable<object> {
		return this.http.post(`${this.uri}/tasks/types/fetch`, {id});
	}
	public getLeads(page: number = 0, per_page: number = 50, sortby = 'id', sort = 'desc', filters: any = undefined, statuses:any = undefined): Observable<object> {
		sortby = sortby.substr(0, sortby.lastIndexOf('_sort'));
		let filterStr = `?page=${page + 1}&per_page=${per_page}&sortby=${sortby}&sort=${sort}`;
		if (filters) {
			for (const key in filters) {
				filterStr += `&filters[${key}]=${filters[key]}`;
			}
		}
		if(statuses){
			if(statuses.exclude){
				var index = 0;
				this.ss.statuses.leads.forEach((status) => {
					for(var i=0; i<statuses.exclude.length; i++){
						if(status[statuses.exclude[i]])
							return;
					}
					filterStr += '&filters[status_id]['+index+']='+status.id;
					index++;
				});
				
			}
		}
		return this.http.get(`${this.uri}/tasks/leads/get${filterStr}`);
	}
	public addLeadTask(data: any): Observable<object> {
		if (!this.us.perm.createtasks) {
			return;
		}
		return this.http.post(`${this.uri}/tasks/leads/create`, data);
	}
	public deleteLeadTask(task_id: number): Observable<object> {
		if (!this.us.perm.deletetasks) {
			return;
		}
		return this.http.post(`${this.uri}/tasks/leads/delete`, {id: [task_id]});
	}
	public deleteLeadTasks(task_ids: Array<number>): Observable<object> {
		if (!this.us.perm.deletetasks) {
			return;
		}
		return this.http.post(`${this.uri}/tasks/leads/delete`, {id: task_ids});
	}
	public updateLeadTask(task: any): Observable<object> {
		if (!this.us.perm.edittasks) {
			return;
		}
		return this.http.post(`${this.uri}/tasks/leads/update`,  task);
	}
	public fetchLeadTask(task_id: number): Observable<object> {
		return this.http.post(`${this.uri}/tasks/leads/fetch`, {id: task_id});
	}
	public getLeadStatuses(): Observable<object> {
		return this.http.get(`${this.uri}/tasks/leads/statuses/get`);
	}
	public addLeadStatus(status): Observable<object> {
		return this.http.post(`${this.uri}/tasks/leads/statuses/create`, status);
	}
	public deleteLeadStatus(statusId): Observable<object> {
		return this.http.post(`${this.uri}/tasks/leads/statuses/delete`, statusId);
	}
	public updateLeadStatus(status): Observable<object> {
		return this.http.post(`${this.uri}/tasks/leads/statuses/update`, status)
	}
	public addLeadStatusReason(title, status_id): Observable<object> {
		return this.http.post(`${this.uri}/tasks/leads/statuses/reasons/create`, {title, status_id});
	}
	public updateLeadStatusReason(reason): Observable<object> {
		return this.http.post(`${this.uri}/tasks/leads/statuses/reasons/update`, reason);
	}
	public deleteLeadStatusReason(id): Observable<object> {
		return this.http.post(`${this.uri}/tasks/leads/statuses/reasons/delete`, {id});
	}
	public leadStatusAssign(lead): Observable<object> {
		if (!this.us.perm.edittasks) {
			return;
		}
		return this.http.post(`${this.uri}/tasks/leads/update`, lead);
	}
	public leadUserAssign(lead): Observable<object> {
		if (!this.us.perm.edittasks) {
			return;
		}
		return this.http.post(`${this.uri}/tasks/leads/update`, lead);
	}
}