import { Component } from '@angular/core';
import { ServicesService, EstimateService, SpecialistsService, EquipmentService, AttachmentsService } from '@services';

@Component({
	inputs: ['estimate', 'service', 'update'],
	selector: 'app-wizard',
	templateUrl: './wizard.component.html',
	styleUrls: ['./wizard.component.scss']
})
export class WizardComponent{
	public estimate = {};
	public service:any = {};
	public update;
	constructor(public ss: ServicesService,
		public qs: EquipmentService,
		public ats: AttachmentsService,
		public ssp: SpecialistsService,
		public es: EstimateService){}
	save(){
		this.ss.calculate(this.service);
		this.es.calculate(this.estimate);
		this.es.refresh();
		if(typeof this.update == 'function'){
			this.update();
		}else{
			this.es.update_services(this.estimate);
		}
	}
	add_specialist(specialist){
		if(!Array.isArray(this.service.specialists)){
			this.service.specialists = [];
		}
		this.service.specialists.push(Object.assign({}, specialist));
		this.save();
	}
	add(equipment){
		if(!Array.isArray(this.service.setups)){
			this.service.setups = [];
		}
		this.service.setups.push({
			equipment: Object.assign({}, equipment),
			attachments: []
		});
		this.save();
	}
	addAttachment(attachment, setup){
		if(!Array.isArray(setup.attachments)){
			setup.attachments = [];
		}
		setup.attachments.push(Object.assign({}, attachment));
		this.save();
	}
}
