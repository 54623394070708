import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from '@services';

@Component({
	selector: 'attachment',
	inputs: ['file', 'visible'],
	templateUrl: './attachment.component.html',
	styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {
	@Output('delete') delete = new EventEmitter();
	public file: any;
	public showAtachInfo:any;
	public visible:any;
	constructor(public us: UserService) {}
	ngOnInit() {
		//console.log(this.file);
	}
}
