import { NgModule, forwardRef, Inject } from '@angular/core';
import { TranslateModule, MissingTranslationHandler,
	MissingTranslationHandlerParams } from '@ngx-translate/core';
import { Translate } from './translate.service';
export class MyMissingTranslationHandler implements MissingTranslationHandler {
	constructor( @Inject(forwardRef(() => Translate)) public translate:Translate){}
	handle(params: MissingTranslationHandlerParams) {
		let key = params.key.split('.');
		if(key.length<3) return;
		this.translate.set(params.key);
		this.translate.create(params.key);
		key.shift();
		key.shift();
		return key.join('.');
	}
}
@NgModule({
	exports: [ TranslateModule ],
	imports: [
		TranslateModule.forChild({
			missingTranslationHandler: {
				useClass: MyMissingTranslationHandler,
				provide: MissingTranslationHandler
			}
		})
	]
})
export class SharedModule {}
