import { Injectable } from '@angular/core';
import { environment } from '@env';
import { HttpService } from '../http.service';
import { SocketService } from '../socket.service';
@Injectable({
	providedIn: 'root'
})
export class FileService {
	private url = environment.api;
	constructor(private http: HttpService,
		private socket: SocketService){
		
	}
	upload(files, url, data={}, cb=resp=>{}){
		let formData: FormData = new FormData();
		for(let i=0; i<files.length; i++){
			formData.append("files["+i+"]", files[i]);
		}
		for(let each in data){
			formData.append(each, data[each]);
		}
		this.http.post(`${this.url}/clients/files/create`, formData, cb);
	}
	delete(files, url, data={}, cb=resp=>{}){
		let formData: FormData = new FormData();
		for(let i=0; i<files.length; i++){
			formData.append("files_delete["+i+"]", files[i]);
		}
		if(data){
			for(let each in data){
				formData.append(each, data[each]);
			}
		}
		this.http.post(`${this.url}/clients/files/delete`, formData, cb);
	}

	add(files, url, data={}, cb=resp=>{}){
		let formData: FormData = new FormData();
		for(let i=0; i<files.length; i++){
			let name = files[i].name;
			if(!name){
				name = 'Artwork '+new Date().toString().split(' GMT')[0]+'.png';
			}
			formData.append("files["+i+"]", files[i], name);
		}
		for(let each in data){
			formData.append(each, data[each]);
		}
		this.http.apipost(url, formData, cb);
	}

	addEquipmentAvatar(files, url, data={}, cb=resp=>{}){
		let formData: FormData = new FormData();
		for(let i=0; i<files.length; i++){
			let name = files[i].name;
			if(!name){
				name = 'Artwork '+new Date().toString().split(' GMT')[0]+'.png';
			}
			formData.append("avatar", files[i], name);
		}
		for(let each in data){
			formData.append(each, data[each]);
		}
		this.http.apipost(url, formData, cb);
	}
}