import { Component, OnInit } from '@angular/core';
import { TaskService, UserService, TrackerService, HttpService, SocketService } from '@services'; 
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
	selector: 'app-time',
	templateUrl: './time.component.html',
	styleUrls: ['./time.component.scss']
})
export class TimeComponent implements OnInit {
	public search;
	constructor(public ts: TaskService,
		public us: UserService, 
		public tr: TrackerService,
		public router: Router,
		public http: HttpService,
		public socket: SocketService) {
		this.http.on('me', ()=>{
			this.ts.getTasks();
		});
			

		this.tr.getCurrent((resp)=> {
			clearInterval(this.tr.get);
			if(resp) {
				this.tr.time = resp;
				if(this.tr.track && this.tr.current) {
					var now = moment.utc().format('YYYY-MM-DD HH:mm:ss');
					this.tr.track = moment(now).diff(this.tr.time.created_at, 'seconds');
					if(this.tr.time.assigned) {
						this.tr.current = (this.tr.time.assigned.human_total_minutes * 60) + this.tr.track;
					}
					this.tr.note = resp.note;
					this.tr.getTime();
				} else {
					clearInterval(this.tr.get);
					var now = moment.utc().format('YYYY-MM-DD HH:mm:ss');
					this.tr.track = moment(now).diff(this.tr.time.created_at, 'seconds');
					if(this.tr.time.assigned) {
						this.tr.current = (this.tr.time.assigned.human_total_minutes * 60) + this.tr.track;
					}
					this.tr.note = resp.note;
					this.tr.getTime();
				}
			} else {
				this.tr.time = null;
				this.tr.track = null;
				this.tr.current = null;
			}
		});
		
	}
	ngOnInit(): void {
		this.socket.on('time_refresh', time => {
			if(time != 'no' && time != null && typeof time !=  'number' && time != 'note') {
				//start tracker with a task
				clearInterval(this.tr.get);
				this.tr.getCurrent((resp)=> {
					this.tr.time = resp;
					this.tr.track = 0;
					if(this.tr.time.assigned_id) {
						this.tr.current = this.tr.time.assigned.human_total_minutes * 60;
						this.tr.getTaskTimes({
							assigned_id: this.tr.time.assigned_id, 
							assigned_type: this.tr.time.assigned_type, 
							sortby: 'start_time', 
							sort: 'desc'
						}, (times)=> {
				           this.tr.task_times[resp.assigned_id] = times;
				        });
						this.tr.getTime();
					}
					
				});
			} else if (time == null){
				//stop tracker
				let time = this.tr.time;
				if(this.tr.time && this.tr.time.assigned) {
					this.tr.getTaskTimes({
						assigned_id: this.tr.time.assigned_id, 
						assigned_type: this.tr.time.assigned_type, 
						sortby: 'start_time', 
						sort: 'desc'
					}, (times)=> {
			            this.tr.task_times[time.assigned_id] = times;
			        });
				}
				
				clearInterval(this.tr.get);
				this.tr.track = 0;
				this.tr.time = null;
				this.tr.note = '';
				this.tr.refresh();
			} else if (time == 'no') {
				//start tracker with no task
				clearInterval(this.tr.get);
				this.tr.getCurrent((resp)=> {
					this.tr.time = resp;
					this.tr.track = 0;
					this.tr.note = '';
					this.tr.getTime();
				});
			} else if (time == 'note') {
				//update track
				this.tr.getCurrent((resp)=> {
					this.tr.time = resp;
					this.tr.note = resp.note;
					if(resp.assigned_id) {
						this.tr.getTaskTimes({
							assigned_id: resp.assigned_id, 
							assigned_type: resp.assigned_type, 
							sortby: 'start_time', 
							sort: 'desc'
						}, (times)=> {
							this.tr.current = resp.assigned.human_total_minutes;
				            this.tr.task_times[resp.assigned_id] = times;
				        });
					}
				});
			} else if (typeof time ==  'number') {
				//update track with task
				this.tr.getCurrent((resp)=> {
					this.tr.time = resp;
					this.tr.current = time;
					this.tr.note = resp.note;
					this.tr.getTaskTimes({
						assigned_id: resp.assigned_id, 
						assigned_type: resp.assigned_type, 
						sortby: 'start_time', 
						sort: 'desc'
					}, (times)=> {
			           this.tr.task_times[resp.assigned_id] = times;
			        });
				});
			} 
		});
		this.socket.on('task_refresh', task=>{
			this.http.afterWhile(this, ()=>{
				this.ts.getTasks();
			},2000);
		});
	}
	getHours(time) {
		if(time) {
			var hours = Math.floor(time / 60);
			var min = time % 60;
			var string_min = '';
			var string_hours = '';
			if(min < 10) {
				string_min = '0' + min;
			} else {
				string_min = ''+min;
			}
			if(hours < 10) {
				string_hours = '0' + hours;
			} else {
				string_hours = ''+hours;
			}
			return string_hours + ':' + string_min;
		}
	}
	
	timepage() {
		this.router.navigate(['/time']);
	}

	fetchTaskData() {
		this.ts.getTasks();
	}

	fetchTaskDataAfter(){
		this.http.afterWhile(this, this.fetchTaskData.bind(this));
	}

	startWithNoTask() {
		this.tr.startTrack(); 
		
	}
	startWithTask(task, type) {
		this.tr.startTrack({id: task.id, type: type});
		this.tr.note = '';
	}
	stopTrack() {
		this.tr.time = null;
		this.tr.note = '';
		this.tr.stopTrack(); 
	}
	updateTrack(assigned?, type?) {
		if(assigned) this.tr.time.assigned = assigned; 
		this.tr.updateTrack({
			assigned: this.tr.time.assigned.id,
			type: type
		});
	}
	updateNote() {
		this.tr.updateTrack({
			note: this.tr.note,
		});
		this.tr.time.note = this.tr.note;
		
	}
	startNew() {
		this.tr.updateTrack({
			note: this.tr.note,
		});
		clearInterval(this.tr.get);
		this.tr.track = 0;
		this.tr.current = null;
		this.tr.getTime();
	}
}
