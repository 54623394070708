import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-postpone',
  templateUrl: './postpone.component.html',
  styleUrls: ['./postpone.component.scss']
})
export class PostponeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
