import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService, CropService } from '@services';
export interface CompanyType {
  value: string;
  viewValue: string;
}
export interface Country {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {
  public companyTypes: CompanyType[] = [
    { value: '1', viewValue: 'Tree service' },
    { value: '2', viewValue: 'Water service' },
    { value: '3', viewValue: 'Fly service' }
  ];
  public countrys: Country[] = [
    { value: 'country1', viewValue: 'USA' },
    { value: 'country2', viewValue: 'Canada' }
  ];
  constructor(private dialogRef: MatDialogRef<StepsComponent>,
    private crop: CropService,
    public us: UserService) {}
  ngOnInit() {}
  save() {
    this.dialogRef.close();
  }
  close() {
    this.dialogRef.close();
  }
  panelOpenState = false;
  step = null;
  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }
  /*
	*	Cropper
	*/
    add(fileEvent, which, resizeToWidth, aspectRatio) {
      this.crop.open({fileEvent, resizeToWidth, aspectRatio }, dataUrl => {
        if (dataUrl) {
          this.us.settings.logo[which] = dataUrl;
          this.us.save_status[1] = true;
        }
      });
    }
    primary(obj, field) {
      for (let i = 0; i < this.us.settings.contacts[field].length; i++) {
        this.us.settings.contacts[field][i].primary = null;
      }
      obj.primary = '1';
    }
    primaryTaxes(obj) {
      for (let i = 0; i < this.us.settings.taxes.length; i++) {
        this.us.settings.taxes[i].primary = null;
      }
      obj.primary = '1';
    }
  /*
	*	End of steps
	*/
}
