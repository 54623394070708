import { Component, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddComponent } from './add/add.component';
import { UserService, ProductsService } from '@services';

@Component({
	inputs: ['custom_class','header', 'data', 'columns'],
	selector: 'simple_table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})
export class SimpleTableComponent implements OnInit {
	@Output('create') create = new EventEmitter();
	@Output('update') update = new EventEmitter();
	@Output('delete') delete = new EventEmitter();
	public columns:any;
	public custom_class='';
	public header;
	public data;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	public dataSource:any;

	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
	constructor(private dialog: MatDialog) {}
	public displayedColumns = [];
	refresh(){
		this.dataSource = new MatTableDataSource<any>(this.data);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;		
	}
	ngOnInit(){
		this.data = this.data.slice();
		for(let i = 0; i < this.columns.length; i++){
			this.displayedColumns.push(this.columns[i].col);
		}
		this.displayedColumns.push('options');
		this.refresh();
	}
	delete_item(item){
		this.delete.emit(item);
		for(let i = 0; i < this.data.length; i++){
			if(this.data[i].id == item.id){
				this.data.splice(i, 1);
				return this.refresh();
			}
		}
	}
	open(item=null) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = Object.assign({}, {
			columns: this.columns,
			item: item
		});
		this.dialog.open(AddComponent, dialogConfig).afterClosed().subscribe(doc => {
			if(item && item.id && doc){
				for(let each in doc){
					item[each] = doc[each];
				}
				for(let each in item){
					item[each] = doc[each];
				}
				this.update.emit(doc);
			}else if(!item && doc){
				this.data.unshift(doc);
				this.create.emit(doc);
			}
			this.refresh();
		});
	}
	show(value){
		if(typeof value == 'boolean'){
			return value && 'Yes' || 'No';
		}
		return value;
	}
}