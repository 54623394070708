import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class BaseService {
	public data:any;
	public module:any = {};
	set(base:any){
		this.data = base;
		for(let modul in base.modules) {
			for(let model in base.modules[modul].models) {
				var obj = {};
				var obj1 = {};
				for(let enums in base.modules[modul].models[model].enums) {
					obj[enums] = base.modules[modul].models[model].enums[enums];
					this.module[model] = obj;
					obj1[enums] = this.convertArrayToObject(base.modules[modul].models[model].enums[enums], 'id');
					this.module['_'+ model] = obj1; 
				}
			}
		}
		console.log(this.module);
	}
	get(value, cb=resp=>{}){
		if(this.data) cb(this.data[value]);
		else setTimeout(()=>{
			this.get(value, cb);
		}, 100);
	}
	convertArrayToObject(array, key) {
  		const initialValue = {};
		return array.reduce((obj, item) => {
			return {
  				...obj,
  				[item[key]]: item,
			};
		}, initialValue);
	}

	constructor() {}
}
