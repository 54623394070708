import { Directive, Injector, Input, OnInit, Provider, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl } from '@angular/forms';

export const MASKEDINPUT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DynamicMasksDirectiveInput),
  multi: true
}

@Directive({
	selector: '[dynamicPhoneMasksInput]',
	exportAs: 'dynamicPhoneMasksInput',
	providers: [MASKEDINPUT_VALUE_ACCESSOR],
	host: {
    '(input)': 'onChange($event.target.value)',
    '(blur)': 'onTouched()',
    //'(compositionstart)': '_compositionStart()',
    //'(compositionend)': '_compositionEnd($event.target.value)'
  },
})
export class DynamicMasksDirectiveInput implements OnInit, ControlValueAccessor {
	constructor(public model: NgControl){

	}

	onChange = (_: any) => {}
  onTouched = () => {}

  ngOnInit(){

	}

	writeValue(value: any) {
    console.log(value);
  }

  registerOnChange(fn: (_: any) => void): void { this.onChange = fn }
	registerOnTouched(fn: () => void): void { this.onTouched = fn }
	
}