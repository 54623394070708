import { Component, Inject, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpService, ClientService, AlertService } from '@services';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

@Component({
	selector: 'app-quote-confirm',
	templateUrl: './quote-confirm.component.html',
	styleUrls: ['./quote-confirm.component.scss']
})
export class QuoteConfirmComponent{
	public showSign;
	@ViewChild(SignaturePad, { static: false }) public signaturePad: SignaturePad;
	@ViewChildren('sigContainer1') public sigContainer1;
	@ViewChild('photos', { static: false }) photos: ElementRef;
	public sign;
	private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
		'backgroundColor': "rgb(255,245,227)"
	};
	public id: any = false;
	public amount_disabled = false;
	public min = 0;
	constructor(private dialogRef: MatDialogRef<QuoteConfirmComponent>,
		public cs: ClientService, public alert: AlertService,
		private http: HttpService, @Inject(MAT_DIALOG_DATA) data) {
		if(data && data.id){
			this.id = data.id;
			this.method = data.payment_method;
			this.type = data.payment_type;
		}
		if(data && data.estimate_id){
			this.estimate_id = data.estimate_id;
		}
		if(data && data.status_id){
			this.status_id = data.status_id;
		}
		if(data && data.amount_disabled){
			this.amount_disabled = data.amount_disabled;
		}
		if(data && data.amount){
			this.min = Number(data.amount);
		}
		this.amount = data.amount || 0;
	}
	ngAfterViewInit() { }

	size() {
		if(!this.sigContainer1.first){
			return setTimeout(()=>{
				this.size();
			}, 50);
		}
		this.signaturePad.set('canvasWidth', this.sigContainer1.first.elementRef.nativeElement.clientWidth);
		this.signaturePad.set('canvasHeight', this.sigContainer1.first.elementRef.nativeElement.clientHeight);
		this.clearSign();
	}

	undoSign(){
		let data = this.signaturePad.toData();
		if (data) {
			data.pop();
			this.signaturePad.fromData(data);
		}
	}
	clearSign(){
		if(this.signaturePad) this.signaturePad.clear();
	}
	saveSign(){
		this.showSign = false;
		if(this.sign){
			var byteString = atob(this.sign.split(',')[1]);
			var mimeString = this.sign.split(',')[0].split(':')[1].split(';')[0];
			var ab = new ArrayBuffer(byteString.length);
			var ia = new Uint8Array(ab);
			for (var i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}
			let file:any = new Blob([ab], {
				type: mimeString
			});
			file.name = 'sign.png';
			file.title = 'sign.png';
			this.files.push(file);
		}
	}
	drawComplete() {
		this.sign=this.signaturePad.toDataURL();
	}
	close() {
		this.dialogRef.close();
	}
	public files:any = [];
	add(files){
		for(let i = 0; i < files.length; i++){
			files[i]['title']=files[i].name;
			this.files.push(files[i]);
		}
		this.photos.nativeElement.value = null;
	}
	public status_id;
	public note;
	create(){
		if(this.type=='deposit'){
			if(!this.amount) return this.alert.fail('Add amount');
			if(this.amount<this.min) return this.alert.fail('Add amount at least '+this.min);
		} 
		if(!this.estimate_id) return this.alert.fail('Select Quote');
		if(!this.files.length) return this.alert.fail('Add atleast one file or sign');
		let formData: FormData = new FormData();
		if(this.files.length){
			for(let i=0; i < this.files.length; i++){
				formData.append("files["+i+"]", this.files[i]);
			}
		}
		formData.append('id', this.estimate_id);
		formData.append('payment_method', this.method.toLowerCase());
		formData.append('payment_type', this.type.toLowerCase());
		formData.append('amount', this.amount.toString());
		formData.append('status_id', this.status_id);
		if(this.note){
			formData.append('note', this.note.toString());
		}
		
		this.http.post(this.http.url+'/estimates/update', formData, resp => {
			if(resp.project&&resp.project.client_id){
				this.cs.force_fetch(resp.project.client_id);
			}
			this.dialogRef.close(resp.project.files);
		}, error => {
			this.alert.fail(error.message);
			this.dialogRef.close();
		});
	}


	public types = ['deposit' ,'signature', 'purchase', 'other'];
	public types_names = {
		other: 'Other',
		purchase: 'Purchase order',
		deposit: 'Deposit',
		signature: 'Signature'
	};
	public type:any = this.types[0];
	public methods = ['cash', 'check', 'debit card', 'card', 'e-transfer', 'creditcard'];
	public methods_names = {
		cash: 'Cash',
		check: 'Check',
		'debit card': 'Debit Card',
		card: 'Card',
		'e-transfer': 'E-Transfer',
		'creditcard': 'Credit Card'
	};
	public method = this.methods[0];
	public amount = 0;
	public estimate_id;
}
