import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DynamicMasksDirectiveInput } from './phone.mask.directive';
import { PhoneMaskByCodePipe } from './phone.mask.pipe';

@NgModule({
  declarations: [DynamicMasksDirectiveInput, PhoneMaskByCodePipe],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [DynamicMasksDirectiveInput, PhoneMaskByCodePipe]
})
export class DynamicMasksModule { }