import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpService, ClientService, AlertService } from '@services';

@Component({
	selector: 'app-add-payment',
	templateUrl: './add-payment.component.html',
	styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent {
	public id: any = false;
	public amount_disabled = false;
	constructor(private dialogRef: MatDialogRef<AddPaymentComponent>,
		public cs: ClientService,
		public alert: AlertService,
		private http: HttpService,
		@Inject(MAT_DIALOG_DATA) data) {
			if(data && data.id){
				this.id = data.id;
				this.method = data.payment_method;
				this.type = data.payment_type;
			}
			if(data && data.estimate_id){
				this.estimate_id = Number(data.estimate_id);
			}
			if(data && data.amount_disabled){
				this.amount_disabled = data.amount_disabled;
			}
			this.amount = data.amount || 0;
		}
	close() {
		this.dialogRef.close();
	}
	public types = ['deposit' ,'invoice'];
	public types_names = {
		deposit: 'Deposit' ,
		invoice: 'Invoice'
	};
	public type:any = this.types[0];
	public methods = ['cash', 'check', 'debit', 'card', 'etransfer', 'creditcard'];
	public methods_names = {
		cash: 'Cash',
		check: 'Check',
		debit: 'Debit',
		card: 'Card',
		etransfer: 'E-Transfer',
		creditcard: 'Credit Card'
	};
	public method = this.methods[0];
	public amount = 0;
	public estimate_id:any;
	public files:any = [];
	create(){
		if(!this.amount) return this.alert.fail('Add amount');
		if(!this.estimate_id) return this.alert.fail('Select Quote');
		if(!this.files.length && !this.id) return this.alert.fail('Add atleast one file');
		let formData: FormData = new FormData();
		if(this.files.length){
			for(let i=0; i < this.files.length; i++){
				formData.append("files["+i+"]", this.files[i]);
			}
		}
		if(this.id){
			formData.append('payment_id', this.id);
		}
		formData.append('estimate_id', this.estimate_id);
		formData.append('client_id', this.cs.client.id);
		formData.append('payment_method', this.method.toLowerCase());
		formData.append('payment_type', this.type.toLowerCase());
		formData.append('amount', this.amount.toString());
		this.http.post(this.http.url+'/payments/'+(this.id&&'update'||'create'), formData, resp => {
			if(this.id){
				for(let i = 0; i < this.cs.payments.length; i++){
					if(this.cs.payments[i].id == this.id){
						this.cs.payments[i] = resp;
						break;
					}
				}
			}else{
				this.cs.payments.push(resp);
			}
			if(this.cs.refill_payments) this.cs.refill_payments();
			this.dialogRef.close(true);
		}, error => {
			this.alert.fail(error.message);
		});
	}
	add(files){
		for(let i = 0; i < files.length; i++){
			files[i]['title']=files[i].name;
			this.files.push(files[i]);
		}
	}
}
