import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService, UiService } from '@services';

@Component({
	selector: 'app-delete',
	templateUrl: './delete.component.html',
	styleUrls: ['./delete.component.scss']
})

export class DeleteComponent {
	public status: any;
	public statuses: any = [];
	public sts:any = [];
	constructor(private dialogRef: MatDialogRef<DeleteComponent>,
		public ui: UiService,
		public us: UserService,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		this.statuses.push(this.data.status);
	}
	cancel() {
		this.dialogRef.close();
	}
	save() {
		this.dialogRef.close(this.status);
	}
}
