import { Injectable } from '@angular/core';
import { TaskService } from './task.service';
import { UserService } from './user.service';
import { ClientService } from './client/client.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class HistoryService {

	constructor(private router: Router,
		private ta: TaskService,
		private cs: ClientService,
		private us: UserService) {
		router.events.subscribe( (event: Event) => {
			if (event instanceof NavigationEnd) {
				this.push_page(event.urlAfterRedirects);
				// Hide loading indicator
			}
			if (event instanceof NavigationStart) {
				// Show loading indicator
			}
			if (event instanceof NavigationError) {
				// Hide loading indicator
				// Present error to user
				console.log(event.error);
			}
		});
	}
	public pages = localStorage.getItem('pagesHistory') && JSON.parse(localStorage.getItem('pagesHistory')) || [];
	public page:any = localStorage.getItem('pageHistory') && JSON.parse(localStorage.getItem('pageHistory')) || null;
	process(url){
		for(let i = this.pages.length-1; i>=0; i--){
			if(this.pages[i].url == url){
				this.pages.splice(i, 1);
			}
		}
		if(Array.isArray(this.page.name)){
			for(let i = 0; i < this.page.name.length; i++){
				this.page.name[i] = this.page.name[i].charAt(0).toUpperCase() + this.page.name[i].slice(1);
			}
			this.page.name = this.page.name.join(' ');
		}
		this.pages.unshift(this.page);
		localStorage.setItem('pagesHistory', JSON.stringify(this.pages));
		localStorage.setItem('pageHistory', JSON.stringify(this.page));
	}
	push_page(url){
		if(url.toLowerCase().indexOf('-redirect')>-1) return;
		if(url.toLowerCase().indexOf('login')>-1) return;
		this.page = {
			url: url,
			name: url.split('/')
		};
		/*
		*	If contact link
		*/
			if(this.page.name[1].toLowerCase()=="contact"){
				url = this.page.url = '/' + this.page.name[2];
				this.page.name = ['', this.page.name[2]];
			}
			if(this.page.name[1]==Number(this.page.name[1])){
				return this.cs.fetch(this.page.name[1], (client:any)=>{
					this.page.client_id = client.id;
					this.page.name = client.name;
					this.process(url);
				});
			}
		/*
		*	If task link
		*/
			if(this.page.url.indexOf('/task/') > -1) {
				var title = parseInt(this.page.url.replace('/task/', ''));
				return this.ta.fetch(title, (task)=> {
					this.page.name = task.title;
					this.process(url);
				});
			}
		/*
		*	End of urls management
		*/
		this.process(url);
	}
	shift_page(){
		this.pages.shift();
		localStorage.setItem('pagesHistory', JSON.stringify(this.pages));
		localStorage.setItem('pageHistory', JSON.stringify(this.page));
	}
}
