import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { EstimateService } from './estimate.service';
import { UserService } from '../user.service';
import { ClientService } from '../client/client.service';
import { Router } from '@angular/router';
import { StatusService } from '../estimate/status.service';
import { LeadService } from '../task/lead.service';

@Injectable({
	providedIn: 'root'
})
export class DraftService {
	/*
	*	Preferences
	*/
		public expire_days:any = localStorage.getItem('estimate_expire_days') || 30;
		save_expire_days(){
			this.expire_days = Math.floor(this.expire_days);
			if(this.expire_days<0) this.expire_days=0;
			localStorage.setItem('estimate_expire_days', this.expire_days);
		}
	/*
	*	Draft
	*/
	public drafts = localStorage.getItem('q-drafts') && JSON.parse(localStorage.getItem('q-drafts')) || [];
	public draft:any = localStorage.getItem('q-draft') && JSON.parse(localStorage.getItem('q-draft')) || {};
	public now = new Date().getTime();
	refresh(){
		this.now = new Date().getTime();
	}
	public submited = false;
	constructor(private http: HttpService,
		private ls: LeadService,
		private ss: StatusService,
		private es: EstimateService,
		private cs: ClientService,
		private router: Router,
		private us: UserService) {
		this.expire_days = Math.floor(this.expire_days);
		if(!this.draft.data){
			this.draft.__fetch = true; 
		}
		this.select(this.draft);
	}
	load(cb:any=()=>{}){
		this.http.apiget('/drafts/get', drafts=>{
			this.drafts = drafts;
			localStorage.setItem( 'q-drafts', JSON.stringify(this.drafts) );
			if(!drafts.length){
				this.create();
			} else if(!this.draft.id) {
				this.select(drafts[0]);
			}
			cb();
		});
	}
	create(prefix='', data:any={}, cb:any=()=>{}){
		this.http.on('me', ()=>{
			let now = new Date();
			if(!data) data={};
			if(!data.date) data.date = now;
			if(!data.expire_date) data.expire_date = new Date(now.getTime()+(this.expire_days*86400000));
			data.tax_id = this.us.settings.taxes[0].id;
			data.tax = this.us.settings.taxes[0];
			this.http.apipost('/drafts/create', {
				user_id: this.us.me.id,
				model: 'common',
				name: (prefix||'')+this.us.me.name+'_'+(now.getMonth()+1)+'/'+now.getDate()+'_'+now.getHours()+':'+now.getMinutes(),
				data: data
			}, draft=>{
				this.select(draft);
				this.drafts.push(draft);
				localStorage.setItem( 'q-drafts', JSON.stringify(this.drafts) );
				cb();
			});
		});
	}
	fetch(id){
		if(!id) return;
		this.http.apipost('/drafts/fetch', {
			id: id
		}, this.select.bind(this));
	}								
	select(draft){
		if(!draft.data) return this.fetch(draft.id);
		if(Array.isArray(draft.data)){
			draft.data = {
				services: []
			};
		}
		if(!draft.data.services){
			draft.data.services = [];
		}
		if(!draft.data.price){
			draft.data.price = 0;
		}
		this.draft = draft;
		localStorage.setItem( 'q-draft', JSON.stringify(draft) );
		if(this.draft.__fetch) return this.fetch(draft.id);
		this.es.calculate(this.draft.data);
		this.submited = false;
	}
	update(){
		if(!this.draft.id) return;
		this.refresh();
		this.http.afterWhile(this, ()=>{
			this.http.apipost('/drafts/update', this.draft);
			localStorage.setItem( 'q-drafts', JSON.stringify(this.drafts) );
			localStorage.setItem( 'q-draft', JSON.stringify(this.draft) );
		});
	}
	delete(id, cb=()=>{}){
		for(let i = 0; i < this.drafts.length; i++){
			if(this.drafts[i].id == id){
				this.drafts.splice(i, 1);
				break;
			}
		}
		if(this.drafts.length){
			this.select(this.drafts[0]);
			localStorage.setItem( 'q-drafts', JSON.stringify(this.drafts) );
		} else {
			this.create();
		}
		this.http.apipost('/drafts/delete', {
			id: id
		}, cb);
	}
	get_by_lead(lead_id){
		for(let i = 0; i < this.drafts.length; i++){
			if(this.drafts[i].name.indexOf('from_lead_'+lead_id+'_')==0){
				return this.drafts[i];
			}
		}
		return null;
	}
	create_from_lead_with_client(lead){
		lead.extra.task_lead_status_id = this.ss.lead_quoted.id;
		lead.extra.status = this.ss.lead_quoted;
		this.ls.save({
			id: lead.id,
			extra: {
				task_lead_status_id: lead.extra.task_lead_status_id
			}
		});
		let draft:any = this.get_by_lead(lead.extra.id);
		let now = new Date();
		if(draft){
			this.select(draft);
			return this.router.navigate(['/new/quote']);
		}
		draft = {
			lead: lead,
			client: lead.client,
			services: [],
			estimate_number: lead.extra.id,
			user_id: lead.creator_id,
			date: now,
			expire_date: new Date(now.getTime()+2592000000),
			client_id: lead.client_id
		};
		this.http.to_address(draft, lead);
		if(!lead.client) lead.client={};
		if(lead.client.primary){
			for(let i = 0; i < lead.client.primary.length; i++){
				if(draft.contact_phone_id && draft.contact_email_id) break;
				if(lead.client.primary[i].primary_email){
					draft.contact_email_id = lead.client.primary[i].primary_email.id;
					draft.contact_email = lead.client.primary[i].primary_email;
				}
				if(lead.client.primary[i].primary_number){
					draft.contact_number_id = lead.client.primary[i].primary_number.id;
					draft.contact_number = lead.client.primary[i].primary_number;
				}
			}
		}
		if(lead.extra.contact_number_id){
			draft.contact_number_id = lead.extra.contact_number_id;
			draft.contact_number = lead.extra.contact_number;
		}
		if(lead.extra.contact_email_id){
			draft.contact_email_id = lead.extra.contact_email_id;
			draft.contact_email = lead.extra.contact_email;
		}
		this.es.initialize(draft);
		this.create('lead_'+lead.extra.id+'_', draft, new_draft=>{
			this.router.navigate(['/new/quote']);
		});
	}
	create_from_lead(lead){
		if(lead.client) this.create_from_lead_with_client(lead);
		else{
			this.cs.fetch(lead.client_id, client=>{
				lead.client = client;
				this.create_from_lead_with_client(lead);
			});
		}
	}
}
