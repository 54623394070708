import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { HttpService } from './http.service';
import { AlertService } from './alert.service';

@Injectable({
	providedIn: 'root'
})
export class TagsService {
	public tags:any = {};
	public _tags:any = {};
	public kinds = ['contacts', 'invoices', 'leads'];
	public kind = this.kinds[0];
	public now = new Date().getTime();
	refresh(){
		this.now = new Date().getTime();
	}
	constructor(private us: UserService,
		private http: HttpService,
		private alert: AlertService) {
		this.us.base('tags', resp=>{
			this.tags = resp;
		});
	}
	create(tag, which, cb=created=>{}, url='clients'){
		if(this.kinds.indexOf(which)==-1) return;
		for(let i = 0; i < this.tags[which].length; i++){
			if(this.tags[which][i].title.toLowerCase() == tag.title.toLowerCase()){
				 return cb(this.tags[which][i]);
			}
		}
		this.http.apipost('/'+url+'/tags/create', tag, created=>{
			this.tags[which].push(created);
			this.refresh();
			cb(created);
		});

	}
	update(tag, which){}
	delete(tagId, which){}
}