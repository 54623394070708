import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { SocketService } from '../socket.service';
import { AlertService } from '../alert.service';
import { BaseService } from '../base.service';
import { CategoriesService } from '../categories.service';
@Injectable({
  providedIn: 'root'
})
export class EqItemService {

  constructor(private http: HttpService,
		private socket: SocketService,
		private alert: AlertService,
		private bs: BaseService,
		public ct: CategoriesService) {
  	this.load({
  		page: this.page,
		perPage: this.perPage,
		sort: this.sort,
		sortby: this.sortby
  	});
  	this.getEquipmentStatuses();
  	this.getMakes();
  	this.ct.getTags('equipments');
  }
  /*
	*	Pagination
	*/
	public now = new Date().getTime();
	refresh(){
	    this.now = new Date().getTime();
	}
	public page: number = 1;
	public perPages = [10, 20, 50, 100];
	public perPage:any = 50;
	private perPageLast = this.perPage;
	paginate(event){
		this.page = event.pageIndex+1;
		this.perPage = event.pageSize;
		if(this.perPageLast!=this.perPage){
			this.perPageLast=this.perPage;
			this.page = 1;
		}
		localStorage.setItem('rfqPerPage', this.perPage);
		this.load({
			page: this.page,
			perPage: this.perPage,
			sort: this.sort,
			sortby: this.sortby
		});
	}
	public equipments:any = [];
	public tags = [];
	public total: number = 0;
	public refill:any;
	public search = '';
	public sortby = localStorage.getItem('equipmentitemsortby') || 'id';
	public sort = localStorage.getItem('equipmentitemSort') || 'asc';
	public filters: any = {};
	public filtersApplied: any = {};
	public filtersIdsArray;
	setsortby(sortby){
		this.sortby = sortby.active;
		this.sort = sortby.direction;
		localStorage.setItem('equipmentitemsortby', this.sortby);
		localStorage.setItem('equipmentitemSort', this.sort);
		this.load({
			page: this.page,
			perPage: this.perPage,
			sort: this.sort,
			sortby: this.sortby
		});
	}
	load(opts:any={}, cb=(resp:any)=>{}){
		this.filtersIdsArray = [];
		for(let key in this.filters){
			if(!this.filters[key]) delete this.filters[key];
		}
		this.filtersApplied = Object.assign({}, this.filters);
		for(let i = 0; i < this.tags.length; i++) {
			this.filtersIdsArray.push(this.tags[i]);
		}
		let params:any = {};
		if(opts.page) params.page = opts.page;
		if(opts.perPage) params.per_page = opts.perPage;
		if(opts.sort) params.sort = opts.sort;
		if(opts.sortby) {
			params.sortby = opts.sortby;
		}
		if(this.filters) {
			for(const key in this.filters) {
				if(!this.filters[key]) continue;
				params['filters['+key+']'] = this.filters[key];
			}
		}
		if(this.tags) {
			for(let i = 0; i < this.tags.length; i++){
				params['filters[tag_id]['+i+']'] = this.tags[i].id;
			}
		}
		if(this.search) params.search = this.search;
		if(this.statuses_selected) {
			for(let i = 0; i < this.statuses_selected.length; i++) {
				params['filters[status_id]['+i+']'] = this.statuses_selected[i].id;
			}
		}
		if(this.groups_selected) {
			for(let i = 0; i < this.groups_selected.length; i++) {
				params['filters[group_id]['+i+']'] = this.groups_selected[i].id;
			}
		}
		if(this.tags) {
			for(let i = 0; i < this.tags.length; i++) {
				params['filters[tag_id]['+i+']'] = this.tags[i].id;
			}
		}
		this.http.c_get('equipments', (resp:any) => {
			for(let i = 0; i < resp.data.length; i++){
				if(opts.replace){
					for(let each in opts.replace){
						if(typeof opts.replace[each] == 'function'){
							resp.data[i][each] = opts.replace[each](resp.data[i]);
						}
					}
				}
			}
			this.equipments = resp.data;
			this.total = resp.total;
			if(this.refill) this.refill();
			cb(this.equipments);
		}, params);
	}
	loadAfterWhile(){
		this.http.afterWhile(this, this.load.bind(this), 2000);
	}
	public fetched = {};
	fetch(id: number, cb=resp=>{}) {
		if (!id) { return; }
		if(!this.fetched[id]){
			this.fetched[id] = {};
			this.http.apipost('/equipments/fetch', {
				id: id
			}, resp=>{
				for(let each in resp){
					this.fetched[id][each] = resp[each];
				}
				cb(this.fetched[id]);
			});
		}else if(!this.fetched[id].id){
			setTimeout(()=>{
				this.fetch(id, cb);
			}, 100);
		}else{
			cb(this.fetched[id]);
		}
		return this.fetched[id];
	}
	public copy: any = {};
	public groups_selected;
	public statuses_selected;
	save(equipment, cb=resp=>{}, ignore={}){
		if(!equipment) return;
		this.http.c_update('equipments', equipment, cb, ignore);
	}
	delete(equipment_id, cb=resp=>{}) {
		this.http.apipost('/equipments/delete', {id: equipment_id}, cb);
	}
	create(equipment, cb=resp=>{}) {
		this.http.apipost('/equipments/create', equipment, cb);
	}

	/* Makes Managment */
	public makes:any = [];

	createMake(make, cb=resp=>{}) {
		this.http.apipost('/equipments/makes/create', make, cb);
	}

	getMakes() {
		this.http.apiget('/equipments/makes/get', (resp)=> {
			this.makes = resp;
		});
	}

	deleteMake(make_id, cb=resp=>{}) {
		this.http.apipost('/equipments/makes/delete', {id: make_id }, cb);
	}
	/* Note Managment */
	createNote(note, cb=resp=>{}) {
		this.http.apipost('/equipments/notes/create', note, cb);
	}
	updateNote(note, cb=resp=>{}) {
		this.http.apipost('/equipments/notes/update', note, cb);
	}
	deleteNote(note_id, cb=resp=>{}) {
		this.http.apipost('/equipments/notes/delete', {id: note_id}, cb);
	}
	deleteNoteAttachment(data, cb=resp=>{}) {
		this.http.apipost('/notes/attachments/delete', data, cb);
	}
	/*Attachment Managment*/
	updateAttachment(data, cb=resp=>{}){
		this.http.apipost('/equipments/attachments/update', data, cb);
	}
	deleteAttachment(attachment_id, cb=resp=>{}) {
		this.http.apipost('/equipments/attachments/delete', {id: attachment_id}, cb);
	}
	//Equipment Statuses

	public statuses: any = [];
	getEquipmentStatuses(cb=resp=>{}) {
		this.http.apiget('/equipments/statuses/get', (resp)=> {
			this.statuses = resp;
			this.http.set('equipmentstatus');
			cb(resp);
		});
	}
	createEquipmentStatus(status, cb=resp=>{}) {
		this.http.apipost('/equipments/statuses/create', status, cb);
	}
	updateEquipmentStatus(status, cb=resp=>{}) {
		this.http.apipost('/equipments/statuses/update', status, cb);
	}
	deleteEquipmentStatus(status, cb=resp=>{}) {
		this.http.apipost('/equipments/statuses/delete', status, cb);
	}
}
