import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

@Injectable({
	providedIn: 'root'
})
export class MapService {
	public markers:any = [];
	public lat = localStorage.getItem('office_lat')||0;
	public lng = localStorage.getItem('office_lng')||0;
	save(){
		localStorage.setItem('office_lat', this.lat.toString());
		localStorage.setItem('office_lng', this.lng.toString());
	}
	constructor(private mal: MapsAPILoader) {
		this.lat = Number(this.lat);
		this.lng = Number(this.lng);
	}
	calculate(lat, lng, cb){
		this.mal.load().then(() => {
			var date = new Date();
			var direction = {};
			var travelTime = 0;
			var travelHrs = 0;
			var daysToWed = new Date().getDay() <= 2 ? 3 - new Date().getDay() : 7 - new Date().getDay() + 3;
			date.setDate(date.getDate() + daysToWed);
			let test:any = google;
			new google.maps.DirectionsService().route({
				origin: new google.maps.LatLng(Number(this.lat), Number(this.lng)),
				destination: new google.maps.LatLng(Number(lat), Number(lng)),
				travelMode: test.maps.DirectionsTravelMode.DRIVING,
				drivingOptions: {
					departureTime: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 7, 30),
					trafficModel: google.maps.TrafficModel.PESSIMISTIC
				}
			}, function(direction:any, status) {
				if (status == google.maps.DirectionsStatus.OK) {
					if(direction.routes[0] != undefined && direction.routes[0].legs[0] != undefined && direction.routes[0].legs[0].duration != undefined &&
						direction.routes[0].legs[0].duration.value != undefined && direction.routes[0].legs[0].duration_in_traffic != undefined && direction.routes[0].legs[0].duration_in_traffic.value != undefined)
						travelTime = direction.routes[0].legs[0].duration_in_traffic.value * 2;//(direction.routes[0].legs[0].duration.value + direction.routes[0].legs[0].duration_in_traffic.value);
					travelHrs = parseFloat((parseFloat((travelTime / 3600 / 15).toFixed(3)) * 15).toFixed(1));
					cb(travelHrs);
				}else cb(0);
			});
		});
	}
}