import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AlertComponent } from '../common/Alert/alert/alert.component';

@Injectable({
	providedIn: 'root'
})
export class AlertService {
	constructor(private snackBar: MatSnackBar) {}
	public alertConfig = new MatSnackBarConfig();
	success(message: string) {
		this.alertConfig.panelClass = 'style-success';
		this.alertConfig.duration = 2000;
		this.alertConfig.horizontalPosition = 'center';
		this.alertConfig.verticalPosition = 'bottom';
		this.alertConfig.data = {
			message: message,
			type: 'success'
		}
		this.snackBar.openFromComponent(AlertComponent, this.alertConfig);
	}
	fail(message: string) {
		this.alertConfig.panelClass = 'style-success';
		this.alertConfig.duration = 4000;
		this.alertConfig.horizontalPosition = 'center';
		this.alertConfig.verticalPosition = 'bottom';
		this.alertConfig.data = {
			message: message,
			type: 'fail'
		}
		this.snackBar.openFromComponent(AlertComponent, this.alertConfig);
	}
	yes(message: string, cbyes = () => {}, cbno = () => {}) {
		this.alertConfig.panelClass = 'style-success';
		this.alertConfig.duration = 1000000;
		this.alertConfig.horizontalPosition = 'left';
		this.alertConfig.verticalPosition = 'top';
		this.alertConfig.data = {
			message: message,
			type: 'confirm',
			yes: cbyes,
			no: cbno
		}
		this.snackBar.openFromComponent(AlertComponent, this.alertConfig);
	}
}
