import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequirementsComponent } from './requirements.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomMaterialModule } from '@root/modules/template';


@NgModule({
	exports: [RequirementsComponent],
	declarations: [RequirementsComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		CustomMaterialModule
	]
})
export class RequirementsModule { }
