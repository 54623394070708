import { Component, OnInit, Inject, ViewChild, ElementRef, Output, Input, EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder, FormArray, Validators, ValidatorFn, AbstractControl, FormGroup, FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HttpService } from '@services';
@Component({
	selector: 'address',
	templateUrl: './address.component.html',
	styleUrls: []
})
export class AddressComponent implements OnInit {

	public fields = ['address', 'city', 'state', 'zip', 'country', 'flat_number', 'area'];

	display_fields = {
		billing:true,
		address:true,
		city:true,
		state:true,
		zip:true,
		country:true,
		flat_number:true,
		area:true,
		full_address: false
	};

	display_billing_fields = {
		billing_address:true, 
		billing_city:true,
		billing_state:true, 
		billing_zip:true, 
		billing_country:true, 
		billing_flat_number:true, 
		billing_area:true
	};

	countries:any[] = [
		{name: 'Canada', value: 'Canada'},
		{name: 'Gabon', value: 'Gabon'},
		{name: 'USA', value: 'USA'}
	];

	valueChanged: Subject<any> = new Subject<any>();

	@Input ('address') defaultAddress:any;
	@Input ('billing_same_checkbox') defaultHideBillingAddress:boolean;
	@Input ('billing_address') defaultBillingAddress:any;
	@Input ('show') show_obj:any;
	@Input ('show_billing') show_billing_obj:any;
	@Input ('hideBillingAddress') hideBillingForm = false;
	@Input ('hideCheckbox') hideCheckbox = false;
	@Input ('hideStandartAddress') hideStandartForm = false;
	@Input ('hidePlaceholder') hidePlaceholder = false;
	@Output('onchange') valueChange = new EventEmitter();
	@Output('oncheckboxchanged') checkboxChange = new EventEmitter();
	@Output('onlocationselected') onlocationselected = new EventEmitter();
	hideBillingAddress:boolean = true;

	constructor(private fb: FormBuilder, public http: HttpService) { }
	ngOnInit() {
		if(this.show_obj) this.display_fields = this.show_obj;
		if(this.show_billing_obj) this.display_fields = this.show_billing_obj;
		if(this.hideCheckbox&&this.hideBillingForm){
			this.http.clean_billing_address(this.defaultAddress);
		}
		this.valueChanged.pipe(
			debounceTime(300)
		).subscribe(()=>{
			if((!this.hideCheckbox||!this.hideBillingForm)&&this.hideBillingAddress){
				this.http.address_to_billing(this.defaultAddress);
			}
			this.valueChange.emit(this.defaultAddress);
		});
		if(this.defaultHideBillingAddress===false){
			this.hideBillingAddress = false;
		}
		setTimeout(()=> {
			this.onBillingAddress();
		}, 1000);
	}

	onLocationSelected(e) {
		this.defaultAddress.lng = e.longitude;
		this.defaultAddress.lat = e.latitude;
		this.valueChanged.next();
	}

	onAutocompleteSelected(e, prefix: string = '') {
		setTimeout(()=> {
			let address = '';
			let cityFound = false;
			e.address_components.forEach(element => {
				if (element.types[0] && element.types[0] == 'locality') {
					cityFound = true;
					this.defaultAddress[prefix + 'city'] = element.long_name;
				} else if (element.types[0] && element.types[0] == 'administrative_area_level_1') {
					this.defaultAddress[prefix + 'state'] = element.long_name;
				} else if (element.types[0] && element.types[0] == 'country') {
					this.defaultAddress[prefix + 'country'] = element.long_name;
				} else if (element.types[0] && element.types[0] == 'postal_code') {
					this.defaultAddress[prefix + 'zip'] = element.long_name;
				} else if (element.types[0] && element.types[0] == 'sublocality_level_1'){
					this.defaultAddress[prefix + 'area'] = element.long_name;
				} else if (!cityFound) {
					address +=  (address&&' ') + element.long_name;
					address = address.replace(/^\s+/g, '');
				}
			});	

			this.defaultAddress[prefix + 'address'] = address;
			this.defaultAddress[prefix + 'flat_number'] = '';
			this.defaultAddress.full_address = this.http.setFullAddress(this.defaultAddress);
			this.onlocationselected.emit(this.defaultAddress);
			this.valueChanged.next();
		}, 100);
		
	}

	onBillingAddress() {
		if (this.hideBillingAddress) {
			this.http.address_to_billing(this.defaultAddress);
			this.valueChanged.next();
		}
		this.checkboxChange.emit(this.hideBillingAddress);
	}
	clearMatMenuAddress() {
		if(!this.defaultAddress.full_address) {
			this.http.clean_address(this.defaultAddress);
		}
	}
}