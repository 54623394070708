import { Component, OnInit, Inject, NgZone, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaskService, FileService, SocketService } from '@services';
@Component({
	selector: 'app-note',
	templateUrl: './note.component.html',
	styleUrls: ['./note.component.scss']
})
export class NoteComponent {
	public note: any = {};
	public files_delete: any = [];
	public attachments: any = [];
	constructor(public ts: TaskService, public file: FileService, public socket: SocketService, public dialogRef: MatDialogRef<NoteComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
		if(this.data.note) {
			this.note = this.data.note;
			if(this.data.note.attachments&&this.data.note.attachments.length > 0)  {
				this.attachments = this.data.note.attachments.slice();
			}
		}
	}

	create() {
		if(this.note.id) {
			this.ts.updateNote({
				task_id: this.data.task.id, 
				id: this.note.id, 
				note: this.note.note
			}, ()=> {
				let counter = 0;
				if(this.files_delete.length > 0) {
					counter++;
					this.ts.deleteNoteAttachment({id: this.files_delete, note_id: this.note.id}, (resp)=> {
						this.note.attachments = resp;
						if(--counter===0) this.ts.getAttachments(this.data.task.id);
						this.socket.emit('task_refresh', this.data.task);
					});
				}
				for (let i = this.attachments.length-1; i >= 0; i--){
					if(this.attachments[i].id) {
						this.attachments.splice(i, 1);
					}
				}
				if(this.attachments.length > 0) {
					counter++;
					this.file.add(this.attachments, '/notes/attachments/create', {
						task_id: this.data.task.id,
						note_id: this.note.id
					}, (created)=> {
						for (var i = 0; i < this.data.task.notes.length; i++){
							if(this.data.task.notes[i].id == this.note.id) {
								for (var j = created.length - 1; j >= 0; j--) {
									this.data.task.notes[i].attachments.unshift(created[j]);
								}
							}
						}
						if(--counter===0) this.ts.getAttachments(this.data.task.id);
						this.socket.emit('task_refresh', this.data.task);
					});
				}
				if(counter===0) this.ts.getAttachments(this.data.task.id);
				this.socket.emit('task_refresh', this.data.task);
			});
		} else {
			this.ts.createNote({
				task_id: this.data.task.id, 
				note: this.note.note,
			}, (resp)=> {
				if(this.attachments.length){
					this.file.add(this.attachments, '/notes/attachments/create', {
						task_id: this.data.task.id,
						note_id: resp.id
					}, created=> {
						for (var i = 0; i < this.data.task.notes.length; i++){
							if(this.data.task.notes[i].id == resp.id) {
								this.data.task.notes[i].attachments = created;
								this.ts.refresh();
							}
						}
						this.ts.getAttachments(this.data.task.id);
						this.socket.emit('task_refresh', this.data.task);
					});
				}
				this.data.task.notes.unshift(resp);
				this.ts.refresh();
				this.socket.emit('task_refresh', this.data.task);
			});
		}
		
	}

	close() {
		this.dialogRef.close();
	}
	attach(fileEvent) {
		for(let i = 0; i < fileEvent.target.files.length; i++){
			fileEvent.target.files[i].new = true;
			fileEvent.target.files[i].title = fileEvent.target.files[i].name;
			this.attachments.push(fileEvent.target.files[i]);
		}
	}
	remove(file) {
		if(file.id) this.files_delete.push(file.id);
	}

}
