import { Component, OnInit } from '@angular/core';
import { RoutingService, UserService, Translate, ConstructorService } from '@services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  public path;
  public subdomain = window.location.host;
  constructor(public routingService: RoutingService,
  	public translate: Translate,
  	private constructorService: ConstructorService,
  	public us: UserService){
  	this.path=window.location.pathname;
  }
}
