import * as $ from 'jquery';
import { MediaMatcher } from '@angular/cdk/layout';
import {
	ChangeDetectorRef,
	Component,
	NgZone,
	OnDestroy,
	ViewChild,
	HostListener,
	Directive,
	AfterViewInit
} from '@angular/core';
import { AppHeaderComponent } from './header/header.component';
import { AppSidebarComponent } from './sidebar/sidebar.component';
import { UserService, SearchService, UiService, ClientService, EstimateService, InvoiceService, LeadService, WorkorderService, StatusService, HistoryService } from '@services';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
/** @title Responsive sidenav */
@Component({
	selector: 'app-full-layout',
	templateUrl: 'full.component.html',
	styleUrls: []
})
export class FullComponent implements OnDestroy, AfterViewInit {
	public showSearch;
	mobileQuery: MediaQueryList;
	dir = 'ltr';
	green: boolean;
	blue: boolean;
	dark: boolean;
	minisidebar: boolean;
	boxed: boolean;
	danger: boolean;
	showHide: boolean;
	sidebarOpened;
	search;
	togo=['contacts', 'leads', 'quotes', 'workorders', 'invoices'];
	public show_all_pages;

	public config: PerfectScrollbarConfigInterface = {};
	private _mobileQueryListener: () => void;
	@ViewChild('snav', {static: false}) snav;
	constructor(
		changeDetectorRef: ChangeDetectorRef,
		media: MediaMatcher,
		public router: Router,
		public us: UserService,
		public ss: SearchService,
		public cs: ClientService,
		public es: EstimateService,
		public is: InvoiceService,
		public ls: LeadService,
		public ws: WorkorderService,
		public sts: StatusService,
		public ui: UiService,
		public hi: HistoryService
		) {
		router.events.subscribe((val:any) => {
			this.mobileQuery = media.matchMedia('(min-width: 959px)');
			this._mobileQueryListener = () => changeDetectorRef.detectChanges();
			this.mobileQuery.addListener(this._mobileQueryListener);

			if(this.snav&&val.urlAfterRedirects&&!this.mobileQuery.matches){
				this.snav.close()
			}
		});
	}

	ngOnDestroy(): void {
		this.mobileQuery.removeListener(this._mobileQueryListener);
	}
	ngAfterViewInit() {
		// This is for the topbar search
		// (<any>$('.srh-btn, .cl-srh-btn, .search-inner_close')).on('click', function() {
			// 	(<any>$('.app-search')).toggle(200);
			// });
			// This is for the megamenu
		}

		// Search

		dropdownSettings = {};
		closeDropdownSelection=false;
		ngOnInit() {
			this.dropdownSettings = {
				singleSelection: false,
				idField: 'id',
				textField: 'name',
				selectAllText: 'Select all',
				unSelectAllText: 'Unselect all',
				itemsShowLimit: 1,
				allowSearchFilter: false
			};
		}
		onItemSelect(item: any) {
			if(this.search){
				this.ss.search(this.ss.classes,this.search);
			}
		}
		onSelectAll(items: any) {
			if(this.search&&!!this.ss.classes.length){
				this.ss.search(this.ss.classes,this.search);
			}
		}
		showAll(c_id){
			switch (c_id){
				case "clients": {
					c_id="contacts";
					this.cs.search=this.search;
					this.sts.quotes_statuses = this.sts.statuses.quotes.slice();
					break;
				}
				case "leads": {
					this.ls.search=this.search;
					this.sts.leads_statuses = this.sts.statuses.quotes.slice();
					break;
				}
				case "estimates": {
					c_id="quotes";
					this.es.search=this.search;
					this.sts.quotes_statuses = this.sts.statuses.quotes.slice();
					break;
				}
				case "invoices": {
					this.is.search=this.search;
					this.sts.invoices_statuses = this.sts.statuses.quotes.slice();
					break;
				}
				case "workorders": {
					this.ws.search=this.search;
					this.sts.workorders_statuses = this.sts.statuses.quotes.slice();
					break;
				}
			}
			this.router.navigate(['/'+c_id+'/list']);
		}
	}