import { Component, OnInit, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormArray, Validators, ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';
import { UserService, ClientService, TaskService, AuthService, SocketService, TaskCategoryService, TaskPriorityService, CategoriesService, HttpService, AlertService, FileService, HistoryService } from '@services';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ContactComponent } from '../../../Create/Contact/contact/contact.component';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators'; 
import { Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
	public form: any;
	constructor(form: any){
		this.form = form;
	}
	isErrorState(control:any): boolean {
		return !control.value && this.form.formSubmitted;
	}
}

@Component({
	selector: 'app-task',
	templateUrl: './task.component.html',
	styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnInit {
	@ViewChild('addressform', { static: true }) addressFormRef:any;
	@Output('fload') fload = new EventEmitter();
	public now;
	public valid = false;
	public hideplannedtime: boolean = false;
	public dropdownSettings = {
		singleSelection: false,
		idField: 'id',
		enableCheckAll: false,
		textField: 'name',
		maxHeight: 500,
		itemsShowLimit: 1,
		allowSearchFilter: true
	};
	clientSearchSettings: any;
	userSearchSettings: any;
	public matcher:any;
	public current;
	constructor(
		private dialogRef: MatDialogRef<TaskComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private socket: SocketService,
		private hs: HistoryService,
		private http: HttpService,
		private fb: FormBuilder,
		public us: UserService,
		public cs: ClientService,
		private ts: TaskService,
		private as: AuthService,
		private tcs: TaskCategoryService,
		private tps: TaskPriorityService,
		private ct: CategoriesService,
		private dialog: MatDialog,
		private router: Router,
		private alert: AlertService,
		private file: FileService
		) {
		this.ct.getTags('tasks');
		this.current = new Date(moment().add(1, 'days').hours(0).format('YYYY-MM-DD HH:mm:ss'));
		this.matcher = new MyErrorStateMatcher(this.taskForm);
		if(this.us.me) {
			this.taskForm.responsible = this.us.me.id;
		}
		if(this.hs.page.client_id) {
			this.taskForm.assigned_id = this.hs.page.client_id;
			this.select(this.hs.page.client_id);
		}
	}

	taskForm:any  = {
		checklists: [],
		participants: [],
		observers: [],
		tags: [],
		formSubmitted: false
	};
	public attachments: any = [];
	public checklists: any = [];
	public full_address: any = {
		address: '',
		city: '',
		country: '',
		flat_number: '',
		area: '',
		state: '',
		zip: '', 
		lat: '',
		lng: ''
	};
	ngOnInit() {
		this.dialogRef.disableClose = true;
		this.dialogRef.backdropClick().subscribe(_ => {
			this.alert.yes("Do you want to discard changes?", ()=>{
				this.dialogRef.close();
			})
		})
		this.clientSearchSettings = {
			singleSelection: true,
			allowSearchFilter: true,
			enableCheckAll: false,
			placeholder: 'Attach contact',
			enableSearchFilter: true,
			text: 'Select client',
			noDataLabel: 'Type name of existing contact...'
		};

		this.userSearchSettings = {
			singleSelection: false,
			allowSearchFilter: true,
			enableCheckAll: false,
			placeholder: 'Attach user',
			enableSearchFilter: true,
			text: 'Select user(s)',
			noDataLabel: 'Type name of existing user...'
		};
	}
	//tags managment
	public tags = []
	addTag(tag){
		if(!tag.title) return;
		if(!tag.id){
			this.ct.create(tag, 'tasks', created=>{
				this.taskForm.tags.unshift(created);
			}, 'tasks');
		}else{
			this.taskForm.tags.unshift(tag);
		}
	}

	public onLocationSelected(e): void {
		this.taskForm.lng = e.longitude;
		this.taskForm.lat = e.latitude;
	}

	public phone_preview = '';
	public email_preview = '';
	public extra = {
		phoneId: '',
		emailId: ''
	};

	public check(client) {
		if(client.extra) {
			if(client.extra.contact_number) {
				this.phone_preview = client.extra.contact_number.value;
				this.extra.phoneId = client.extra.contact_number_id;
			} 
			if (client.extra.contact_email) {
				this.email_preview  = client.extra.contact_email.value;
				this.extra.emailId = client.extra.contact_email_id;
			}
		}

	}
	moment() {
		return moment();
	}
	save() {
		this.taskForm.formSubmitted = true;
		if(!this.taskForm.title) return this.alert.fail('Add Title');
		if (this.taskForm) {
			const userInfo = this.as.decode();
			const data = JSON.parse(JSON.stringify(this.taskForm));
			;
			if(data.planned_hours || data.planned_minutes) {
				if(data.planned_hours > 900) data.planned_hours = 900;
				data.planned_time = (data.planned_hours * 60) + data.planned_minutes;
			}
			data.related_users = [];
			if(data.responsible) {
				let user = {
					id: data.responsible,
					type: 'Responsible person'
				};
				data.related_users.push(user);
			}
			if(data.participants) {
				for(var i = 0; i < data.participants.length; i++) {
					var user = {
						id: data.participants[i].id,
						type: 'Participant'
					};
					data.related_users.push(user);
				}
			} 
			if(data.observers) {
				for(var i = 0; i < data.observers.length; i++) {
					var user = {
						id: data.observers[i].id,
						type: 'Observer'
					};
					data.related_users.push(user);
				}
			}
			if(data.tags) {
				let tags = [];
				for(let i = 0; i < data.tags.length; i++) {
					if(data.tags[i].id) {
						tags.push(data.tags[i].id);
					} else {
						tags.push(data.tags[i].title);
					}
					
				} 
				data.tags = tags;
			}
			if(data.checklists.length > 0) {
				for(let i = 0; i < data.checklists.length; i++) {
					data.checklists[i].order = i + 1;
				}
			}
			if(data.assigned_id) data.assigned_type = 'client';
			data.address = this.full_address.address;
			data.city = this.full_address.city;
			data.state = this.full_address.state;
			data.country = this.full_address.country;
			data.zip = this.full_address.zip;
			data.lat = this.full_address.lat;
			data.lng = this.full_address.lng;
			data.area = this.full_address.area;
			data.flat_number = this.full_address.flat_number;
			if(data.deadline_at) {
				data.deadline_at = moment(data.deadline_at).format('YYYY-MM-DD HH:mm:ss');
			}
			if(data) {
				this.ts.create(data, (resp)=> {
					this.router.navigate(['/tasks/list']);
					if(this.attachments.length > 0) {
						this.file.add(this.attachments, '/tasks/attachments/create', {
							task_id: resp.id
						}, created=> {
							for(let i = 0; i < created.length; i++){
								this.ts.files.unshift(created[i]);
							}
						});
					}
					this.ts.getTasks();
					this.ts.load({
						page: this.ts.page,
						perPage: this.ts.perPage,
						sort: this.ts.sort,
						sortby: this.ts.sortby
					});

					if(this.cs.client.id) this.cs.client.tasks.push(resp);
					this.ts.now = Date.now();
				});
				this.dialogRef.close(true);
			} else {
				this.dialogRef.close(true);
			}
		}
	}

	updateAddress(loc){
		this.http.to_address(this.full_address, loc);
	}

	createClient() {
		const dialogConfig = new MatDialogConfig();
		//dialogConfig.disableClose = true;
		dialogConfig.autoFocus = false;
		dialogConfig.data = {
			hide_lead: true
		};
		this.dialog.open(ContactComponent, dialogConfig).afterClosed().subscribe(newClient => {
			if(newClient) {
				this.taskForm.assigned_id = newClient.id;
				for(let i = 0; i < this.cs.clients.length; i++){
					if(this.cs.clients[i].id == newClient.id) return;
				}
				this.cs.clients.push(newClient);
			}
		});
	}

	close() {
		this.alert.yes("Do you want to discard changes?", ()=>{
			this.dialogRef.close();
		})
	}

	attach(fileEvent) {
		for(let i = 0; i < fileEvent.target.files.length; i++){
			fileEvent.target.files[i].new = true;
			fileEvent.target.files[i].title = fileEvent.target.files[i].name;
			this.attachments.push(fileEvent.target.files[i]);
		}
	}
	select(id) {
		this.cs.fetch(id, (client)=> {
			this.http.clean_address(this.full_address);
			this.http.to_address(this.full_address, client);
			this.full_address.full_address = this.http.setFullAddress(client);
		});
	}
	push() {
		if(this.taskForm.check) {
			this.taskForm.checklists.push({order: '', note: this.taskForm.check, is_complete: false}); 
			this.taskForm.check = '';
		}
	}
}
