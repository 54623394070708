import { Pipe, PipeTransform } from '@angular/core';
/*
*	careful with alternative_split, which is used in most cases with '-'
*	and in different case of phone mask can broke the idea of split
*	alternative_join is the supportive variable for alternative_split
*/

@Pipe({
	name: 'shift'
})
export class ShiftPipe implements PipeTransform {
	transform(value: any, split=' ', join=' ', number=1, alternative_split='-', alternative_join='-'): any {
		if(!value || typeof value != 'string') return value;
		while(number>0){
			if(value.indexOf(split)>-1){
				value = value.split(split);
				value.shift();
				value = value.join(join);
			}else{
				value = value.split(alternative_split);
				value.shift();
				value = value.join(alternative_join);
			}
			number--;
		}
		return value;
	}
}
