import { Injectable } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
export class Matcher implements ErrorStateMatcher {
	private form:any;
	constructor(form:any){
		this.form = form;
	}
	isErrorState(control:any): boolean {
		return !control.value && this.form.submited;
	}
}
export class MatcherMail implements ErrorStateMatcher {
	private form:any;
	constructor(form:any){
		this.form = form;
	}
	private re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	isErrorState(control:any): boolean {
		return !this.re.test(String(control.value).toLowerCase()) && this.form.submited;
	}
}

@Injectable({
	providedIn: 'root'
})
export class UiService {
	/*
	*	Icons Management
	*/
		public icons: Array<any> = [
			{value: 'access_time', name: 'access_time'},
			{value: 'airport_shuttle', name: 'airport_shuttle'},
			{value: 'announcement', name: 'announcement'},
			{value: 'attach_money', name: 'attach_money'},
			{value: 'build', name: 'build'},
			{value: 'business_center', name: 'business_center'},
			{value: 'cake', name: 'cake'},
			{value: 'directions_car', name: 'directions_car'},
			{value: 'error', name: 'error'},
			{value: 'explore', name: 'explore'},
			{value: 'favorite', name: 'favorite'},
			{value: 'group', name: 'group'},
			{value: 'group_add', name: 'group_add'},
			{value: 'flag', name: 'flag'},
			{value: 'help', name: 'help'},
			{value: 'help_outline', name: 'help_outline'},
			{value: 'info', name: 'info'},
			{value: 'info_outline', name: 'info_outline'},
			{value: 'insert_emoticon', name: 'insert_emoticon'},
			{value: 'live_help', name: 'live_help'},
			{value: 'local_activity', name: 'local_activity'},
			{value: 'mood', name: 'mood'},
			{value: 'mood_bad', name: 'mood_bad'},
			{value: 'payment', name: 'payment'},
			{value: 'priority_high', name: 'priority_high'},
			{value: 'headset_mic', name: 'headset_mic'},
			{value: 'store', name: 'store'}
		];
	/*
	*	Scroll Management
	*/
		private Scrolling = {};
		get_scroll(which){
			return this.Scrolling[which] && this.Scrolling[which].scrollTop || 0;
		}
		set_scroll(which, value){
			if(this.Scrolling[which]) this.Scrolling[which].scrollTop = value;
		}
		set_native(which, native){
			this.Scrolling[which] = native;
		}
	/*
	*	Per Page Management
	*/
		public perPages = [10, 25, 50];
		public perPage:any = localStorage.getItem('perPage') || 50;
		setPerPage(perPage){
			this.perPage = perPage;
			localStorage.setItem('perPage', perPage);
		}
	/*
	*	Initialize
	*/
	public noscrolContent;
	public customFieldsPage;
	public favContactAdded;
	public constructorMode;

	public darkTheme = true;

	// colors for color picker
	public colors: any[] = [
		{c: '#B71C1C'}, {c: '#D32F2F'}, {c: '#EF5350'}, {c: '#880E4F'}, {c: '#C2185B'}, {c: '#EC407A'},
		{c: '#4A148C'}, {c: '#7B1FA2'}, {c: '#AB47BC'}, {c: '#311B92'}, {c: '#512DA8'}, {c: '#7E57C2'},
		{c: '#1A237E'}, {c: '#303F9F'}, {c: '#5C6BC0'}, {c: '#0D47A1'}, {c: '#1976D2'}, {c: '#42A5F5'},
		{c: '#01579B'}, {c: '#0288D1'}, {c: '#29B6F6'}, {c: '#006064'}, {c: '#0097A7'}, {c: '#26C6DA'},
		{c: '#004D40'}, {c: '#00796B'}, {c: '#26A69A'}, {c: '#1B5E20'}, {c: '#388E3C'}, {c: '#66BB6A'},
		{c: '#33691E'}, {c: '#689F38'}, {c: '#9CCC65'}, {c: '#827717'}, {c: '#AFB42B'}, {c: '#D4E157'},
		{c: '#E65100'}, {c: '#F57C00'}, {c: '#FFA726'}, {c: '#BF360C'}, {c: '#E64A19'}, {c: '#FF7043'},
		{c: '#3E2723'}, {c: '#5D4037'}, {c: '#8D6E63'}, {c: '#263238'}, {c: '#455A64'}, {c: '#78909C'}
	];
	public times: any[] = [
		{label: '12:00 AM'}, {label: '12:15 AM'}, {label: '12:30 AM'}, {label: '12:45 AM'},
		{label: '01:00 AM'}, {label: '01:15 AM'}, {label: '01:30 AM'}, {label: '01:45 AM'},
		{label: '02:00 AM'}, {label: '02:15 AM'}, {label: '02:30 AM'}, {label: '02:45 AM'},
		{label: '03:00 AM'}, {label: '03:15 AM'}, {label: '03:30 AM'}, {label: '03:45 AM'},
		{label: '04:00 AM'}, {label: '04:15 AM'}, {label: '04:30 AM'}, {label: '04:45 AM'},
		{label: '05:00 AM'}, {label: '05:15 AM'}, {label: '05:30 AM'}, {label: '05:45 AM'},
		{label: '06:00 AM'}, {label: '06:15 AM'}, {label: '06:30 AM'}, {label: '06:45 AM'},
		{label: '07:00 AM'}, {label: '07:15 AM'}, {label: '07:30 AM'}, {label: '07:45 AM'},
		{label: '08:00 AM'}, {label: '08:15 AM'}, {label: '08:30 AM'}, {label: '08:45 AM'},
		{label: '09:00 AM'}, {label: '09:15 AM'}, {label: '09:30 AM'}, {label: '09:45 AM'},
		{label: '10:00 AM'}, {label: '10:15 AM'}, {label: '10:30 AM'}, {label: '10:45 AM'},
		{label: '11:00 AM'}, {label: '11:15 AM'}, {label: '11:30 AM'}, {label: '11:45 AM'},
		{label: '12:00 PM'}, {label: '12:15 PM'}, {label: '12:30 PM'}, {label: '12:45 PM'},
		{label: '01:00 PM'}, {label: '01:15 PM'}, {label: '01:30 PM'}, {label: '01:45 PM'},
		{label: '02:00 PM'}, {label: '02:15 PM'}, {label: '02:30 PM'}, {label: '02:45 PM'},
		{label: '03:00 PM'}, {label: '03:15 PM'}, {label: '03:30 PM'}, {label: '03:45 PM'},
		{label: '04:00 PM'}, {label: '04:15 PM'}, {label: '04:30 PM'}, {label: '04:45 PM'},
		{label: '05:00 PM'}, {label: '05:15 PM'}, {label: '05:30 PM'}, {label: '05:45 PM'},
		{label: '06:00 PM'}, {label: '06:15 PM'}, {label: '06:30 PM'}, {label: '06:45 PM'},
		{label: '07:00 PM'}, {label: '07:15 PM'}, {label: '07:30 PM'}, {label: '07:45 PM'},
		{label: '08:00 PM'}, {label: '08:15 PM'}, {label: '08:30 PM'}, {label: '08:45 PM'},
		{label: '09:00 PM'}, {label: '09:15 PM'}, {label: '09:30 PM'}, {label: '09:45 PM'},
		{label: '10:00 PM'}, {label: '10:15 PM'}, {label: '10:30 PM'}, {label: '10:45 PM'},
		{label: '11:00 PM'}, {label: '11:15 PM'}, {label: '11:30 PM'}, {label: '11:45 PM'}
	];
	arr(counter) {
		let arr=[];
		for (let i = counter - 1; i >= 0; i--) {
			arr.push({});
		}
		return arr;
	}
	constructor() {}
	back() {
		window.history.back();
	}
	print(){
		window.print();
	}
	isEmail(email){
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	isPhone(phone){
		return phone && phone.toString().length>=10 || false;
	}
	public dropdownSettings = {
		singleSelection: false,
		idField: 'id',
		enableCheckAll: false,
		textField: 'title',
		maxHeight: 500,
		itemsShowLimit: 1,
		allowSearchFilter: false
	};

	// dashboard
	public activeWidget = '';
}
