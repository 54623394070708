import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'timetodate'
})
export class TimetodatePipe implements PipeTransform {

  transform(value: any): any {
    var day = new Date('2015-06-17 ' + value);
    return moment(day).format('hh:mm A');
  }

}
