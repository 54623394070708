import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UserService } from './user.service';
import { SocketService } from './socket.service';
import { GridsterItem } from 'angular-gridster2';

/*
POST /api/constructor/save
GET /api/constructor/fetch/<slug>
GET /api/constructor/get
*/


@Injectable({
	providedIn: 'root'
})
export class DashboardService {
	public EstimatesLostReasons;
	public summEstimatesLostReasons;

	public CustomerSourceWidgetData=[];
	public CustomerSourceWidgetLabels=[];

	public screen;
	public screens=['mobile','tablet','desktop'];
	public show:any = {};
	private default:any = {
		mobile:[
		{cols: 12, rows: 2, y: 0, x: 0, name:"Leads Counter Widget", id: "LeadsCounterWidget", minItemCols: 12, minItemRows: 2, hidden: false},
		{cols: 12, rows: 2, y: 3, x: 0, name:"Quotes Counter Widget", id: "QuotesCounterWidget", minItemCols: 12, minItemRows: 2, hidden: false},
		{cols: 12, rows: 2, y: 5, x: 0, name:"Workorders Counter Widget", id: "WorkordersCounterWidget", minItemCols: 12, minItemRows: 2, hidden: false},
		{cols: 12, rows: 2, y: 7, x: 0, name:"Invoices Counter Widget", id: "InvoicesCounterWidget", minItemCols: 12, minItemRows: 2, hidden: false},
		{cols: 12, rows: 3, y: 9, x: 0, name:"Sales Funnel Widget", id: "SalesFunnelWidget", minItemCols: 12, minItemRows: 3, hidden: false},
		{cols: 12, rows: 5, y: 12, x: 0, name:"Customer Source Widget", id: "CustomerSourceWidget", minItemCols: 12, minItemRows: 4, hidden: false},
		{cols: 12, rows: 5, y: 17, x: 0, name:"Estimates Lost Reasons Widget", id: "EstimatesLostReasonsWidget", minItemCols: 12, minItemRows: 3, hidden: false},
		{cols: 12, rows: 5, y: 17, x: 0, name:"My Tasks Widget", id: "MyTasksWidget", minItemCols: 12, minItemRows: 3, hidden: false}
		],
		tablet:[
		{cols: 6, rows: 2, y: 0, x: 0, name:"Leads Counter Widget", id: "LeadsCounterWidget", minItemCols: 6, minItemRows: 2, hidden: false},
		{cols: 6, rows: 2, y: 0, x: 6, name:"Quotes Counter Widget", id: "QuotesCounterWidget", minItemCols: 6, minItemRows: 2, hidden: false},
		{cols: 6, rows: 2, y: 2, x: 0, name:"Workorders Counter Widget", id: "WorkordersCounterWidget", minItemCols: 6, minItemRows: 2, hidden: false},
		{cols: 6, rows: 2, y: 2, x: 6, name:"Invoices Counter Widget", id: "InvoicesCounterWidget", minItemCols: 6, minItemRows: 2, hidden: false},
		{cols: 6, rows: 3, y: 10, x: 0, name:"Sales Funnel Widget", id: "SalesFunnelWidget", minItemCols: 6, minItemRows: 2, hidden: false},
		{cols: 6, rows: 5, y: 5, x: 0, name:"Customer Source Widget", id: "CustomerSourceWidget", minItemCols: 6, minItemRows: 4, hidden: false},
		{cols: 6, rows: 5, y: 5, x: 0, name:"Estimates Lost Reasons Widget", id: "EstimatesLostReasonsWidget", minItemCols: 6, minItemRows: 3, hidden: false},
		{cols: 6, rows: 5, y: 5, x: 0, name:"My Tasks Widget", id: "MyTasksWidget", minItemCols: 6, minItemRows: 3, hidden: false},
		],
		desktop:[
		{cols: 3, rows: 2, y: 0, x: 0, name:"Leads Counter Widget", id: "LeadsCounterWidget", minItemCols: 3, minItemRows: 2, hidden: false},
		{cols: 3, rows: 2, y: 0, x: 3, name:"Quotes Counter Widget", id: "QuotesCounterWidget", minItemCols: 3, minItemRows: 2, hidden: false},
		{cols: 3, rows: 2, y: 0, x: 6, name:"Workorders Counter Widget", id: "WorkordersCounterWidget", minItemCols: 3, minItemRows: 2, hidden: false},
		{cols: 3, rows: 2, y: 0, x: 9, name:"Invoices Counter Widget", id: "InvoicesCounterWidget", minItemCols: 3, minItemRows: 2, hidden: false},
		{cols: 6, rows: 3, y: 3, x: 0, name:"Sales Funnel Widget", id: "SalesFunnelWidget", minItemCols: 3, minItemRows: 2, hidden: false},
		{cols: 6, rows: 9, y: 12, x: 0, name:"Customer Source Widget", id: "CustomerSourceWidget", minItemCols: 4, minItemRows: 5, hidden: false},
		{cols: 6, rows: 6, y: 3, x: 6, name:"Estimates Lost Reasons Widget", id: "EstimatesLostReasonsWidget", minItemCols: 4, minItemRows: 3, hidden: false},
		{cols: 6, rows: 6, y: 3, x: 6, name:"My Tasks Widget", id: "MyTasksWidget", minItemCols: 4, minItemRows: 3, hidden: false}
		],
	};
	public dashboard:any = {};
	public edit_dashboard={};
	constructor(private http: HttpService,
		private us: UserService,
		private socket: SocketService) {
		this.socket.on("widgets_refresh", dashboard=>{
			this.http.afterWhile(this, ()=>{
				this.dashboard=dashboard;
			});
		});

	}
	dissScroll(){
		console.log(document.getElementsByClassName("scrollVertical"));
		document.getElementsByClassName("scrollVertical")[0]['style']['overflow-y'] = "hidden";
	}
	enableScroll(){
		console.log(document.getElementsByClassName("scrollVertical"));
		document.getElementsByClassName("scrollVertical")[0]['style']['overflow-y'] = "auto";
	}
	fetchAll(){
		if(!this.us.data.widgets||!this.screen){
			return setTimeout(()=>{
				this.fetchAll();
			},500);
		}
		this.http.get(this.http.url + '/dashboard/get', data=>{
			if(!data||data==null){
				data=this.default;
			}
			let showWidgets:any={
				query: []
			};
			for (let k=this.screens.length-1; k>=0; k--) {
				for (let j=0; j<data[this.screens[k]].length; j++) {
					if(this.screens[k]==this.screen){
						showWidgets['query'].push({'alias':this.us.data.widgets[data[this.screens[k]][j].id].alias});
					}
				}
			}
			this.http.post(this.http.url + '/widgets/show', showWidgets, resp=>{
				for (let i=0; i<resp.length; i++) {
					this.show[resp[i].alias]=resp[i];

					if(resp[i].alias=='EstimatesLostReasonsWidget'){
						/* EstimatesLostReasons Widget */
						this.EstimatesLostReasons=resp[i].data.sort((a, b) => {
							return b.count - a.count;
						});
						this.summEstimatesLostReasons=resp[i].data.map(obj =>{
							return obj.count;
						}).reduce((a, b) => a + b, 0);
					}
					if(resp[i].alias=='CustomerSourceWidget'){
						/* CustomerSourceWidget */
						this.CustomerSourceWidgetData=resp[i].data.map(obj=>{
							return obj.count;
						});
						this.CustomerSourceWidgetLabels=resp[i].data.map(obj=>{
							return obj.source;
						});
					}
				}
			});
			this.dashboard = data;
		});
	}
	update(user_id:number=1){
		this.http.post(this.http.url + '/dashboard/save', this.dashboard, resp=>{
			this.socket.emit("widgets_refresh", this.dashboard);
		});
		this.fetchAll();
	}
	reset(){
		this.dashboard = JSON.parse(JSON.stringify(this.default));
		this.update();
	}
}
