import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { HttpService } from '../http.service';
import * as moment from 'moment';
import { SocketService } from '../socket.service';

@Injectable({
	providedIn: 'root'
})
export class WorkerScheduleService {
	constructor(
		private http: HttpClient,
		private socket: SocketService,
		private hs: HttpService
	){}

	public current_teams:Array<any> = [];
	public workers:Array<any> = [];
	public equipment_items:Array<any> = [];
}