import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { ICurrentUser } from '@root/modules/currentUser';
import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {

    const authToken = this.authService.getAuthToken();
    const tokenIsRequired = this.isRequestRequiredToken(request.url);
    if (!authToken && tokenIsRequired) {
      return EMPTY;
    }

    const isExpired = helper.isTokenExpired(authToken);
    if (isExpired && authToken && tokenIsRequired) {
      return this.handle401Error(request, next);
    }

    return next.handle(this.addTokenToRequest(request, this.authService.getAuthToken())).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse) {
        switch ((<HttpErrorResponse>err).status) {
          case 401:
            return this.handle401Error(request, next);
          case 400:
            this.authService.logout();
            return throwError(err.error);
          case 403:
            // this.authService.logout();
            // return throwError(err.error);
            console.log('refreshing token');
            // FIXME: Why this method is called when access is forbidden?
            return this.handle401Error(request, next);
          case 404:
            return throwError(err);
          case 422:
            return throwError(err);
          case 500:
            //return <any>this.authService.logout();
            return throwError(err);
          case 0:
            return throwError(err);
        }
      } else {
        return throwError(err);
      }
    }));
  }
  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}`}});
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.authService.refreshToken()
        .pipe(
          switchMap((user: ICurrentUser) => {
            if (user) {
              this.tokenSubject.next(user.accessToken);
              localStorage.setItem('currentUser', JSON.stringify(user));
              return next.handle(this.addTokenToRequest(request, user.accessToken));
            }
            return <any>this.authService.logout();
          }),
          catchError(err => {
            return <any>this.authService.logout();
          }),
          finalize(() => {
            this.isRefreshingToken = false;
          })
        );
    } else {
      this.isRefreshingToken = false;

      return this.tokenSubject
        .pipe(filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addTokenToRequest(request, token));
        }));
    }
  }

  private isRequestRequiredToken(requestUrl: string): boolean {
    let requiredToken = true;
    const REQUEST_URLS: string[] = [
      'api/run',
      'api/forgot',
      'api/activation',
      'api/token/refresh',
      'api/reset',
      'api/login'
    ];
    REQUEST_URLS.forEach((url) => {
      if (requestUrl.search(url) > -1) {
        requiredToken = false;
      }
    });
    return requiredToken;
  }
}
