import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { UserService } from '../user.service';
import { HttpService } from '../http.service';
import { FieldsService } from '../fields.service';
import { AlertService } from '../alert.service';
import { CustomFieldService } from '../custom.fields.service';
import * as moment from 'moment';
import { SocketService } from '../socket.service';

@Injectable({
	providedIn: 'root'
})
export class ClientService {
	constructor(private http: HttpClient,
		private alert: AlertService,
		private hs: HttpService,
		private socket: SocketService,
		private cs: CustomFieldService,
		private fs: FieldsService,
		private us: UserService) {
		this.us.cs=this;
		this.load();
		this.loadbySearch();
	}
	public client_id:any;
	public dropdownclients:any = [];
	public isloading = false;
	primary(client:any){
		if(!client) return {email_content:{}, number_content: {}};
		let json:any = {};
		if(client.primary){
			for(let i = 0; i < client.primary.length; i++){
				if(client.primary[i].primary_email){
					json.email = client.primary[i].primary_email.value;
					json.email_content = client.primary[i].primary_email;
				}
				if(client.primary[i].primary_number){
					json.number = client.primary[i].primary_number.value;
					json.number_content = client.primary[i].primary_number;
				}
			}
		}
		return json;
	}
	/*
	*	Payments
	*/
	public refill_payments:any;
	public payments:any = [];
	public quotes:any = [];
	private last_loaded_quotes:any;
	load_payments_quotes(client_id, cb = ()=>{}){
		if(this.last_loaded_quotes == client_id) return;
		this.last_loaded_quotes = client_id;
		this.hs.apiget('/estimates/dropdown/get?filters[client_id]='+client_id, resp=>{
			this.quotes = resp;
			cb();
		});
	};
	load_payments(opts, cb=payments=>{}){
		if(!opts.client_id) return;
		let filters = '';
		for(let each in opts){
			if(filters){
				filters += '&filters[' + each + ']=' + opts[each];
			}else{
				filters += '?filters[' + each + ']=' + opts[each];
			}
		}
		this.hs.apiget('/payments/get'+filters, resp=>{
			this.payments = resp.data;
			if(this.refill_payments) this.refill_payments();
			cb(this.payments);
		});
	}
	delete_payment(payment){
		this.alert.yes('Are you sure you want to delete this payment?', () => {
			this.hs.apipost('/payments/delete', {
				payment_id: payment.id
			}, resp=>{
				for(let i = 0; i < this.payments.length; i++){
					if(this.payments[i].id == payment.id){
						this.payments.splice(i, 1);
					}
				}
				if(this.refill_payments) this.refill_payments();	
			});
		});
	}
	/*
	*	External Use
	*/
	public lead_id:any;
	public sources = [
		{ name: 'Website', value: 'website' },
		{ name: 'Google', value: 'google' },
		{ name: 'Facebook', value: 'facebook' },
		{ name: 'Truck Signage', value: 'truck signage' },
		{ name: 'Friend', value: 'friend' },
		{ name: 'Employee', value: 'employee' }
	];
	/*
	*	Load Clients
	*/
	public copy:any = {};
	public _loaded = {};
	public preload = true;
	public selected_tags = [];
	public search;
	public searchclient;
	private pre_search = 'pre_load';
	load(search=''){
		if(this.pre_search == search) return;
		this.pre_search = search;
		this.hs.afterWhile(this, ()=>{
			this.getClientsWithParams(0, 50, search).subscribe((data:any)=>{
				this.fix_fields(data.data);
				this.clients = data.data;
				this.dropdownclients = JSON.parse(JSON.stringify(data.data));
				this.preload = false;
				for(let i = 0; i < this.clients.length; i++){
					this._loaded[this.clients[i].id] = this.clients[i];
				}
			});
		}, 1000);
	}
	loadFilters(filtersData){
		this.filters = filtersData.filters ? filtersData.filters : {};
		this.filters_ids = filtersData.filters_ids ? filtersData.filters_ids : {};
		this.address = '';
		this.address += this.filters.address ? this.filters.address + ', ' : '';
		this.address += this.filters.city ? this.filters.city + ', ' : '';
		this.address += this.filters.state ? this.filters.state + ', ' : '';
		this.address += this.filters.country ? this.filters.country + ', ' : '';
		this.hs.on('field_load', ()=>{
			this.fieldsData = this.cs.fields.slice();
			this.fieldsData.forEach((field: any) => {
				if (field.type == 'dropdown' || field.type == 'radio') {
					this.checkboxData[field.id] = {};
					if (this.filters_ids[field.id]) {
						this.checkboxData[field.id] = {};
						this.filters_ids[field.id].forEach((checkbox) => {
							this.checkboxData[field.id][checkbox] = true;
						});
					}
				} else if (field.type == 'date') {
					this.dateData[field.id] = {};
					if (this.filters_ids[field.id]) {
						this.dateData[field.id] = {
							from: moment(this.filters_ids[field.id].from, 'YYYY-MM-DD HH:mm:ss'),
							to:   moment(this.filters_ids[field.id].to  , 'YYYY-MM-DD HH:mm:ss')
						};
					}
				} else if (field.type == 'checkbox') {
					field.data=['Selected','Not selected'];
					this.checkboxData[field.id] = {};
					if (this.filters_ids[field.id]) {
						this.checkboxData[field.id] = {};
						this.filters_ids[field.id].forEach((checkbox) => {
							this.checkboxData[field.id][checkbox] = true;
						});
					}
				}
			});
		} );
	}
	loadbySearch(search='') {
		this.isloading = true;
		let params:any = {};
		if(search) params['filters[search]'] = search;
		this.hs.afterWhile(this, ()=>{
			this.hs.c_get('clients/dropdown', (resp:any)=> {
				this.dropdownclients = resp;
				this.isloading = false;
			}, params);
		}, 1000);
	}
	/*
	*	Client Information Management
	*/
	cContact(contact: any, cb=resp=>{}) {
		this.hs.apipost('/clients/contact/create', contact, cb);
	}
	uContact(contact: any, cb=resp=>{}) {
		this.hs.apipost('/clients/contact/update', contact, cb);
	}
	dContact(contact: any, cb=resp=>{}) {
		this.hs.apipost('/clients/contact/delete', {
			client_id: contact.client_id,
			id: contact.id
		}, cb);
	}
	createContact(contact_data: any) {
		return this.http.post(`${this.uri}/clients/contact/create`, contact_data);
	}
	updateContact(contact_data: any) {
		return this.http.post(`${this.uri}/clients/contact/update`, contact_data);
	}
	deleteContact(contact_data: any) {
		return this.http.post(`${this.uri}/clients/contact/delete`, contact_data);
	}
	/*
	*	Client View
	*/
	public editable = {};
	public client:any = {};
	public notes = [];
	public files = [];
	public view;
	public billing_copy: any = {};
	save(){
		this.client = Object.assign({}, this.editable);
	}
	cancel(){
		this.editable = Object.assign({}, this.client);
	}
	intiialize(client){
		if(!client.tags) client.tags=[];
		if(!client.projects) client.projects=[];
		for(let i = 0; i < client.projects.length; i++){
			client.projects[i].created_at = new Date(client.projects[i].created_at);
			if(client.projects[i].estimate){
				client.projects[i].estimate.date = new Date(client.projects[i].estimate.date);
			}
			if(client.projects[i].workorder){
				client.projects[i].workorder.created_at = new Date(client.projects[i].workorder.created_at);
			}
			if(client.projects[i].invoice){
				client.projects[i].invoice.created_at = new Date(client.projects[i].invoice.created_at);
			}
		}
	}
	set_by_project(projectId, cb=client=>{}){
		this.http.post(`${this.uri}/clients/fetch`, {
			project_id: projectId
		}).subscribe((resp:any)=>{
			cb(resp);
			this.intiialize(resp);
			this.setClient(resp);
			this.fetched[resp.id] = resp;
			this.load_payments({
				client_id: resp.id
			});
			this.load_payments_quotes(resp.id);
		});
	}
	set(clientId, cb=resp=>{}, load_payments = false){
		if(load_payments){
			this.load_payments({
				client_id: clientId
			});
			this.load_payments_quotes(clientId);
		}
		this.fetch(clientId, client=>{
			this.intiialize(client);
			this.client = client;
			this.fs.cset(client.fields);
			this.fetched[client.id] = client;
			this.cancel();
			cb(client);
		});
	}
	setClient(client:any){
		if(!client) return;
		this.intiialize(client);
		this.fetched[client.id] = client;
		this.client = client;
		this.fs.cset(client.fields);
		this.cancel();
	}
	public fetched:any = {};
	fetch(id: number, cb=resp=>{}) {
		if (!id) { return; }
		//if (!id || !this.us.perm.viewclients) { return; }
		//console.log(id, this.fetched[id]);
		if(!this.fetched[id]){
			this.fetched[id] = {};
			this.http.post(`${this.uri}/clients/fetch`, {
				id: id
			}).subscribe(resp=>{
				for(let each in resp){
					this.fetched[id][each] = resp[each];
				}
				cb(this.fetched[id]);
			});
		}else if(!this.fetched[id].id){
			setTimeout(()=>{
				this.fetch(id, cb);
			}, 100);
		}else{
			cb(this.fetched[id]);
		}
		return this.fetched[id];
	}
	/*
	* to use this function, first ask dev lead
	*/
	force_fetch(id, cb=resp=>{}){
		if (!id) { return; }
		//if (!id || !this.us.perm.viewclients) { return; }
		this.fetched[id] = {};
		this.http.post(`${this.uri}/clients/fetch`, {
			id: id
		}).subscribe(resp=>{
			for(let each in resp){
				this.fetched[id][each] = resp[each];
			}
			cb(this.fetched[id]);
			return this.fetched[id];
		});
	}
	update(client: any = this.client, cb = resp=>{}) {
		if (!this.us.perm.editclients || !client) { return; }
		this.hs.apipost('/clients/update', client, cb);
	}

	use_fetch(id, cb = resp=>{}){
		if(!this.fetched[id]){
			return this.fetch(id);
		}
		return this.fetched[id];
	}
	private data = {};
	use_fetch_data(id, where, ID){
		if(!this.data[where+ID]) this.data[where+ID]={};
		if(this.data[where+ID].__fetched) return this.data[where+ID];
		if(this.fetched[id] && this.fetched[id].id){
			for(let i = 0; i < this.fetched[id][where].length; i++){
				if(this.fetched[id][where][i].id == ID){
					for(let each in this.fetched[id][where][i]){
						this.data[where+ID][each] = this.fetched[id][where][i][each];
					}
					break;
				}
			}
			this.data[where+ID].__fetched = true;
		}else{
			this.fetch(id);
			setTimeout(()=>{
				this.use_fetch_data(id, where, ID);
			}, 50);
		}
		return this.data[where+ID];
	}
	fetchClient(id: number) {
		//if (!this.us.perm.viewclients) { return; }
		console.trace('Should not be used');
		return this.http.post(`${this.uri}/clients/fetch`, {id: id});
	}
	/*
	*	End Of
	*/


























	public settab:any;
	
	public dropdownSettings = {
		singleSelection: false,
		idField: 'id',
		enableCheckAll: false,
		textField: 'name',
		maxHeight: 500,
		allowSearchFilter: false
	};
	fieldsData: Array<any> = [];
	chosenFields: Array<any> = [];
	filters: any = {};
	filters_ids: any = {};
	dateData: any = {};
	checkboxData: any = {};
	address: any = '';
	selectedIndex: any = null;//by RG
	clProfile: any=false;

	public collapseLeft;
	public collapseRight;

	public customFetch:any;
	public back=false;

	uri = environment.api;
	public clients = [];
	public edit_client:any={};
	fix_fields(clients){
		for(let i = 0; i < clients.length; i++){
			clients[i]._fields = {};
			for(let j = 0; j < clients[i].fields.length; j++){
				clients[i]._fields[clients[i].fields[j].field_id] = clients[i].fields[j].value;
			}
		}
	}
	getClients() {
		return this.http.get(`${this.uri}/clients/get`);
	}
	public types = [];
	getClientsWithParams(page = 0, limit = 50, search = '', sortby = '', sort = '', filters: any = undefined) {
		const reqBody = {
			search: search
		};
		let filterStr = '';
		if (filters) {
			if (filters.filters) {
				for (const key in filters.filters) {
					filterStr += `&filters[${key}]=${filters.filters[key]}`;
				}
			}
			if (filters.filters_ids) {
				for (const key in filters.filters_ids) {
					if (Array.isArray(filters.filters_ids[key])) {
						filters.filters_ids[key].forEach((element: any, index: number) => {
							filterStr += `&filters_ids[${key}][${index}]=${element.id?element.id:element}`;
						});
					} else if (typeof filters.filters_ids[key] === 'object') {
						if (filters.filters_ids[key].from) {
							filterStr += `&filters_ids[${key}][from]=${filters.filters_ids[key].from}`;
						}
						if (filters.filters_ids[key].to) {
							filterStr += `&filters_ids[${key}][to]=${filters.filters_ids[key].to}`;
						}
					} else {
						filterStr += `&filters_ids[${key}]=${filters.filters_ids[key]}`;
					}
				}
			}
		}
		for(let i = 0; i < this.selected_tags.length; i++){
			filterStr += '&filters_ids[tags]['+i+']='+this.selected_tags[i].id;
		}
		let url = this.uri+'/clients/get?page='+(page + 1);
		if(limit) url += '&per_page='+limit;
		if(sortby) url += '&sortby='+sortby;
		if(sort) url += '&sort='+sort;
		if(search) url += '&search='+search;
		if(filterStr) url += filterStr;
		if(this.types.length && this.types.length!=3){
			for(let i = 0; i < this.types.length; i++){
				url += '&filters[type]['+i+']='+this.types[i].title;
			}
		}
		return this.http.get(url);
	}
	clean(contact){
		for(let i = 0; i < contact.contact_information.length; i++){
			for(let j = contact.contact_information[i].emails.length-1; j >= 0; j--){
				if(!contact.contact_information[i].emails[j].value){
					contact.contact_information[i].emails.splice(j, 1);
				}
			}
		}
	}
	createNewClient(data) {
		data = JSON.parse(JSON.stringify(data));
		if (!this.us.perm.createclients) { return; }
		data.lat = data.lat || 0;
		data.lng = data.lng || 0;
		this.clean(data);
		return this.http.post(`${this.uri}/clients/create`, data);
	}
	updateClient(updated_data: any) {
		if (!this.us.perm.editclients) { return; }
		return this.http.post(`${this.uri}/clients/update`, updated_data);
	}
	deleteClient(delete_data: any) {
		if (!this.us.perm.deleteclients) { return; }
		return this.http.post(`${this.uri}/clients/delete`, delete_data);
	}
	setPrimaryContact(contact_data: any) {
		return this.http.post(`${this.uri}/clients/contact/primary`, contact_data);
	}
	clean_files(fileId){
		for(let i = this.files.length-1; i >= 0; i--){
			if(this.files[i].id == fileId){
				this.files.splice(i, 1);
				break;
			}
		}
	}
	clean_files_in_notes(fileId){
		for(let j = this.notes.length-1; j >= 0; j--){
			for(let i = this.notes[j].files.length-1; i >= 0; i--){
				if(this.notes[j].files[i].id == fileId){
					this.notes[j].files.splice(i, 1);
					break;
				}
			}
		}
	}

	/*** TAGS ***/
	public tags = [];
	getTags(){
		return this.http.get(`${this.uri}/clients/tags/get`);
	}
	addTag(title){
		return this.http.post(`${this.uri}/clients/tags/create`, {title: title});
	}
	deleteTag(id){
		return this.http.post(`${this.uri}/clients/tags/delete`,{id:id});
	}

	// End of
}