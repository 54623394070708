import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';

@Injectable({
	providedIn: 'root'
})
export class PdfService {
	public config = {
		colors: {
			text: '#263238',
			subt: '#263238',
			title: '#E67E22',
			page: '#ffffff',
			section: '#f0f0f0',
			footer: '#5b863b',
			header: '#5b863b',
		},
		show: {
			logo: true,
			bg: true,
			name: true,
			address: true,
			phone: true,
			email: true,
			footer: true,
			header: true,
			freecontent: true,
		},
		images: {
			logo: 'https://test.arbostar.com/storage/pdf/view-imgs1.png',
			bg: 'https://test.arbostar.com/storage/pdf/view-left1.png',
		},
		textes: {
			title: 'Quote #',
			name: 'Name',
			address: 'Address',
			email: 'Email',
			phone: 'Phone',
			subtitle1: 'Contact information',
			subtitle2: 'Proposed work',
			description: 'Description',
			price: 'Price',
			total: 'TOTAL',
			footer: 'ADDRESS: 425 Kipling Ave, Ontario, M8Z 5C8 OFFICE: (416) 201-8000 WEB: TreeDoctors.ca',
			header: 'Header TreeDoctors.ca Arborists Who Care',
		},
		freecontent: ''
	};
	private replaces = [
		{ from: 'logo_SHOW', to: ()=>{ return this.config.show.logo?'block':'none'; }}, 
		{ from: 'bg_SHOW', to: ()=>{ return this.config.show.bg?'block':'none'; }}, 
		{ from: 'name_SHOW', to: ()=>{ return this.config.show.name?'block':'none'; }}, 
		{ from: 'address_SHOW', to: ()=>{ return this.config.show.address?'block':'none'; }}, 
		{ from: 'phone_SHOW', to: ()=>{ return this.config.show.phone?'block':'none'; }}, 
		{ from: 'email_SHOW', to: ()=>{ return this.config.show.email?'block':'none'; }}, 
		{ from: 'footer_SHOW', to: ()=>{ return this.config.show.footer?'block':'none'; }}, 
		{ from: 'header_SHOW', to: ()=>{ return this.config.show.header?'block':'none'; }}, 
		{ from: 'freecontent_SHOW', to: ()=>{ return this.config.show.freecontent?'block':'none'; }}, 
		{ from: 'testttt', to: ()=>{ return this.config.textes.title; }}, 
		{ from: 'subt_COLOR', to: ()=>{ return this.config.colors.subt; }}, 
		{ from: 'text_COLOR', to: ()=>{ return this.config.colors.text; }}, 
		{ from: 'title_COLOR', to: ()=>{ return this.config.colors.title; }}, 
		{ from: 'page_COLOR', to: ()=>{ return this.config.colors.page; }}, 
		{ from: 'section_COLOR', to: ()=>{ return this.config.colors.section; }}, 
		{ from: 'header_COLOR', to: ()=>{ return this.config.colors.header; }}, 
		{ from: 'footer_COLOR', to: ()=>{ return this.config.colors.footer; }}, 
		{ from: 'title_TEXT', to: ()=>{ return this.config.textes.title; }}, 
		{ from: 'name_TEXT', to: ()=>{ return this.config.textes.name; }}, 
		{ from: 'address_TEXT', to: ()=>{ return this.config.textes.address; }}, 
		{ from: 'email_TEXT', to: ()=>{ return this.config.textes.email; }}, 
		{ from: 'phone_TEXT', to: ()=>{ return this.config.textes.phone; }}, 
		{ from: 'subtitle1_TEXT', to: ()=>{ return this.config.textes.subtitle1; }}, 
		{ from: 'subtitle2_TEXT', to: ()=>{ return this.config.textes.subtitle2; }}, 
		{ from: 'description_TEXT', to: ()=>{ return this.config.textes.description; }}, 
		{ from: 'price_TEXT', to: ()=>{ return this.config.textes.price; }}, 
		{ from: 'freecontent_TEXT', to: ()=>{ return this.config.freecontent?this.config.freecontent:''; }}, 
		{ from: 'total_TEXT', to: ()=>{ return this.config.textes.total; }}, 
		{ from: 'FOOTER_CODE', to: ()=>{ return this.config.textes.footer; }}, 
		{ from: 'HEADER_CODE', to: ()=>{ return this.config.textes.header; }}, 
		{ from: 'LEFT_IMG', to: ()=>{ return this.config.images.bg; }}, 
		{ from: 'TOP_IMG', to: ()=>{ return this.config.images.logo; }}
		];
	private free_replaces = [
		{ from: '[[key]]', to: "I'M BIRD!!!"},
		{ from: '[[page-break]]', to: `<div class="break-before"></div>`}, 
		
	];
	
	update(){
		let config=Object.assign({},this.config);
		if(this.config.freecontent){
			for (let i=0; i<=this.free_replaces.length-1; i++){
				while(this.config.freecontent!=this.config.freecontent.replace(this.free_replaces[i].from,this.free_replaces[i].to)){
					this.config.freecontent=this.config.freecontent.replace(this.free_replaces[i].from,this.free_replaces[i].to);
				}
			}
		}
		let code = this.code;
		for(let i = 0; i < this.replaces.length; i++){
			code = code.split(this.replaces[i].from).join(this.replaces[i].to());
		}
		this.http.apipost('/pdf/update', {
			slug: 'Estimate',
			template: code,
			data: this.config
		},resp=>{

		});
		this.config=Object.assign({},config);
	}
	afterWhile(){
		this.http.afterWhile(this, this.update.bind(this),500);
	}
	constructor(private http: HttpService){
		this.http.apipost('/pdf/fetch', {
			slug: 'Estimate'
		}, (resp)=>{
			//* Toggle first / to toggle code which will rewrite the config from file
			this.config = resp.data;
			if(this.config.freecontent){
				for (let i=0; i<=this.free_replaces.length-1; i++){
					while(this.config.freecontent!=this.config.freecontent.replace(this.free_replaces[i].to,this.free_replaces[i].from)){
						this.config.freecontent=this.config.freecontent.replace(this.free_replaces[i].to,this.free_replaces[i].from);
					}
				}
			}
			this.afterWhile();
			/*/
			this.update();
			//*/
		});
		//this.afterWhile();
	}
	public code = `
		<!DOCTYPE html>
		<html lang='en'>
		<head>
		    <meta charset='UTF-8'>
		    <title>Document</title></head>
		<body>
		<style>


		    * {  outline: none;  box-sizing: border-box; }
		    html { margin: 0;  padding: 0; height: 100%; }
			body { margin: 0; font-size: 14px;  padding: 0; height: 100%; font-family: Arial, Helvetica, sans-serif; color: text_COLOR; }

		    .subtitle { color: subt_COLOR; font-size: 16px; padding: 0 0 5px 0;}

		    .section { background: section_COLOR; padding: 5px 20px; border-radius: 2px; margin: 0 0 20px; }
		    
		    .toplogo { margin: 0 0 10px; display: logo_SHOW;  min-height: 40px; text-align: center }
		    .toplogo img { width: auto; max-width: 100%; height: auto; display: inline-block; }

		    .title {  text-align: center; margin: 0 0 20px; }
		    .title_text { color: title_COLOR; font-size: 20px; font-weight: 600; }
		    .title_num { display: inline-block; background: section_COLOR; padding: 5px 20px; border-radius: 2px; margin: 0 0 0 10px; font-weight: 600; font-size: 20px; }

			.c-data-title { width: 180px; display: inline-block; vertical-align: top;}
			.c-price-col { width: 120px;}

			table {width: 100%;}
			td {
				vertical-align: top;
				padding: 5px 0;
				border-bottom: 1px dashed silver !important;
			}
			tr:last-of-type td {
				border-bottom: none !important;	
			}
			ul { padding: 0 0 0 8px; margin: 5px 0}
			li { padding: 0; margin: 0 0 5px; font-size: 14px; }
		    .keep-together {  page-break-inside: avoid; }
		    .break-before { page-break-before: always; }
		    .break-after {  page-break-after: always; }

		    .doc { padding: 20px 0; }
		    .fw600 { font-weight: 600; }
		    .ta-r { text-align: right; }
		    .fs14 { font-size: 14px;}
		    .mr5 { margin: 0 5px 0 0}
		    .mb10 { margin: 0 0 10px 0}
		    .coma-item:after {content: ','}
		    .coma-item:last-of-type:after {content: ''}
		    @page :left {  }

		    @page {
		        size: Letter; /* Change from the default size of A4 */
		        margin: 20px 0 20px 0; /* Set margin on each page */
		        padding: 0 30px 0 85px;
		        font-family: Arial, Helvetica, sans-serif;
		        background: url('https://test.arbostar.com/storage/pdf/view-left1.png') 0 0 repeat-y, page_COLOR;/**LEFT BACKGROUND**/
		    
			    @top-center { content: string(title); text-align: center; color: header_COLOR; font-size: 13px; }
			    @bottom-center { content: string(footer); text-align: center; color: footer_COLOR; font-size: 13px; }
		    }

		    header { width: 0; height: 0;  visibility: hidden; string-set: title content(); text-align: center;  padding-left: 50px; font-size: 13px; }
		    footer { width: 0; height: 0; visibility: hidden; string-set: footer content(); text-align: center; padding-left: 50px; font-size: 13px; }
		</style>
		<header>
		    HEADER_CODE
		</header>
		<footer>
		    FOOTER_CODE
		</footer>
		<div class='doc'>

            <div class='toplogo'><img src='https://test.arbostar.com/storage/pdf/view-imgs1.png' alt=''></div>

            <div class='title'><span class='title_text'>title_TEXT</span><span class='title_num'>{{$estimate->estimate_number}}</span></div>

            <div class='subtitle'>subtitle1_TEXT</div>
			
            <div class='section'>
	            <table>
	            	<tr>
	            		<td><span class='c-data-title'>name_TEXT</span></td>
	            		<td>{{$estimate && $estimate->client &&
		                    $estimate->client->name ?
		                    $estimate->client->name : '-'}}</td>
	            	</tr>
	            	<tr>
	            		<td><span class='c-data-title'>address_TEXT</span></td>
	            		<td>{{$estimate && $estimate->client && $estimate->client->address ?
		                    $estimate->client->address : '-'}}</td>
	            	</tr>
	            	<tr>
	            		<td><span class='c-data-title'>phone_TEXT</span></td>
	            		<td>{{$estimate && $estimate->project && $estimate->project->contact_number &&
		                    $estimate->project->contact_number->value ? $estimate->project->contact_number->value : '-'}}</td>
	            	</tr>
	            	<tr>
	            		<td><span class='c-data-title'>email_TEXT</span></td>
	            		<td>{{$estimate && $estimate->project && $estimate->project->contact_email &&
		                    $estimate->project->contact_email->value ? $estimate->project->contact_email->value : '-'}}</td>
	            	</tr>
	            </table>
            </div>

            <div class='subtitle'>subtitle2_TEXT</div>
            <div class='section'>
            	<table>
	            	<tr>
	            		<td class="fw600">description_TEXT</td>
	            		<td class="fw600 c-price-col ta-r">price_TEXT</td>
	            	</tr>
	            	@foreach ($estimate->services as $service)
	            	<tr>
	            		<td class="fs14">{{$service->description}}</td>
	            		<td class="c-price-col ta-r">{{$service->price}}</td>
	            	</tr>
                    @endforeach
	            	<tr>
	            		<td class="fs14">Product name (x3)</td>
	            		<td class="c-price-col ta-r">50$</td>
	            	</tr>
	            	<tr>
	            		<td class="fw600 ta-r">total_TEXT</td>
	            		<td class="ta-r">44444$</td>
	            	</tr>
	            </table>
            </div>


        	<table>
            	<tr>
            		<td>
            			<div class="subtitle">Team requirements:</div>
            			<div class="mb10">
            				<span class="coma-item fs14">Climber</span>
            				<span class="coma-item fs14">Digger</span>
            				<span class="coma-item fs14">Rider</span>
            				<span class="coma-item fs14">Batman</span>
            			</div>
            		</td>
            		<td>
            			<div class="subtitle">Equipment requirements:</div>
            			<div class="mb10">
            				<span class="coma-item fs14">Climber</span>
            				<span class="coma-item fs14">Digger</span>
            				<span class="coma-item fs14">Rider</span>
            				<span class="coma-item fs14">Batman</span>
            			</div>
            		</td>
            	</tr>
            </table>
            <div class="subtitle">Project requirements:</div>
			<ul>
				<li>
            		<span class="fw600 mr5">Must be at home:</span>
            		<span>Yes</span>
            	</li>
				<li>
            		<span class="fw600 mr5">Clean Up:</span>
            		<span>Brush Disposal, Wood Disposal, General Cleanup</span>
            	</li>
			</ul>
			<div class="break-before">freecontent_TEXT</div>

	    </div>
		        
		</body>
		</html>`;
	// end of code
}
