/*import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, pipe, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';*/
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICurrentUser } from '../modules/currentUser';
import { Observable, throwError, empty } from 'rxjs';
import { environment } from '../../environments/environment';

import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /*  private message: string;*/
  private helper = new JwtHelperService();
  private apiUrl = environment.api;
  constructor(private http: HttpClient, private router: Router) {}
  login(email: string, password: string): Observable<ICurrentUser> {
    return this.http.post<ICurrentUser>(this.apiUrl + '/login', {
      password: password,
      email: email
    }).pipe( map(user => {
      if (user && user.accessToken) {
        localStorage.setItem('currentUser', JSON.stringify(user));
      }
      return <ICurrentUser>user;
    }) );
  }
  logout() {
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
  }
  refreshToken(): Observable<ICurrentUser> {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const token = currentUser.refreshToken;
    //console.log(token)
    return this.http.get<ICurrentUser>(this.apiUrl + '/token/refresh').pipe( map(user => {
      if (user && user.accessToken) {
        localStorage.setItem('currentUser', JSON.stringify(user));
      }
      return <ICurrentUser>user;
    }));
  }
  getAuthToken(): string {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser != null) {
      return currentUser.accessToken;
    }

    return '';
  }
  // /**
  //  * this is used to clear anything that needs to be removed
  //  */
  // clear(): void {
  //   localStorage.clear();
  // }

  // /**
  //  * check for expiration and if token is still existing or not
  //  * @return {boolean}
  //  */
  // isAuthenticated(): boolean {
  //   return localStorage.getItem('token') != null && !this.isTokenExpired();
  // }

  // // simulate jwt token is valid
  // // https://github.com/theo4u/angular4-auth/blob/master/src/app/helpers/jwt-helper.ts
  // isTokenExpired(): boolean {
  //   return false;
  // }

  // loginAdmin(): void {
  //   localStorage.setItem('token', `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE1MzMyNzM5NjksImV4cCI6MTU2NDgxMDAwNSwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoiVGVzdCBHdWFyZCIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJyb2xlIjoiQWRtaW4ifQ.rEkg53_IeCLzGHlmaHTEO8KF5BNfl6NEJ8w-VEq2PkE`);

  //   this._router.navigate(['/dashboard']);
  // }

  // login(): void {
  //   localStorage.setItem('token', `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE1MzMyNzM5NjksImV4cCI6MTU2NDgxMDAwNSwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoiVGVzdCBHdWFyZCIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20ifQ.GA0Y9gYIjmx1jLwuRHuBgZ8m6o-NgkD84nO0ym68CWo`);

  //   this._router.navigate(['/dashboard']);
  // }

  // /**
  //  * this is used to clear local storage and also the route to login
  //  */
  // logout(): void {
  //   this.clear();
  //   this._router.navigate(['/login']);
  // }

  decode() {
    return this.helper.decodeToken(localStorage.getItem('currentUser'));
  }
}
