import { Injectable } from '@angular/core';
import { ContactComponent } from './Client/contact/contact.component';
import { EditRepairComponent } from './Equipment/Repair/editrepair/editrepair.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditServiceComponent } from './Equipment/Service/editservice/editservice.component';
import { SettingsComponent } from './Equipment/Service/settings/settings.component';
import { AddComponent } from './Equipment/Status/add/add.component';
import { DeleteComponent } from './Equipment/Status/delete/delete.component';
import { EditReminderComponent } from './Equipment/Service/editreminder/editreminder.component';
@Injectable({
	providedIn: 'root'
})
export class ModalService {
	constructor(private dialog: MatDialog) {}
	edit_contact(client, cb:any=()=>{}){
		const dialogConfig = new MatDialogConfig();
		//dialogConfig.disableClose = true;
		dialogConfig.autoFocus = false;
		dialogConfig.data = client;
		this.dialog.open(ContactComponent, dialogConfig).afterClosed().subscribe(data=>{
			cb(data);
		});
	}
	edit_repair(repair, complete=false, cb:any=()=>{}) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {repair: repair, complete: complete};
		this.dialog.open(EditRepairComponent, dialogConfig).afterClosed().subscribe(data=>{
			cb(data);
		});
	}
	edit_service(service, cb:any=()=>{}) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			service: service
		};
		this.dialog.open(EditServiceComponent, dialogConfig).afterClosed().subscribe(data=>{
			cb(data);
		});
	}
	edit_service_reminder_settings(reminder, cb:any=()=>{}) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			reminder: reminder
		};
		this.dialog.open(SettingsComponent, dialogConfig).afterClosed().subscribe(data=>{
			cb(data);
		});
	}
	edit_reminder(reminder, type='Edit', cb:any=()=>{}) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			reminder: reminder,
			type: type
		};
		this.dialog.open(EditReminderComponent, dialogConfig).afterClosed().subscribe(data=>{
			cb(data);
		});
	}
	add_status(status, cb:any=()=>{}) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = this.copyObject(status);
		this.dialog.open(AddComponent, dialogConfig).afterClosed().subscribe(data=>{
			cb(data);
		});
	}
	delete_status(data, cb:any=()=>{}) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = this.copyObject(data);
		this.dialog.open(DeleteComponent, dialogConfig).afterClosed().subscribe(data=>{
			cb(data);
		});
	}
	copyObject(data: any) {
        return JSON.parse(JSON.stringify(data));
    }
}
