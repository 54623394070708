import { Component, Output, EventEmitter } from '@angular/core';

@Component({
	inputs: ['field', '_selected'],
	selector: 'field-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss']
})
export class SelectComponent {
	public field;
	public _selected;
	@Output('update') update = new EventEmitter();
	constructor() {}
}
