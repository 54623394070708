import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService, EqRepairService, UiService } from '@services';

@Component({
	selector: 'app-add',
	templateUrl: './add.component.html',
	styleUrls: ['./add.component.scss']
})
export class AddComponent {
	public status: any = {
		color: '#B71C1C'
	};
	public update_status:any = {};
	constructor(private dialogRef: MatDialogRef<AddComponent>,
		public eqr: EqRepairService,
		public ui: UiService,
		public us: UserService,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		if (this.data) {
			this.status = this.data;
		}
	}
	cancel() {
		this.dialogRef.close();
	}
	save() {
		if(this.status.id) {
			this.update_status.id = this.status.id;
			this.dialogRef.close(this.update_status);
		} else {
			this.dialogRef.close(this.status);
		}
	}
}
