import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sort'
})
export class SortPipe implements PipeTransform {
	transform(arr: any, field='id', asc=1, refresh?): any {
		arr = arr.slice();
		arr.sort(function(a, b){
			if(a[field]>b[field]) return -1*asc;
			if(a[field]==b[field]){
				if(a.id>b.id) return -1;
				return 1;
			}
			return asc;
		});
		return arr;
	}
}
