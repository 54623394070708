import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
@Injectable({
	providedIn: 'root'
})
export class SocketService {
	constructor(private socket: Socket) {}
	public rooms = [];
	public _rooms = {};
	join(roomId) {
		if (typeof roomId != 'string') { return; }
		if (this._rooms[roomId]) { return; }
		this._rooms[roomId] = true;
		this.rooms.push(roomId);
		this.socket.emit('join', roomId);
	}
	on(to, cb = resp => {}) {
		this.socket.on(to, cb);
	}
	emit(to, data, room=false) {
		if (room) {
			this.socket.emit('secure', {
				room: room,
				data: data,
				to: to
			});
		} else {
			this.socket.emit('bridge', {
				data: data,
				to: to
			});
		}
	}
}
