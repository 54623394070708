import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { UiService, UserService, EqJobService, BaseService, AlertService, EqServicesService, EqItemService } from '@services';
import { ErrorStateMatcher } from '@angular/material/core';
import * as moment from 'moment';
export class MyErrorStateMatcher implements ErrorStateMatcher {
	public form: any;
	constructor(form: any){
		this.form = form;
	}
	isErrorState(control:any): boolean {
		return !control.value && this.form.formSubmitted;
	}
}
@Component({
	selector: 'app-editreminder',
	templateUrl: './editreminder.component.html',
	styleUrls: ['./editreminder.component.scss']
})
export class EditReminderComponent {
	public reminder: any = {};
	public update_reminder: any = {};
	public searchEquipment = '';
	public searchAssigned = '';
	public matcher:any;
	public date;
	public hours_spent;
	public min_spent;
	public current = moment();
	constructor(private dialogRef: MatDialogRef<EditReminderComponent>,
		public ui: UiService,
		public eqj: EqJobService,
		public bs: BaseService,
		public alert: AlertService,
		public eqs: EqServicesService,
		public eqi: EqItemService,
		public us: UserService,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		if(this.data.reminder) {
			this.reminder = this.data.reminder;
			this.date = this.data.reminder.date;
		}
		this.matcher = new MyErrorStateMatcher(this.reminder);
	}
	cancel() {
		this.dialogRef.close();
	}
	save() {
		this.reminder.formSubmitted = true;
		if(!this.reminder.equipment_id) return this.alert.fail('Add Equipment');
		if(!this.reminder.equipment_service_id) return this.alert.fail('Add Service');
		if(!this.reminder.date) return this.alert.fail('Add Date');
		if(!this.reminder.status_id) return this.alert.fail('Add Status');

		if(!this.reminder.id) {
			this.eqj.create(this.reminder, ()=>{
				this.eqj.load({
					page: this.eqj.page,
					perPage: this.eqj.perPage,
					sort: this.eqj.sort,
					sortby: this.eqj.sortby
				});
			});
			this.cancel();
		} else if(this.reminder.id){
			this.eqj.save(this.update_reminder, ()=>{
				this.eqj.load({
					page: this.eqj.page,
					perPage: this.eqj.perPage,
					sort: this.eqj.sort,
					sortby: this.eqj.sortby
				});
			});
			this.cancel();
		}
	}
	editdate() {
		this.reminder.date = moment(this.date).format('YYYY-MM-DD HH:mm:ss');
		this.update_reminder.date = moment(this.date).format('YYYY-MM-DD HH:mm:ss');
	}
	timespent() {
		if(this.hours_spent || this.min_spent) {
			if(!this.hours_spent) this.hours_spent = 0;
			if(!this.min_spent) this.min_spent = 0;
			if(this.hours_spent > 900) this.hours_spent = 900;
			this.reminder.time = (this.hours_spent * 60) + this.min_spent;
			this.reminder.time = (this.hours_spent * 60) + this.min_spent;
		}
	}
}
