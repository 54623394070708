import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SimpleTableComponent } from './table.component';
import { AddComponent } from './add/add.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@services';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CustomMaterialModule } from '@root/modules/template';
@NgModule({
	entryComponents: [AddComponent],
	declarations: [SimpleTableComponent, AddComponent],
	exports: [SimpleTableComponent],
	imports: [
		RouterModule,
		FormsModule,
		SharedModule,
		CommonModule,
		FlexLayoutModule,
		NgScrollbarModule,
		CustomMaterialModule
	]
})
export class TableModule { }
