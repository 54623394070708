import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { EstimateService } from './estimate.service';
import { SocketService } from '../socket.service';
import { AlertService } from '../alert.service';

@Injectable({
	providedIn: 'root'
})
export class SpecialistsService {
	public specialists = [];
	constructor(private http: HttpService,
		private socket: SocketService,
		private alert: AlertService,
		private es: EstimateService){
		this.es.offers('specialists', specialists=>{
			this.specialists = specialists;
			if(this.refresh) this.refresh();
		});
		socket.on('specialist_create', specialistId => {
			this.fetch(specialistId, specialist=>{
				this.specialists.push(specialist);
				if(this.refresh) this.refresh();
			});
		});
		socket.on('specialist_update', specialistId => {
			this.fetch(specialistId, specialist=>{
				for(let i = 0; i < this.specialists.length; i++){
					if(this.specialists[i].id == specialistId){
						for(let each in this.specialists[i]){
							this.specialists[i][each] = specialist[each];
						}
						for(let each in specialist){
							this.specialists[i][each] = specialist[each];
						}
						break;
					}
				}
				if(this.refresh) this.refresh();
			});
		});
		socket.on('specialist_delete', specialistId => {
			for(let i = 0; i < this.specialists.length; i++){
				if(this.specialists[i].id == specialistId){
					this.specialists.splice(i, 1);
				}
			}
			if(this.refresh) this.refresh();
		});
	}
	public refresh;
	fetch(specialistId, cb:any=()=>{}){
		this.http.c_fetch('estimates/offers/specialists', {
			id: specialistId
		}, cb);
	}
	create(doc){
		this.http.c_create('estimates/offers/specialists', doc, resp => {
			this.specialists.push(resp);
			this.socket.emit('specialist_create', resp.id);
			if(this.refresh) this.refresh();
		});
	}
	update(doc){
		this.http.c_update('estimates/offers/specialists', doc, resp => {
			this.socket.emit('specialist_update', resp.id);
			if(this.refresh) this.refresh();
		});
	}
	delete(doc){
		this.alert.yes('Are you sure that you want to delete specialist?', () => {
			for(let i = 0; i < this.specialists.length; i++){
				if(this.specialists[i].id == doc.id){
					this.specialists.splice(i, 1);
				}
			}
			this.http.c_delete('estimates/offers/specialists', doc, resp => {
				this.socket.emit('specialist_delete', doc.id);
				if(this.refresh) this.refresh();
			});
		});
	}
}