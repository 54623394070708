import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from './http.service';

@Injectable({
	providedIn: 'root'
})
export class Translate {
	/*
	*	Languages List "public languages_list"
	*/
		public languages_list = [{	
			name: "English",
			country: "US",
			code: "en_US"
		},{	
			name: "German",
			country: "Germany",
			code: "de_DE"
		},{	
			name: "Chinese",
			country: "PRC",
			code: "zh_CN"
		},{	
			name: "Chinese",
			country: "Taiwan",
			code: "zh_TW"
		},{	
			name: "Czech",
			country: "Czech",
			code: "epublic"
		},{	
			name: "Dutch",
			country: "Belgium",
			code: "nl_BE"
		},{	
			name: "Dutch",
			country: "Netherlands",
			code: "nl_NL"
		},{	
			name: "English",
			country: "Australia",
			code: "en_AU"
		},{	
			name: "English",
			country: "Britain",
			code: "en_GB"
		},{	
			name: "English",
			country: "Canada",
			code: "en_CA"
		},{	
			name: "English",
			country: "New",
			code: "ealand"
		},{	
			name: "English",
			country: "Singapore",
			code: "n_SG"
		},{	
			name: "French",
			country: "Belgium",
			code: "fr_BE"
		},{	
			name: "French",
			country: "Canada",
			code: "fr_CA"
		},{	
			name: "French",
			country: "France",
			code: "fr_FR"
		},{	
			name: "French",
			country: "Switzerland",
			code: "fr_CH"
		},{	
			name: "German",
			country: "Austria",
			code: "de_AT"
		},{	
			name: "German",
			country: "Liechtenstein",
			code: "de_LI"
		},{	
			name: "German",
			country: "Switzerland",
			code: "de_CH"
		},{	
			name: "Italian",
			country: "Italy",
			code: "it_IT"
		},{	
			name: "Italian",
			country: "Switzerland",
			code: "it_CH"
		},{	
			name: "Japanese",
			country: "Japanese",
			code: "ja_JP"
		},{	
			name: "Korean",
			country: "Korean",
			code: "ko_KR"
		},{	
			name: "Polish",
			country: "Polish",
			code: "pl_PL"
		},{	
			name: "Russian",
			country: "Russian",
			code: "ru_RU"
		},{	
			name: "Spanish",
			country: "Spanish",
			code: "es_ES"
		},{	
			name: "Arabic",
			country: "Egypt",
			code: "ar_EG"
		},{	
			name: "Arabic",
			country: "Israel",
			code: "ar_IL"
		},{	
			name: "Bulgarian",
			country: "Bulgaria",
			code: "bg_BG"
		},{	
			name: "Catalan",
			country: "Spain",
			code: "ca_ES"
		},{	
			name: "Croatian",
			country: "Croatia",
			code: "hr_HR"
		},{	
			name: "Danish",
			country: "Denmark",
			code: "a_DK"
		},{	
			name: "English",
			country: "India",
			code: "en_IN"
		},{	
			name: "English",
			country: "Ireland",
			code: "en_IE"
		},{	
			name: "English",
			country: "Zimbabwe",
			code: "en_ZA"
		},{	
			name: "Finnish",
			country: "Finland",
			code: "fi_FI"
		},{	
			name: "Greek",
			country: "Greece",
			code: "el_GR"
		},{	
			name: "Hebrew",
			country: "Israel",
			code: "iw_IL"
		},{	
			name: "Hindi",
			country: "India",
			code: "hi_IN"
		},{	
			name: "Hungarian",
			country: "Hungary",
			code: "hu_HU"
		},{	
			name: "Indonesian",
			country: "Indonesia",
			code: "in_ID"
		},{	
			name: "Latvian",
			country: "Latvia",
			code: "lv_LV"
		},{	
			name: "Lithuanian",
			country: "Lithuania",
			code: "lt_LT"
		},{	
			name: "Norwegian-Bokmol",
			country: "Norway",
			code: "b_NO"
		},{	
			name: "Portuguese",
			country: "Brazil",
			code: "pt_BR"
		},{	
			name: "Portuguese",
			country: "Portugal",
			code: "pt_PT"
		},{	
			name: "Romanian",
			country: "Romania",
			code: "ro_RO"
		},{	
			name: "Serbian",
			country: "Serbian",
			code: "sr_RS"
		},{	
			name: "Slovak",
			country: "Slovakia",
			code: "sk_SK"
		},{	
			name: "Slovenian",
			country: "Slovenia",
			code: "sl_SI"
		},{	
			name: "Spanish",
			country: "US",
			code: "es_US"
		},{	
			name: "Swedish",
			country: "Sweden",
			code: "sv_SE"
		},{	
			name: "Tagalog",
			country: "Philippines",
			code: "tl_PH"
		},{	
			name: "Thai",
			country: "Thailand",
			code: "th_TH"
		},{	
			name: "Turkish",
			country: "Turkey",
			code: "tr_TR"
		},{	
			name: "Ukrainian",
			country: "Ukraine",
			code: "uk_UA"
		},{	
			name: "Vietnamese",
			country: "Vietnam",
			code: "vi_VN"
		}]
	/*
	*	End of List
	*/
	public words = localStorage.getItem('words') && JSON.parse(localStorage.getItem('words')) || {};
	public _words = localStorage.getItem('words') && JSON.parse(localStorage.getItem('words')) || {};
	create(word){
		return;
		if(typeof word != 'string') return;
		this.http.post(this.http.url + '/translate/words/create', {
			word: word
		});
	}
	set(word){
		this.words[word] = true;
		localStorage.setItem('words', JSON.stringify(this.words));
	}
	public _languages_list = {};
	public validCode = {};
	public languages = localStorage.getItem('languages') && JSON.parse(localStorage.getItem('languages')) || [this.languages_list[0]];
	fill(){
		if(!this.languages.length) return;
		localStorage.setItem('languages', JSON.stringify(this.languages));
	}
	public language = localStorage.getItem('language') || this.languages[0].code;
	select(code){
		if(!this.validCode[code]) return;
		this.language = code;
		this.translate.use(this.language);
		localStorage.setItem('language', code)
	}
	public translations = localStorage.getItem('translations') && JSON.parse(localStorage.getItem('translations')) || {};
	private translation_timeout = {};
	save(word, lang=this.language){
		this.translate.setTranslation(this.language, this.translations[this.language]);
		localStorage.setItem('translations', JSON.stringify(this.translations));
		if(!word) return;
		if(!this.translation_timeout[lang+word]) this.translation_timeout[lang+word] = {};
		this.http.afterWhile(this.translation_timeout[lang+word], ()=>{
			this.http.post(this.http.url + '/translate/save', {
				translation: this.translations[lang][word],
				word: word,
				lang: lang,
			});
		});
	}
	load(lang=this.language){
		if(!lang) return;
		this.http.get(this.http.url + '/translate/get/'+lang, translations=>{
			for(let i = 0; i < translations.length; i++){
				this.translations[lang][translations[i].word] = translations[i].translation;
			}
			this.translate.setTranslation(this.language, this.translations[this.language]);
		});
	}
	constructor(private translate: TranslateService, private http: HttpService) {
		for(let i = 0; i < this.languages_list.length; i++){
			this._languages_list[this.languages_list[i].code] = this.languages_list[i];
		}
		for(let i = 0; i < this.languages.length; i++){
			this.validCode[this.languages[i].code] = true;
			if(!this.translations[this.languages[i].code]){
				this.translations[this.languages[i].code] = {};
			}
			translate.setTranslation(this.languages[i].code, this.translations[this.languages[i].code]);
		}
		translate.setDefaultLang(this.languages[0].code);
		translate.use(this.language);
		this.load();
		this.http.get(this.http.url + '/translate/words', words=>{
			for(let i = 0; i < words.length; i++){
				this._words[words[i].word] = false;
				this.set(words[i].word);
			}
			for(let each in this._words){
				if(this._words[each]) this.create(each);
			}
		});
	}
}
