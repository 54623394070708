import { Component } from '@angular/core';
import { FileService, DraftService, ClientService } from '@services';

@Component({
	inputs: ['service', 'estimate', 'update'],
	selector: 'attachments-direct',
	templateUrl: './attachments.component.html',
	styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent {
	public service: any;
	public estimate: any;
	public update;
	public newFile;
	constructor(public file: FileService, public ds: DraftService, public cs: ClientService) {}
	add(files){
		this.file.add(files, '/drafts/attachments/create', {}, created=>{
			if(!this.service.files) this.service.files = [];
			for(let i = 0; i<created.length; i++){
				this.service.files.push(created[i]);
			}
			this.ds.update();
			delete this.newFile;
		});
	}
}
