import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CropComponent } from './crop.component';
@Injectable({
  providedIn: 'root'
})
export class CropService {
  constructor(private dialog: MatDialog) {}
  open (opts={}, cb = dataUrl => {}) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = opts;
    this.dialog.open(CropComponent, dialogConfig).afterClosed().subscribe(cb);
  }
}
