import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMaterialModule } from '@root/modules/template';
import { SharedModule } from '@services';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeocodeService } from '@services';
import { SharedPipes } from '@pipes';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AddressComponent } from './address.component';
import { AddressDisplayComponent } from './address-display.component';

@NgModule({
    declarations: [
        AddressComponent,
        AddressDisplayComponent
    ],
    providers: [
		GeocodeService
	],
    imports:[
        CommonModule,
        CustomMaterialModule,
        SharedModule,
        FlexLayoutModule,
        AgmCoreModule,
        FormsModule, 
        ReactiveFormsModule,
        SharedPipes,
        MatGoogleMapsAutocompleteModule,

    ],
    exports: [
        AddressComponent,
        AddressDisplayComponent
    ]
})
export class AddressModule { }