export { FieldsModule } from './Fields/fields/fields.module';

export { AddPaymentComponent } from './add-payment/add-payment.component';
export { AddPaymentModule } from './add-payment/add-payment.module';

export { DynamicMasksModule } from './phone-mask/phone.mask.module';

export { AddressModule } from './address/address.module';

export { ActivityModule } from './activity/activity.module';
export { ActivitiesModule } from './Activities/activities/activities.module';

export { NoteComponent } from './note/note/note.component';
export { NoteModule } from './note/note/note.module';

export { TagsModule } from './Tags/tags/tags.module';
export { TimeModule } from './Time/time/time.module';
export { CategoriesModule } from './Categories/categories/categories.module';

export { MapModule } from './Map/map/map.module';
export { MapModule as CleanMapModule } from './Map/clean_map/map.module';
export { MapModule as PickLocationModule } from './Map/pick_loc/map.module';
export { PhoneMaskByCodePipe } from './phone-mask/phone.mask.pipe';

export { RequirementsModule } from './Requirements/requirements/requirements.module';
export { QuoteConfirmModule } from './QuoteConfirm/quote-confirm/quote-confirm.module';
export { QuoteConfirmComponent } from './QuoteConfirm/quote-confirm/quote-confirm.component';
export { ClientInfoCommonModule } from './client-info/client-info.module';

export { ServicesModule } from './Services/services/services.module';

export { ContactComponent } from './Create/Contact/contact/contact.component';
export { ContactModule } from './Create/Contact/contact/contact.module';

export { TaskComponent } from './Create/Task/task/task.component';
export { TaskModule } from './Create/Task/task/task.module';

export { PreviewModule } from './Preview/preview/preview.module';
export { TableModule } from './Table/table/table.module';

export { AlertComponent } from './Alert/alert/alert.component';

export { DatepickerModule } from './Datepicker/datepicker/datepicker.module';

export { AttachmentModule } from './Attachment/attachment/attachment.module';
export { StatusModule } from './Status/status/status.module';
