import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatepickerComponent } from './datepicker.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CustomMaterialModule } from '@root/modules/template';

@NgModule({
  exports: [DatepickerComponent],
  declarations: [DatepickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatMomentDateModule,
    CustomMaterialModule
  ]
})
export class DatepickerModule { }
