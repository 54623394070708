import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { UserService } from './user.service';
import { HttpService } from './http.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {
    uri = environment.api;
    public templates = [];
    public template:any = {};

    public config: any = {
        height: 250,
        theme: 'modern',
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor textcolor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        /* need to add image uploading */
        toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link code | help',
        content_css: [
          '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
          '//www.tinymce.com/css/codepen.min.css'
        ],
        branding: false
     };
	constructor(private http:HttpClient,
        private hs:HttpService,
        private us:UserService) {
    }
    fetch(template){
      for (let i=this.templates.length-1; i>=0; i--) {
        if(this.templates[i].id==template.id){
          return this.templates[i]=template;
        }
      }
    }
    getTemplates() {
        return this.http.get(`${this.uri}/communication/email/templates/get`);
    }
    create(data: any) {
        /*if (!this.us.perm.createtasks) { return; }*/
        return this.http.post(`${this.uri}/communication/email/templates/create`, data);
    }
    update(template:any){
        return this.http.post(`${this.uri}/communication/email/templates/update`, template);
    }
    send(template,cb){
        this.hs.upload(`${this.uri}/communication/email/sender`, template, cb)

    }
    deleteTemplate(template_id: number) {
        /*if (!this.us.perm.deletetasks) { return; }*/
        return this.http.post(`${this.uri}/communication/email/templates/delete`, {id:[template_id]});
    }

    deleteTemplates(template_ids) {
        /*if (!this.us.perm.deletetasks) { return; }*/
        return this.http.post(`${this.uri}/communication/email/templates/delete`, {id:template_ids});
    }
}
