import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagsComponent } from './tags.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedPipes } from '@pipes';
import { CustomMaterialModule } from '@root/modules/template';

@NgModule({
	exports: [TagsComponent],
	declarations: [TagsComponent],
	imports: [
		ReactiveFormsModule,
		FormsModule,
		SharedPipes,
		CommonModule,
		CustomMaterialModule
	]
})
export class TagsModule { }