import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TagsService } from '@services';

@Component({
	selector: 'tags-picker',
	inputs: ['which', 'tags', 'placeholder'],
	templateUrl: './tags.component.html',
	styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
	public tagsCtrl = new FormControl();
	@Output() update = new EventEmitter<any>();
	@Output() delete = new EventEmitter<any>();
  	@ViewChild('tagsInput', { static: true }) tagsInput: ElementRef<HTMLInputElement>;
	public placeholder:any;
	public which:any;
	public tags:any;
	constructor(public ts: TagsService){
		if(!this.which) this.which='contacts';
	}
	ngOnInit() {}
}
