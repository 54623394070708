import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '@services';
@Pipe({
  name: 'usersofrole'
})
export class UsersofrolePipe implements PipeTransform {
  constructor(private us: UserService) {}
  transform(role: any, refresh?: any, refresh2?: any): any {
    const arr = [];
    for (let i = 0; i < this.us.users.length; i++) {
      if (!this.us.users[i].roles) { continue; }
      for (let j = 0; j < this.us.users[i].roles.length; j++) {
        if (role.id == this.us.users[i].roles[j].id) {
          arr.push(this.us.users[i]);
          break;
        }
      }
    }
    return arr.slice(0, 4);
  }
}
