import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';

@Injectable({
    providedIn: 'root'
})
export class SessionsService {
    uri = environment.api;

    constructor(private http: HttpClient) { }

    getSessions() {
        return this.http.get(`${this.uri}/sessions/get`);
    }

    deleteSessions() {
        return this.http.post(`${this.uri}/sessions/delete`, {});
    }
}
