import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@services';

@Component({
	selector: 'app-favorite',
	templateUrl: './favorite.component.html',
	styleUrls: ['./favorite.component.scss']
})
export class FavoriteComponent implements OnInit {
	public types = {
		lead: 'Lead',
		client: 'Client',
		partner: 'Partner',
		subcontractor: 'Subcontractor',
		supplier: 'Supplier',
		vendor: 'Vendor',
		other: 'Other'
	};
	constructor( private dialogRef: MatDialogRef<FavoriteComponent>,
		public us: UserService) {}
	close() {
		this.dialogRef.close();
	}
	ngOnInit() {}
}
