import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ttime'
})
export class TtimePipe implements PipeTransform {
	transform(dateString: any): any {
		return dateString;
		// console.log(Date.parseExact(dateString, "yyyy-MM-dd hh:mm:ss"));
		// return Date.parseExact(dateString, "yyyy-MM-dd hh:mm:ss");
	}
}