export const masks = [
    {
      "mask": "0 (000) 000-0000",
      "cc": [
        "US",
        "CA"
      ],
      "name_en": "USA and Canada",
      "code": 1
    },
    {
      "mask": "00 (000) 000-0000",
      "cc": "EG",
      "name_en": "Egypt",
      "code": 20
    },
    {
      "mask": "000-00-000-0000",
      "cc": "SS",
      "name_en": "South Sudan",
      "code": 211
    },
    {
      "mask": "000-00-0000-000",
      "cc": "MA",
      "name_en": "Morocco",
      "code": 212
    },
    {
      "mask": "000-00-000-0000",
      "cc": "DZ",
      "name_en": "Algeria",
      "code": 213
    },
    {
      "mask": "000-00-000-000",
      "cc": "TN",
      "name_en": "Tunisia",
      "code": 216
    },
    {
      "mask": "000-00-000-000",
      "cc": "LY",
      "name_en": "Libya",
      "code": 218
    },
    {
      "mask": "000 (000) 00-00",
      "cc": "GM",
      "name_en": "Gambia",
      "code": 220
    },
    {
      "mask": "000-00-000-0000",
      "cc": "SN",
      "name_en": "Senegal",
      "code": 221
    },
    {
      "mask": "000-00-00-0000",
      "cc": "MR",
      "name_en": "Mauritania",
      "code": 222
    },
    {
      "mask": "000-00-00-0000",
      "cc": "ML",
      "name_en": "Mali",
      "code": 223
    },
    {
      "mask": "000-00-000-000",
      "cc": "GN",
      "name_en": "Guinea",
      "code": 224
    },
    {
      "mask": "000-00-000-000",
      "cc": "CI",
      "name_en": "Cote d’Ivoire  (Ivory Coast) ",
      "code": 225
    },
    {
      "mask": "000-00-00-0000",
      "cc": "BF",
      "name_en": "Burkina Faso",
      "code": 226
    },
    {
      "mask": "000-00-00-0000",
      "cc": "NE",
      "name_en": "Niger",
      "code": 227
    },
    {
      "mask": "000-00-000-000",
      "cc": "TG",
      "name_en": "Togo",
      "code": 228
    },
    {
      "mask": "000-00-00-0000",
      "cc": "BJ",
      "name_en": "Benin",
      "code": 229
    },
    {
      "mask": "000-000-0000",
      "cc": "MU",
      "name_en": "Mauritius",
      "code": 230
    },
    {
      "mask": "000-00-000-000",
      "cc": "LR",
      "name_en": "Liberia",
      "code": 231
    },
    {
      "mask": "000-00-000000",
      "cc": "SL",
      "name_en": "Sierra Leone",
      "code": 232
    },
    {
      "mask": "000 (000) 000-000",
      "cc": "GH",
      "name_en": "Ghana",
      "code": 233
    }/*,
    {
      "mask": "000 (000) 000-0000",
      "cc": "NG",
      "name_en": "Nigeria",
      "code": 234
    }*/,
    {
      "mask": "000-00-00-00-00",
      "cc": "TD",
      "name_en": "Chad",
      "code": 235
    },
    {
      "mask": "000-00-00-0000",
      "cc": "CF",
      "name_en": "Central African Republic",
      "code": 236
    },
    {
      "mask": "000-0000-0000",
      "cc": "CM",
      "name_en": "Cameroon",
      "code": 237
    },
    {
      "mask": "000 (000) 00-00",
      "cc": "CV",
      "name_en": "Cape Verde",
      "code": 238
    },
    {
      "mask": "000-00-00000",
      "cc": "ST",
      "name_en": "Sao Tome and Principe",
      "code": 239
    },
    {
      "mask": "000-00-000-0000",
      "cc": "GQ",
      "name_en": "Equatorial Guinea",
      "code": 240
    },
    {
      "mask": "000-0-00-00-00",
      "cc": "GA",
      "name_en": "Gabon",
      "code": 241
    },
    {
      "mask": "000-00-000-0000",
      "cc": "CG",
      "name_en": "Congo  (Brazzaville) ",
      "code": 242
    },
    {
      "mask": "000 (000) 000-000",
      "cc": "CD",
      "name_en": "Dem. Rep. Congo",
      "code": 243
    },
    {
      "mask": "000 (000) 000-000",
      "cc": "AO",
      "name_en": "Angola",
      "code": 244
    },
    {
      "mask": "000-0-000000",
      "cc": "GW",
      "name_en": "Guinea-Bissau",
      "code": 245
    },
    {
      "mask": "000-000-0000",
      "cc": "IO",
      "name_en": "Diego Garcia",
      "code": 246
    },
    {
      "mask": "000-0000",
      "cc": "AC",
      "name_en": "Ascension",
      "code": 247
    },
    {
      "mask": "000-0-000-000",
      "cc": "SC",
      "name_en": "Seychelles",
      "code": 248
    },
    {
      "mask": "000-00-000-0000",
      "cc": "SD",
      "name_en": "Sudan",
      "code": 249
    },
    {
      "mask": "000 (000) 000-000",
      "cc": "RW",
      "name_en": "Rwanda",
      "code": 250
    },
    {
      "mask": "000-00-000-0000",
      "cc": "ET",
      "name_en": "Ethiopia",
      "code": 251
    },
    {
      "mask": "000-00-000-000",
      "cc": "SO",
      "name_en": "Somalia",
      "code": 252
    },
    {
      "mask": "000-0-000-000",
      "cc": "SO",
      "name_en": "Somalia",
      "code": 252
    },
    {
      "mask": "000-00-00-00-00",
      "cc": "DJ",
      "name_en": "Djibouti",
      "code": 253
    },
    {
      "mask": "000-000-000000",
      "cc": "KE",
      "name_en": "Kenya",
      "code": 254
    },
    {
      "mask": "000-00-000-0000",
      "cc": "TZ",
      "name_en": "Tanzania",
      "code": 255
    },
    {
      "mask": "000 (000) 000-000",
      "cc": "UG",
      "name_en": "Uganda",
      "code": 256
    },
    {
      "mask": "000-00-00-0000",
      "cc": "BI",
      "name_en": "Burundi",
      "code": 257
    },
    {
      "mask": "000-00-000-000",
      "cc": "MZ",
      "name_en": "Mozambique",
      "code": 258
    },
    {
      "mask": "000-00-000-0000",
      "cc": "ZM",
      "name_en": "Zambia",
      "code": 260
    },
    {
      "mask": "000-00-00-00000",
      "cc": "MG",
      "name_en": "Madagascar",
      "code": 261
    },
    {
      "mask": "000-00000-0000",
      "cc": "FR",
      "name_en": "Mayotte",
      "code": 262
    },
    {
      "mask": "000-0-000000",
      "cc": "ZW",
      "name_en": "Zimbabwe",
      "code": 263
    },
    {
      "mask": "000-00-000-0000",
      "cc": "NA",
      "name_en": "Namibia",
      "code": 264
    },
    {
      "mask": "000-0-0000-0000",
      "cc": "MW",
      "name_en": "Malawi",
      "code": 265
    },
    {
      "mask": "000-0-000-0000",
      "cc": "LS",
      "name_en": "Lesotho",
      "code": 266
    },
    {
      "mask": "000-00-000-000",
      "cc": "BW",
      "name_en": "Botswana",
      "code": 267
    },
    {
      "mask": "000-00-00-0000",
      "cc": "SZ",
      "name_en": "Swaziland",
      "code": 268
    },
    {
      "mask": "000-00-00000",
      "cc": "KM",
      "name_en": "Comoros",
      "code": 269
    },
    {
      "mask": "00-00-000-0000",
      "cc": "ZA",
      "name_en": "South Africa",
      "code": 27
    },
    {
      "mask": "000-0000",
      "cc": "SH",
      "name_en": "Saint Helena",
      "code": 290
    },
    {
      "mask": "000-0-000-000",
      "cc": "ER",
      "name_en": "Eritrea",
      "code": 291
    },
    {
      "mask": "000-000-0000",
      "cc": "AW",
      "name_en": "Aruba",
      "code": 297
    },
    {
      "mask": "000-000-000",
      "cc": "FO",
      "name_en": "Faroe Islands",
      "code": 298
    },
    {
      "mask": "000-00-00-00",
      "cc": "GL",
      "name_en": "Greenland",
      "code": 299
    },
    {
      "mask": "00 (000) 000-0000",
      "cc": "GR",
      "name_en": "Greece",
      "code": 30
    },
    {
      "mask": "00-00-000-0000",
      "cc": "NL",
      "name_en": "Netherlands",
      "code": 31
    },
    {
      "mask": "00 (000) 000-000",
      "cc": "BE",
      "name_en": "Belgium",
      "code": 32
    },
    {
      "mask": "00 (000) 000-000",
      "cc": "FR",
      "name_en": "France",
      "code": 33
    },
    {
      "mask": "00 (000) 000-000",
      "cc": "ES",
      "name_en": "Spain",
      "code": 34
    },
    {
      "mask": "000-000-00000",
      "cc": "GI",
      "name_en": "Gibraltar",
      "code": 350
    },
    {
      "mask": "000-00-000-0000",
      "cc": "PT",
      "name_en": "Portugal",
      "code": 351
    },
    {
      "mask": "000 (000) 000-000",
      "cc": "LU",
      "name_en": "Luxembourg",
      "code": 352
    },
    {
      "mask": "000 (000) 000-000",
      "cc": "IE",
      "name_en": "Ireland",
      "code": 353
    },
    {
      "mask": "000-000-0000",
      "cc": "IS",
      "name_en": "Iceland",
      "code": 354
    },
    {
      "mask": "000 (000) 000-000",
      "cc": "AL",
      "name_en": "Albania",
      "code": 355
    },
    {
      "mask": "000-0000-0000",
      "cc": "MT",
      "name_en": "Malta",
      "code": 356
    },
    {
      "mask": "000-00-000-000",
      "cc": "CY",
      "name_en": "Cyprus",
      "code": 357
    }/*,
    {
      "mask": "000 (000) 000-00-00",
      "cc": "FI",
      "name_en": "Finland",
      "code": 358
    }*/,
    {
      "mask": "000 (000) 000-000",
      "cc": "BG",
      "name_en": "Bulgaria",
      "code": 359
    },
    {
      "mask": "00 (000) 000-000",
      "cc": "HU",
      "name_en": "Hungary",
      "code": 36
    },
    {
      "mask": "000 (000) 00-000",
      "cc": "LT",
      "name_en": "Lithuania",
      "code": 370
    },
    {
      "mask": "000-00-000-000",
      "cc": "LV",
      "name_en": "Latvia",
      "code": 371
    },
    {
      "mask": "000-0000-0000",
      "cc": "EE",
      "name_en": "Estonia ",
      "code": 372
    },
    {
      "mask": "000-0000-0000",
      "cc": "MD",
      "name_en": "Moldova",
      "code": 373
    },
    {
      "mask": "000-00-000-000",
      "cc": "AM",
      "name_en": "Armenia",
      "code": 374
    },
    {
      "mask": "000 (00) 000-00-00",
      "cc": "BY",
      "name_en": "Belarus",
      "code": 375
    },
    {
      "mask": "000-000-000",
      "cc": "AD",
      "name_en": "Andorra",
      "code": 376
    },
    {
      "mask": "000 (000) 000-000",
      "cc": "MC",
      "name_en": "Monaco",
      "code": 377
    }/*,
    {
      "mask": "000-0000-000000",
      "cc": "SM",
      "name_en": "San Marino",
      "code": 378
    }*/,
    {
      "mask": "000 (00) 000-00-00",
      "cc": "UA",
      "name_en": "Ukraine",
      "code": 380
    },
    {
      "mask": "000-00-000-0000",
      "cc": "RS",
      "name_en": "Serbia",
      "code": 381
    },
    {
      "mask": "000-00-000-000",
      "cc": "ME",
      "name_en": "Montenegro",
      "code": 382
    },
    {
      "mask": "000-00-000-000",
      "cc": "HR",
      "name_en": "Croatia",
      "code": 385
    },
    {
      "mask": "000-00-000-000",
      "cc": "SI",
      "name_en": "Slovenia",
      "code": 386
    },
    {
      "mask": "000-00-0000",
      "cc": "BA",
      "name_en": "Bosnia and Herzegovina",
      "code": 387
    },
    {
      "mask": "000-00-000-000",
      "cc": "MK",
      "name_en": "Republic of Macedonia",
      "code": 389
    },
    {
      "mask": "00 (000) 0000-000",
      "cc": "IT",
      "name_en": "Italy",
      "code": 39
    },
    {
      "mask": "00-0-000-00000",
      "cc": "VA",
      "name_en": "Vatican City",
      "code": 396698
    },
    {
      "mask": "00-00-000-0000",
      "cc": "RO",
      "name_en": "Romania",
      "code": 40
    },
    {
      "mask": "00-00-000-0000",
      "cc": "CH",
      "name_en": "Switzerland",
      "code": 41
    },
    {
      "mask": "000 (000) 000-000",
      "cc": "CZ",
      "name_en": "Czech Republic",
      "code": 420
    },
    {
      "mask": "000 (000) 000-000",
      "cc": "SK",
      "name_en": "Slovakia",
      "code": 421
    }/*,
    {
      "mask": "000 (000) 000-0000",
      "cc": "LI",
      "name_en": "Liechtenstein",
      "code": 423
    }*/,
    {
      "mask": "00 (000) 000-0000",
      "cc": "AT",
      "name_en": "Austria",
      "code": 43
    },
    {
      "mask": "00-00-0000-0000",
      "cc": "UK",
      "name_en": "United Kingdom",
      "code": 44
    },
    {
      "mask": "00-00-00-00-00",
      "cc": "DK",
      "name_en": "Denmark",
      "code": 45
    },
    {
      "mask": "00-00-000-0000",
      "cc": "SE",
      "name_en": "Sweden",
      "code": 46
    },
    {
      "mask": "00 (000) 00-000",
      "cc": "NO",
      "name_en": "Norway",
      "code": 47
    },
    {
      "mask": "00 (000) 000-000",
      "cc": "PL",
      "name_en": "Poland",
      "code": 48
    },
    {
      "mask": "00 (0000) 000-0000",
      "cc": "DE",
      "name_en": "Germany",
      "code": 49
    },
    {
      "mask": "000-00000",
      "cc": "FK",
      "name_en": "Falkland Islands",
      "code": 500
    },
    {
      "mask": "000-000-0000",
      "cc": "BZ",
      "name_en": "Belize",
      "code": 501
    },
    {
      "mask": "000-0-000-0000",
      "cc": "GT",
      "name_en": "Guatemala",
      "code": 502
    },
    {
      "mask": "000-00-00-0000",
      "cc": "SV",
      "name_en": "El Salvador",
      "code": 503
    },
    {
      "mask": "000-0000-0000",
      "cc": "HN",
      "name_en": "Honduras",
      "code": 504
    },
    {
      "mask": "000-0000-0000",
      "cc": "NI",
      "name_en": "Nicaragua",
      "code": 505
    },
    {
      "mask": "000-0000-0000",
      "cc": "CR",
      "name_en": "Costa Rica",
      "code": 506
    },
    {
      "mask": "000-000-0000",
      "cc": "PA",
      "name_en": "Panama",
      "code": 507
    },
    {
      "mask": "000-00-0000",
      "cc": "FR",
      "name_en": "St Pierre & Miquelon",
      "code": 508
    },
    {
      "mask": "000-00-00-0000",
      "cc": "HT",
      "name_en": "Haiti",
      "code": 509
    },
    {
      "mask": "00 (000) 000-000",
      "cc": "PE",
      "name_en": "Peru",
      "code": 51
    },
    {
      "mask": "00 (000) 000-0000",
      "cc": "MX",
      "name_en": "Mexico",
      "code": 52
    },
    {
      "mask": "00-0-000-0000",
      "cc": "CU",
      "name_en": "Cuba",
      "code": 53
    },
    {
      "mask": "00 (000) 000-0000",
      "cc": "AR",
      "name_en": "Argentina",
      "code": 54
    },
    {
      "mask": "00 (00) 0000-0000",
      "cc": "BR",
      "name_en": "Brazil",
      "code": 55
    },
    {
      "mask": "00-0-0000-0000",
      "cc": "CL",
      "name_en": "Chile",
      "code": 56
    },
    {
      "mask": "00 (000) 000-0000",
      "cc": "CO",
      "name_en": "Colombia",
      "code": 57
    },
    {
      "mask": "00 (000) 000-0000",
      "cc": "VE",
      "name_en": "Venezuela",
      "code": 58
    },
    {
      "mask": "000 (000) 000-000",
      "cc": "FR",
      "name_en": "Guadeloupe",
      "code": 590
    },
    {
      "mask": "000-0-000-0000",
      "cc": "BO",
      "name_en": "Bolivia",
      "code": 591
    },
    {
      "mask": "000-000-0000",
      "cc": "GY",
      "name_en": "Guyana",
      "code": 592
    },
    {
      "mask": "000-00-000-0000",
      "cc": "EC",
      "name_en": "Ecuador ",
      "code": 593
    },
    {
      "mask": "000-00000-0000",
      "cc": "GF",
      "name_en": "Guiana  (French) ",
      "code": 594
    },
    {
      "mask": "000 (000) 000-000",
      "cc": "PY",
      "name_en": "Paraguay",
      "code": 595
    },
    {
      "mask": "000 (000) 00-00-00",
      "cc": "MQ",
      "name_en": "Martinique",
      "code": 596
    },
    {
      "mask": "000-000-0000",
      "cc": "SR",
      "name_en": "Suriname ",
      "code": 597
    },
    {
      "mask": "000-0-000-00-00",
      "cc": "UY",
      "name_en": "Uruguay",
      "code": 598
    },
    {
      "mask": "000-000-0000",
      "cc": "AN",
      "name_en": "Caribbean Netherlands",
      "code": 599
    },
    {
      "mask": "00 (000) 000-000",
      "cc": "MY",
      "name_en": "Malaysia",
      "code": 60
    },
    {
      "mask": "00-0-0000-0000",
      "cc": "AU",
      "name_en": "Australia",
      "code": 61
    },
    {
      "mask": "00 (000) 000-000",
      "cc": "ID",
      "name_en": "Indonesia ",
      "code": 62
    },
    {
      "mask": "00 (000) 000-0000",
      "cc": "PH",
      "name_en": "Philippines",
      "code": 63
    },
    {
      "mask": "00 (000) 000-0000",
      "cc": "NZ",
      "name_en": "New Zealand",
      "code": 64
    },
    {
      "mask": "00-0000-0000",
      "cc": "SG",
      "name_en": "Singapore",
      "code": 65
    },
    {
      "mask": "00-00-000-0000",
      "cc": "TH",
      "name_en": "Thailand ",
      "code": 66
    },
    {
      "mask": "000-000-0000",
      "cc": "TL",
      "name_en": "East Timor",
      "code": 670
    },
    {
      "mask": "000-000-000",
      "cc": "AQ",
      "name_en": "Australian bases in Antarctica",
      "code": 672
    },
    {
      "mask": "000-000-0000",
      "cc": "BN",
      "name_en": "Brunei Darussalam",
      "code": 673
    },
    {
      "mask": "000-000-0000",
      "cc": "NR",
      "name_en": "Nauru",
      "code": 674
    },
    {
      "mask": "000 (000) 00-000",
      "cc": "PG",
      "name_en": "Papua New Guinea",
      "code": 675
    },
    {
      "mask": "000-00000",
      "cc": "TO",
      "name_en": "Tonga",
      "code": 676
    },
    {
      "mask": "000-000-0000",
      "cc": "SB",
      "name_en": "Solomon Islands ",
      "code": 677
    },
    {
      "mask": "000-00-00000",
      "cc": "VU",
      "name_en": "Vanuatu ",
      "code": 678
    },
    {
      "mask": "000-00-00000",
      "cc": "FJ",
      "name_en": "Fiji",
      "code": 679
    },
    {
      "mask": "000-000-0000",
      "cc": "PW",
      "name_en": "Palau",
      "code": 680
    },
    {
      "mask": "000-00-0000",
      "cc": "WF",
      "name_en": "Wallis and Futuna",
      "code": 681
    },
    {
      "mask": "000-00-000",
      "cc": "CK",
      "name_en": "Cook Islands",
      "code": 682
    },
    {
      "mask": "000-0000",
      "cc": "NU",
      "name_en": "Niue",
      "code": 683
    },
    {
      "mask": "000-00-0000",
      "cc": "WS",
      "name_en": "Samoa",
      "code": 685
    },
    {
      "mask": "000-00-000",
      "cc": "KI",
      "name_en": "Kiribati",
      "code": 686
    },
    {
      "mask": "000-00-0000",
      "cc": "NC",
      "name_en": "New Caledonia",
      "code": 687
    },
    {
      "mask": "000-00000",
      "cc": "TV",
      "name_en": "Tuvalu",
      "code": 688
    },
    {
      "mask": "000-00-00-00",
      "cc": "PF",
      "name_en": "French Polynesia",
      "code": 689
    },
    {
      "mask": "000-0000",
      "cc": "TK",
      "name_en": "Tokelau",
      "code": 690
    },
    {
      "mask": "000-000-0000",
      "cc": "FM",
      "name_en": "F.S. Micronesia",
      "code": 691
    },
    {
      "mask": "000-000-0000",
      "cc": "MH",
      "name_en": "Marshall Islands",
      "code": 692
    },
    {
      "mask": "0 (000) 000-00-00",
      "cc": "RU",
      "name_en": "Russia",
      "code": 7
    },
    {
      "mask": "00 (000) 000-0000",
      "cc": "JP",
      "name_en": "Japan",
      "code": 81
    },
    {
      "mask": "00-00-000-0000",
      "cc": "KR",
      "name_en": "Korea  (South) ",
      "code": 82
    },
    {
      "mask": "00 (000) 0000-000",
      "cc": "VN",
      "name_en": "Vietnam",
      "code": 84
    },
    {
      "mask": "000-0000-0000",
      "cc": "KP",
      "name_en": "DPR Korea  (North) ",
      "code": 850
    },
    {
      "mask": "000-0000-0000",
      "cc": "HK",
      "name_en": "Hong Kong",
      "code": 852
    },
    {
      "mask": "000-0000-0000",
      "cc": "MO",
      "name_en": "Macau",
      "code": 853
    },
    {
      "mask": "000-00-000-000",
      "cc": "KH",
      "name_en": "Cambodia",
      "code": 855
    },
    {
      "mask": "000 (0000) 000-000",
      "cc": "LA",
      "name_en": "Laos ",
      "code": 856
    },
    {
      "mask": "00 (000) 0000-0000",
      "cc": "CN",
      "name_en": "China  (PRC) ",
      "code": 86
    },
    {
      "mask": "000-00-000-000",
      "cc": "BD",
      "name_en": "Bangladesh",
      "code": 880
    },
    {
      "mask": "000-0-0000-0000",
      "cc": "TW",
      "name_en": "Taiwan",
      "code": 886
    },
    {
      "mask": "00 (000) 000-0000",
      "cc": "TR",
      "name_en": "Turkey",
      "code": 90
    },
    {
      "mask": "00 (0000) 000-000",
      "cc": "IN",
      "name_en": "India",
      "code": 91
    },
    {
      "mask": "00 (000) 000-0000",
      "cc": "PK",
      "name_en": "Pakistan",
      "code": 92
    },
    {
      "mask": "00-00-000-0000",
      "cc": "AF",
      "name_en": "Afghanistan",
      "code": 93
    },
    {
      "mask": "00-00-000-0000",
      "cc": "LK",
      "name_en": "Sri Lanka",
      "code": 94
    },
    {
      "mask": "00-00-000-000",
      "cc": "MM",
      "name_en": "Burma  (Myanmar) ",
      "code": 95
    },
    {
      "mask": "000-000-0000",
      "cc": "MV",
      "name_en": "Maldives",
      "code": 960
    },
    {
      "mask": "000-00-000-000",
      "cc": "LB",
      "name_en": "Lebanon ",
      "code": 961
    },
    {
      "mask": "000-0-0000-0000",
      "cc": "JO",
      "name_en": "Jordan",
      "code": 962
    },
    {
      "mask": "000-00-0000-000",
      "cc": "SY",
      "name_en": "Syrian Arab Republic",
      "code": 963
    }/*,
    {
      "mask": "000 (000) 000-0000",
      "cc": "IQ",
      "name_en": "Iraq",
      "code": 964
    }*/,
    {
      "mask": "000-0000-0000",
      "cc": "KW",
      "name_en": "Kuwait",
      "code": 965
    },
    {
      "mask": "000-0-0000-0000",
      "cc": "SA",
      "name_en": "Saudi Arabia",
      "code": 966
    },
    {
      "mask": "000-000-000-000",
      "cc": "YE",
      "name_en": "Yemen ",
      "code": 967
    },
    {
      "mask": "000-00-000-000",
      "cc": "YE",
      "name_en": "Yemen",
      "code": 967
    },
    {
      "mask": "000-00-000-000",
      "cc": "OM",
      "name_en": "Oman",
      "code": 968
    },
    {
      "mask": "000-00-000-0000",
      "cc": "PS",
      "name_en": "Palestine",
      "code": 970
    },
    {
      "mask": "000-0-000-0000",
      "cc": "AE",
      "name_en": "United Arab Emirates",
      "code": 971
    },
    {
      "mask": "000-00-000-0000",
      "cc": "IL",
      "name_en": "Israel ",
      "code": 972
    },
    {
      "mask": "000-0000-0000",
      "cc": "BH",
      "name_en": "Bahrain",
      "code": 973
    },
    {
      "mask": "000-0000-0000",
      "cc": "QA",
      "name_en": "Qatar",
      "code": 974
    },
    {
      "mask": "000-0-000-000",
      "cc": "BT",
      "name_en": "Bhutan",
      "code": 975
    },
    {
      "mask": "000-00-00-0000",
      "cc": "MN",
      "name_en": "Mongolia",
      "code": 976
    },
    {
      "mask": "000-00-000-000",
      "cc": "NP",
      "name_en": "Nepal",
      "code": 977
    },
    {
      "mask": "00 (000) 000-0000",
      "cc": "IR",
      "name_en": "Iran",
      "code": 98
    }/*,
    {
      "mask": "992-00-000-0000",
      "cc": "TJ",
      "name_en": "Tajikistan",
      "code": 992
    }*/,
    {
      "mask": "000-0-000-0000",
      "cc": "TM",
      "name_en": "Turkmenistan",
      "code": 993
    },
    {
      "mask": "000-00-000-00-00",
      "cc": "AZ",
      "name_en": "Azerbaijan",
      "code": 994
    },
    {
      "mask": "000 (000) 000-000",
      "cc": "GE",
      "name_en": "Rep. of Georgia",
      "code": 995
    },
    {
      "mask": "000 (000) 000-000",
      "cc": "KG",
      "name_en": "Kyrgyzstan",
      "code": 996
    },
    {
      "mask": "000-00-000-0000",
      "cc": "UZ",
      "name_en": "Uzbekistan",
      "code": 998
    }
  ]