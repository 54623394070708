import { Injectable } from '@angular/core';
import * as moment from 'moment'
import { HttpService } from './http.service';
import { SocketService } from './socket.service';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root'
})
export class CalendarService {
	public events:any=[];
	public fields = ['address', 'area', 'city', 'state', 'zip', 'country', 'flat_number', 'lat', 'lng'];
	public types = ['event', 'task', 'lead', 'equipment_service'];
	public filter_users:any = [];
	public filter_type:any =['', 'task', 'lead'];
	constructor(private hs: HttpService,
		public us: UserService,
		public socket: SocketService) {
		
	}
	load(from, to, cb?){
		if(!this.us.me.id||!this.us.users.length){
			return setTimeout(()=>{
				this.load(from, to, cb);
			},500);
		}
		if(!this.filter_users.length) this.filter_users=[this.us.me.id];
		let url='/calendar/events/get?start='+moment(from).format("YYYY-MM-DD")+'&end='+moment(to).format("YYYY-MM-DD");
		for (let i=this.filter_users.length-1; i>=0; i--) {
		    if(this.filter_users[i]) url+='&user_ids['+i+']='+this.filter_users[i];
		}
		this.hs.apiget(url, resp=>{
			this.events=resp.slice();
			for (let i=this.events.length-1; i>=0; i--) {
				if(this.events[i].user&&this.events[i].user.color&&!this.events[i].is_private) this.events[i]['color']=this.events[i].user.color;
				else if(this.events[i].is_private) this.events[i]['color']='#808080';
				else {
					this.events[i]['color']='#1796b0';
				}
				let del=true;
				for (let j=this.filter_type.length-1; j>=0; j--) {
					if(this.filter_type[j]==this.events[i].assigned_type) del=false;
				}
				if(del) this.events.splice(i, 1);
			}
			if(cb)cb(resp);
		});
	}
	create(event, cb?){
		let e:any={
			user: event.user,
			user_id: event.user_id,
			text: event.text,
			is_private: event.is_private,
			assigned: event.assigned,
			assigned_id: event.assigned.id,
			start_date: moment(event.start_date).format("YYYY-MM-DD HH:mm:ss"),
			end_date: moment(event.end_date).format("YYYY-MM-DD HH:mm:ss")
		}
		if(event.assigned_type.length) e['assigned_type']=event.assigned_type;
		if(event.note) e['note']=event.note;
		for (let i=this.fields.length-1; i>=0; i--) {
			if(event[this.fields[i]]) e[this.fields[i]]=event[this.fields[i]];
		}
		this.hs.apipost('/calendar/events/create', e, resp=>{
			resp.start_date=new Date(resp.start_date);
			resp.end_date=new Date(resp.end_date);
			this.events.push(resp);
			this.socket.emit('calendar_refresh', resp);
			if(cb)cb(resp);
		});
	}
	update(event, cb?){
		console.log(event);
		let e:any={
			id: event.id,
			user: event.user,
			user_id: event.user_id,
			text: event.text,
			is_private: event.is_private,
			assigned: event.assigned,
			assigned_id: event.assigned_id,
			start_date: moment(event.start_date).format("YYYY-MM-DD HH:mm:ss"),
			end_date:  moment(event.end_date).format("YYYY-MM-DD HH:mm:ss")
		}
		if(event.assigned_type.length) e['assigned_type']=event.assigned_type;
		if(event.note) e['note']=event.note;
		for (let i=this.fields.length-1; i>=0; i--) {
			if(event[this.fields[i]]) e[this.fields[i]]=event[this.fields[i]];
		}
		this.hs.apipost('/calendar/events/update', e, resp=>{
			resp.start_date=new Date(resp.start_date);
			resp.end_date=new Date(resp.end_date);
			console.log(resp);
			for (let i=this.events.length-1; i>=0; i--) {
				if(this.events[i].id==resp.id){
					this.events[i]=resp;
					break;
				}
			}
			this.socket.emit('calendar_refresh', resp);
			if(cb)cb(resp);
		});
	}
	delete(id, cb?){
		this.hs.apipost('/calendar/events/delete', {id:id}, resp=>{
			for (let i=this.events.length-1; i>=0; i--) {
				if(this.events[i].id==id){
					this.events.splice(i, 1);
					break;
				}
			}
			this.socket.emit('calendar_refresh', resp);
		});
		
	}
}
