import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { SocketService } from '../socket.service';
import { AlertService } from '../alert.service';

@Injectable({
	providedIn: 'root'
})
export class EqLicensesService {
	public licenses:any = [];
	public now = new Date().getTime();
	refresh(){
	    this.now = new Date().getTime();
	}
	constructor(private http: HttpService,
		private socket: SocketService,
		private alert: AlertService){
		this.get();
	}
	get(cb=resp=>{}){
		this.http.c_get('equipments/driving-licenses', (resp:any) => {
			this.licenses = resp;
			this.http.set('equipmentlicense');
			cb(resp);
		});
	}
	create(license, cb=resp=>{}) {
		this.http.apipost('/equipments/driving-licenses/create', license, cb);
	}
	save(license, cb=resp=>{}, ignore={}){
		if(!license) return;
		this.http.c_update('equipments/driving-licenses', license, cb, ignore);
	}
	deleteLicense(license, cb=resp=>{}) {
		this.http.apipost('/equipments/driving-licenses/delete', license, cb);
	}
}



