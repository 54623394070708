import { Component, OnInit } from '@angular/core';
import { FileService, EstimateService, ClientService } from '@services';

@Component({
	inputs: ['service', 'estimate', 'update', 'from'],
	selector: 'attachments',
	templateUrl: './attachments.component.html',
	styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {
	public service: any;
	public estimate: any;
	public update;
	public from;
	public newFile;
	constructor(public file: FileService,
		public es: EstimateService, 
		public cs: ClientService) {}
	ngOnInit() {
		if(!this.service.files){
			this.service.files = [];
		}
	}
	add(files){
		let obj:any = {
			lead_id: this.estimate.estimate_number,
			estimate_service_id: this.service.id,
			client_id: this.estimate.client_id
		};
		if(this.from.indexOf('workorder_')>-1){
			obj.workorder_id = this.from.replace('workorder_', '');
		}else if(this.from.indexOf('invoice_')>-1){
			obj.invoice_id = this.from.replace('invoice_', '');
		}else{
			obj.estimate_id = this.estimate.id;
		}
		this.file.add(files, '/clients/files/create', obj, created=>{
			for(let i = 0; i < created.length; i++){
				this.cs.files.push(created[i]);
			}
			for(let i = 0; i < created.length; i++){
				this.service.files.push(created[i]);
			}
		});
		delete this.newFile;
	}
	delete(files, i){
		this.file.delete(files, '/'+this.cs.view+'/files');
		this.es.clean_files(files[0]);
		this.cs.clean_files(files[0]);
		if(typeof this.update == 'function'){
			this.service.files.splice(i, 1);
			this.update();
		}
	}
}
