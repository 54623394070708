import { Component, Inject, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UiService, ClientService, AlertService } from '@services';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
	public clientClasses: Array<any> = [
	{ name: 'Main', value: 'Main' },
	{ name: 'Work', value: 'Work' },
	{ name: 'Mobile', value: 'Mobile' },
	{ name: 'Home', value: 'Home' },
	{ name: 'Other', value: 'Other' }
	];
	public client:any = {
		contact_information: []
	};
	constructor(private dialogRef: MatDialogRef<ContactComponent>,
		public cs: ClientService,
		public ui: UiService,
		public alert: AlertService,
		@Inject(MAT_DIALOG_DATA) data) {
		if(data){
			this.client = JSON.parse(JSON.stringify(data));
			for(let i = 0; i < this.client.contact_information.length; i++){
				this._marked[this.client.contact_information[i].id] = JSON.stringify(this.client.contact_information[i]);
			}
		}
	}
	public _added = [];
	public _deleted = [];
	public _marked = {};
	@ViewChildren('phones') private phones:any;
	public show_errors = false;
	create(contact, cb){
		this.cs.cContact(contact, resp=>{
			this.client.contact_information = resp.contact_information.slice();
			this.client.primary = resp.primary.slice();
			cb();
		});
	}
	update(contact, cb){
		this.cs.uContact(contact, resp=>{
			this.client.contact_information = resp.contact_information.slice();
			this.client.primary = resp.primary.slice();
			cb(contact);
		});
	}
	save(){
		this.show_errors = true;
		for(let i = 0; i < this.phones.length; i++){
			if(this.phones._results[i].nativeElement.classList.value.indexOf('ng-invalid')>-1) return;
		}
		for(let i = 0; i < this.client.contact_information.length; i++){
			if(!this.client.contact_information[i].name) return;
			for(let j = 0; j < this.client.contact_information[i].emails.length; j++){
				if(!this.ui.isEmail(this.client.contact_information[i].emails[j].value)) return;
			}
			for(let j = 0; j < this.client.contact_information[i].numbers.length; j++){
				if(!this.ui.isPhone(this.client.contact_information[i].numbers[j].value)) return;
			}
		}
		this.show_errors = false;
		let counter=0;
		for(let i = 0; i < this.client.contact_information.length; i++){
			if(this.client.contact_information[i].added) continue;
			if(this._marked[this.client.contact_information[i].id] == JSON.stringify(this.client.contact_information[i])) continue;
			counter++;
			this.client.contact_information[i].client_id = this.client.id;
			this.update(this.client.contact_information[i], (resp)=>{
				console.log(this.client);
				this.cs.fetched[this.client.id]=this.client;
				if(--counter==0) this.dialogRef.close(this.client);
			});
		}
		for(let i = 0; i < this._added.length; i++){
			counter++;
			delete this._added[i].added;
			this._added[i].client_id = this.client.id;
			this.create(this._added[i], (resp)=>{
				this.cs.fetched[this.client.id]=this.client;
				if(--counter==0) this.dialogRef.close(this.client);
			});
		}
		for(let i = 0; i < this._deleted.length; i++){
			this.cs.dContact({
				client_id: this.client.id,
				id: this._deleted[i].id
			});
		}
		if(!counter) this.dialogRef.close(this.client);
	}
	delete(member){
		if(member.added){
			for(let i = 0; i < this._added.length; i++){
				if(this._added[i].added == member.added){
					return this._added.splice(i, 1);
				}
			}
		}else{
			this._deleted.push(member);
		}
	}
	close(){
		this.dialogRef.close();
	}
	add_member(){
		let contact = {
			added: Date.now(),
			name: '',
			emails: [{
				class: 'Main'
			}],
			numbers: [{
				class: 'Main',
				value: '1'
			}]
		}
		this._added.push(contact);
		this.client.contact_information.push(contact);
	}
	removeMember(member, index){
		if(!member.id){
			this.client.contact_information.splice(index, 1);
			this.ensure();
			this.delete(member);
			return;
		}
		this.alert.yes("Are you sure you want to delete this member?",()=>{
			this.client.contact_information.splice(index, 1);
			this.ensure();
			this.delete(member);
		});
		
	}
	deleteEmail(member, index: number) {
		if(!member.emails[index].id) return member.emails.splice(index, 1);
		if(member.emails[index].primary==1){
			return this.alert.success('You can not delete primary email');
		}
		this.alert.yes("Are you sure you want to delete this email?",()=>{
			member.emails.splice(index, 1);
			this.ensure();
		});
	}
	deletePhone(member, index: number) {
		if(!member.numbers[index].id) return member.numbers.splice(index, 1);
		if(member.numbers[index].primary==1){
			return this.alert.success('You can not delete primary phone');
		}
		this.alert.yes("Are you sure you want to delete this phone?",()=>{
			member.numbers.splice(index, 1);
			this.ensure();
		});
	}
	clear(which='emails'){
		for(let i = 0; i < this.client.contact_information.length; i++){
			for(let j = 0; j < this.client.contact_information[i][which].length; j++){
				this.client.contact_information[i][which][j].primary = 0;
			}
		}
	}
	ensure(){
		let number_exists = false, email_exists = false;
		for(let i = 0; i < this.client.contact_information.length; i++){
			for(let j = 0; j < this.client.contact_information[i].emails.length; j++){
				if(email_exists){
					break;
				}
				if(this.client.contact_information[i].emails[j].primary){
					email_exists = true;
				}
			}
			for(let j = 0; j < this.client.contact_information[i].numbers.length; j++){
				if(number_exists){
					break;
				}
				if(this.client.contact_information[i].numbers[j].primary){
					number_exists = true;
				}
			}
		}
		if(!email_exists){
			for(let i = 0; i < this.client.contact_information.length; i++){
				if(this.client.contact_information[i].emails.length){
					this.client.contact_information[i].emails[0].primary = 1;
					break;
				}
			}
		}
		if(!number_exists){
			for(let i = 0; i < this.client.contact_information.length; i++){
				if(this.client.contact_information[i].numbers.length){
					this.client.contact_information[i].numbers[0].primary = 1;
					break;
				}
			}
		}
		for(let i = 0; i < this.client.contact_information.length; i++){
			for(let j = 0; j < this.client.contact_information[i].emails.length; j++){
				if(!this.client.contact_information[i].emails[j].primary){
					this.client.contact_information[i].emails[j].primary = 0;
				}
			}
			for(let j = 0; j < this.client.contact_information[i].numbers.length; j++){
				if(!this.client.contact_information[i].numbers[j].primary){
					this.client.contact_information[i].numbers[j].primary = 0;
				}
			}
		}
	}
}
