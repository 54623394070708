import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { UserService } from '../user.service';
import { HttpService } from '../http.service';
import { StatusService } from '../estimate/status.service';
import { UiService } from '../ui.service';
import { SocketService } from '../socket.service';
import * as moment from 'moment';
@Injectable({
	providedIn: 'root'
})
export class LeadService {
	public url = environment.api;
	public status:any;
	constructor(private http: HttpClient,
		private ui: UiService,
		private sts: StatusService,
		private https: HttpService,
		private socket: SocketService,
		private us: UserService){}
	/*
	*	Pagination
	*/
		public page: number = 1;
		paginate(event){
			this.page = event.pageIndex+1;
			this.ui.setPerPage(event.pageSize);
			this.load();
		}
	/*
	*	Load
	*/
		public mine_leads = false;
		public make_filters = false;
		public refill:any;
		public search = '';
		public lead:any = {};
		public leads = [];
		public total: number = 0;
		public sortby = localStorage.getItem('leadsortby') || 'id';
		public sort = localStorage.getItem('leadSort') || 'desc';
		setsortby(sortby){
			this.sortby = sortby.active;
			this.sort = sortby.direction;
			localStorage.setItem('leadsortby', this.sortby);
			localStorage.setItem('leadSort', this.sort);
			this.load();
		}
		public tags = [];
		public selected_status;
		public filters: any = {};
		public filtersApplied: any = {};
		load(options:any={}, cb:any=()=>{}){
			this.https.afterWhile(this, ()=>{
				for(let key in this.filters){
					if(!this.filters[key]) delete this.filters[key];
				}
				this.filtersApplied = Object.assign({}, this.filters);
				this.sortby = this.sortby || 'id';
				let filterStr = `?page=${this.page}&per_page=${options.perPage||this.ui.perPage}&sortby=${this.sortby}&sort=${this.sort}`;
				if(this.search) filterStr += '&search='+this.search;
				if (this.filters) {
					for (const key in this.filters) {
						if(!this.filters[key]) continue;
						if(key=='id'){
							filterStr += `&filters[id]=${this.filters[key]}`;
						} else if(key=='date_from'||key=='date_to'){
							filterStr += `&filters[${key}]=${moment(this.filters[key]).format('YYYY-MM-DD')}`;
						}else filterStr += `&filters[${key}]=${this.filters[key]}`;
					}
				}
				if(this.tags.length){
					for(let i = 0; i < this.tags.length; i++){
						filterStr += `&filters_ids[tags][`+i+`]=${this.tags[i].id}`;
					}
				}
				if(this.sts.leads_statuses.length){
					for(let i = 0; i < this.sts.leads_statuses.length; i++){
						filterStr += `&filters[status_id][`+this.sts.leads_statuses[i].id+`]=${this.sts.leads_statuses[i].id}`;
					}
				} else return;
				this.http.get(`${this.url}/tasks/leads/get${filterStr}`).subscribe((resp:any)=>{
					for(let i = 0; i < resp.data.length; i++){
						if(!resp.data.extra) resp.data.extra={contact_email: {}, contact_phone: {}};
						for(let each in options.replace){
							resp.data[i][each] = options.replace[each](resp.data[i])
						}
					}
					this.leads = resp.data;
					this.total = resp.total;
					if(this.refill) this.refill();
					cb(this.leads);
				});
			}, 250);
		}
		loadAfterWhile(){
			this.https.afterWhile(this, this.load.bind(this), 2000);
		}
	/*
	*	CRUD
	*/
	to(lead){
		return {
			// 'client_id', 'user_id', 'address', 'note', 'city', 'state', 'zip', 'country', 'lat', 'lng', 'creator_id', 'date_start', 'task_type_id', 'area', 'flat_number'
			// services
			// extra
			id: lead.id,
			extra: {
				task_lead_status_id: lead.extra.task_lead_status_id,
				task_lead_reason_id: lead.extra.task_lead_reason_id
			},
			tags: this.https.to_id(lead.extra.tags)
		};
	}
	create(lead){}


	private fetched = {};
	fetch(id: number, cb=resp=>{}) {
		if (!id) { return; }
		//if (!id || !this.us.perm.viewclients) { return; }
		if(!this.fetched[id]){
			this.fetched[id] = {};
			this.http.post(`${this.url}/tasks/leads/fetch`, {
				id: id
			}).subscribe(resp=>{
				for(let each in resp){
					this.fetched[id][each] = resp[each];
				}
				cb(this.fetched[id]);
			});
		}else if(!this.fetched[id].id){
			setTimeout(()=>{
				this.fetch(id, cb);
			}, 100);
		}else{
			cb(this.fetched[id]);
		}
		return this.fetched[id];
	}
	force_fetch(id, cb=resp=>{}){
		this.http.post(`${this.url}/tasks/leads/fetch`, {
			id: id
		}).subscribe(resp=>{
			for(let each in resp){
				this.fetched[id][each] = resp[each];
			}
			cb(resp);
		});
	}
	project_fetch(projectId, cb=resp=>{}){
		this.http.post(`${this.url}/tasks/leads/fetch`, {
			project_id: projectId
		}).subscribe((resp:any)=>{
			if(!this.fetched[resp.id]){
				this.fetched[resp.id] = {};
			}
			for(let each in resp){
				this.fetched[resp.id][each] = resp[each];
			}
			cb(resp);
		});
	}



	update(lead, cb = resp=>{}){
		if (!this.us.perm.edittasks) {
			return;
		}
		this.https.apipost('/tasks/leads/update', this.to(lead), (resp)=>{
			this.socket.emit('lead_refresh', resp);
			if(cb) cb(resp);
		});
	}

	private timeout = {};
	saveAfterWhile(lead, cb?:any, ignore={}){
		if(!lead) return;
		let timeout = '';
		for(let each in lead){
			timeout += each;
		}
		clearTimeout(this.timeout[timeout]);
		this.timeout[timeout] = setTimeout(()=>{
			this.https.c_update('tasks/leads', lead, (resp)=>{
			this.socket.emit('lead_refresh', resp);
			if(cb) cb(resp);
		}, ignore);
		}, 2000);
	}
	save(lead, cb?:any, ignore={}){
		if(!lead) return;
		this.https.c_update('tasks/leads', lead, (resp)=>{
			this.socket.emit('lead_refresh', resp);
			if(cb) cb(resp);
		}, ignore);
	}

	// save(lead, cb=resp=>{}){
	// 	this.http.post(`${this.url}/tasks/leads/update`, lead).subscribe(cb);
	// }
	delete(lead){}
}