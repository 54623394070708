import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { EstimateService } from './estimate.service';
import { SocketService } from '../socket.service';
import { AlertService } from '../alert.service';

@Injectable({
	providedIn: 'root'
})
export class EquipmentService {
	public equipments:any = [];
	constructor(private http: HttpService,
		private socket: SocketService,
		private alert: AlertService,
		private es: EstimateService) {
		this.es.offers('equipments', equipments=>{
			this.equipments = equipments;
			if(this.refresh) this.refresh();
			socket.on('equipment_create', equipmentId => {
				this.fetch(equipmentId, equipment=>{
					this.equipments.push(equipment);
					if(this.refresh) this.refresh();
				});
			});
			socket.on('equipment_update', equipmentId => {
				this.fetch(equipmentId, equipment=>{
					for(let i = 0; i < this.equipments.length; i++){
						if(this.equipments[i].id == equipmentId){
							for(let each in this.equipments[i]){
								this.equipments[i][each] = equipment[each];
							}
							for(let each in equipment){
								this.equipments[i][each] = equipment[each];
							}
							break;
						}
					}
					if(this.refresh) this.refresh();
				});
			});
			socket.on('equipment_delete', equipmentId => {
				for(let i = 0; i < this.equipments.length; i++){
					if(this.equipments[i].id == equipmentId){
						this.equipments.splice(i, 1);
					}
				}
				if(this.refresh) this.refresh();
			});
		});
	}
	public refresh;
	create(doc){
		this.http.c_create('estimates/offers/equipments', {
			attachments: this.http.to_id(doc.attachments),
			full_day_price: doc.full_day_price,
			price: doc.price,
			title: doc.title
		}, resp => {
			this.equipments.push(resp);
			this.socket.emit('equipment_create', resp.id);
			if(this.refresh) this.refresh();
		});
	}
	fetch(equipmentId, cb:any=()=>{}){
		this.http.c_fetch('estimates/offers/equipments', {
			id: equipmentId
		}, cb);
	}
	update(doc){
		this.http.c_update('estimates/offers/equipments', {
			attachments: this.http.to_id(doc.attachments),
			full_day_price: doc.full_day_price,
			short_title: doc.short_title,
			price: doc.price,
			title: doc.title,
			id: doc.id
		}, resp => {
			this.socket.emit('equipment_update', doc.id);
			if(this.refresh) this.refresh();
		});
		localStorage.setItem('equipments', JSON.stringify(this.equipments));
	}
	delete(doc){
		this.alert.yes('Are you sure that you want to delete equipment?', () => {
			for(let i = 0; i < this.equipments.length; i++){
				if(this.equipments[i].id == doc.id){
					this.equipments.splice(i, 1);
				}
			}
			this.http.c_delete('estimates/offers/equipments', doc, resp => {
				this.socket.emit('equipment_delete', doc.id);
				if(this.refresh) this.refresh();
			});
		});
	}
}
