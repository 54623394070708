import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HTTP_INTERCEPTORS, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaderResponse, HttpSentEvent, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';
import logs from './fake.logs';
import sessions from './fake.sessions';

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
    fields: Array<any> = [];
    values: Array<any> = [];

    tasks: Array<any> = [];
    task_priorities: Array<any> = [];
    task_categories: Array<any> = [];
    dahboard_data:any;

    constructor() {
        if (localStorage.getItem('custom-fields')) {
            this.fields = JSON.parse(localStorage.getItem('custom-fields'));
        }
        if (localStorage.getItem('custom-values')) {
            this.values = JSON.parse(localStorage.getItem('custom-values'));
        }
        if (localStorage.getItem('tasks-data')) {
            this.tasks = JSON.parse(localStorage.getItem('tasks-data'));
        }

        if (localStorage.getItem('task-priorities')) {
            this.task_priorities = JSON.parse(localStorage.getItem('task-priorities'));
        }
        if (localStorage.getItem('task-categories')) {
            this.task_categories = JSON.parse(localStorage.getItem('task-categories'));
        }
        if (localStorage.getItem('dashboard-data')) {
            this.dahboard_data = JSON.parse(localStorage.getItem('dashboard-data'));
        }
    }
    saveCustomToStorage() {
        localStorage.setItem('custom-fields', JSON.stringify(this.fields));
        localStorage.setItem('custom-values', JSON.stringify(this.values));
    }
    saveTasksToStorage() {
        localStorage.setItem('tasks-data', JSON.stringify(this.tasks));
    }

    saveTaskCategoriesToStorage() {
        localStorage.setItem('task-categories', JSON.stringify(this.task_categories));
    }
    saveTaskPrioritiesToStorage() {
        localStorage.setItem('task-priorities', JSON.stringify(this.task_priorities));
    }
    saveDashboardDataToStorage() {
        localStorage.setItem('dashboard-data', JSON.stringify(this.dahboard_data));
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
        // wrap in delayed observable to simulate server api call
        return of(null).pipe(mergeMap(() => {
            // get users
            /*if (request.url.endsWith('client/custom/add') && request.method === 'POST') {

                let newField = JSON.parse(JSON.stringify(request.body));
                if(this.fields.length){
                    newField.id = this.fields[this.fields.length-1].id+1;
                }
                else
                    newField.id = 0;
                this.fields.push(newField);
                this.saveCustomToStorage();
                return of(new HttpResponse({ status: 200, body: {
                    id: newField.id
                } }));
            }
            else if (request.url.endsWith('client/custom/update') && request.method === 'POST') {
                //this.fields[request.body.id] = request.body;
                let updatedField = this.fields.find(field=>field.id==request.body.id);
                updatedField.value = request.body.value;
                updatedField.name = request.body.name;
                updatedField.required = request.body.required;
                updatedField.options = request.body.options;
                this.saveCustomToStorage();
                return of(new HttpResponse({ status: 200, body: [] }));
            }
            else if (request.url.endsWith('client/custom/delete') && request.method === 'POST') {
                let deletedField = this.fields.find(field=>field.id==request.body.id);
                this.fields.splice(this.fields.indexOf(deletedField),1);
                this.saveCustomToStorage();
                return of(new HttpResponse({ status: 200, body: [] }));
            }
            else if (request.url.endsWith('client/custom/get') && request.method === 'GET') {
                return of(new HttpResponse({ status: 200, body: JSON.parse(JSON.stringify(this.fields)) }));
            }


            if (request.url.endsWith('client/custom/update_values') && request.method === 'POST') {
                let reqBody = JSON.parse(JSON.stringify(request.body));
                console.log(reqBody);
                reqBody.forEach(element => {
                    let value = this.values.find(value=>{
                        return element.field_id == value.field_id && value.client_id == element.client_id
                    });
                    if(value)
                        value.value = element.value;
                    else{
                        element.id = this.values.length;
                        this.values.push(element);
                    }
                });
                this.saveCustomToStorage();
                return of(new HttpResponse({ status: 200, body: [] }));
            }
            if (request.url.endsWith('client/custom/fetch_values') && request.method === 'POST') {
                let values = this.values.filter(value=>{
                    return value.client_id == request.body.client_id
                });
                return of(new HttpResponse({ status: 200, body: JSON.parse(JSON.stringify(values)) }));
            }*/

            //for logs fetch imitation
            if (request.url.endsWith('/logs/get') && request.method === 'GET') {
                const filteredLogs = logs;
                return of(new HttpResponse({ status: 200, body: {data: filteredLogs.slice(0, 25), total: filteredLogs.length} }));
            }
            if (request.url.endsWith('/logs/search') && request.method === 'POST') {
                let filteredLogs = logs;
                const params = request.body;
                if (params.search && params.search !== '') {
                    filteredLogs = filteredLogs.filter(
                        log => log.user.includes(params.search) ||
                        log.action.includes(params.search)
                    );
                }
                return of(new HttpResponse({ status: 200, body: {
                    data: filteredLogs.slice(params.page * params.limit, params.page * params.limit + params.limit),
                    total: filteredLogs.length
                } }));
            }
            if (request.url.endsWith('/sessions/get') && request.method === 'GET') {
                return of(new HttpResponse({ status: 200, body: {data: sessions} }));
            }
            if (request.url.endsWith('/sessions/delete') && request.method === 'POST') {
                return of(new HttpResponse({ status: 200, body: {data: []} }));
            }
            

            if (request.url.endsWith('/tasks/priority/create') && request.method === 'POST') {
                let taskPriorityToAdd = JSON.parse(JSON.stringify(request.body));
                taskPriorityToAdd.id = this.task_priorities.length?this.task_priorities[this.task_priorities.length-1].id+1:0;
                this.task_priorities.push(taskPriorityToAdd);
                this.saveTaskPrioritiesToStorage();
                return of(new HttpResponse({ status: 200, body: {id:0} }));
            }
            if (request.url.endsWith('/tasks/priority/delete') && request.method === 'POST') {
                let params = request.body;
                let taskPriorityToDelete = this.task_priorities.find((task_priority:any)=>{
                    return task_priority.id === params.id;
                });
                if(taskPriorityToDelete){
                    this.task_priorities.splice(this.task_priorities.indexOf(taskPriorityToDelete),1);
                    this.saveTaskPrioritiesToStorage();
                }
                return of(new HttpResponse({ status: 200, body: [] }));
            }
            if (request.url.endsWith('/tasks/priority/update') && request.method === 'POST') {
                let params = request.body;
                let taskPriorityToUpdate = this.task_priorities.find((task_priority:any)=>{
                    return task_priority.id === params.id;
                });
                if(taskPriorityToUpdate){
                    let taskPriorityUpdated = JSON.parse(JSON.stringify(request.body));
                    this.task_priorities[this.task_priorities.indexOf(taskPriorityToUpdate)] = taskPriorityUpdated;
                    this.saveTaskPrioritiesToStorage();
                }
                return of(new HttpResponse({ status: 200, body: [] }));
            }
            if (request.url.endsWith('/tasks/priority/get') && request.method === 'GET'){
                return of(new HttpResponse({ status: 200, body: {data: this.task_priorities} }));
            }



            if (request.url.endsWith('/tasks/category/create') && request.method === 'POST') {
                let taskCategoryToAdd = JSON.parse(JSON.stringify(request.body));
                taskCategoryToAdd.id = this.task_categories.length?this.task_categories[this.task_categories.length-1].id+1:0;
                this.task_categories.push(taskCategoryToAdd);
                this.saveTaskCategoriesToStorage();
                return of(new HttpResponse({ status: 200, body: {id:0} }));
            }
            if (request.url.endsWith('/tasks/category/delete') && request.method === 'POST') {
                let params = request.body;
                let taskCategoryToDelete = this.task_categories.find((task_category:any)=>{
                    return task_category.id === params.id;
                });
                if(taskCategoryToDelete){
                    this.task_categories.splice(this.task_categories.indexOf(taskCategoryToDelete),1);
                    this.saveTaskCategoriesToStorage();
                }
                return of(new HttpResponse({ status: 200, body: [] }));
            }
            if (request.url.endsWith('/tasks/category/update') && request.method === 'POST') {
                let params = request.body;
                let taskCategoryToUpdate = this.task_categories.find((task_category:any)=>{
                    return task_category.id === params.id;
                });
                if(taskCategoryToUpdate){
                    let taskCategoryUpdated = JSON.parse(JSON.stringify(request.body));
                    this.task_categories[this.task_categories.indexOf(taskCategoryToUpdate)] = taskCategoryUpdated;
                    this.saveTaskCategoriesToStorage();
                }
                return of(new HttpResponse({ status: 200, body: [] }));
            }
            if (request.url.endsWith('/tasks/category/get') && request.method === 'GET'){
                return of(new HttpResponse({ status: 200, body: {data: this.task_categories} }));
            }
            if (request.url.endsWith('/dashboard/get') && request.method === 'GET'){
                return of(new HttpResponse({ status: 200, body: this.dahboard_data }));
            }
            if (request.url.endsWith('/dashboard/save') && request.method === 'POST'){
                this.dahboard_data = request.body;
                this.saveDashboardDataToStorage();
                return of(new HttpResponse({ status: 200, body: [] }));
            }

            //Task section
            /*if (request.url.endsWith('/tasks/get') && request.method === 'GET'){
                return of(new HttpResponse({ status: 200, body: {data: this.tasks} }));
            }*/
            /*if (request.url.endsWith('/tasks/search') && request.method === 'POST'){
                let params = request.body;
                let searchedTaskArray = this.tasks.filter((task:any)=>{
                    //search by address
                    return (!params.city||task.city.includes(params.city))&&
                    (!params.country||task.country.includes(params.country))&&
                    (!params.state||task.state.includes(params.state))&&
                    (!params.zip||task.zip.includes(params.zip))&&
                    (!params.address||task.address.includes(params.address))&&
                    //search by content
                    (!params.details||task.details.includes(params.details))&&
                    //search by client
                    (!params.client_id||task.client_id==params.client_id)&&
                    //search by status
                    (!params.status||task.status==params.status)
                });
                return of(new HttpResponse({ status: 200, body: {
                    data: searchedTaskArray.slice(params.page*params.limit, params.page*params.limit+params.limit),
                    total: this.tasks.length
                } }));
            }
            if (request.url.endsWith('/tasks/add') && request.method === 'POST'){
                let taskToAdd = JSON.parse(JSON.stringify(request.body));
                taskToAdd.id = this.tasks.length?this.tasks[this.tasks.length-1].id+1:0;
                this.tasks.push(taskToAdd);
                this.saveTasksToStorage();
                return of(new HttpResponse({ status: 200, body: {id:0} }));
            }
            if (request.url.endsWith('/tasks/delete') && request.method === 'POST'){
                let params = request.body;
                let taskToDelete = this.tasks.find((task:any)=>{
                    return task.id = params.id;
                });
                if(taskToDelete)
                    this.tasks.splice(this.tasks.indexOf(taskToDelete),1);
                return of(new HttpResponse({ status: 200, body: [] }));
            }
            if (request.url.endsWith('/tasks/update') && request.method === 'POST'){
                let params = request.body;
                let taskToUpdate = this.tasks.find((task:any)=>{
                    return task.id = params.id;
                });
                if(taskToUpdate){
                    let taskUpdated = JSON.parse(JSON.stringify(request.body));
                    this.tasks[this.tasks.indexOf(taskToUpdate)] = taskUpdated;
                }
                return of(new HttpResponse({ status: 200, body: [] }));
            }
            if (request.url.endsWith('/tasks/fetch') && request.method === 'POST'){
                let params = request.body;
                let taskToFetch = this.tasks.find((task:any)=>{
                    return task.id = params.id;
                });
                return of(new HttpResponse({ status: 200, body: taskToFetch }));
            }*/
            // pass through any requests not handled above
            return next.handle(request);

        }))

        .pipe(materialize())
        .pipe(delay(0))
        .pipe(dematerialize());
    }
}

export let fakeBackendProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};
