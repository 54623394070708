import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'requirements',
	inputs: ['modify', 'requirements'],
	templateUrl: './requirements.component.html',
	styleUrls: ['./requirements.component.scss']
})
export class RequirementsComponent implements OnInit {
	constructor() { }
	public requirements;
	public modify;
	public reqName='';
	ngOnInit() {
		if(!this.requirements) this.requirements=[];
	}
}
