import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientService } from './client.service';
import { UserService } from '../user.service';
import { environment } from '@env';

// Check if we need this service

@Injectable({
	providedIn: 'root'
})
export class AttachmentService {
	uri = environment.api;
	public attachments:any = [];
	public attachment:any = {};
	constructor(private http: HttpClient, private cs: ClientService, private us:UserService) {}
	getAttachments(client_id,cb?:any){
    	this.attachments=localStorage.getItem('attachments'+client_id) && JSON.parse(localStorage.getItem('attachments'+client_id)) || [];
    	cb(this.attachments);
		//return this.http.get(`${this.uri}/clients/attachments/get?client_id=${client_id}`);
	}
	save(client,cb?){
		localStorage.setItem('attachments'+client.id, JSON.stringify(this.attachments));
		cb();
	}
	add(attachment,client,cb?){
		this.attachments.push(attachment);
		this.save(client, cb);
		cb();
	}
	delete(attachment, client,cb?){
	    for(let i=this.attachments.length-1;i>=0;i--){
	      if(this.attachments[i].id==attachment.id){
	        this.attachments.splice(i, 1);
	      }
	    }
	    this.save(client, cb);
	}
}
