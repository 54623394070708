import { ContactComponent } from './contact.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FieldsModule } from '../../../Fields/fields/fields.module';
import { DynamicMasksModule } from '../../../phone-mask/phone.mask.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TextMaskModule } from 'angular2-text-mask';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { SharedModule } from '@services';
import { NgxMaskModule } from 'ngx-mask';
import { AddressModule } from '../../../address/address.module';
import { TagsModule } from '../../../Tags/tags/tags.module';
import { RouterModule } from '@angular/router';
import { CustomMaterialModule } from '@root/modules/template';

@NgModule({
	entryComponents: [ContactComponent],
	declarations: [ContactComponent],
	exports: [ContactComponent],
	imports: [
		RouterModule,
		TagsModule,
		CommonModule,
		AddressModule,
		DynamicMasksModule,
		NgxMaskModule.forRoot(),
		SharedModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		TextMaskModule,
		MatGoogleMapsAutocompleteModule,
		FieldsModule,
		CustomMaterialModule
	]
})
export class ContactModule { }
