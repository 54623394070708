import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'tdate'
})
export class TdatePipe implements PipeTransform {
	transform(dateString: any, refresh?): any {
		if(typeof dateString == 'object') return dateString;
		if(!dateString || typeof dateString != 'string') return new Date();
		return dateString.replace(' ', 'T');
	}
}
