import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from './alert.service';
import { RoutingService } from './routing.service';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { SocketService } from './socket.service';
import { RolesService } from './roles.service';
import { Observable, Subject } from 'rxjs';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

export interface User {
	id: number;
	name: string;
	type: string;
	email: string;
	position: string;
	agent: boolean;
	list: boolean;
	phone: string;
}


@Injectable({
	providedIn: 'root'
})
export class UserService {
	constructor(private http: HttpService,
		private rs: RolesService,
		private router: Router,
		private socket: SocketService,
		private alert: AlertService,
		private routingService: RoutingService) {
		

		this.rs.us = this;
		this.load();
		// System init
		socket.on('users_update', userId => {
			for (let i = 0; i < this.users.length; i++) {
				if (this.users[i].id == userId) {
					return this.fetch(this.users[i]);
				}
			}
		});
		socket.on('users_create', userId => {
			let created = {
				id: userId
			};
			this.fetch(created, () => {
				this.users.push(created);
				this.review();
			});
		});
		socket.on('system_update', this.load_system.bind(this));
	}
	/*
	*	General Pull
	*/
	public cs: any;
	private used = false;
	public data:any = {};
	base(kind, cb){
		if(this.data[kind]) return cb(this.data[kind]);
		if(this.used){
			return setTimeout(()=>{
				this.base(kind, cb);
			}, 300);
		}
		this.used = true;
		this.http.apiget('/base/get', resp => {
			this.data = resp;
			cb(this.data[kind]);
		});
	}

	/*
	*	Perm Management
	*/
	public perm: any = {};
	public me: any = {
		background: '',
		avatar: '',
		password: '',
		name: ''
	};
	public me_edit: any = {};
	public users: any = [];
	public _users: any = {};
	public mat_users: any = [];
	public paginator: any;
	public sort: any;

	public isPasswordCorrect: Subject<boolean> = new Subject();
	public isPasswordCorrect$: Observable<boolean> = this.isPasswordCorrect.asObservable();

	public isEmailCorrect: Subject<any> = new Subject();
	public isEmailCorrect$: Observable<any> = this.isEmailCorrect.asObservable();


	public isActivationCorrect: Subject<any> = new Subject();
	public isActivationCorrect$: Observable<any> = this.isActivationCorrect.asObservable();


	role_preview(user) {
		if (user.roles) {
			for (let j = 0; j < user.roles.length; j++) {
				if (user.roleView) {
					user.roleView += ', ' + user.roles[j].name;
				} else {
					user.roleView = user.roles[j].name;
				}
			}
		}
	}

	public now = new Date().getTime();

	refresh() {
		this.now = new Date().getTime();
	}

	set_perm() {
		this.http.on('permissions roles', () => {
			this.perm = {};
			for (let i = 0; i < this.me.roles.length; i++) {
				for (let j = 0; j < this.rs._roles[this.me.roles[i].id].permissions_ids.length; j++) {
					if (!this.rs._permissions[this.rs._roles[this.me.roles[i].id].permissions_ids[j]]) {
						continue;
					}
					let slug = this.rs._permissions[this.rs._roles[this.me.roles[i].id].permissions_ids[j]].slug;
					slug = slug.split('.').join('');
					this.perm[slug] = true;
				}
			}

			this.perm.viewsettings = true;
			
			this.perm.viewestimates = true;
			this.perm.createestimates = true;
			this.perm.editestimates = true;
			this.perm.deleteestimates = true;

			this.perm.viewtranslate = true;

			this.perm.viewoffers = true;
			this.perm.createoffers = true;
			this.perm.editoffers = true;
			this.perm.deleteoffers = true;
			
			this.perm.viewinvoice = true;
			this.perm.createinvoice = true;
			this.perm.editinvoice = true;
			this.perm.deleteinvoice = true;

			this.http.set('my_perms');
		});
	}

	load() {
		this.http.get(this.http.url + '/profile', resp => {
			this.me = resp;
			this.http.set('me');
			this.set_perm();
			this.refresh();
			this.me_edit = Object.assign({}, resp);
			if (!this.me.avatar) {
				this.me.avatar = 'assets/images/users/4.jpg';
			}
			if (!this.me.background) {
				this.me.background = 'assets/images/background/profile-bg.jpg';
			}
			if (!Array.isArray(this.me.phones)) {
				this.me.phones = [];
			}
			this.http.c_get_all('users', resp => {
				this.users = resp;
				this.refresh();
				for (let i = 0; i < this.users.length; i++) {
					if (this.users[i].status == 'active') {
						this.users[i]._status = true;
					} else {
						this.users[i]._status = false;
					}
					this.users[i].roleView = '';
					this.role_preview(this.users[i]);
					this._users[this.users[i].id]=this.users[i];
				}
				this.http.set('users');
				for (let i = 0; i < this.users.length; i++) {
					if (this.users[i].id == this.me.id) {
						this.users[i] = this.me;
						break;
					}
				}
				this.review();
			});
		});
		this.http.on('my_perms', this.load_system.bind(this));
		this.http.get(this.http.url + '/clients/favorite/get', resp => {
			this.favClients = resp;
			for(let i = 0; i < this.favClients.length; i++){
				this.isFavClients[this.favClients[i].id] = true;
			}
		});
	}
	/*
	*	Users Management
	*/
	create(doc) {
		if (!this.perm.createusers) {
			return;
		}
		this.http.c_create('users', {
			roles: this.http.to_id(doc.roles),
			email: doc.email,
			name: doc.name
		}, created => {
			this.socket.emit('users_create', created.id);
			this.users.push(created);
			this.review();
		});
	}

	fetch(doc, cb = resp => {}) {
		this.http.c_fetch('users', doc, resp => {
			this.role_preview(doc);
			cb(resp);
		});
	}


	update(doc) {
		if (!this.perm.editusers) {
			return;
		}
		this.http.afterWhile(doc, () => {
			if (doc._status) {
				doc.status = 'active';
			} else {
				doc.status = 'inactive';
			}
			for (let i = 0; i < doc.roles.length; i++) {
				if (typeof doc.roles[i] == 'object') {
					doc.roles[i] = doc.roles[i].id;
				}
			}
			for (let i = 0; i < doc.phones.length; i++) {
				delete doc.phones[i].primary;
			}
			this.http.c_update('users', doc, newDoc => {
				let roleView = '';
				for (let j = 0; j < newDoc.roles.length; j++) {
					if (roleView) {
						roleView += ', ' + newDoc.roles[j].name;
					} else {
						roleView = newDoc.roles[j].name;
					}
				}
				for (let i = 0; i < this.users.length; i++) {
					if (this.users[i].id == doc.id) {
						this.users[i].roleView = roleView;
						this.users[i].phones = newDoc.phones;
					}
				}
				this.query();
				this.socket.emit('users_update', doc.id);
			});
		});
	}

	save() {
		let update: any = {
			phones: this.me.phones,
			email: this.me.email,
			name: this.me.name
		};
		if (this.me.avatar.length > 100) {
			update.avatar = this.me.avatar;
		}
		if (this.me.background.length > 100) {
			update.background = this.me.background;
		}
		this.http.c_update('profile', update);
	}
	update_one(update) {
		this.http.c_update('profile', update);
	}

	remove(user, restore) {
		for (let i = this.users.length - 1; i >= 0; i--) {
			if (this.users[i].id == user.id) {
				if (restore) {
					this.users[i].status = 'active';
				} else {
					this.users[i].status = 'inactive';
				}
				this.update(this.users[i]);
				break;
			}
		}
		this.review();
	}

	private _delete = (doc, restore) => {
		if (Array.isArray(doc)) {
			let length = doc.length;
			for (let i = 0; i < doc.length; i++) {
				this.remove(doc[i], restore);
			}
		} else {
			this.remove(doc, restore);
		}
		this.query();
	};

	delete(doc, restore = false) {
		if (restore) {
			this._delete(doc, restore);
			this.query();
		} else {
			this.alert.yes('Are you sure that you want to delete?', () => {
				this._delete(doc, restore);
				this.query();
			});
		}
	}

	public views = ['All', 'Active', 'Inactive', 'Pending'];
	public view = [];

	review() {
		let users = this.users.slice();
		if (this.view.join() == 'Active') {
			for (let i = users.length - 1; i >= 0; i--) {
				if (users[i].status != 'active') {
					users.splice(i, 1);
				}
			}
		} else if (this.view.join() == 'Inactive') {
			for (let i = users.length - 1; i >= 0; i--) {
				if (users[i].status != 'inactive') {
					users.splice(i, 1);
				}
			}
		} else if (this.view.join() == 'Pending') {
			for (let i = users.length - 1; i >= 0; i--) {
				if (users[i].status != 'pending') {
					users.splice(i, 1);
				}
			}
		}
		this.mat_users = new MatTableDataSource<User>(users);
		if (this.paginator) {
			this.mat_users.paginator = this.paginator;
		}
		if (this.sort) {
			console.log('adding sort');
			this.mat_users.sort = this.sort;
		}
	}

	query(query = this.mat_users.filter) {
		this.mat_users.filter = query.trim().toLowerCase();
	}

	logout() {
		this.http.get(this.http.url + '/logout');
		localStorage.removeItem('currentUser');
		this.router.navigate(['/login']);
	}

	/*
	*	Status Management
	*/
	information() {
		return this.settings.info.company_name &&
		this.settings.info.director_name &&
		this.settings.info.industry_type;
	}

	public completed = 0;

	isComplete() {
		let completed = 0;
		if (this.information()) {
			completed++;
		}
		if (this.settings.contacts.emails.length) {
			completed++;
		}
		let set = true;
		for (let i = 0; i < this.settings.contacts.emails.length; i++) {
			if (this.settings.contacts.emails[i].primary == '1') {
				set = false;
				break;
			} else {
				delete this.settings.contacts.emails[i].primary;
			}
		}
		if (this.settings.contacts.addresses.length) {
			completed++;
		}
		set = true;
		for (let i = 0; i < this.settings.contacts.addresses.length; i++) {
			if (this.settings.contacts.addresses[i].primary == '1') {
				set = false;
				break;
			} else {
				delete this.settings.contacts.addresses[i].primary;
			}
		}
		if (set && this.settings.contacts.addresses.length) {
			this.settings.contacts.addresses[0].primary = '1';
		}
		if (this.settings.taxes.length) {
			completed++;
		}
		set = true;
		for (let i = 0; i < this.settings.taxes.length; i++) {
			if (this.settings.taxes[i].primary == '1') {
				set = false;
				break;
			} else {
				delete this.settings.taxes[i].primary;
			}
		}
		if (set && this.settings.taxes.length) {
			this.settings.taxes[0].primary = '1';
		}
		/*Progress bar Changed from 5, portfolio shoud be added*/
		this.completed = Math.floor(completed / 4 * 100);
	}

	minePhones() {
	}

	/*
	*	System Management
	*/
	public save_status = {};
	public settings: any = {
		logo: {
			wide: 'assets/images/background/user-info.jpg',
			square: 'assets/images/background/user-info.jpg',
			icon: 'assets/images/background/user-info.jpg',
			login_background: 'assets/images/background/user-info.jpg',
		},
		taxes: [],
		contacts: {
			addresses: [],
			emails: [],
			phones: []
		},
		info: {}
	};

	public backup:any = {};
	load_system() {

		if (!this.perm.viewcompanysettings) {
			return;
		}
		this.http.get(this.http.url + '/company/settings/get', resp => {
			this.settings.taxes = resp.taxes;
			if (typeof resp.logo == 'object' && !Array.isArray(resp.logo)) {
				this.settings.logo = resp.logo;
			}
			if (typeof resp.info == 'object' && !Array.isArray(resp.info)) {
				this.settings.info = resp.info;
			}
			if (typeof resp.contacts == 'object' && !Array.isArray(resp.contacts)) {
				this.settings.contacts = resp.contacts;
			}
			if (!this.settings.contacts.addresses) {
				this.settings.contacts.addresses = [];
			}
			if (!this.settings.contacts.emails) {
				this.settings.contacts.emails = [];
			}
			if (!this.settings.contacts.phones) {
				this.settings.contacts.phones = [];
			}
			this.backup = JSON.parse(JSON.stringify(this.settings));
			this.isComplete();
		});
	}

	clone(obj) {
		return JSON.parse(JSON.stringify(obj));
	}

	emit_system() {
		this.socket.emit('system_update', '');
	}

	save_company() {
		if (!this.perm.editcompanysettings) {
			return;
		}
		this.http.post(this.http.url + '/company/info/update', this.settings.info, ()=>{
			this.routingService.settings['COMPANY_NAME']=this.settings.info.company_name;
			this.emit_system.bind(this);
		});
	}

	save_contacts(what) {
		if (!this.perm.editcompanysettings) {
			return;
		}
		const update = {};
		update[what] = this.settings.contacts[what];
		this.http.post(this.http.url + '/company/contacts/update', update, this.emit_system.bind(this));
	}

	save_taxes() {
		if (!this.perm.editcompanysettings) {
			return;
		}
		this.http.post(this.http.url + '/company/taxes/update', {
			taxes: this.settings.taxes
		}, this.emit_system.bind(this));
	}

	save_logo() {
		if (!this.perm.editcompanysettings) {
			return;
		}
		this.http.post(this.http.url + '/company/logo/update', {
			logo: {
				square: this.settings.logo.square.length > 100 && this.settings.logo.square,
				wide: this.settings.logo.wide.length > 100 && this.settings.logo.wide,
				icon: this.settings.logo.icon.length > 100 && this.settings.logo.icon,
				login_background: this.settings.logo.login_background.length > 100 && this.settings.logo.login_background
			}
		}, ()=>{
			this.routingService.settings['LOGIN_BACKGROUND']=this.settings.logo.login_background;
			this.routingService.settings['SQUARE']=this.settings.logo.square;
			this.emit_system.bind(this)
		}
		);
	}

	changePass(currentPass, newPass) {
		this.http.post(this.http.url + '/profile/password/update', {
			current_password: currentPass,
			password: newPass
		}, (res) => {
			if (res.status === false) {
				this.isPasswordCorrect.next(false);
			} else {
				this.isPasswordCorrect.next(true);
			}
		});
	}

	sendEmail(newEmail) {
		this.http.post(this.http.url + '/profile/email/update', {
			email: newEmail
		}, (res) => {
			this.isEmailCorrect.next(res);
		}, (res) => {
			this.isEmailCorrect.next(res.error.errors.email[0]);
			console.log(res);
		});

	}

	sendActivationCode(code) {
		this.http.post(this.http.url + '/profile/reset-email', {
			token: code
		}, (res) => {
			console.log(res.status);
			this.isActivationCorrect.next(res);

		}, (res) => {
			console.log(res)
			this.isActivationCorrect.next(res);
		});
	}

	/*
	*	Client Favorites
	*/

	/*
	// /api//<get|create|delete>
	// @param id - client_id (for create and delete)
	*/
	public favClients = [];
	public isFavClients = {};
	toggle_fav_client(client){
		for(let i = 0; i < this.favClients.length; i++){
			if(this.favClients[i].id == client.id){
				this.favClients.splice(i, 1);
				this.isFavClients[client.id] = false;
				return this.http.post(this.http.url + '/clients/favorite/delete', {
					id: client.id
				}, resp => {});
			}
		}
		this.favClients.unshift(client);
		this.isFavClients[client.id] = true;
		this.http.post(this.http.url + '/clients/favorite/create', {
			id: client.id
		}, resp => {});
	}
	/*
	*	End of Service
	*/
}
