import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoriesComponent } from './categories.component';
import { CustomMaterialModule } from '@root/modules/template';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedPipes } from '@pipes';
@NgModule({
	exports: [CategoriesComponent],
	declarations: [CategoriesComponent],
	imports: [
		ReactiveFormsModule,
		FormsModule,
		SharedPipes,
		CommonModule,
		CustomMaterialModule
	]
})
export class CategoriesModule { }
