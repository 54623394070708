import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { EstimateService } from './estimate.service';
import { SocketService } from '../socket.service';
import { AlertService } from '../alert.service';

@Injectable({
	providedIn: 'root'
})
export class ProductsService {
	public products:any=[];
	public _products:any={};
	constructor(private http: HttpService,
		private alert: AlertService,
		private socket: SocketService,
		private es: EstimateService) {
		this.es.offers('products', products=>{
			this.products = products;
			for(let i = 0; i < products.length; i++){
				this._products[products[i].id] = products[i];
			}
			if(this.refresh) this.refresh();
		});
		socket.on('product_create', productId => {
			this.fetch(productId, product=>{
				this.products.push(product);
				if(this.refresh) this.refresh();
			});
		});
		socket.on('product_update', productId => {
			this.fetch(productId, product=>{
				for(let i = 0; i < this.products.length; i++){
					if(this.products[i].id == productId){
						for(let each in this.products[i]){
							this.products[i][each] = product[each];
						}
						for(let each in product){
							this.products[i][each] = product[each];
						}
						break;
					}
				}
				if(this.refresh) this.refresh();
			});
		});
		socket.on('product_delete', productId => {
			for(let i = 0; i < this.products.length; i++){
				if(this.products[i].id == productId){
					this.products.splice(i, 1);
				}
			}
			if(this.refresh) this.refresh();
		});
	}
	public refresh;
	create(doc){
		this.http.c_create('estimates/offers/products', doc, resp => {
			this.products.push(resp);
			this.socket.emit('product_create', resp.id);
			if(this.refresh) this.refresh();
		});
	}
	fetch(productId, cb:any=()=>{}){
		this.http.c_fetch('estimates/offers/products', {
			id: productId
		}, cb);
	}
	update(doc){
		this.http.c_update('estimates/offers/products', doc, resp => {
			this.socket.emit('product_update', doc.id);
			if(this.refresh) this.refresh();
		});
	}
	delete(doc){
		this.alert.yes('Are you sure that you want to delete product?', () => {
			for(let i = 0; i < this.products.length; i++){
				if(this.products[i].id == doc.id){
					this.products.splice(i, 1);
				}
			}
			this.http.c_delete('estimates/offers/products', doc, resp => {
				this.socket.emit('product_delete', doc.id);
				if(this.refresh) this.refresh();
			});
		});
	}
}
