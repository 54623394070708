import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { UserService, ProductsService } from '@services';

export interface DemoColor {
	name: string;
	color: string;
}
@Component({
	selector: 'app-add',
	templateUrl: './add.component.html',
	styleUrls: ['./add.component.scss']
})
export class AddComponent {
	public item:any = {};
	public columns:any = [];
	public option='';
	public _tags:any = {};
	constructor(private dialogRef: MatDialogRef<AddComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		this.item = this.data.item || {};
		this.columns = this.data.columns;
		for(let i = 0; i<this.columns.length; i++){
			if(!this.columns[i].if) this.columns[i].if = function(){ return true; };
			if(this.columns[i].type=='tags'){
				if(Array.isArray(this.item[this.columns[i].col])){
					this._tags[this.columns[i].col] = this.item[this.columns[i].col];
				}else if(this.item[this.columns[i].col]){
					this._tags[this.columns[i].col] = this.item[this.columns[i].col].split(',');
				}else{
					this._tags[this.columns[i].col] = [];
				}
			}
		}
		if (this.data) {
		}
	}
	set(col){
		for(let i = this._tags[col].length-1; i>=0;i--){
			if(!this._tags[col][i]) this._tags[col].splice(i, 1);
		}
		this.item[col] = this._tags[col].join(',');
	}
	close() {
		this.dialogRef.close(this.item);
	}
	cancel() {
		this.dialogRef.close();
	}
}
