import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { SocketService } from '../socket.service';
import { AlertService } from '../alert.service';
import { BaseService } from '../base.service';
import { MatTableDataSource } from '@angular/material/table';
@Injectable({
  providedIn: 'root'
})
export class EqRepairService {
public status_selected: any = [];
	constructor(private http: HttpService,
		private socket: SocketService,
		private alert: AlertService,
		private bs: BaseService) {
		this.getRepairStatuses();
		this.getRepairTypes();
	}
  /*
	*	Pagination
	*/
	public now = new Date().getTime();
	refresh(){
	    this.now = new Date().getTime();
	}
	public page: number = 1;
	public perPages = [10, 20, 50, 100];
	public perPage:any = 50;
	private perPageLast = this.perPage;
	paginate(event){
		this.page = event.pageIndex+1;
		this.perPage = event.pageSize;
		if(this.perPageLast!=this.perPage){
			this.perPageLast=this.perPage;
			this.page = 1;
		}
		localStorage.setItem('rfqPerPage', this.perPage);
		this.load({
			page: this.page,
			perPage: this.perPage,
			sort: this.sort,
			sortby: this.sortby
		});
	}
	public repairs:any = [];
	public repairs_table: any = new MatTableDataSource<any>();
	public priorities:any = [];
	public total: number = 0;
	public refill:any;
	public search = '';
	public sortby = localStorage.getItem('equipmentrepairsortby') || 'id';
	public sort = localStorage.getItem('equipmentrepairSort') || 'asc';
	public filters: any = {};
	public filtersApplied: any = {};
	setsortby(sortby){
		this.sortby = sortby.active;
		this.sort = sortby.direction;
		localStorage.setItem('equipmentrepairsortby', this.sortby);
		localStorage.setItem('equipmentrepairSort', this.sort);
		this.load({
			page: this.page,
			perPage: this.perPage,
			sort: this.sort,
			sortby: this.sortby
		});
	}
	load(opts:any={}, cb=(resp:any)=>{}){
		for(let key in this.filters){
			if(!this.filters[key]) delete this.filters[key];
		}
		this.filtersApplied = Object.assign({}, this.filters);
		let params:any = {};
		if(opts.page) params.page = opts.page;
		if(opts.perPage) params.per_page = opts.perPage;
		if(opts.sort) params.sort = opts.sort;
		if(opts.sortby) {
			params.sortby = opts.sortby
		} else {
			params.sortby = 'asc';
		}
		if(this.filters) {
			for(const key in this.filters) {
				if(!this.filters[key]) continue;
				params['filters['+key+']'] = this.filters[key];
			}
		}
		if(this.status_selected.length > 0) {
			for (var i = 0; i < this.status_selected.length; i++) {
				params['filters[status_id]['+ i +']'] = this.status_selected[i].id;
			}
			
		}
		if(this.search) params.search = this.search;
		this.http.c_get('equipments/repairs', (resp:any) => {
			for(let i = 0; i < resp.data.length; i++){
				if(opts.replace){
					for(let each in opts.replace){
						if(typeof opts.replace[each] == 'function'){
							resp.data[i][each] = opts.replace[each](resp.data[i]);
						}
					}
				}
			}
			this.repairs = resp.data;
			this.repairs_table = new MatTableDataSource(this.repairs);
			this.total = resp.total;
			if(this.refill) this.refill();
			cb(this.repairs);
		}, params);
	}
	loadAfterWhile(){
		this.http.afterWhile(this, this.load.bind(this), 2000);
	}
	public fetched = {};
	fetch(id: number, cb=resp=>{}) {
		if (!id) { return; }
		if(!this.fetched[id]){
			this.fetched[id] = {};
			this.http.apipost('equipments/repairs/fetch', {
				id: id
			}, resp=>{
				for(let each in resp){
					this.fetched[id][each] = resp[each];
				}
				cb(this.fetched[id]);
			});
		}else if(!this.fetched[id].id){
			setTimeout(()=>{
				this.fetch(id, cb);
			}, 100);
		}else{
			cb(this.fetched[id]);
		}
		return this.fetched[id];
	}

	save(repair, cb=resp=>{}, ignore={}){
		if(!repair) return;
		this.http.c_update('equipments/repairs', repair, cb, ignore);
	}
	updateStatus(status, cb=resp=>{}) {
		this.http.apipost('/equipments/repairs/status', status, cb);
	}
	finish(repair, cb=resp=>{}) {
		this.http.apipost('/equipments/repairs/finish', repair, cb);
	}
	delete(repair_id, cb=resp=>{}) {
		this.http.apipost('/equipments/repairs/delete', {id: repair_id}, cb);
	}
	create(repair, cb=resp=>{}) {
		this.http.apipost('/equipments/repairs/create', repair, cb);
	}
	//Repair Statuses
	public statuses: any = [];
	getRepairStatuses(cb=resp=>{}) {
		this.http.apiget('/equipments/repairs/statuses/get', (resp)=> {
			this.statuses = resp;
			this.status_selected = resp;
			this.http.set('repairstatus');
			cb(resp);
		});
	}
	createRepairStatus(status, cb=resp=>{}) {
		this.http.apipost('/equipments/repairs/statuses/create', status, cb);
	}
	updateRepairStatus(status, cb=resp=>{}) {
		this.http.apipost('/equipments/repairs/statuses/update', status, cb);
	}
	deleteRepairStatus(status, cb=resp=>{}) {
		this.http.apipost('/equipments/repairs/statuses/delete', status, cb);
	}
	//Repair types

	public types: any = [];
	getRepairTypes(cb=resp=>{}) {
		this.http.apiget('/equipments/repairs/types/get', (resp)=> {
			this.types = resp;
			cb(resp);
		});
	}
	createRepairType(type, cb=resp=>{}) {
		this.http.apipost('/equipments/repairs/types/create', type, cb);
	}
	updateRepairType(type, cb=resp=>{}) {
		this.http.apipost('/equipments/repairs/types/update', type, cb);
	}
	deleteRepairType(data, cb=resp=>{}) {
		this.http.apipost('/equipments/repairs/types/delete', data, cb);
	}
}
