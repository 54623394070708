import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FieldsService } from '@services';

@Component({
	inputs: ['page', 'module', 'selected', 'fields', 'header'],
	selector: 'fields',
	templateUrl: './fields.component.html',
	styleUrls: ['./fields.component.scss']
})
export class FieldsComponent implements OnInit {
	@Output('select') select = new EventEmitter();
	@Output('update') update = new EventEmitter();
	public header = 'Project Requirements';
	public page = 'confirm';
	public module = 'quote';
	public fields;
	public selected;
	public _selected={};
	constructor(public fs: FieldsService) {}
	/*
	*	Select Management
	*/
		init(){
			if(!this.fields&&!this.fs.load){
				return setTimeout(this.init.bind(this), 100);
			}
			if(!this.fields) this.fields=this.fs.fields;
		}
		ngOnInit(){
			if(this.selected && this.selected.length){
				for(let i = 0; i < this.selected.length; i++){
					this._selected[this.selected[i].id] = true;
				}
			}
			this.init();
		}
		prepare_select(){
			let fields = [];
			for(let each in this._selected){
				if(this._selected[each]){
					fields.push(this.fs._fields[each]);
				}
			}
			this.select.emit(fields);
		}
	/*
	*	End of 
	*/
}
