import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TaskComponent } from './task.component';
import { CustomMaterialModule } from '@root/modules/template';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
import { SharedModule } from '@services';
import { MccColorPickerModule } from 'material-community-components';
import { SharedPipes } from '@pipes';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddressModule } from '../../../address/address.module';
import { CategoriesModule } from '../../../Categories/categories/categories.module';
import { DatepickerModule } from '../../../Datepicker/datepicker/datepicker.module';
import { Routes, RouterModule } from '@angular/router';
import { AttachmentModule } from '../../../Attachment/attachment/attachment.module';

import { NgxMaskModule } from 'ngx-mask';
@NgModule({
    declarations: [TaskComponent],
    imports: [
        RouterModule,
        SharedPipes,
        SharedModule,
        CommonModule,
        CustomMaterialModule,
        MatGoogleMapsAutocompleteModule,
        NgxMatSelectSearchModule,
        DatepickerModule,
        MatDatetimepickerModule,
        MatMomentDatetimeModule,
        MatMomentDateModule,
        NgMultiSelectDropDownModule,
        CategoriesModule,
        FlexLayoutModule,
        FormsModule,
        AddressModule,
        NgxMaskModule,
        ReactiveFormsModule,
        AttachmentModule,
        MccColorPickerModule.forRoot({
            empty_color: '#ffffff'
        })
    ],
    exports: [TaskComponent],
    entryComponents: [TaskComponent]
})
export class TaskModule { }