import { Component, OnInit } from '@angular/core';
import { ProductsService, EstimateService, ServicesService } from '@services';

@Component({
	inputs: ['estimate', 'update'],
	selector: 'products-direct',
	templateUrl: './products.component.html',
	styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
	public estimate: any;
	public update;
	constructor(public ps: ProductsService, public es: EstimateService) {}
	ngOnInit() {}
	add(product){
		if(!Array.isArray(this.estimate.products)){
			this.estimate.products = [];
		}
		this.estimate.products.push({
			offers_product_id: product.id,
			description: '',
			unit_price: product.price,
			price: product.price,
			amount: 1
		});
		this.save();
	}
	save(){
		for(let i = 0; i < this.estimate.products.length; i++){
			this.estimate.products[i].price = Math.floor(this.estimate.products[i].amount * this.estimate.products[i].unit_price*100)/100;
			this.estimate.products[i].preview = this.estimate.products[i].amount+' x '+Number(this.estimate.products[i].unit_price).toFixed(2)+' $ = '+Number(this.estimate.products[i].price).toFixed(2)+' $';
		}
		this.es.calculate(this.estimate);
		this.es.refresh();
		if(typeof this.update == 'function'){
			this.update();
		}else{
			this.es.update_products(this.estimate);
		}
	}
}
