import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMaterialModule } from '@root/modules/template';
import { SharedPipes } from '@pipes';
import { ActivityComponent } from './activity.component';
import { ClientCreateComponent } from './client-create/client-create.component';
import { ClientUpdateComponent } from './client-update/client-update.component';

@NgModule({
	exports: [ActivityComponent],
	declarations: [ActivityComponent, ClientCreateComponent, ClientUpdateComponent],
	imports: [
		CustomMaterialModule,
		CommonModule,
		SharedPipes
	]
})
export class ActivityModule { }
