import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { SocketService } from '../socket.service';
import { AlertService } from '../alert.service';

@Injectable({
	providedIn: 'root'
})
export class EqGroupsService {
	public groups:any = [];
	public now = new Date().getTime();
	refresh(){
	    this.now = new Date().getTime();
	}
	public page: number = 1;
	public perPages = [5, 10, 25];
	public perPage:any = 10;
	private perPageLast = this.perPage;
	public sortby = localStorage.getItem('EquipmentGroupsortby') || 'id';
	public sort = localStorage.getItem('EquipmentGroupSort') || 'desc';
	public filters: any = {};
	public filtersApplied: any = {};
	public filtersIdsArray;
	public search;
	public total: number = 0;
	public refill:any;
	constructor(private http: HttpService,
		private socket: SocketService,
		private alert: AlertService){
		this.load();
	}
	setsortby(sortby){
		this.sortby = sortby.active;
		this.sort = sortby.direction;
		localStorage.setItem('EquipmentGroupsortby', this.sortby);
		localStorage.setItem('EquipmentGroupSort', this.sort);
		this.load({
			page: this.page,
			perPage: this.perPage,
			sort: this.sort,
			sortby: this.sortby
		});
	}
	paginate(event){
		this.page = event.pageIndex+1;
		this.perPage = event.pageSize;
		if(this.perPageLast!=this.perPage){
			this.perPageLast=this.perPage;
			this.page = 1;
		}
		this.load({
			page: this.page,
			perPage: this.perPage,
			sort: this.sort,
			sortby: this.sortby
		});
	}

	load(opts:any={}, cb=(resp:any)=>{}){
		let params:any = {};
		if(opts.page) params.page = opts.page;
		if(opts.perPage) params.per_page = opts.perPage;
		if(opts.sort) params.sort = opts.sort;
		if(opts.sortby) {
			params.sortby = opts.sortby;
		} else {
			params.sortby = 'id';
		}
		if(this.search) params.search = this.search;
		this.http.c_get('equipments/groups', (resp:any) => {
			for(let i = 0; i < resp.data.length; i++){
				if(opts.replace){
					for(let each in opts.replace){
						if(typeof opts.replace[each] == 'function'){
							resp.data[i][each] = opts.replace[each](resp.data[i]);
						}
					}
				}
			}
			this.groups = resp.data;
			this.total = resp.total;
			if(this.refill) this.refill();
			cb(this.groups);
		}, params);
	}
	loadAfterWhile(){
		this.http.afterWhile(this, this.load.bind(this), 2000);
	}
	private fetched = {};
	fetch(id: number, cb=resp=>{}) {
		if (!id) { return; }
		if(!this.fetched[id]){
			this.fetched[id] = {};
			this.http.apipost('/equipments/groups', {
				id: id
			}, resp=>{
				for(let each in resp){
					this.fetched[id][each] = resp[each];
				}
				cb(this.fetched[id]);
			});
		}else if(!this.fetched[id].id){
			setTimeout(()=>{
				this.fetch(id, cb);
			}, 100);
		}else{
			cb(this.fetched[id]);
		}
		return this.fetched[id];
	}
	create(group, cb=resp=>{}) {
		this.http.apipost('/equipments/groups/create', group, cb);
	}
	update(doc){
		/*this.http.c_update('estimates/offers/groups', doc, resp => {
			this.socket.emit('specialist_update', resp.id);
			if(this.refresh) this.refresh();
		});*/
		for(let i = 0; i < this.groups.length; i++){
			if(this.groups[i].id == doc.id){
				this.groups[i]=doc;
			}
		}
		localStorage.setItem('EquipmentGroups', JSON.stringify(this.groups));
		if(this.refresh) this.refresh();
	}
	delete(doc){
		this.alert.yes('Are you sure that you want to delete group?', () => {
			this.deleteEquipment(doc.id, ()=> {
				this.load({
					page: this.page,
					perPage: this.perPage,
					sort: this.sort,
					sortby: this.sortby
				});
			});
		});
	}
	save(group, cb=resp=>{}, ignore={}){
		if(!group) return;
		this.http.c_update('equipments/groups', group, cb, ignore);
	}
	deleteEquipment(group_id, cb=resp=>{}) {
		this.http.apipost('/equipments/groups/delete', {id: group_id}, cb);
	}
}



