import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '@services';

@Component({
	selector: 'actClientUpdate',
	inputs: ['activity'],
	templateUrl: './client-update.component.html',
	styleUrls: ['./client-update.component.scss']
})
export class ClientUpdateComponent implements OnInit {
	@Input ('activity') activity;
	constructor(public us: UserService) {}
	ngOnInit() {}
}
