import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CustomMaterialModule } from '@root/modules/template';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '@services';
import { SharedPipes } from '@pipes';
import { NoteComponent } from './note.component';
import { AttachmentModule } from '../../Attachment/attachment/attachment.module';
@NgModule({
    declarations: [NoteComponent],
    imports: [
        SharedPipes,
        SharedModule,
        CommonModule,
        CustomMaterialModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        AttachmentModule
    ],
    exports: [NoteComponent],
    entryComponents: [NoteComponent]
})
export class NoteModule { }