import { Component, OnInit, Output, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '@services';
@Component({
	selector: 'app-dialog',
	inputs: ['src'],
	templateUrl: './dialog.component.html',
	styleUrls: ['./preview.component.scss']
})
export class DialogComponent {
	public src = '';
	public delete;
	constructor(@Inject(MAT_DIALOG_DATA) data:any,
		public dialogRef: MatDialogRef<DialogComponent>,
		public alert: AlertService) {
		this.src = data.src;
		this.delete = data.delete;
		console.log(data);
	}
	close(){
		this.dialogRef.close();
	}

	deleteFile(){
		this.alert.yes('Are ye sure you want to delete this file?', () => {
			this.delete.emit();
			this.close();
		});
	}
}