import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IEquipmentGroup, IEquipmentIssue, IEquipmentItem } from '@root/interfaces';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';

@Injectable({
	providedIn: 'root'
})
export class EquipmentGroupService {

	public groupList: Subject<any> = new Subject();
	public groupList$: Observable<any> = this.groupList.asObservable();

	public itemList: Subject<any> = new Subject();
	public itemList$: Observable<any> = this.itemList.asObservable();

	public issueList: Subject<IEquipmentIssue[]> = new Subject();
	public issueList$: Observable<IEquipmentIssue[]> = this.issueList.asObservable();

	public group: Subject<IEquipmentGroup> = new Subject();
	public group$: Observable<IEquipmentGroup> = this.group.asObservable();

	private uri = environment.api;


	constructor(private http: HttpClient,
	            ) {
	}


	public getGroupList(): void {
		this.http.get(`${this.uri}/equipment/groups/get`).subscribe((groups) => {
			this.groupList.next(groups);
		});
	}

	public getItems(): void {
		this.http.get(`${this.uri}/equipment/items/get`).subscribe((items) => {
			this.itemList.next(items);
		});
	}

	public getItem(id: string): Observable<object> {
		return this.http.post(`${this.uri}/equipment/items/fetch`, {id: id});


	}

	public getGroupItemList(groupId: string): void {
		const groups: IEquipmentGroup[] = JSON.parse(localStorage.getItem('equipmentGroups'));
		const find = this.findById(groupId, groups);
		const res = groups.slice(find, find + 1);
		this.group.next(res[0]);
	}

	public addNewItem(item: any): Observable<object> {
		return this.http.post(`${this.uri}/equipment/items/create`, item);
	}

	public deleteItem(id): Observable<object> {
		return this.http.post(`${this.uri}/equipment/items/delete`, {id});
	}

	public editItem(item: any): Observable<object> {
		return this.http.post(`${this.uri}/equipment/items/update`, item);
	}

	private findById(id: string, arr: (IEquipmentGroup | IEquipmentItem | IEquipmentIssue)[]): number {
		let findItemInd = -1;
		if (arr) {
			arr.forEach((item, index) => {
				if (item.id === id) {
					findItemInd = index;
				}
			});
		}
		return findItemInd;
	}

	public addNewGroup(group): Observable<object> {
		return this.http.post(`${this.uri}/equipment/groups/create`, group);
	}

	public deleteGroup(id): Observable<object> {
		return this.http.post(`${this.uri}/equipment/groups/delete`, {id: id});
	}

	public addSelectedItem(item: IEquipmentItem): void {

		// this.removeItemFromGroup(item.id);
		// this.deleteItem(item.id);
		// this.addNewItem(item);

	}

	public editGroup(group): Observable<object> {
		return this.http.post(`${this.uri}/equipment/groups/update`, group);
	}

	public getIssues(): void {
		const issues: IEquipmentIssue[] = JSON.parse(localStorage.getItem('equipmentIssues'));
		this.issueList.next(issues);
	}

	public getItemIssues(id: string): void {
		const items: IEquipmentItem[] = JSON.parse(localStorage.getItem('equipmentItems'));
		items.forEach((item) => {
			if (item.id === id) {
				this.issueList.next(item.issues);
			}
		});

	}

	public addNewIssue(issue: IEquipmentIssue): void {
		let issues: IEquipmentIssue[] = JSON.parse(localStorage.getItem('equipmentIssues'));
		const items: IEquipmentItem[] = JSON.parse(localStorage.getItem('equipmentItems'));
		if (issues != null) {
			if (items) {
				items.forEach((item) => {
					if (item.id === issue.itemId) {
						if (!item.issues) {
							item.issues = [];
						}
						item.issues.push(issue);
						localStorage.setItem('equipmentItems', JSON.stringify(items));
						this.itemList.next(items);
					}
				});
			}
			issues.push(issue);
			localStorage.setItem('equipmentIssues', JSON.stringify(issues));
			this.issueList.next(issues);
		} else {
			if (items) {
				items.forEach((item) => {
					if (item.id === issue.itemId) {
						if (!item.issues) {
							item.issues = [];
						}
						item.issues.push(issue);
						localStorage.setItem('equipmentItems', JSON.stringify(items));
						this.itemList.next(items);
					}
				});
			}
			issues = [issue];
			localStorage.setItem('equipmentIssues', JSON.stringify(issues));
			this.issueList.next(issues);
		}
	}

	public deleteIssue(issueId): void {
		const issues: IEquipmentIssue[] = JSON.parse(localStorage.getItem('equipmentIssues'));
		const items: IEquipmentItem[] = JSON.parse(localStorage.getItem('equipmentItems'));
		const groups: IEquipmentGroup[] = JSON.parse(localStorage.getItem('equipmentGroups'));

		if (issues) {
			const findInd = this.findById(issueId, issues);
			issues.splice(findInd, 1);
			localStorage.setItem('equipmentIssues', JSON.stringify(issues));
			this.issueList.next(issues);

			if (items) {
				items.forEach((item) => {
					const findIndItemIssue = this.findById(issueId, item.issues);
					if (findIndItemIssue > -1) {
						item.issues.splice(findIndItemIssue, 1);
						localStorage.setItem('equipmentItems', JSON.stringify(items));
						this.itemList.next(items);
					}
				});
			}
			if (groups) {
				groups.forEach((group) => {
					group.equipmentItems.forEach((item) => {
						const findIndItemIssue = this.findById(issueId, item.issues);
						if (findIndItemIssue > -1) {
							item.issues.splice(findIndItemIssue, 1);
							localStorage.setItem('equipmentItems', JSON.stringify(groups));
							this.groupList.next(groups);
						}
					});
				});
			}
		}
	}

	public editIssue(issue: IEquipmentIssue): void {
		const issues: IEquipmentIssue[] = JSON.parse(localStorage.getItem('equipmentIssues'));
		const items: IEquipmentItem[] = JSON.parse(localStorage.getItem('equipmentItems'));

		if (items) {
			items.forEach((item) => {
				const issueItemsInd = item.issues.findIndex((child) => child.id === issue.id);
				if (issueItemsInd !== -1) {
					item.issues.splice(issueItemsInd, 1);
					localStorage.setItem('equipmentItems', JSON.stringify(items));
				}
			});
		}

		if (!issues) {
			return;
		}

		const findInd = this.findById(issue.id, issues);
		issues.splice(findInd, 1, issue);
		localStorage.setItem('equipmentIssues', JSON.stringify(issues));
		this.issueList.next(issues);

		if (!items) {
			return;
		}

		items.forEach((item) => {
			if (item.id === issue.itemId) {
				item.issues.push(issue);
				localStorage.setItem('equipmentItems', JSON.stringify(items));
				this.itemList.next(items);
			}
		});
	}

	public editStatus(status: string, id: string): void {
		const items: IEquipmentItem[] = JSON.parse(localStorage.getItem('equipmentItems'));
		items.forEach((item) => {
			if (item.id === id) {
				item.status = status;
				this.itemList.next(items);
				localStorage.setItem('equipmentItems', JSON.stringify(items));
			}
		});
	}

	public getBrands(): Observable<object> {
		return this.http.get(`${this.uri}/equipment/brands/get`);
	}

	public getNotes(id): Observable<object> {
		return this.http.get(`${this.uri}/equipment/items/notes/get`, {
			params: {
				item_id: id
			}
		});
	}

	public addNote(note): Observable<object> {
		return this.http.post(`${this.uri}/equipment/items/notes/create`, note);
	}

	public editNote(note): Observable<object> {
		return this.http.post(`${this.uri}/equipment/items/notes/update`, note);
	}


	public deleteNote(id): Observable<object> {
		return this.http.post(`${this.uri}/equipment/items/notes/delete`, {id});
	}

	public fetchNote(id): Observable<object> {
		return this.http.post(`${this.uri}/equipment/items/notes/fetch`, id);
	}

	public addNewAttachment(data): Observable<object> {
		return this.http.post(`${this.uri}/equipment/items/files/create`, data);
	}

	public deleteAttachment(id): Observable<object> {
		return this.http.post(`${this.uri}/equipment/items/files/delete`, {id});
	}


	public decodeVin(vin: string): Observable<object> {
		return this.http.get(`${this.uri}/equipment/decoder/get`, {
			params: {
				vin: vin
			}
		});
	}

}
