import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { FileService } from '../client/file.service';

@Injectable({
	providedIn: 'root'
})
export class StickersService {
	public stickers:any = [];
	constructor(private http: HttpService, public file: FileService) {
		this.http.apiget('/draw/stickers/get', stickers=>{
			this.stickers = stickers;
		});
	}
	add(files){
		for(let i = files.length-1; i >= 0; i--){
			if(files[i].type != "image/svg+xml"){
				files.splice(i, 1);
			}
		}
		this.file.add(files, '/draw/stickers/create', {}, created=>{
			for(let i = 0; i < created.length; i++){
				this.stickers.push(created[i]);
			}
		});
	}
	remove(sticker){
		this.http.apipost('/draw/stickers/delete', {
			id: sticker.id
		});
	}
}
