import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { SocketService } from '../socket.service';

@Injectable({
	providedIn: 'root'
})
export class ServicesService {
	public services:any = [];
	to(doc){
		return {
			id: doc.id || null,
			order: doc.order,
			markup_rate: doc.markup_rate,
			markup_amount: doc.markup_amount,
			title: doc.title,
			description: doc.description,
			price: doc.price || 0,
			work_hours: doc.work_hours,
			tax_id: doc.tax_id,
			specialists: this.http.to_id(doc.specialists),
			products: this.http.to_id(doc.products, product=>{
				return {
					offers_product_id: product.id,
					quantity: product.pivot.quantity
				};
			}),
			fields: this.http.to_id(doc.fields, field=>{
				return field.id;
			}),
			setups: this.http.to_id(doc.setups, setup=>{
				return {
					attachments: this.http.to_id(setup.attachments, attachment=>{
						return {
							offers_attachment_id: attachment.id,
							price_type: attachment.pivot.price_type
						}
					}),
					equipment: {
						offers_equipment_id: setup.equipment.id,
						price_type: setup.price_type
					}
				};
			})
		};
	}
	constructor(private socket: SocketService,
		private http: HttpService) {
		socket.on('service_create', serviceId => {
			this.fetch(serviceId, service=>{
				this.calculate(service);
				this.services.push(service);
			});
		});
		socket.on('service_update', serviceId => {
			this.fetch(serviceId, service=>{
				for(let i = 0; i < this.services.length; i++){
					if(this.services[i].id == serviceId){
						for(let each in this.services[i]){
							this.services[i][each] = service[each];
						}
						for(let each in service){
							this.services[i][each] = service[each];
						}
						break;
					}
				}
			});
		});
		socket.on('service_delete', serviceId => {
			for(let i = 0; i < this.services.length; i++){
				if(this.services[i].id == serviceId){
					this.services.splice(i, 1);
				}
			}
		});
	}
	fetch(serviceId, cb:any=()=>{}){
		this.http.c_fetch('estimates/offers/services', {
			id: serviceId
		}, cb);
	}
	create(doc, close=()=>{}){
		this.http.c_create('estimates/offers/services', this.to(doc), created => {
			this.calculate(created);
			this.services.push(created);
			this.socket.emit('service_create', created.id);
			close();
		});
	}
	update(doc){
		this.http.c_update('estimates/offers/services', this.to(doc), ()=>{
			this.socket.emit('service_update', doc.id);
		});
	}
	delete(doc){
		for(let i = 0; i < this.services.length; i++){
			if(this.services[i].id == doc.id){
				this.services.splice(i, 1);
			}
		}
		this.http.c_delete('estimates/offers/services', doc, ()=>{
			this.socket.emit('service_delete', doc.id);
		});
	}
	initialize(service){
		this.calculate(service);
		this.http.on('services_loaded', ()=>{
			let values;
			for(let i = 0; i < this.services.length; i++){
				if(this.services[i].id == service.offers_service_id){
					values = service.fields;
					service.fields = this.services[i].fields;
					break;
				}
			}
			if(!service.fields) service.fields=[];
			for(let i = 0; i < service.fields.length; i++){
				service.fields[i].pivot = {};
				if(!values) values=[];
				for(let j = 0; j < values.length; j++){
					if(values[j].pivot.field_id == service.fields[i].id){
						service.fields[i].value = values[j].pivot.value;
						break;
					}
				}
			}
		});
	}
	calculate(service){
		let hours = Number(service.work_hours||0) + Number(service.extra_hours||0);
		service.hours = hours * service.specialists.length;
		service.cprice = 0; // service.additionalPrice || 
		if(!service.work_hours) service.work_hours=0;
		if(!Array.isArray(service.specialists)) service.specialists = [];
		for(let i = 0; i < service.specialists.length; i++){
			if(service.specialists[i].price && hours){
				service.cprice += Number(service.specialists[i].price) * hours;
			}
		}
		if(!Array.isArray(service.setups)) service.setups = [];
		for(let i = 0; i < service.setups.length; i++){
			if(service.setups[i].price_type == 'daily' && service.setups[i].equipment.daily){
				service.cprice += Number(service.setups[i].equipment.full_day_price);
			}else if(service.setups[i].equipment && service.setups[i].equipment.price){
				service.cprice += Number(service.setups[i].equipment.price) * hours;
			}
			if(!service.setups[i].attachments) service.setups[i].attachments=[];
			for(let j = 0; j < service.setups[i].attachments.length; j++){
				if(service.setups[i].attachments[j].kind == 'daily' && service.setups[i].attachments[j].daily){
					service.cprice += Number(service.setups[i].attachments[j].full_day_price);
				}else if(service.setups[i].attachments[j].price){
					service.cprice += Number(service.setups[i].attachments[j].price) * hours;
				}
			}
		}
		service.cprice = Math.round(Number(service.cprice) * 100) / 100;
		if(service.price && service.price!='0.00'){
			service.tprice = Math.round(Number(service.price) * 100) / 100;
			let added = service.price - service.cprice;
			service.markup_rate = Math.round(added/service.cprice*10000)/100;

			let markup = Number(service.markup_rate)/100;
			service.markup_amount = service.tprice * markup;
			service.markup_amount = Math.round(Number(service.markup_amount) * 100) / 100;
		}else{
			service.tprice = service.cprice;
			if(service.markup_rate){
				let markup = Number(service.markup_rate)/100;
				//	service.markup_rate = Math.round(service.markup_rate).toFixed(2);
				service.markup_amount = service.tprice * markup;
				service.markup_amount = Math.round(Number(service.markup_amount) * 100) / 100;
				markup++;
				service.tprice *= markup;
			}else{
				service.markup_rate = 0;
			}
			service.tprice = Math.round(Number(service.tprice) * 100) / 100;
		}
	}
}
