import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relatedUsers'
})
export class RelatedUsersPipe implements PipeTransform {

  transform(arr: any, me: any, type: '', refresh?): any {
    let tasks = [];
  	for(let i = 0; i < arr.length; i++ ) {
  		for(let j = 0; j < arr[i].related_users.length; j++) {
  			if(arr[i].related_users[j].pivot.type == type && arr[i].related_users[j].id == me) {
  				  tasks.push(arr[i]);
  			}
  		}
  	}
    return tasks;
  }

}
