import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'before'
})
export class BeforePipe implements PipeTransform {
	transform(arr, date, field='created_at'): any {
		if(!date) return arr;
		if(typeof date.getTime == 'function'){
			date = date.getTime();
		}
		arr = arr.slice();
		for(let i = arr.length-1; i >= 0; i--){
			if(arr[i][field] && typeof arr[i][field].getTime == 'function' &&
				date > arr[i][field].getTime()){
				continue;
			}
			arr.splice(i, 1);
		}
		return arr;
	}
}
