import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

export class AppDateAdapter extends NativeDateAdapter {

  format(date: Date, displayFormat: Object): string {
      return moment(date).format('MMM DD, YYYY')
  }
}

@Component({
  selector: 'datepicker',
  inputs: ['date', 'placeholder', 'min', 'hide_clean', 'appearance', 'label', 'floatLabel'],
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [{
      provide: DateAdapter, useClass: AppDateAdapter
    },{
      provide: MAT_DATE_FORMATS, useValue: MY_FORMATS
    }],
})
export class DatepickerComponent implements OnInit {
  @Output('update') update = new EventEmitter();
 public date;
 public min;
 public placeholder;
 public hide_clean;
 public appearance;
 public label;
 public floatLabel;
 constructor() { }

 ngOnInit() {
  //console.log(this.minimalDate);
 }
 onChange(ev) {
    /*let p = ev.targetElement.value.split(',').join(' ').value.split('/').join(' ').split('-').join(' ').split(' ');*/
    this.date = new Date(ev.targetElement.value);
    this.update.emit(this.date);
  }
}