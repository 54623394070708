import { Component, OnInit } from '@angular/core';
import { ProductsService, EstimateService, ServicesService, HttpService } from '@services';

@Component({
	inputs: ['estimate', 'update'],
	selector: 'products',
	templateUrl: './products.component.html',
	styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
	public estimate: any;
	public estimate_editing: any;
	public update;
	constructor(public ps: ProductsService,
		private http: HttpService,
		public es: EstimateService) {}
	cancel(){
		this.estimate_editing = JSON.parse(JSON.stringify(this.estimate));
	}
	ngOnInit() {
		this.cancel();
	}
	add(product){
		if(!Array.isArray(this.estimate_editing.products)){
			this.estimate_editing.products = [];
		}
		this.estimate_editing.products.push({
			offers_product_id: product.id,
			description: '',
			unit_price: product.price,
			price: product.price,
			amount: 1
		});
	}
	after_delete(){
		this.estimate.products = JSON.parse(JSON.stringify(this.estimate_editing.products));
		for(let i = 0; i < this.estimate.products.length; i++){
			this.estimate.products[i].price = Math.floor(this.estimate.products[i].amount * this.estimate.products[i].unit_price * 100)/100;
			this.estimate.products[i].preview = this.estimate.products[i].amount+' x '+Number(this.estimate.products[i].unit_price).toFixed(2)+' $ = '+Number(this.estimate.products[i].price).toFixed(2)+' $';
		}
		this.es.calculate(this.estimate);
		this.es.refresh();
		if(typeof this.update == 'function'){
			this.update();
		}else{
			this.es.update_products(this.estimate);
		}
	}
	save(){
		let deleted = this.http.to_id(this.estimate.products);
		console.log(this.estimate);
		let counter = 0;
		for(let i = 0; i < deleted.length; i++){
			let delete_product = true;
			for(let j = 0; j < this.estimate_editing.products.length; j++){
				if(deleted[i]==this.estimate_editing.products[j].id){
					console.log(this.estimate_editing.products[j]);
					delete_product = false;
					break;
				}
			}
			if(delete_product){
				counter++;
				this.http.apipost('/estimates/products/delete', {
					id: deleted[i]
				}, ()=>{
					if(--counter===0) this.after_delete();
				});
			}
		}
		if(!counter) this.after_delete();
		
	}
}
