import { NgModule } from '@angular/core';
import { EachPipe } from './each.pipe';
import { SearchPipe } from './search.pipe';
import { SplicePipe } from './splice.pipe';
import { TdatePipe } from './tdate.pipe';
import { TtimePipe } from './ttime.pipe';
import { SortPipe } from './sort.pipe';
import { BeforePipe } from './before.pipe';
import { AfterPipe } from './after.pipe';
import { UsersofrolePipe } from './usersofrole.pipe';
import { PhonePipe } from './phone.pipe';
import { ShiftPipe } from './shift.pipe';
import { StatusPipe } from './status.pipe';
import { RelatedUsersPipe } from './related-users.pipe';
import { SecondtotimePipe } from './secondtotime.pipe';
import { TimetodatePipe } from './timetodate.pipe';
import { AddcontactPipe } from './addcontact.pipe';
import { JobsPipe } from './jobs.pipe';
@NgModule({
	exports: [EachPipe, SearchPipe, SplicePipe, TdatePipe, TtimePipe, SortPipe, BeforePipe, AfterPipe, UsersofrolePipe, PhonePipe, ShiftPipe, StatusPipe, RelatedUsersPipe, SecondtotimePipe, TimetodatePipe, AddcontactPipe, JobsPipe],
	declarations: [EachPipe, SearchPipe, SplicePipe, TdatePipe, TtimePipe, SortPipe, BeforePipe, AfterPipe, UsersofrolePipe, PhonePipe, ShiftPipe, StatusPipe, RelatedUsersPipe, SecondtotimePipe, TimetodatePipe, AddcontactPipe, JobsPipe]
})
export class SharedPipes {}
