import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { UserService } from '../user.service';

@Injectable({
	providedIn: 'root'
})
export class SearchService {
	public classes: any = [];
	public selected_classes: any = [];
	public exist=false;
	public loading=false;

	public search_classes:any = [];
	constructor(private http: HttpService,
				private us: UserService) {
		this.fetch();
	}
	fetch(){
		if(!this.us.data.search){
			return setTimeout(()=>{
				this.fetch();
			}, 300);
		}

		this.classes=Object.values(this.us.data.search);
	}
	search(classes, event){
		this.loading=true;
		this.http.afterWhile(this, ()=>{
			if(!event){
				this.search_classes=[];
				this.loading=false;
				return;
			}
			this.http.apipost('/search/query',{
				"aliases": classes.map(item => {
					return item.alias;
				}),
				"query": event,
				"limit": 20
			}, resp=>{
				for(let cl in resp){
					if(resp[cl].result.length){
						this.exist=true;
						break;
					}
					else this.exist=false;
				}
				this.search_classes=Object.values(resp);
				this.loading=false;
			}, 500);
		});
		
	}
}