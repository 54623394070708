import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { AlertService } from './alert.service';
import { environment } from '@env';

@Injectable({
	providedIn: 'root'
})
export class CategoriesService {
	public uri = environment.api;
	public categories:any = {
		tasks: [],
		equipments: []
	};
	public kinds = ['tasks', 'equipments'];
	public kind = this.kinds[0];
	public now = new Date().getTime();
	refresh(){
		this.now = new Date().getTime();
	}
	constructor(private us: UserService,
		private https: HttpService,
		private alert: AlertService,
		private http: HttpClient) {
	}
	
	create(categorie, which, cb=created=>{}, url='tasks'){
		if(this.kinds.indexOf(which)==-1) return;
		for(let i = 0; i < this.categories[which].length; i++){
			if(this.categories[which][i].title.toLowerCase() == categorie.title.toLowerCase()){
				return this.alert.fail('Categorie '+categorie.title+' already exists');
			}
		}
		this.https.apipost('/'+url+'/tags/create', categorie, created=>{
			this.categories[which].push(created);
			this.refresh();
			cb(created);
		});
		
	}
	getTags(which) {
		this.https.apiget('/' + which + '/tags/get', (resp)=> {
			this.categories[which] = resp;
		});
	}
	update(categorie, which){}
	delete(categorieId, which = 'tasks'){
		this.https.apipost('/' + which + '/tags/delete', {id: [categorieId]});
	}
}