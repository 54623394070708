import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { UserService, EqServicesService, BaseService } from '@services';

@Component({
	selector: 'app-editservice',
	templateUrl: './editservice.component.html',
	styleUrls: ['./editservice.component.scss']
})
export class EditServiceComponent implements OnInit {
	public search = '';
	public service: any = {};
	public update_service:any = {};
	public now = new Date().getTime();
	refresh(){
		this.now = new Date().getTime();
	}
	constructor(private dialogRef: MatDialogRef<EditServiceComponent>,
		public eqs: EqServicesService,
		public us: UserService,
		public bs: BaseService,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		if (this.data.service) {
			this.service = this.data.service;
		}
	}
	ngOnInit() {}
	close() {
		this.dialogRef.close();
	}
	save() {
		if(!this.service.id) {
			this.eqs.create(this.service, ()=> {
				this.dialogRef.close(true);
			});
		} else {
			this.eqs.save(this.update_service, ()=> {
				this.dialogRef.close(true);
			});
		}
	}
}
