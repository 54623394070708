import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { StepsComponent } from './steps/steps.component';
import { UserService, DraftService } from '@services';
import { FavoriteComponent } from '../favorite/favorite.component';
import { Overlay } from '@angular/cdk/overlay';
import { ContactComponent, TaskComponent } from '@common';
import { Router } from '@angular/router';


@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: []
})
export class AppHeaderComponent {
	constructor(private dialog: MatDialog, 
		public ds: DraftService,
		private router: Router,
		private overlay: Overlay,
		public us: UserService) {}
	openFavorite() {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		this.dialog.open(FavoriteComponent, dialogConfig);
	}
	openDialog() {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		this.dialog.open(StepsComponent, dialogConfig);
	}

	public config: PerfectScrollbarConfigInterface = {};
	// This is for Notifications
	notifications: Object[] = [
	{
		round: 'round-danger',
		icon: 'ti-link',
		title: 'Launch Admin',
		subject: 'Just see the my new admin!',
		time: '9:30 AM'
	}, {
		round: 'round-success',
		icon: 'ti-calendar',
		title: 'Event today',
		subject: 'Just a reminder that you have event',
		time: '9:10 AM'
	}, {
		round: 'round-info',
		icon: 'ti-settings',
		title: 'Settings',
		subject: 'You can customize this template as you want',
		time: '9:08 AM'
	}, {
		round: 'round-primary',
		icon: 'ti-user',
		title: 'Pavan kumar',
		subject: 'Just see the my admin!',
		time: '9:00 AM'
	}
	];
	// This is for Mymessages
	mymessages: Object[] = [
	{
		useravatar: 'assets/images/users/1.jpg',
		status: 'online',
		from: 'Pavan kumar',
		subject: 'Just see the my admin!',
		time: '9:30 AM'
	}, {
		useravatar: 'assets/images/users/2.jpg',
		status: 'busy',
		from: 'Sonu Nigam',
		subject: 'I have sung a song! See you at',
		time: '9:10 AM'
	}, {
		useravatar: 'assets/images/users/2.jpg',
		status: 'away',
		from: 'Arijit Sinh',
		subject: 'I am a singer!',
		time: '9:08 AM'
	}, {
		useravatar: 'assets/images/users/4.jpg',
		status: 'offline',
		from: 'Pavan kumar',
		subject: 'Just see the my admin!',
		time: '9:00 AM'
	}
	];

	create() {
		const scrollStrategy = this.overlay.scrollStrategies.reposition();
		const dialogConfig = new MatDialogConfig();
		//dialogConfig.disableClose = true;
		dialogConfig.autoFocus = false;
		dialogConfig.scrollStrategy = scrollStrategy;
		setTimeout(() => {
			const dialogRef = this.dialog.open(ContactComponent, dialogConfig);
			dialogRef.afterClosed().subscribe((newClient) => {
				if(newClient) {
					this.router.navigate(['/'+newClient.id]);
				}
				//if(client) this.fetchClientData();
			});
		});
	}

	createTask() {
		const scrollStrategy = this.overlay.scrollStrategies.reposition();
		const dialogConfig = new MatDialogConfig();
		dialogConfig.autoFocus = false;
		dialogConfig.scrollStrategy = scrollStrategy;
		setTimeout(() => {
			const dialogRef = this.dialog.open(TaskComponent, dialogConfig);
			dialogRef.afterClosed().subscribe(isTaskAdded => {
			});
		});
	}
}