import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { BehaviorSubject, Observable } from 'rxjs';

/*
POST /api/constructor/save
GET /api/constructor/fetch/<slug>
GET /api/constructor/get
*/


@Injectable({
	providedIn: 'root'
})
export class ConstructorService {
	/*
	*	Columns Management
	*/
		verify_columns(columns, unique){
			for(let i = columns.length-1; i >= 0; i--){
				if(unique[columns[i]]){
					unique[columns[i]] = false;
				}else{
					columns.splice(i, 1);
				}
			}
			for(let each in unique){
				if(unique[each]){
					columns.push(each);
				}
			}
		}
		private timeout = {};
		update_columns(opts) {
			if(!this.timeout[opts.slug]) this.timeout[opts.slug]={};
			this.http.afterWhile(this.timeout[opts.slug], ()=>{
				this.http.apipost('/constructor/save', opts, resp=>{});
			});
		}
		set_columns(module, page, columns, checkboxes){
			this.http.on('constructors', ()=>{
				let unique = {};
				for(let i = 0; i < columns.length; i++){
					unique[columns[i]] = true;
				}
				let cols = localStorage.getItem(module+page+'columns');
				if(cols){
					columns.splice(0, columns.length);
					Array.prototype.push.apply(columns, JSON.parse(cols));
				}
				this.verify_columns(columns, Object.assign({}, unique));
				let boxes:any = localStorage.getItem(module+page+'checkboxes');
				if(boxes){
					boxes = JSON.parse(boxes);
					for(let each in boxes){
						checkboxes[each] = boxes[each];
					}
				}
				this.get(module + page, (cnstrctr:any)=>{
					if(Array.isArray(cnstrctr.displayedColumns) && cnstrctr.displayedColumns.length){
						columns.splice(0, columns.length);
						Array.prototype.push.apply(columns, cnstrctr.displayedColumns);
						this.verify_columns(columns, Object.assign({}, unique));
					}
					if(typeof cnstrctr.checkboxColumns == 'object' && cnstrctr.checkboxColumns){
						for(let each in cnstrctr.checkboxColumns){
							checkboxes[each] = cnstrctr.checkboxColumns[each];
						}
					}
				});
			});
			return (newColumns=false)=>{
				if(newColumns){
					columns.splice(0, columns.length);
					Array.prototype.push.apply(columns, newColumns);
				}
				let options = columns.indexOf('options');
				if(options>-1){
					columns.splice(options, 1);
					columns.push('options');
				}
				localStorage.setItem(module+page+'columns', JSON.stringify(columns));
				localStorage.setItem(module+page+'checkboxes', JSON.stringify(checkboxes));
				this.update_columns({
					module: module,
					page: page,
					slug: module + page,
					displayedColumns: columns,
					checkboxColumns: checkboxes,
					user_id: true
				});
			}
		}
	/*
	*	constructors list
	*/
		public constructors = {};
		public cnstrctr: any = {};
		public collapseLeft = false;
		public collapseRight = false;
		// availible sizes group
		private loaded = false;
		get(slug, cb){
			if(!this.loaded) return setTimeout(()=>{
				this.get(slug, cb);
			}, 200);
			if(this.constructors[slug]) cb(this.constructors[slug]);
		}
		constructor(private http: HttpService) {
			this.http.get(this.http.url + '/constructor/get', constructors => {
				this.constructors = constructors;
				if (this.constructors[this.cnstrctr.slug]) {
					for (let each in this.constructors[this.cnstrctr.slug]) {
						this.cnstrctr[each] = this.constructors[this.cnstrctr.slug][each];
					}
				}
				if (constructors.ClientsList) {
					localStorage.setItem('clientlistcolumns', JSON.stringify(constructors.ClientsList.displayedColumns));
					localStorage.setItem('clientlistcheckboxes', JSON.stringify(constructors.ClientsList.checkboxColumns));
				}
				if (constructors.LeadsList) {
					localStorage.setItem('leadslistcolumns', JSON.stringify(constructors.LeadsList.displayedColumns));
					localStorage.setItem('leadslistcheckboxes', JSON.stringify(constructors.LeadsList.checkboxColumns));
				}
				if (constructors.EstimateList) {
					localStorage.setItem('estimatelistcolumns', JSON.stringify(constructors.EstimateList.displayedColumns));
					localStorage.setItem('estimatelistcheckboxes', JSON.stringify(constructors.EstimateList.checkboxColumns));
				}
				if (constructors.workorderlist) {
					localStorage.setItem('workorderlistcolumns', JSON.stringify(constructors.workorderlist.displayedColumns));
					localStorage.setItem('estimatelistcheckboxes', JSON.stringify(constructors.workorderlist.checkboxColumns));
				}
				if (constructors.invoicelist) {
					localStorage.setItem('invoicelistcolumns', JSON.stringify(constructors.invoicelist.displayedColumns));
					localStorage.setItem('invoicelistcheckboxes', JSON.stringify(constructors.invoicelist.checkboxColumns));
				}
				if (constructors.OrderMenu) {
					localStorage.setItem('Menu', JSON.stringify(constructors.OrderMenu.order));
				}
				if (constructors.selected_statuses) {
					localStorage.setItem('selected_statuses', JSON.stringify(constructors.selected_statuses.schema));
				}
				this.http.set('constructors');
				this.loaded = true;
			});
		}
		public sizes = {
			mobile: {
				default: [
					{size: 100}
				]
			},
			tablet: {
				default: [
					{size: 100},
					{size: 66.66},
					{size: 60},
					{size: 50},
					{size: 40},
					{size: 33.33}
				],
				Services: [
					{size: 100},
					{size: 66.66},
					{size: 60},
					{size: 50},
					{size: 40},
					{size: 33.33}
				]
			},
			desktop: {
				default: [
					{size: 100},
					{size: 70},
					{size: 66.66},
					{size: 60},
					{size: 50},
					{size: 40},
					{size: 33.33},
					{size: 30}
				],
				Services: [
					{size: 100},
					{size: 66.66},
					{size: 60},
					{size: 50},
					{size: 40},
					{size: 33.33}
				]
			}
		};
		private default:any = {
			mobile:[
				{cols: 12, rows: 4, y: 0, x: 0, id: "Tasks list", minItemCols: 3, minItemRows: 3, hidden: true},
	                  {cols: 12, rows: 13, y: 0, x: 0, id: "Widget 2", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 12, rows: 19, y: 13, x: 0, id: "Widget 3", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 12, rows: 8, y: 32, x: 0, id: "Widget 4", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 12, rows: 8, y: 40, x: 0, id: "Widget 5", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 12, rows: 8, y: 48, x: 0, id: "Widget 6", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 12, rows: 8, y: 56, x: 0, id: "Widget 7", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 12, rows: 19, y: 64, x: 0, id: "Widget 8", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 12, rows: 19, y: 87, x: 0, id: "Widget 9", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 12, rows: 4, y: 106, x: 0, id: "Widget 10", minItemCols: 3, minItemRows: 3, hidden: true},
	                  {cols: 12, rows: 4, y: 110, x: 0, id: "Widget 11", minItemCols: 3, minItemRows: 3, hidden: true},
	                  {cols: 12, rows: 4, y: 114, x: 0, id: "Widget 12", minItemCols: 3, minItemRows: 3, hidden: true}
	      		],
			tablet:[
	                  {cols: 6, hidden: false, id: "Widget 2", minItemCols: 3, minItemRows: 3, rows: 13, x: 0, y: 0},
	                  {cols: 6, rows: 16, y: 0, x: 6, id: "Widget 3", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 3, rows: 8, y: 16, x: 0, id: "Widget 4", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 3, rows: 8, y: 16, x: 3, id: "Widget 5", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 6, rows: 16, y: 16, x: 6, id: "Widget 8", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 3, rows: 8, y: 24, x: 0, id: "Widget 6", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 3, rows: 8, y: 24, x: 3, id: "Widget 7", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 12, rows: 30, y: 32, x: 0, id: "Widget 9", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 12, rows: 4, y: 172, x: 0, id: "Widget 12", minItemCols: 3, minItemRows: 3, hidden: true},
	                  {cols: 12, rows: 4, y: 176, x: 0, id: "Widget 11", minItemCols: 3, minItemRows: 3, hidden: true},
	                  {cols: 12, rows: 4, y: 180, x: 0, id: "Tasks list", minItemCols: 3, minItemRows: 3, hidden: true},
	                  {cols: 12, rows: 4, y: 184, x: 0, id: "Widget 10", minItemCols: 3, minItemRows: 3, hidden: true}
	            ],
	      		desktop:[
	                  {cols: 6, hidden: false, id: "Widget 2", minItemCols: 3, minItemRows: 3, rows: 13, x: 0, y: 0},
	                  {cols: 6, rows: 16, y: 0, x: 6, id: "Widget 3", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 3, rows: 8, y: 16, x: 0, id: "Widget 4", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 3, rows: 8, y: 16, x: 3, id: "Widget 5", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 6, rows: 16, y: 16, x: 6, id: "Widget 8", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 3, rows: 8, y: 24, x: 0, id: "Widget 6", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 3, rows: 8, y: 24, x: 3, id: "Widget 7", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 12, rows: 30, y: 32, x: 0, id: "Widget 9", minItemCols: 3, minItemRows: 3, hidden: false},
	                  {cols: 12, rows: 4, y: 172, x: 0, id: "Widget 12", minItemCols: 3, minItemRows: 3, hidden: true},
	                  {cols: 12, rows: 4, y: 176, x: 0, id: "Widget 11", minItemCols: 3, minItemRows: 3, hidden: true},
	                  {cols: 12, rows: 4, y: 180, x: 0, id: "Tasks list", minItemCols: 3, minItemRows: 3, hidden: true},
	                  {cols: 12, rows: 4, y: 184, x: 0, id: "Widget 10", minItemCols: 3, minItemRows: 3, hidden: true}
			],
		};
		reset(){
			console.log('reset');
		}
	/*
	*	List
	*/
		set(def) {
			let cnstrctr = localStorage.getItem('widget_contstructor' + def.slug)
				&& JSON.parse(localStorage.getItem('widget_contstructor' + def.slug)) || def;
			if (this.constructors[def.slug]) {
				cnstrctr = this.constructors[def.slug];
			}
			for (let each in cnstrctr) {
				this.cnstrctr[each] = cnstrctr[each];
			}
		}
		update() {
			this.http.afterWhile(this, ()=>{
				localStorage.setItem('widget_contstructor' + this.cnstrctr.slug, JSON.stringify(this.cnstrctr));
				this.http.post(this.http.url + '/constructor/save', this.cnstrctr);
			});
		}
		save(field) {
			this.http.apipost('/constructor/save', field);
		}
	/*
	*	End Of
	*/
}
