import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuoteConfirmComponent } from './quote-confirm.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { SignaturePadModule } from 'angular2-signaturepad';
import { AttachmentModule } from '../../Attachment/attachment/attachment.module';
import { CustomMaterialModule } from '@root/modules/template';

@NgModule({
	entryComponents: [QuoteConfirmComponent],
	declarations: [QuoteConfirmComponent],
	exports: [QuoteConfirmComponent],
	imports: [
		FormsModule,
		CommonModule,
		FlexLayoutModule,
		SignaturePadModule,
		AttachmentModule,
		CustomMaterialModule
	]
})
export class QuoteConfirmModule { }
