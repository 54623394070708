import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { masks } from './phone.masks.list';

@Pipe({name: 'phoneMaskByCode'})
export class PhoneMaskByCodePipe implements PipeTransform {
	constructor(private cdr:ChangeDetectorRef){}
	applied:Array<any> = [];
	caching:boolean = false;
	cachedValue:string='';
	transform(value: string, phone: string): string {
		// return '(000) 000-0000';
		if(!this.cachedValue) this.cachedValue = '+'+value;
		if(!phone){
			return this.cachedValue;
		}
		this.applied = masks.filter((mask)=>{
			if(phone) return phone.startsWith(mask.code+'');
		});
		if(this.applied.length) {
			this.cachedValue = '+'+this.applied[0].mask;
			return this.cachedValue;
		} else {
			return this.cachedValue;
		}
	}
}