import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewComponent } from './preview.component';
import { DialogComponent } from './dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomMaterialModule } from '@root/modules/template';


@NgModule({
	exports: [PreviewComponent, DialogComponent],
	declarations: [PreviewComponent, DialogComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		CustomMaterialModule
	],
  	entryComponents: [DialogComponent]
})
export class PreviewModule { }
