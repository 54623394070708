import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { UserService, UiService, EqRepairService, BaseService, AlertService } from '@services';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
	public form: any;
	constructor(form: any){
		this.form = form;
	}
	isErrorState(control:any): boolean {
		return !control.value && this.form.formSubmitted;
	}
}
@Component({
	selector: 'app-editrepair',
	templateUrl: './editrepair.component.html',
	styleUrls: ['./editrepair.component.scss']
})
export class EditRepairComponent {
	public repair: any = {};
	public update_repair: any = {};
	public searchUser = '';
	public hours_spent; 
	public min_spent;
	public dropdownSetwtings = {
		singleSelection: false,
		idField: 'id',
		enableCheckAll: false,
		textField: 'title',
		maxHeight: 500,
		itemsShowLimit: 1,
		allowSearchFilter: false
	};
	public matcher:any;
	constructor(private dialogRef: MatDialogRef<EditRepairComponent>,
		public ui: UiService,
		public us: UserService,
		public eqr: EqRepairService,
		public bs: BaseService,
		public alert: AlertService,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		if(this.data.repair) {
			this.repair = this.data.repair;
			if(this.repair.time) {
				this.hours_spent = Math.floor(this.repair.time / 60);
				this.min_spent = this.repair.time % 60;
			}
		}
		this.matcher = new MyErrorStateMatcher(this.repair);
	}
	cancel() {
		this.dialogRef.close();
	}
	save() {
		this.repair.formSubmitted = true;
		if(!this.repair.type_id) return this.alert.fail('Add Type');
		if(!this.repair.priority) return this.alert.fail('Add Priority');

		if(!this.repair.id) {
			this.repair.equipment_id = this.repair.equipment.id;
			this.repair.status_id = 1; //shoud be removed
			this.eqr.create(this.repair, ()=> {
				this.eqr.load({
					page: this.eqr.page,
					perPage: this.eqr.perPage,
					sort: this.eqr.sort,
					sortby: this.eqr.sortby
				});
			});
			this.dialogRef.close();
		} else if(this.repair.id&&!this.data.complete){
			this.update_repair.id = this.repair.id;
			this.eqr.save(this.update_repair, (resp)=> {
				if(this.update_repair.status_id) {
					this.eqr.updateStatus({
						id: this.repair.id,
						status_id: this.update_repair.status_id
					}, ()=> {
						this.eqr.load({
							page: this.eqr.page,
							perPage: this.eqr.perPage,
							sort: this.eqr.sort,
							sortby: this.eqr.sortby
						});
					});
				} else {
					this.eqr.load({
						page: this.eqr.page,
						perPage: this.eqr.perPage,
						sort: this.eqr.sort,
						sortby: this.eqr.sortby
					});
				}
			});
			this.dialogRef.close();
		} else if (this.repair.id&&this.data.complete) {
			this.update_repair.id = this.repair.id;
			this.update_repair.end_note = this.repair.end_note; //shoud be removed
			this.eqr.finish(this.update_repair, ()=> {
				this.eqr.load({
					page: this.eqr.page,
					perPage: this.eqr.perPage,
					sort: this.eqr.sort,
					sortby: this.eqr.sortby
				});
			});
			this.dialogRef.close();
		}
	}
	timespent() {
		if(this.hours_spent || this.min_spent) {
			if(!this.hours_spent) this.hours_spent = 0;
			if(!this.min_spent) this.min_spent = 0;
			if(this.hours_spent > 900) this.hours_spent = 900;
			this.repair.time = (this.hours_spent * 60) + this.min_spent;
			this.update_repair.time = (this.hours_spent * 60) + this.min_spent;
		}
	}
}
