import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UserService } from './user.service';
import { SocketService } from './socket.service';
import { AlertService } from './alert.service';
import { TaskService } from './task.service';
@Injectable({
	providedIn: 'root'
})
export class TrackerService {
	public times = [];
	public task_times: any = {};
	public days = [];
	public time;
	public track:any = Number( localStorage.getItem('TrackerSeconds') || 0 );
	public get;
	public clock = false;
	public lat: any;
	public lng: any;
	public note;
	public now = new Date().getTime();
	public current:any = Number( localStorage.getItem('TrackerCurrent') || 0 );
	constructor(private http: HttpService, public us: UserService, public socket: SocketService, private alert: AlertService, public ta: TaskService) { }
	/*
	* Pagination
	*/
	public page: number = 1;
	public perPages = [10, 25, 50, 100];
	public perPage:any = 10;
	private perPageLast = this.perPage;
	paginate(event){
		this.page = event.pageIndex+1;
		this.perPage = event.pageSize;
		if(this.perPageLast!=this.perPage){
			this.perPageLast=this.perPage;
			this.page = 1;
		}
		localStorage.setItem('rfqPerPage', this.perPage);
		this.load({
			page: this.page,
			perPage: this.perPage,
			sort: this.sort,
			sortby: this.sortby
		});
	}
	public total: number = 0;
	public search = '';
	public sortby = localStorage.getItem('timesortby') || 'date';
	public sort = localStorage.getItem('timeSort') || 'desc';
	public filters: any = {};
	public filtersApplied: any = {};
	setsortby(sortby){
		this.sortby = sortby.active;
		this.sort = sortby.direction;
		localStorage.setItem('timesortby', this.sortby);
		localStorage.setItem('timeSort', this.sort);
		this.load({
			page: this.page,
			perPage: this.perPage,
			sort: this.sort,
			sortby: this.sortby
		});
	}
	load(opts:any={}, cb=(resp:any)=>{}){
		this.filtersApplied = Object.assign({}, this.filters);
		let params:any = {};
		if(opts.page) params.page = opts.page;
		if(opts.perPage) params.per_page = opts.perPage;
		if(opts.sort) params.sort = opts.sort;
		if(opts.sortby) params.sortby = opts.sortby;
		if(this.search) params.search = this.search;
		/*if(this.filters) {
			for(const key in this.filters) {
				if(!this.filters[key]) continue;
				if(key=='date_from'||key=='date_to'||key=='deadline_from'||key=='deadline_to') {
					params['filters['+key+']'] = this.filters[key].format('YYYY-MM-DD');
				} else {
					params['filters['+key+']'] = this.filters[key];
				}
				
			}
		}*/
		this.http.c_get('time', (resp:any) => {
			this.days = resp.data;
			this.total = resp.total;
			cb(this.days);
		}, params);
	}
	stop(data, cb=resp=>{}) {
		this.http.apipost('/time/stop', data, cb);
	}
	start(data, cb=resp=>{}) {
		this.http.apipost('/time/start', data, cb);
	}
	update(task, cb=resp=>{}) {
		this.http.apipost('/time/update', task, cb);
	}
	fetch(day, cb=resp=>{}) {
		this.http.apipost('/time/fetch', day, cb);
	}
	getTaskTimes(data, cb=resp=>{}) {
		this.http.apiget('/time/times?assigned_id='+data.assigned_id + '&assigned_type=' + data.assigned_type + '&sortby=' + data.sortby + '&sort=' + data.sort, cb);
	}
	getCurrent(cb=resp=>{}) {
		this.http.apiget('/time/current', cb);
	}
	updateTrack(data, cb=resp=>{}) {
		this.getLocation(() => {
			if(data.assigned) {
				this.update({
					assigned_type: data.type,
					assigned_id: data.assigned,
					lat: this.lat,
					lng: this.lng
				}, (resp)=> {
					this.time = resp;
					this.load({
						page: this.page,
						perPage: this.perPage,
						sort: this.sort,
						sortby: this.sortby
					});
					if(this.time.assigned.human_total_minutes) {
						this.current = this.time.assigned.human_total_minutes * 60;
					}
					
					localStorage.setItem('TrackerCurrent', this.current);
					this.socket.emit('time_refresh', this.current);
				});
			} else {
				this.update({
					note: data.note, 
					lat: this.lat,
					lng: this.lng
				}, (resp)=> {
					this.time = resp;
					this.load({
						page: this.page,
						perPage: this.perPage,
						sort: this.sort,
						sortby: this.sortby
					});
					if(resp.assigned) {
						this.getTaskTimes({
							assigned_id: resp.assigned_id, 
							assigned_type: resp.assigned_type, 
							sortby: 'start_time', 
							sort: 'desc'
						}, (time)=> {
				           this.task_times[resp.assigned.id] = time;
				        });
					}
					this.socket.emit('time_refresh', 'note');
				});
			}
		});
	}
	startTrack(data?) {
		localStorage.setItem('TrackerSeconds', null);
		localStorage.setItem('TrackerCurrent', null);
		this.track = 0;
		this.current = 0;
		clearInterval(this.get);
		this.refresh();
		this.getLocation(() => {

			if(!data) {
				this.start({
					lat: this.lat,
					lng: this.lng
				}, (current)=> {
					this.time = current;
					this.load({
						page: this.page,
						perPage: this.perPage,
						sort: this.sort,
						sortby: this.sortby
					});
					this.getTime();
					this.socket.emit('time_refresh', 'no');
				});
				
			} else if(data){
				this.start({
					lat: this.lat,
					lng: this.lng,
					assigned_type: data.type,
					assigned_id: data.id
				}, (current)=> {
					this.load({
						page: this.page,
						perPage: this.perPage,
						sort: this.sort,
						sortby: this.sortby
					});
					this.getTaskTimes({
						assigned_id: data.id, 
						assigned_type: data.type, 
						sortby: 'start_time', 
						sort: 'desc'
					}, (resp)=> {
			           this.task_times[data.id] = resp;
			        });
					this.time = current;
					if(this.time.assigned) {
						this.current = this.time.assigned.human_total_minutes * 60;
					}
					localStorage.setItem('TrackerCurrent', this.current);
					this.getTime();
					this.socket.emit('time_refresh', current.assigned);
				});
				
			}
		});
	}
	stopTrack() {
		clearInterval(this.get);
		localStorage.setItem('TrackerSeconds', null);
		localStorage.setItem('TrackerCurrent', null);
		this.current = 0;
		this.track = null;
		this.refresh();
		this.getLocation(() => {
			this.stop({
				lat: this.lat,
				lng: this.lng
			}, (resp)=> {
				this.load({
					page: this.page,
					perPage: this.perPage,
					sort: this.sort,
					sortby: this.sortby
				});
				if(resp.assigned) {
		        	this.getTaskTimes({
						assigned_id: resp.assigned_id, 
						assigned_type: resp.assigned_type, 
						sortby: 'start_time', 
						sort: 'desc'
					}, (times)=> {
			           this.task_times[resp.assigned.id] = times;
			        });
				}
				
				this.socket.emit('time_refresh', this.time);
			});
		});
	}
	getTime() {
		if(!this.track) this.track = 0;
		this.get = setInterval(()=> {
			if(!this.clock && !this.time.assigned_id && this.track >= 300) {
					this.clock = true;
			}
			this.track++;
			this.current++;
			localStorage.setItem('TrackerSeconds', this.track);
			localStorage.setItem('TrackerCurrent', this.current);
			this.refresh();
		}, 1000);
		
	}
	getLocation(cb: any= resp => {}) {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((pos) => {
				this.lat = pos.coords.latitude;
				this.lng = pos.coords.longitude;
				cb();
			}, (err) => {
				if(err) {
					this.lat = undefined;
					this.lng = undefined;
				}
				cb();
			});
		}
		
	}
	refresh(){
		this.now = new Date().getTime();
	}
}
