import {
	ChangeDetectorRef,
	Component,
	NgZone,
	OnDestroy,
	ViewChild,
	HostListener,
	Directive,
	AfterViewInit
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { UserService, MenuService, UiService } from '@services';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FavoriteComponent } from '../favorite/favorite.component';
@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: []
})
export class AppSidebarComponent implements OnDestroy {
	public config: PerfectScrollbarConfigInterface = {};
	mobileQuery: MediaQueryList;

	private _mobileQueryListener: () => void;
	constructor(
		private dialog: MatDialog,
		public ms: MenuService,
		changeDetectorRef: ChangeDetectorRef,
		media: MediaMatcher,
		public us: UserService,
		public ui: UiService
	){
		this.mobileQuery = media.matchMedia('(min-width: 959px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
	}
	openFavorite() {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		this.dialog.open(FavoriteComponent, dialogConfig);
	}

	ngOnDestroy(): void {
		this.mobileQuery.removeListener(this._mobileQueryListener);
	}
}
