import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './map.component';
import { MatIconModule } from '@angular/material';
import { MatButtonModule } from '@angular/material';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';

import { AgmCoreModule } from '@agm/core';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [MapComponent],
	exports: [MapComponent],
	imports: [
	    MatGoogleMapsAutocompleteModule,
	    NgMultiSelectDropDownModule,
		MatIconModule,
		MatButtonModule,
		AgmCoreModule,
		CommonModule,
		FormsModule
	]
})
export class MapModule {}
