import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { AlertService } from '../alert.service';
import { StatusService } from '../estimate/status.service';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class InvoiceService {
	constructor(private http: HttpService,
		private alert: AlertService,
		private sts: StatusService) {}
	/*
	*	General use
	*/
		public now = new Date().getTime();
		refresh(){
			this.now = new Date().getTime();
		}
		initialize(invoice:any){
			let now = new Date().getTime();
			if(invoice.status) invoice.status_id = invoice.status.id;
			if(!invoice.due_date){
				invoice.due_date = new Date(new Date(invoice.created_at).getTime()+2628000000);
			}
			invoice.days_past_due = 0;
			let due = invoice.due_date.getTime();
			if(due < now){
				invoice.days_past_due = Math.floor( (now-due) / 86400000);
			}
		}
	/*
	*	Show invoices
	*/
		public invoices:any = [];
		public refill: any;
		public search = '';
		public perPages = [10, 25, 50];
		public perPage:any = localStorage.getItem('invoicePerPage') || 50;
		public page = 1;
		public total: any;
		public sortBy = localStorage.getItem('invoiceSortBy') || 'id';
		public sort = localStorage.getItem('invoiceSort') || 'asc';
		setSortBy(sortBy){
			this.sortBy = sortBy.active;
			this.sort = sortBy.direction;
			localStorage.setItem('invoiceSortBy', this.sortBy);
			localStorage.setItem('invoiceSort', this.sort);
			this.load();
		}
		paginate(event){
			this.page = event.pageIndex+1;
			this.load();
		}
		public make_filters = false;
		public filters: any = {};
		public filtersApplied: any = {};
		public types = [];
		public tags = [];
		load(){
			this.http.afterWhile(this, ()=>{
				for(let key in this.filters){
					if(!this.filters[key]) delete this.filters[key];
				}
				this.filtersApplied = Object.assign({}, this.filters);
				let opts = {
					search: this.search,
					perPage: this.perPage,
					page: this.page,
					sortBy: this.sortBy,
					sort: this.sort
				}
				for(let each in this.filters){
					if(each=='date_from'||each=='date_to'||each=='expire_date_from'||each=='expire_date_to'){
						opts['filters['+each+']']=moment(this.filters[each]).format('YYYY-MM-DD');
					}else opts['filters['+each+']']=this.filters[each];
				}
				if(this.sts.quotes_statuses.length){
					for(let i = 0; i < this.sts.quotes_statuses.length; i++){
						opts['filters[status_id]['+i+']'] = this.sts.quotes_statuses[i].id;
					}
				}else{
					opts['filters[status_id][0]'] = 1;
					opts['filters[status_id][1]'] = 2;
				}
				if(this.types.length && this.types.length!=3){
					for(let i = 0; i < this.types.length; i++){
						opts['filters[type]['+i+']'] = this.types[i].title;
					}
				}
				if(this.tags.length){
					for(let i = 0; i < this.tags.length; i++){
						opts['filters_ids[tags]['+i+']'] = this.tags[i].id;
					}
				}
				this.http.c_get('invoices', resp => {
					for(let i = 0; i < resp.data.length; i++){
						this.initialize(resp.data[i]);
					}
					this.invoices = resp.data;
					this.total = resp.total;
					if(typeof this.refill == 'function') this.refill();
				}, opts);
			}, 250);
		}
		loadAfterWhile(){
			this.http.afterWhile(this, this.load.bind(this), 2000);
		}
	/*
	*	CRUD
	*/
		public invoice:any = { estimate: {} };
		update_status(invoice=this.invoice, cb?:any){
			this.http.c_update('invoices', {
				invoice_status_id: invoice.status_id,
				reason_id: invoice.reason_id,
				id: invoice.id
			}, cb);
		}
		fetch(id, cb=resp=>{}){
			this.http.c_fetch('invoices', {
				id: id
			}, cb);
		}
		fetch_by_project(id, cb=resp=>{}){
			this.http.c_fetch('invoices', {
				project_id: id
			}, cb);
		}
		private timeout = {};
		update(invoice, cb?:any, ignore={}){
			if(!invoice) return;
			let timeout = '';
			for(let each in invoice){
				timeout += each;
			}
			if(!invoice.id) invoice.id = this.invoice.id;
			clearTimeout(this.timeout[timeout]);
			this.timeout[timeout] = setTimeout(()=>{
				this.http.c_update('invoices', invoice, cb, ignore);
			}, 2000);
		}
		delete(id = this.invoice.id, cb=()=>{}){
			this.alert.yes('Are you sure that you want to delete this invoice?', ()=>{
				this.http.c_delete('invoices', {
					id: id
				}, ()=>{
					this.load();
					cb();
				});
			});
		}
		clean_files(fileId){
			if(!this.invoice.files) return;
			for(let i = this.invoice.files.length-1; i >= 0; i--){
				for(let j = this.invoice.estimate.services.length-1; j >= 0; j--){
					for(let k = this.invoice.estimate.services[j].files.length-1; k >= 0; k--){
						if(this.invoice.estimate.services[j].files[k].id == fileId){
							this.invoice.estimate.services[j].files.splice(i, 1);
							break;
						}
					}
				}
				if(this.invoice.files[i].id == fileId){
					this.invoice.files.splice(i, 1);
					break;
				}
			}
		}
		update_services(invoice=this.invoice, cb?:any){
			this.http.afterWhile(this, ()=>{

			}, 2000);
		}
	/*
	*	End of
	*/
}
