import { SocketService } from './socket.service';
import { AlertService } from './alert.service';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class RolesService {
  public permissions = [];
  public _permissions = {};
  public permByModules = [];
  public roles = [];
  public _roles = {};
  public us: any;
  ids(role) {
    role.ids = {};
    if (!role.permissions_ids) { role.permissions_ids = []; }
    for (let j = 0; j < role.permissions_ids.length; j++) {
      role.ids[role.permissions_ids[j]] = true;
    }
  }
  constructor(private http: HttpService,
    private socket: SocketService,
    private alert: AlertService) {
    this.http.c_get('users/permissions', resp => {
      this.permissions = resp;
      const unique = {};
      for (let i = 0; i < resp.length; i++) {
        this._permissions[resp[i].id] = resp[i];
        if (!unique[resp[i].module]) {
          unique[resp[i].module] = {
            name: resp[i].module
          };
          this.permByModules.push(unique[resp[i].module]);
        }
        unique[resp[i].module][resp[i].action] = resp[i];
      }
      this.http.set('permissions');
    }, {
      page: 1
    });
    this.http.c_get('users/roles', resp => {
      for (let i = 0; i < resp.length; i++) {
        this._roles[resp[i].id] = resp[i];
        this.ids(resp[i]);
      }
      this.roles = resp;
      this.http.set('roles');
    }, {
      page: 1
    });
    socket.on('roles_create', roleId => {
      const created = {
        id: roleId
      };
      this.fetch(created, () => {
        this.ids(created);
        this.roles.push(created);
        this.us.set_perm();
      });
    });
    socket.on('roles_update', roleId => {
      for (let i = 0; i < this.roles.length; i ++) {
        if (this.roles[i].id == roleId) {
          return this.fetch(this.roles[i], () => {
            this.us.set_perm();
          });
        }
      }
    });
    socket.on('roles_delete', roleId => {
      this.remove({
        id: roleId
      });
      this.us.set_perm();
    });
  }
  create(doc) {
    this.http.c_create('users/roles', doc, newDoc => {
      this.ids(newDoc);
      this.socket.emit('roles_create', newDoc.id);
      this.roles.push(newDoc);
    });
  }
  fetch(doc, cb = resp => {}) {
    this.http.c_fetch('users/roles', doc, resp => {
      doc.perms = {};
      doc.permissions_ids = [];
      for (let i = 0; i < doc.permissions.length; i++) {
        doc.permissions_ids.push(doc.permissions[i].id);
        doc.perms[doc.permissions[i].id] = true;
      }
      cb(resp);
    });
  }
  update(doc: any, toggle= false) {
    this.http.afterWhile(doc, () => {
      const permissions = [];
      for (const each in doc.ids) {
        if (doc.ids[each]) {
          permissions.push(each);
        }
      }
      this.http.c_update('users/roles', {
        description: doc.description,
        permissions: permissions,
        name: doc.name,
        id: doc.id
      }, resp => {
        this.socket.emit('roles_update', doc.id);
      });
    });
  }
  remove(doc) {
    for (let i = 0; i < this.roles.length; i++) {
      if (this.roles[i].id == doc.id) {
        return this.roles.splice(i, 1);
      }
    }
  }
  delete(doc) {
    this.alert.yes('Are you sure that you want to delete?', () => {
      if (Array.isArray(doc)) {
        const length = doc.length;
        for (let i = 0; i < doc.length; i++) {
          this.remove(doc[i]);
          this.http.c_delete('users/roles', doc[i], resp => {
            this.socket.emit('roles_delete', doc[i].id);
          });
        }
      } else {
        this.remove(doc);
        this.http.c_delete('users/roles', doc, resp => {
          this.socket.emit('roles_delete', doc.id);
        });
      }
    });
  }
}
