import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedPipes } from '@pipes';
import { ActivitiesComponent } from './activities.component';
import { ActivityModule } from '../../activity/activity.module';
import { DatepickerModule } from '../../Datepicker/datepicker/datepicker.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { CustomMaterialModule } from '@root/modules/template';
@NgModule({
	exports: [ActivitiesComponent],
	declarations: [ActivitiesComponent],
	imports: [
		CustomMaterialModule,
		DatepickerModule,
		ActivityModule,
		CommonModule,
		SharedPipes,
		NgMultiSelectDropDownModule,
		FlexLayoutModule,
		FormsModule,
		ReactiveFormsModule,
		SatDatepickerModule,
		SatNativeDateModule,

	]
})

export class ActivitiesModule { }