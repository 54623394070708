import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
	selector: 'status',
	inputs: ['statuses', 'status', 'reason_id'],
	templateUrl: './status.component.html',
	styleUrls: ['./status.component.scss']
})

export class StatusComponent implements OnInit {
	@Input('type') type:any;
	@Output('update') update = new EventEmitter();
	@Output('update_reason') update_reason = new EventEmitter();
	public status:any = {};
	public reason_id;
	public statuses:any = [];
	public reasons:any = [];
	public searchStatuses = '';
	constructor(){}
	ngOnInit(){
		for(let i = 0; i < this.statuses.length; i++){
			if(this.statuses[i].declined){
				this.reasons = this.statuses[i].reasons;
				break;
			}
		}
	}
}
