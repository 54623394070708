import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
// import { environment } from '@env';

@Injectable({
	providedIn: 'root'
})
export class RoutingService {
	private apiUrl = environment.api;
	public initialized = true;
	public settings:any = {};
	constructor(private injector: Injector,
		private base: BaseService,
		private http: HttpClient/*, private router: Router*/) {
		//console.log(this.apiUrl);
	}
	loadRoutes(): Promise<any> {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				const router = this.injector.get(Router);
				this.getSettings().subscribe( response => {
					this.initialized = true;
					this.settings=response.settings;
					//console.log(response);
					return resolve(true);
					//if (!environment.production) { return resolve(true); }
					router.resetConfig(response);
					resolve(true);
				}, err => {
					this.initialized = false;
					//reject(false);
					resolve(true);
				});
			});
		});
	 }
	public getSettings(): Observable<any> {
		const url = this.apiUrl + '/run';
		return this.http.post(url, {}).pipe(map((res: any) => {
			if(!res.is_auth && localStorage.getItem('currentUser')) {
				localStorage.removeItem('currentUser');
				window.location.href = '/login';
			}
			this.base.set(res);

			return res;
		}));
	}
}
