import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxTinymceModule } from 'ngx-tinymce';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};
import { RoutingService, TokenInterceptor, fakeBackendProvider } from '@services';
export function initRoutes(routing: RoutingService) { return () => routing.loadRoutes(); }
import { AppComponent } from './app.component';
import { AppRoutingModule } from './routing';
import { SpinnerComponent } from './shared/spinner.component';

// Remove below when modules got separated
import { CustomMaterialModule } from './modules/template';
import { SharedModule } from './shared/shared.module';
//import * as $ from 'jquery';
import { FullComponent } from './layouts/full/full.component';
import { AlertComponent } from './common/Alert/alert/alert.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { StepsComponent } from './layouts/full/header/steps/steps.component';
import { TimeComponent } from './layouts/full/header/time/time.component';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CropComponent } from './services/crop/crop.component';
import { TranslateModule } from '@ngx-translate/core';
import { DragulaModule } from 'ng2-dragula';
import { FavoriteComponent } from './layouts/full/favorite/favorite.component';
import { DynamicMasksModule, ContactModule, TaskModule } from '@common';
import { NgxMaskModule } from 'ngx-mask';
import { SharedPipes } from '@pipes';
import "hammerjs";
import {MAT_SNACK_BAR_DATA} from '@angular/material';

import { ModalsModule } from '@modals';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
const config: SocketIoConfig = {
  url: 'wss://test.arbostar.com:8896',
  options: {}
};

@NgModule({
	declarations: [
		AppComponent,
		SpinnerComponent,
		FullComponent,
		AppHeaderComponent,
		AppBlankComponent,
		AppSidebarComponent,
		StepsComponent,
		TimeComponent,
		CropComponent,
		FavoriteComponent,
		AlertComponent
	],
	entryComponents: [StepsComponent, TimeComponent, CropComponent, FavoriteComponent, AlertComponent],
	imports: [
		DragulaModule.forRoot(),
		SocketIoModule.forRoot(config),
		TranslateModule.forRoot(),
		ModalsModule,
		DynamicMasksModule,
		NgxMaskModule.forRoot(),
		ContactModule,
		SharedPipes,
		TaskModule,
		ImageCropperModule,
		BrowserModule,
		FormsModule,
    	ReactiveFormsModule,
		AgmCoreModule.forRoot({
			apiKey:'AIzaSyBdC4r950fi36-lE0x9jr2LrQ2-53Q9wXQ',
			language: 'en',
			libraries: ['places', 'map', 'geometry']
		}),
		NgMultiSelectDropDownModule.forRoot(),
		NgxTinymceModule.forRoot({
	      baseURL: '//cdnjs.cloudflare.com/ajax/libs/tinymce/4.9.0/'
	    }),
		PerfectScrollbarModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FlexLayoutModule,
		AppRoutingModule,
		MatGoogleMapsAutocompleteModule.forRoot(),
		CustomMaterialModule,
		SharedModule
	],
	providers: [{
		provide: PERFECT_SCROLLBAR_CONFIG,
		useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
	}, fakeBackendProvider, {
		'provide': APP_INITIALIZER,
		'useFactory': initRoutes,
		'deps': [RoutingService],
		'multi': true,
	}, {
		provide: HTTP_INTERCEPTORS,
		useClass: TokenInterceptor,
		multi: true
	}, { 
		provide: MAT_SNACK_BAR_DATA, 
		useValue: {} 
	},
	RoutingService
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
