import { Component, OnInit, Inject, ViewChild, ElementRef, Output, Input, EventEmitter } from '@angular/core';

@Component({
	selector: 'address-display',
	template: `<span>{{showAddress()}}</span>`,
	styleUrls: []
})
export class AddressDisplayComponent implements OnInit {
	@Input('address_keys') addressKeys:Array<string>;
	@Input('address') address:any;
	@Input ('hidePlaceholder') hidePlaceholder = false;
	@Input('address_separator') separator:string;
	addressStr:string = '';
	ngOnInit() {
		
	}

	showAddress() {
		this.addressStr = '';
		this.addressKeys.forEach((key,index)=>{
			if(this.address[key]){
				this.addressStr += this.address[key];
				if(index<this.addressKeys.length-1){
					this.addressStr += this.separator+" ";
				}
			}
		});
		if(!this.addressStr) this.addressStr = 'No Location Added';
		return this.addressStr;
	}
}