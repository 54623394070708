import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from '@env';
import { UserService } from './user.service';

export interface SubChildren {
	state: string;
	name: string;
	type?: string;
}
export interface ChildrenItems {
	state: string;
	name: string;
	type?: string;
	child?: SubChildren[];
}

export interface Menu {
	state: string;
	name: string;
	type: string;
	icon: string;
	children?: ChildrenItems[];
}

@Injectable({
	providedIn: 'root'
})
export class MenuService {
	public url = environment.api;
	private default = [
		{
			slug: 'viewclients',
			url: '/dashboard',
			name: 'Dashboard',
			icon: 'explore'
		}, {
			slug: 'viewclients',
			name: 'Contacts',
			icon: 'people',
			children: [
				{ url: '/contacts/list', name: 'Contacts list', slug: 'viewclients'},
				{ url: '/contacts/templates', name: 'Templates', slug: 'viewclients'}
			]
		}, {
			slug: 'viewusers',
			name: 'Leads',
			icon: 'ring_volume',
			children: [
				{ url: '/leads/list', name: 'Leads list', slug: 'createestimates'},
				{ url: '/leads/map', name: 'Leads map', slug: 'createestimates'},
			]
		}, {
			slug: 'viewestimates',
			name: 'Quotes',
			icon: 'assignment',
			children: [
				{ url: '/new/quote/create', name: 'Create quote', slug: 'createestimates' },
				{ url: '/quotes/list', name: 'Quotes list', slug: 'createestimates' },
				{ url: '/services/list', name: 'Services', slug: 'createestimates' },
				{
					name: 'Offers',
					children: [
						{ url: '/quotes/offers/specialists', name: 'Specialists', slug: 'createestimates' },
						{ url: '/quotes/offers/equipment', name: 'Equipment', slug: 'createestimates' },
						{ url: '/quotes/offers/products', name: 'Products', slug: 'createestimates' },
					]
				}
			]
		}, {
			slug: 'viewestimates',
			name: 'Workorders',
			icon: 'work',
			children: [
				{ url: '/workorders/list', name: 'Workorders list', slug: 'createestimates'},
				{ url: '/workorders/map', name: 'Workorders map', slug: 'createestimates'}
			]
		}, {
			slug: 'viewestimates',
			name: 'Invoices',
			icon: 'payment',
			url: '/invoices/list'
		}, {
			slug: 'viewcompanysettings',
			name: 'Equipment',
			icon: 'airport_shuttle',
			children: [
				{ url: '/equipments/list', name: 'Equipments list', slug: 'createestimates' },
				{ url: '/equipment/requests', name: 'Requests', slug: 'createestimates' },
				{ url: '/equipment/reminders', name: 'Reminders', slug: 'createestimates' },
				{ url: '/equipment/reports', name: 'Reports', slug: 'createestimates' }
			]
		}, {
			slug: 'viewtasks',
			name: 'Tasks',
			icon: 'assignment_turned_in',
			children: [
				{ url: '/tasks/list', name: 'Tasks list', slug: 'createestimates' },
				{ url: '/tasks/map', name: 'Tasks map', slug: 'createestimates' }
			]
		}, {
			slug: 'viewestimates',
			name: 'Calendar',
			icon: 'event',
			url: '/calendar'
		}, {
			slug: 'viewestimates',
			name: 'Mail',
			icon: 'mail',
			url: '/mail'
		}, {
			slug: 'viewusers',
			url: '/users',
			name: 'Users',
			icon: 'face'
		}, {
			slug: 'viewsettings',
			name: 'System',
			icon: 'build',
			children: [{
				slug: 'viewsettings',
				url: '/system/modules',
				name: 'Modules'
			}, {
				slug: 'viewmodulessettings',
				url: '/system/settings',
				name: 'Preferences',
				icon: 'perm_data_setting'
			}, {
				slug: 'viewsettings',
				url: '/order/settings',
				name: 'Menu Order',
				icon: 'perm_data_setting'
			}, {
				slug: 'viewtranslate',
				url: '/language/settings',
				name: 'Translate',
				icon: 'language'
			}, {
				slug: 'viewtranslate',
				url: '/status/management',
				name: 'Status Management'
			}, {
				slug: 'viewroles',
				url: '/roles/settings',
				name: 'Roles Managment'
			}]
		}
	];
	public menus = localStorage.getItem('Menu') && JSON.parse(localStorage.getItem('Menu')) || JSON.parse(JSON.stringify(this.default));
	constructor(private http: HttpService, private us: UserService) {
		this.http.on('constructors', ()=>{
			const menuOrder = JSON.parse(localStorage.getItem('Menu'));
			if (menuOrder) {
				this.menus = menuOrder;
			}
			/*this.menus = this.default;
			this.update();*/
		});
	}
	reset(){
		this.menus = JSON.parse(JSON.stringify(this.default));
		this.update();
	}
	public update(): void {
		localStorage.setItem('Menu', JSON.stringify(this.menus));
		const menuOrder = {
			module: 'Users',
			page: 'Order',
			slug: 'OrderMenu',
			order: this.menus
		};
		this.http.apipost('/constructor/save', menuOrder, (res: any) => {});
	}
}
