import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ClientService, LeadService, EstimateService, HttpService } from '@services';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalService } from '../../modals/modal.service';



@Component({
	inputs: ['client', 'estimate_id'],
	selector: 'client-info',
	templateUrl: './client-info.component.html',
	styleUrls: ['./client-info.component.scss']
})
export class ClientInfoCommonComponent implements OnInit {
	@Output('update') update = new EventEmitter();
	@Output('d') d = new EventEmitter();
	public client:any;
	public client_editing:any;
	//public no_phones=true;
	//public no_emails=true;
	constructor(private modal: ModalService,
		public cs:ClientService,
		public http: HttpService,
		public es: EstimateService,
		private ls: LeadService){}
	@ViewChild('address', { static: false }) address:any;
	ngOnInit(){
		if(this.client) {
			this.client.full_address = this.http.setFullAddress(this.client);
			this.client_editing = JSON.parse(JSON.stringify(this.client));
		}
		this.checkEmpty();
	}
	checkEmpty(){
		if(!this.client||!this.client.id){
			return setTimeout(()=>{
				this.checkEmpty();
			}, 500);
		}
		/*this.no_emails=true;
		this.no_phones=true;
		let contacts=this.cs.use_fetch(this.client.client_id).contact_information;
		if(contacts.length==0){
			this.no_emails=true;
			this.no_phones=true;
			return;
		}
		for (let i=contacts.length-1; i>=0; i--) {
		    if(contacts[i].emails.length>0) this.no_emails=false;
		    if(contacts[i].numbers.length>0) this.no_phones=false;
		}*/
		//console.log(this.client);
		this.client.full_address = this.http.setFullAddress(this.client);
		this.client_editing = JSON.parse(JSON.stringify(this.client));
	}
	updateEmail(){
		this.ls.save({
			"extra": {
				"id": this.client.estimate_number||this.client.workorder_number||this.client.invoice_number,
				"contact_email_id": this.client.project.contact_email_id
			}
		});
	}
	updatePhone(){
		this.ls.save({
			"extra": {
				"id": this.client.estimate_number||this.client.workorder_number||this.client.invoice_number,
				"contact_number_id": this.client.project.contact_number_id
			}
		});
	}
	populateAddress(){
		this.address.clearAddress();
		this.address.updateAddress(this.client_editing);
	}
	onAddressChanged(e){
		let location={ extra: { id: this.client_editing.estimate_number||this.client_editing.workorder_number||this.client_editing.invoice_number }};
		for(let f in e){
			location[f]=e[f];
			this.client_editing[f]=e[f];
		}
	}

	updateAddress(){
		let location={ extra: { id: this.client_editing.estimate_number||this.client_editing.workorder_number||this.client_editing.invoice_number }};
		this.merge_address(this.client_editing, location);
		this.merge_address(this.client_editing, this.client);
		this.client.full_address = this.http.setFullAddress(this.client_editing);
		this.ls.save(location);
		this.d.emit();
	}

	updateFullAddress(){
		let location={ extra: { id: this.client.estimate_number||this.client.workorder_number||this.client.invoice_number }};
		this.merge_address(this.client, location);
		this.merge_address(this.client, this.client_editing);
		this.client.full_address = this.http.setFullAddress(this.client);
		this.ls.save(location);
		this.d.emit();
	}

	merge_address(from, to){
		this.http.clean_address(to);
		this.http.to_address(to, from);
	}

	fill_contact(addEmail=false){
		this.modal.edit_contact(this.client.client, newClient=>{
			if(newClient) {
				let emails = [];
				let not_empty=false;
				let not_new=false;
				for(let i = 0; i < newClient.contact_information.length; i++){
					for(let j = 0; j <newClient.contact_information[i].emails.length; j++){
						not_empty=true;
						emails.unshift(newClient.contact_information[i].emails[j]);
					}
				}

				for(let i = 0; i < this.client.client.contact_information.length; i++){
					for(let j = 0; j <this.client.client.contact_information[i].emails.length; j++){
						for(let k = 0; k <emails.length; k++){
							if(this.client.project.contact_email_id==emails[k].id){
								continue;
								not_new=true;
							} 
							if(this.client.client.contact_information[i].emails[j].id==emails[k].id) emails.splice(k, 1);
						}

					}
				}
				if(!not_empty){
					this.client.project.contact_email = {};
					this.client.project.contact_email_id = '';
					this.updateEmail();
				}
				if(emails.length&&not_empty){
					console.log(emails[0]);
					this.client.project.contact_email = emails[0];
					this.client.project.contact_email_id = emails[0].id;
					this.updateEmail();
				}
			}
			if(newClient) {
				let numbers = [];
				let not_empty=false;
				let not_new=false;
				for(let i = 0; i < newClient.contact_information.length; i++){
					for(let j = 0; j <newClient.contact_information[i].numbers.length; j++){
						not_empty=true;
						numbers.unshift(newClient.contact_information[i].numbers[j]);
					}
				}

				for(let i = 0; i < this.client.client.contact_information.length; i++){
					for(let j = 0; j <this.client.client.contact_information[i].numbers.length; j++){
						for(let k = 0; k <numbers.length; k++){
							if(this.client.project.contact_number_id==numbers[k].id){
								continue;
								not_new=true;
							} 
							if(this.client.client.contact_information[i].numbers[j].id==numbers[k].id) numbers.splice(k, 1);
						}

					}
				}
				if(!not_empty){
					this.client.project.contact_number = {};
					this.client.project.contact_number_id = '';
					this.updatePhone();
				}
				if(numbers.length&&not_empty){
					console.log(numbers[0]);
					this.client.project.contact_number = numbers[0];
					this.client.project.contact_number_id = numbers[0].id;
					this.updatePhone();
				}
			}
			if(newClient) {
				this.client.client =  Object.assign({},newClient);
				this.cs.client= Object.assign({},newClient);
			}
		});
	}
}