import { Component, ElementRef, NgZone, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { } from 'googlemaps';
import { MapsAPILoader } from '@agm/core';

@Component({
	inputs: ['lat', 'lng'],
	selector: 'pick_location',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
	@Output('update') update = new EventEmitter();
	public lat;
	public lng;
	public map:any = {
		lat: 43.653908,
		lng: -79.384293
	};
	public marker:any = {
		lat: 43.653908,
		lng: -79.384293
	};
	public zoom = 12;
	public searchControl: FormControl;
	@ViewChild("search", { static: true}) searchElementRef: ElementRef;
	constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {}
	ngOnInit() {
		//create search FormControl
		this.searchControl = new FormControl();
		//set current position
		this.setCurrentPosition();
		//load Places Autocomplete
		this.mapsAPILoader.load().then(() => {
			let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
				types: ["address"]
			});
			autocomplete.addListener("place_changed", () => {
				this.ngZone.run(() => {
					//get the place result
					let place: google.maps.places.PlaceResult = autocomplete.getPlace();
					//verify result
					if (place.geometry === undefined || place.geometry === null) {
						return;
					}
					//set latitude, longitude and zoom
					this.map = this.marker = {
						lat: place.geometry.location.lat(),
						lng: place.geometry.location.lng()
					};
					this.zoom = 12;
					this.update.emit(this.map);
				});
			});
		});
	}
	private setCurrentPosition() {
		if(this.lat && this.lng){
			this.map = this.marker = {
				lat: this.lat,
				lng: this.lng
			};
		}else if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				this.map = this.marker = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};
			});
		}
	}
	placeMarker($event){
		this.marker = $event.coords;
		this.update.emit($event.coords);
	}
}