import { Component, ViewChild } from '@angular/core';
import { ServicesService,
	EstimateService,
	FieldsService,
	AlertService,
	ProductsService,
	UiService,
	HttpService } from '@services';

@Component({
	inputs: ['estimate', 'update', 'direct', 'from'],
	selector: 'services',
	templateUrl: './services.component.html',
	styleUrls: ['./services.component.scss']
})
export class ServicesComponent {
	@ViewChild('customFields', { static: false }) customFields: any;
	public from = 'estimate';
	public direct = false;
	public search = '';
	public update;
	public estimate;
	scrollte(i){
		if(this.es.scrollable) this.es.scrollable.scrollToElement('#s'+i, 0, 500)
	}
	add_service(service){
		let top = this.ui.get_scroll('profile');
		service = JSON.parse(JSON.stringify(service));
		service.fixed_price = service.price;
		service.status = 1;
		delete service.price;
		service.offers_service_id = service.id;
		delete service.id;
		for(let i = 0; i < service.fields.length; i++){
			delete service.fields[i].value;
		}
		this.estimate.services.push(service);
		this.ss.calculate(service);
		this.save();
		setTimeout(()=>{
			this.scrollte(this.estimate.services.length);
			//this.ui.set_scroll('profile', top);
		});
	}
	new_service(){
		let top = this.ui.get_scroll('profile');
		this.estimate.services.push({
			status: 1
		});
		this.save();
		setTimeout(()=>{
			this.scrollte(this.estimate.services.length);
			//this.ui.set_scroll('profile', top);
		});
	}
	init(){
		if(!this.estimate){
			return setTimeout(this.init.bind(this), 100);
		}
		if(!this.estimate.services) return;
		for(let i = 0; i < this.estimate.services.length; i++){
			this.fs.set(this.estimate.services[i].fields);
		}
	}
	constructor(public ss: ServicesService,
		public ps: ProductsService,
		public ui: UiService,
		public http: HttpService,
		public alert: AlertService,
		public fs: FieldsService,
		public es: EstimateService){
		this.init();
	}
	save(){
		console.log(this.estimate);
		this.es.calculate(this.estimate);
		if(typeof this.update == 'function'){
			this.update();
		}else{
			this.es.update_services(this.estimate);
		}
	}
	remove(id){
		this.alert.yes('Are you sure you want to delete this service?', () => {
			for(let i = 0; i < this.estimate.services.length; i++){
				if(this.estimate.services[i].id == id){
					this.estimate.services.splice(i, 1);
					break;
				}
			}
			this.es.calculate(this.estimate);
			if(typeof this.update == 'function'){
				this.update();
			}else{
				this.http.apipost('/estimates/services/delete', {
					id: id
				}, this.es.update_estimate.bind(this.es));
			}
		});
	}
}
