import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { StatusService } from '../estimate/status.service';
import { AlertService } from '../alert.service';
import { UiService } from '../ui.service';
import { UserService } from '../user.service';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class WorkorderService {
	constructor(private alert: AlertService,
		private http: HttpService,
		private ui: UiService,
		private us: UserService,
		private sts: StatusService) {}
	/*
	*	Show workorders
	*/
		public workorders:any = [];
		public refill: any;
		public search = '';
		public page = 1;
		public total: any;

		public sortBy = localStorage.getItem('workorderSortBy') || 'id';
		public sort = localStorage.getItem('workorderSort') || 'asc';
		setSortBy(sortBy){
			this.sortBy = sortBy.active;
			this.sort = sortBy.direction;
			localStorage.setItem('workorderSortBy', this.sortBy);
			localStorage.setItem('workorderSort', this.sort);
			this.load();
		}
		paginate(event){
			this.page = event.pageIndex+1;
			this.ui.setPerPage(event.pageSize);
			this.load();
		}
		public tags = [];
		public make_filters = false;
		public filters: any = {};
		public filtersApplied: any = {};
		public types = [];
		load(options:any={}, cb: any=()=>{}){
			this.http.afterWhile(this, ()=>{
				for(let key in this.filters){
					if(!this.filters[key]) delete this.filters[key];
				}
				this.filtersApplied = Object.assign({}, this.filters);
				let opts:any = {
					search: this.search,
					perPage: options.perPage || this.ui.perPage,
					page: this.page
				}
				if(this.sort && this.sortBy){
					opts.sortBy = this.sortBy;
					opts.sort = this.sort;
				}
				for(let each in this.filters){
					if(each=='date_from'||each=='date_to'){
						opts['filters['+each+']']=moment(this.filters[each]).format('YYYY-MM-DD');
					}else opts['filters['+each+']']=this.filters[each];
				}
				if(this.sts.workorders_statuses.length){
					for(let i = 0; i < this.sts.workorders_statuses.length; i++){
						opts['filters[status_id]['+i+']'] = this.sts.workorders_statuses[i].id;
					}
				}else{
					opts['filters[status_id][0]'] = 1;
					opts['filters[status_id][1]'] = 2;
				}
				if(this.tags.length){
					for(let i = 0; i < this.tags.length; i++){
						opts['filters_ids[tags]['+i+']'] = this.tags[i].id;
					}
				}
				if(this.types.length && this.types.length!=3){
					for(let i = 0; i < this.types.length; i++){
						opts['filters[type]['+i+']'] = this.types[i].title;
					}
				}
				this.http.c_get('workorders', resp => {
					this.http.on('users', ()=>{
						for(let i = 0; i < resp.data.length; i++){
							if(this.us._users[resp.data[i].user_id]){
								resp.data[i].user = JSON.parse(JSON.stringify(this.us._users[resp.data[i].user_id]));
							}
							for(let each in options.replace){
								resp.data[i][each] = options.replace[each](resp.data[i])
							}
						}
						this.workorders = resp.data;
						this.total = resp.total;
						if(typeof this.refill == 'function') this.refill();
						cb(this.workorders);
					});
				}, opts);
			}, 250);
		}

		loadAfterWhile(){
			this.http.afterWhile(this, this.load.bind(this), 2000);
		}
	/*
	*	General use
	*/
		public now = new Date().getTime();
		refresh(){
			this.now = new Date().getTime();
		}
	/*
	*	CRUD
	*/
		public workorder:any = {estimate:{}};
		update_status(workorder=this.workorder, cb?:any){
			this.http.c_update('workorders', {
				workorder_status_id: workorder.status_id,
				id: workorder.id
			}, cb);
		}
		update_priority(workorder=this.workorder, cb?:any){
			this.http.c_update('workorders', {
				priority: workorder.priority,
				id: workorder.id
			}, cb);
		}
		private timeout = {};
		update(workorder, cb?:any, ignore={}){
			if(!workorder) return;
			let timeout = '';
			for(let each in workorder){
				timeout += each;
			}
			if(!workorder.id) workorder.id = this.workorder.id;
			clearTimeout(this.timeout[timeout]);
			this.timeout[timeout] = setTimeout(()=>{
				this.http.c_update('workorders', workorder, cb, ignore);
			}, 2000);
		}
		fetch(id, cb=resp=>{}){
			this.http.c_fetch('workorders', {
				id: id
			}, cb);
		}
		fetch_by_project(id, cb=resp=>{}){
			this.http.c_fetch('workorders', {
				project_id: id
			}, cb);
		}
		delete(id = this.workorder.id, cb = ()=>{}){
			this.alert.yes('Are you sure that you want to delete this workorder? This action will remove invoice if such exists.', () => {
				this.http.c_delete('workorders', {
					id: id
				}, ()=>{
					cb();
					this.load();
				});
			});
		}
		clean_files(fileId){
			if(!this.workorder.files) return;
			for(let i = this.workorder.files.length-1; i >= 0; i--){
				for(let j = this.workorder.services.length-1; j >= 0; j--){
					for(let k = this.workorder.services[j].files.length-1; k >= 0; k--){
						if(this.workorder.services[j].files[k].id == fileId){
							this.workorder.services[j].files.splice(i, 1);
							break;
						}
					}
				}
				if(this.workorder.files[i].id == fileId){
					this.workorder.files.splice(i, 1);
					break;
				}
			}
		}
	/*
	*	End Of WO
	*/
}
