import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldsComponent } from './fields.component';
import { DirectComponent } from './direct/direct.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { SelectComponent } from './select/select.component';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DatepickerModule } from '../../Datepicker/datepicker/datepicker.module';
import { SharedPipes } from '@pipes';
import { CustomMaterialModule } from '@root/modules/template';

@NgModule({
	exports: [FieldsComponent],
	declarations: [FieldsComponent, DirectComponent, ConfirmComponent, SelectComponent],
	imports: [
		CommonModule,
		FormsModule,
		DatepickerModule,
		FlexLayoutModule,
		SharedPipes,
		CustomMaterialModule
	]
})
export class FieldsModule { }