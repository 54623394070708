import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondtotime'
})
export class SecondtotimePipe implements PipeTransform {

  transform(value: any, refresh?, kind= 2): any {
  	var formatted;
	  let hours   = Math.floor(value / 3600);
    let minutes = Math.floor((value - (hours * 3600)) / 60); 
    let seconds = value - (hours * 3600) - (minutes * 60); 
	if(kind == 1) {
		formatted =  ("0"+hours).slice(-2)+":"+("0"+minutes).slice(-2);
	} else if (kind == 2) {
		formatted =  ("0"+hours).slice(-2)+":"+("0"+minutes).slice(-2)+":"+("0"+seconds).slice(-2);
	}
    return formatted;
  }
}
